import { styled, TextareaAutosize } from "@mui/material";

export const FormTextAreaWrapper: any = styled(TextareaAutosize)(({ theme }) => ({
  padding: "8.5px 32px 8.5px 14px",
  borderRadius: "8px",
  border: `1px solid #5F6A7E3D`,/*TODO replace color code by ${theme.palette.text.gray3d} after fixing the theme file*/
  [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},
  [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {}
}));
