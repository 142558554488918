import { styled } from "@mui/material";

const PageBpdy = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-grow: 1;
  flex-basis: 0;
`;

export default PageBpdy;
