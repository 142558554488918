import { styled } from '@mui/material';

const ProjectSettingsFooterWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: '0',
  right: '0',
  backgroundColor: theme.palette.common.white,
  width: 'calc(100% - 25%)',
  boxShadow: `0px -1px 24px ${theme.palette.shadow.main}`,
  zIndex: '1050',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  minHeight: '65px',
  paddingInline: '50px',

  [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},
  [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {},
}));
export default ProjectSettingsFooterWrapper;
