import React from "react";

const ActionIcon = () => (
  <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 31" width="21" height="31">
    <title>iconAction</title>
    <defs>
      <image width="21" height="31" id="img2"
             href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAfCAMAAAA/UnbCAAAAAXNSR0IB2cksfwAAAFFQTFRFAAAAAHj/AHz/AHz/AHz/AHr/AHr/AHv/AHv/AHz/AHv/AHz/AID/AHv/AHz/AHz/AH3/AHz/AHz/AHz/AHz/AHv/AHv/AHv/AHv/AHv/AHv//IGY8wAAABt0Uk5TACDvQP8wYKDwULCQEI+/fz/QgMBvn1/gz3DfktO7HwAAAORJREFUeJx1kuuWgyAMhAOEi0BZjW1d+v4PuoDoBtvODw98hpzJAMAuIeGDFF6JPqliEE2n1rFagxMIDxCc5i2m/aQZYFG8pR++F/M8CwgLOdPWsVGHiB5WgkB9/a9Cid6G+ELNJ1pEKd314xEZ8hOQowXDmp7P84cotRbMUibftt+xr1oLjRHrqHE+PDQKUKmSKPUbLcCn8skZtAexdZohOuRmKzWvmvCVwmaa3wutxYN2ZyXZQU60BK7FTdFlvg39+qb9TJc+0rHyvN7MPFp5a+UqDW9CrfhKSaLlqdd2iuh4Z38auwlhqqaZ/gAAAABJRU5ErkJggg==" />
    </defs>
    <style>
    </style>
    <use id="Background" href="#img2" x="0" y="0" />
  </svg>
);
export default ActionIcon;
