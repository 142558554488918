import React from 'react';

const ShowIcon = function (props: any): object {
  const { color = '#007cff' } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
      <g id="Groupe_11285" data-name="Groupe 11285" transform="translate(-139 -479.07)">
        <rect
          id="Rectangle_2538"
          data-name="Rectangle 2538"
          width="38"
          height="38"
          rx="14"
          transform="translate(139 517.07) rotate(-90)"
          fill="#e8eaed"
          opacity="0.236"
        />
        <g
          id="Groupe_11274"
          data-name="Groupe 11274"
          transform="translate(-477.483 396.41)"
        >
          <path
            id="Tracé_11953"
            data-name="Tracé 11953"
            d="M643.724,101.505a8.951,8.951,0,0,0-.866-1.6c-.026-.039-.053-.078-.081-.117l-.045-.064-.029-.04-.078-.106a9.033,9.033,0,0,0-1.656-1.686l0,0a8.872,8.872,0,0,0-4.612-1.848l-.187-.017-.1-.007-.19-.01-.089,0-.055,0-.077,0h-.349l-.077,0-.056,0-.089,0-.19.01-.1.007-.187.017A8.868,8.868,0,0,0,630,97.892l0,0a9.033,9.033,0,0,0-1.565,1.567h0l-.073.1c-.012.014-.022.029-.033.044l-.047.063-.075.1v0l-.094.134a8.974,8.974,0,0,0-.866,1.6q-.032.077-.063.155.027.068.056.137l.034.081.025.059.039.089.017.038q.026.058.053.115l.061.127q.051.1.1.206l.005.01a8.947,8.947,0,0,0,4.6,4.164l0,0a8.828,8.828,0,0,0,2.5.6l.176.014.091.006c.1.006.2.01.3.013h.486c.1,0,.2-.007.3-.013l.091-.006.176-.014a8.829,8.829,0,0,0,2.5-.6l0,0a8.868,8.868,0,0,0,1.28-.637,8.973,8.973,0,0,0,3.43-3.748l.007-.015c.035-.074.071-.148.1-.222l.017-.038.039-.089.025-.059c.012-.027.023-.054.034-.081.019-.045.037-.091.056-.137C643.766,101.608,643.745,101.557,643.724,101.505ZM639,103.046a3.779,3.779,0,0,1-3.32,2.382l-.051,0-.073,0h-.137l-.073,0-.051,0a3.769,3.769,0,0,1-3.583-3.768,3.484,3.484,0,0,1,.047-.574c0-.012,0-.024.006-.037a3.77,3.77,0,0,1,3.538-3.158c.062,0,.123,0,.185,0s.121,0,.182,0a3.777,3.777,0,0,1,3.539,3.158c0,.012,0,.024.006.037a3.538,3.538,0,0,1,.047.574A3.766,3.766,0,0,1,639,103.046Z"
            transform="translate(0 0)"
            fill={color}
          />
          <circle
            id="Ellipse_1440"
            data-name="Ellipse 1440"
            cx="2.585"
            cy="2.585"
            r="2.585"
            transform="translate(632.901 99.075)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};
export default ShowIcon;
