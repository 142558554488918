import { styled } from "@mui/material";

const CustomCloseButton = styled('button')`
  display: inline-flex;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export default CustomCloseButton;
