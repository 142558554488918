import React, { useState } from 'react';
import { Avatar, Stack, Typography } from '@mui/material';
import { BugSvg, DeleteWL } from 'assets/svgs/componentsIcons';
import EditIconProfile from 'assets/svgs/componentsIcons/EditIconProfile';
import {
  EXTERNAL_ROLE,
  USER_TYPE,
} from 'containers/ProjectAdministrationUserManagement/constants';
import { USER_ROLE, YES_NO_OPTIONS } from 'containers/UserDetailedView/constants';
import { SETTINGS_USERS_ADD_EXTERNAL } from 'routes/constants';
import { useNavigate } from 'react-router-dom';
import { setOperation } from 'services/slices/user-management/user-management-option';
import UserManagementOption from 'containers/ProjectAdministrationUserManagement/UserManagementItems/UserManagementItem/UserManagementOption';
import { OPERATIONS } from 'containers/ProjectAdministrationUserManagement/UserManagementItems/UserManagementItem/UserManagementOption/constants';
import { useDispatch } from 'react-redux';
import { PLATFORM_SETTINGS_TYPES } from 'utils/constants/specificRoles';
import PropTypes from 'prop-types';

function UserInformation({ userAvatar, userDetail, hideDelete = false }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isEditUser, setIsEditUser] = useState(false);
  const handleEditUser = () => {
    if (userDetail?.external) {
      navigate(`${SETTINGS_USERS_ADD_EXTERNAL}/${userDetail?.id}`);
    } else {
      dispatch(setOperation(OPERATIONS.EDIT));
      setIsEditUser(true);
    }
  };
  const handleFreezeAccess = () => {
    dispatch(setOperation(OPERATIONS.FREEZE));
    setIsEditUser(true);
  };
  return (
    <>
      <Stack className="sub-header mt-25">
        {isEditUser && (
          <UserManagementOption
            openElem={userDetail?.id}
            isFromUserDetail
            userDetail={userDetail}
          />
        )}
        <Stack className="avatar-container">
          <Typography variant="subtitle2_HelveticaNeue_Regular" color="text.main">
            User avatar
          </Typography>
          <Avatar alt={userDetail?.firstName} className="avatar-style" src={userAvatar} />
        </Stack>
        <Stack className="optionsBtn">
          <button
            type="button"
            className={`deleteBtn ${hideDelete ? 'hide' : ''}`}
            onClick={() => {}}
          >
            <DeleteWL />
          </button>
          <button type="button" className="userBtn" onClick={handleFreezeAccess}>
            <BugSvg />
          </button>
          <button type="button" className="userBtn" onClick={handleEditUser}>
            <EditIconProfile />
          </button>
        </Stack>
      </Stack>
      <Stack className="mt-25">
        <Stack className="userDetailsLine mb-20">
          <Stack>
            <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.lighter">
              First Name
            </Typography>
            <Typography variant="subtitle1_HelveticaNeue_Medium" color="primary.main">
              {userDetail?.firstName || '-'}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.lighter">
              Last Name
            </Typography>
            <Typography variant="subtitle1_HelveticaNeue_Medium" color="primary.main">
              {userDetail?.lastName || '-'}
            </Typography>
          </Stack>
        </Stack>
        <Stack className="userDetailsLine mb-20">
          <Stack>
            <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.lighter">
              Email
            </Typography>
            <Typography variant="subtitle1_HelveticaNeue_Medium" color="primary.main">
              {userDetail?.mail || '-'}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.lighter">
              Phone
            </Typography>
            <Typography variant="subtitle1_HelveticaNeue_Medium" color="primary.main">
              {userDetail?.mobilePhone || '-'}
            </Typography>
          </Stack>
        </Stack>
        <Stack className="userDetailsLine mb-20">
          <Stack>
            <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.lighter">
              Specific Role
            </Typography>
            <Typography variant="subtitle1_HelveticaNeue_Medium" color="primary.main">
              {userDetail?.specificRole?.label || '-'}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.lighter">
              Bu/Sector/Program/Discipline
            </Typography>
            <Typography variant="subtitle1_HelveticaNeue_Medium" color="primary.main">
              {userDetail?.[
                PLATFORM_SETTINGS_TYPES[userDetail?.specificRole?.code]?.labelColumn
              ] || '-'}
            </Typography>
          </Stack>
        </Stack>
        <Stack className="userDetailsLine mb-20">
          <Stack>
            <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.lighter">
              User type
            </Typography>
            <Typography variant="subtitle1_HelveticaNeue_Medium" color="primary.main">
              {userDetail?.external ? USER_TYPE.EXTERNAL.label : USER_TYPE.INTERNAL.label}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.lighter">
              User role
            </Typography>
            <Typography variant="subtitle1_HelveticaNeue_Medium" color="primary.main">
              {userDetail?.admin ? USER_ROLE.ADMIN : USER_ROLE.USER}
            </Typography>
          </Stack>
        </Stack>
        {userDetail?.external && (
          <Stack className="userDetailsLine mb-20">
            <Stack>
              <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.lighter">
                External role
              </Typography>
              <Typography variant="subtitle1_HelveticaNeue_Medium" color="primary.main">
                {userDetail?.externalRole?.label || '-'}
              </Typography>
            </Stack>
            {userDetail?.externalRole?.code === EXTERNAL_ROLE.EXTERNAL_CONTRACTOR && (
              <Stack>
                <Typography
                  variant="subtitle1_HelveticaNeue_Regular"
                  color="text.lighter"
                >
                  Linked to all companies
                </Typography>
                <Typography variant="subtitle1_HelveticaNeue_Medium" color="primary.main">
                  {userDetail?.linkedToAllCompanies
                    ? YES_NO_OPTIONS.YES
                    : YES_NO_OPTIONS.NO}
                </Typography>
              </Stack>
            )}
          </Stack>
        )}
        <Stack className="userDetailsLine mb-20">
          {userDetail?.externalRole?.code === EXTERNAL_ROLE.EXTERNAL_CONTRACTOR && (
            <Stack>
              <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.lighter">
                Company list values
              </Typography>
              <Typography variant="subtitle1_HelveticaNeue_Medium" color="primary.main">
                {userDetail?.companyList?.length
                  ? userDetail?.companyList?.map((c) => c.name).join(' - ')
                  : '-'}
              </Typography>
            </Stack>
          )}
          {!userDetail?.external && (
            <Stack>
              <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.lighter">
                DPE
              </Typography>
              <Typography variant="subtitle1_HelveticaNeue_Medium" color="primary.main">
                {userDetail?.dpe ? YES_NO_OPTIONS.YES : YES_NO_OPTIONS.NO}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </>
  );
}

UserInformation.propTypes = {
  userAvatar: PropTypes.string,
  userDetail: PropTypes.shape({
    id: PropTypes.number,
    external: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    mail: PropTypes.string,
    mobilePhone: PropTypes.string,
    specificRole: PropTypes.shape({
      label: PropTypes.string,
      code: PropTypes.string,
    }),
    admin: PropTypes.bool,
    externalRole: PropTypes.shape({
      code: PropTypes.string,
      label: PropTypes.string,
    }),
    companyList: PropTypes.arrayOf(PropTypes.string),
    dpe: PropTypes.string,
    linkedToAllCompanies: PropTypes.bool,
  }),
  hideDelete: PropTypes.bool,
};
export default UserInformation;
