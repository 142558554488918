export const FILTER_INPUTS = {
  name: 'name',
  platform: 'platformName',
  forExternal: 'isForExternal',
  forClient: 'isForClient',
  forContractor: 'isForContractor',
};

export const defaultMessageOfErrorPopup =
  'The profile is associated to other records, please remove all links and try again';
