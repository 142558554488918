import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

function CustomTimelineContent({ title, subtitle }) {
  return (
    <>
      <Typography variant="h6_HelveticaNeue_Bold">{title}</Typography>
      <br />
      <Typography variant="subtitle2_HelveticaNeue_Regular">{subtitle}</Typography>
    </>
  );
}
CustomTimelineContent.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
export default CustomTimelineContent;
