import { styled } from "@mui/material";

const CustomMenuButton = styled('button')`
  font-family: 'HelveticaNeueMedium', sans-serif;
  font-size: 14px;
  color: ${(props) =>
    props?.danger
      ? props.theme.AppColors.lightDanger
      : props.theme.AppColors.DarkElectricBlue};
  cursor: pointer;
  width: 100%;
  min-height: 44px;
  background: transparent;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  border: none;
  padding: 12px;

  span:not(.MuiTypography-root) {
    color: currentColor;

    svg {
      color: currentColor;
    }
  }

  &:hover {
    background: ${(props) => props.theme.GreyColors[1000]};
  }
`;

export default CustomMenuButton;
