import { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import DeleteWL from 'assets/svgs/componentsIcons/DeleteWL';
import { baseRoutes } from 'helpers/constants';
import { useLazyGetAvatarUrlQuery } from 'services/apis/avatar';
import AppTheme from 'ui/theme/app-theme';
import { TruncateText } from 'utils/TextUtils';
import { ActionsCard, Cards, ContentCard, MediaCard, StatusCard } from './Wrapper';

export default function CardWorkLocation({
  workLocation,
  onDelete,
  onclickAction,
  enumerationsProjectState,
  disabled,
}) {
  const [doGetAvatarUrl, { data: avatar }] = useLazyGetAvatarUrlQuery();
  const workLocationImg = '/workLocation.png';
  const [userAvatar, setUserAvatar] = useState(workLocationImg);

  useEffect(() => {
    if (avatar) {
      setUserAvatar(URL.createObjectURL(avatar));
    }
  }, [avatar]);

  useEffect(() => {
    if (workLocation?.fileUpload) {
      setUserAvatar(workLocation?.fileUpload);
    } else if (workLocation?.id && workLocation?.image) {
      doGetAvatarUrl(`${baseRoutes.workLocation}/${workLocation?.id}`);
    } else {
      setUserAvatar(workLocationImg);
    }
  }, [workLocation]);

  const getWorkOrder = () =>
    workLocation?.workOrders?.map((workOrder) => (
      <Typography
        key={workOrder.id}
        color={AppTheme.AppColors.DarkElectricBlue}
        variant="HelveticaNeue_Regular"
        component="div"
      >
        {TruncateText(workOrder?.number, 20)}
      </Typography>
    ));
  return (
    <Cards onClick={onclickAction}>
      <MediaCard image={userAvatar} title="test image">
        {disabled && (
          <Stack
            className="SvgWrapper"
            onClick={(e) => {
              e.stopPropagation();
              onDelete(workLocation);
            }}
          >
            <Stack className="SvgIcon">
              <DeleteWL />
            </Stack>
          </Stack>
        )}
      </MediaCard>
      <ContentCard className="show flex__row--center-space no-padding-top no-padding-bottom mt-15">
        <ContentCard>
          <Typography className="wlTitleDimension" variant="h6_HelveticaNeue_Bold">
            {TruncateText(workLocation?.name, 15)}
          </Typography>
          {getWorkOrder()}
        </ContentCard>
        <ContentCard className="no-padding-bottom">
          <StatusCard>
            {' '}
            {
              enumerationsProjectState?.find((e) => e.code === workLocation?.statusCode)
                ?.label
            }{' '}
          </StatusCard>
        </ContentCard>
      </ContentCard>
      <ActionsCard className="justifyContent-flexend">
        <Typography variant="h6_HelveticaNeue_Bold" color="" component="div">
          {TruncateText(workLocation?.location, 20)}
        </Typography>
      </ActionsCard>
    </Cards>
  );
}

CardWorkLocation.propTypes = {
  workLocation: PropTypes.shape({
    id: PropTypes.number,
    image: PropTypes.string,
    isDeleted: PropTypes.bool,
    location: PropTypes.string,
    name: PropTypes.string,
    projectId: PropTypes.number,
    statusCode: PropTypes.string,
    fileUpload: PropTypes.string,
    workOrders: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        number: PropTypes.string,
        overallWorkOrder: PropTypes.bool,
      }),
    ),
  }),
  onDelete: PropTypes.func,
  onclickAction: PropTypes.func,
  disabled: PropTypes.bool,
  enumerationsProjectState: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      code: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
};
