import React from "react";

const WarehousingIcon = () => (
  <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" width="25" height="25">
    <title>iconWarehousing</title>
    <defs>
      <image width="25" height="25" id="img5"
             href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAAXNSR0IB2cksfwAAAPlQTFRFAAAAAHj/AHz/AHv/AHT/AID/AHn/AHv/AHv/AHv/AHv/AHr/AFX/AHv/AHr/AHz/AID/AID/AHv/AHv/AIT/AAD/AHz/AHv/AHv/AHr/AHz/AHr/AID/AHz/AHz/AHv/AGb/AHT/AHj/AHv/AHz/AHr/AH3/AHH/AHz/AHv/AID/AHf/AHz/AHr/AHz/AHv/AID/AHr/AHv/AHv/AID/AHz/AGb/AHv/AHr/AHz/AHn/AHv/AHv/AID/AH3/AHv/AHz/AHz/AHz/AHz/AH3/AH3/AHz/AHz/AHr/AHz/AH3/AHv/AHv/AHv/AHn/AHn/AHX/AHr/AHn/YUzlZwAAAFN0Uk5TACK3uCEEZfC5x/FWAx+rggYIg54dAVTi1TJCRQyS/5EKC0TU1OMzCYSfHB6e8sZVAmRmuiBlBXTxZ+JT/xA/sIDAUNK6bnelYECPvn7fj9RT1VAD7z4IAAABA0lEQVR4nGNgQAKMTMwsDNgBKxs7BycXNw82OV4+Ln4BQSEuYREMKVExcQlJEENKQlwaTU5GVk5eAcJUBDIVkeWUlFWUEDxpVWU1OEddQ05TC1mtto4uvyCEKanHpW+AaguPIZceI8glRlzGvJhuN+FgN2Vg0GQ3NcPmMwFzC34GCyNsUiBgacUgZ41LUt8GIWlrBwW2WCTt5aDAAYukI1DQAYqxSjo54ZEEASpKgrzh7OLq6ubm6mrsDvUOTBLkDRuYV0AMeyRJkJFg7Aj1jiOapIcnRNLTCYsk2FGOUEdRLGnlJSYmJuHtAwa+vhDaWwIo6OfPEKBiAwTI3gDTQBAYBAChcztf6e03zQAAAABJRU5ErkJggg==" />
    </defs>
    <style>
    </style>
    <use id="Background" href="#img5" x="0" y="0" />
  </svg>
);
export default WarehousingIcon;
