import { Button, Stack, styled } from '@mui/material';

export const ProfileWrapper = styled(Stack)(({ theme }) => ({
  flex: '1 1 80%',
  padding: '48px 56px 34px 56px',
  overflowY: 'auto',
  '& .top-header': {
    gap: '8px',

    '& .heading-area': {
      flexDirection: 'row',
      alignItems: 'center',
      gap: '12px',
    },
  },
  '& .ProfilSetting': {
    width: '100%',
    marginTop: '30px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  '& .leftSide': {
    width: '25%',
    marginRight: '2%',
    paddingRight: '1%',
    maxHeight: '66vh',
    overflowY: 'auto',
  },
  '& .centerSide': {
    width: '35%',
    paddingRight: '2%',
    marginRight: '2%',
    maxHeight: '70vh',
    overflowY: 'auto',
  },
  '& .rightSide': {
    width: '40%',
    maxHeight: '66vh',
    overflowY: 'auto',
  },
  '& .iconList': {
    marginBottom: '8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  '& .iconRowList': {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    width: '125px',
    height: '40px',
    padding: '6px',
    background: theme.palette.common.white,
    borderRadius: '14px',
  },
  '& .iconHideList': {
    padding: '6px',
  },

  [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},
  [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {},
}));
export const ProfileMenuWrapper = styled(Button)(({ theme }) => ({
  marginBottom: '5px',
  height: '52px',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  background: theme.palette.common.white,
  boxShadow: '0px 3px 16px #3235390A',
  border: '1px solid #5F6A7E05',
  borderRadius: '8px',
  '& .module_icon': {
    width: '30px',
  },
  '&:hover': {
    background: theme.palette.common.white,
  },
  '&:active': {
    background:
      'transparent linear-gradient(284deg, #007CFF 0%, #00338D 100%) 0% 0% no-repeat padding-box' /* theme.palette.gradient_blue.main */,
    '& > *': {
      fill: theme.palette.common.white,
    },

    '& > :nth-child(2)': {
      color: theme.palette.common.white,
    },
  },
  [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},
  [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {},
}));
