import {createSelector} from 'reselect';
import {selectAccessManagementDomain} from "services/slices/access-management/index";

const makeSelectProfileData = createSelector(
  selectAccessManagementDomain,
  (substate) => substate.profileData,
);

const makeSelectSubmit = createSelector(
  selectAccessManagementDomain,
  (substate) => substate.submitted,
);
const makeSelectSuccessPopup = createSelector(
  selectAccessManagementDomain,
  (substate) => substate.successPopup,
);
const makeSelectCancelPopup = createSelector(
  selectAccessManagementDomain,
  (substate) => substate.cancelPopup,
);
const makeSelectBackPopup = createSelector(
  selectAccessManagementDomain,
  (substate) => substate.backPopup,
);
const makeSelectDeletePopup = createSelector(
  selectAccessManagementDomain,
  (substate) => substate.deletePopup,
);
const makeSelectDeletedProfileId = createSelector(
  selectAccessManagementDomain,
  (substate) => substate.deletedProfileId,
);
export {
  makeSelectProfileData,
  makeSelectSubmit,
  makeSelectSuccessPopup,
  makeSelectCancelPopup,
  makeSelectBackPopup,
  makeSelectDeletePopup,
  makeSelectDeletedProfileId,
};
