import React from 'react';

const SearchIcon = (props) => (
  <svg
    data-name="Group 64001"
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={15}
    {...props}
  >
    <g opacity="0.6">
      <path
        d="M10.5,5.5a5,5,0,1,0,5,5A5,5,0,0,0,10.5,5.5ZM4,10.5A6.5,6.5,0,1,1,10.5,17,6.5,6.5,0,0,1,4,10.5Z"
        transform="translate(-4 -4)"
        fill="currentColor"
      />
      <path
        d="M14.47,14.47a.75.75,0,0,1,1.061,0l4,4A.75.75,0,0,1,18.47,19.53l-4-4A.75.75,0,0,1,14.47,14.47Z"
        transform="translate(-4 -4)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default SearchIcon;
