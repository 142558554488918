import { createSlice } from '@reduxjs/toolkit';
import { type RootState } from 'store';
import { PROJECT_CREATION_STATUS } from 'helpers/constants';

interface ProjectCreationSliceState {
  projectFormData: any;
  workOrder: any;
  submit: boolean;
  withWorkLocation: boolean;
  picture: any[];
  projectCreationStatus: string;
}

export const initialState: ProjectCreationSliceState = {
  projectFormData: {},
  workOrder: {},
  submit: false,
  withWorkLocation: false,
  picture: [],
  projectCreationStatus: PROJECT_CREATION_STATUS.DRAFT,
};
/* eslint-disable no-param-reassign */
const projectCreation = createSlice({
  name: 'projectCreation',
  initialState,
  reducers: {
    setProjectLocation: (state, { payload: projectLocation }) => {
      state.projectFormData.latitude = projectLocation.latitude;
      state.projectFormData.longitude = projectLocation.longitude;
    },
    setProjectFormData: (state, { payload: projectFormData }) => {
      state.projectFormData = projectFormData;
    },
    setWorkOrder: (state, { payload: workOrder }) => {
      state.workOrder = workOrder;
    },
    setSubmit: (state, { payload: submit }) => {
      state.submit = submit;
    },
    setWithWorkLocation: (state, { payload: withWorkLocation }) => {
      state.withWorkLocation = withWorkLocation;
    },
    setProjectCreationStatus: (state, { payload: projectCreationStatus }) => {
      state.projectCreationStatus = projectCreationStatus;
    },
    resetProjectCreation: () => initialState,
  },
});

export const {
  setProjectFormData,
  setWorkOrder,
  setSubmit,
  setWithWorkLocation,
  setProjectCreationStatus,
  resetProjectCreation,
  setProjectLocation,
} = projectCreation.actions;

export const selectProjectsSettingsProjectCreationDomain = (state: RootState): any =>
  state.projectSetting.projectCreation;

export default projectCreation.reducer;
