import { Stack, styled } from '@mui/material';

const DroppedContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',

  '& .leftDragDrop': {
    display: 'flex',
    flexDirection: 'colomn',
    height: '360px',
    marginRight: '15px',
    width: 'calc(50% - 15px)',
  },

  '& .rightDragDrop': {
    width: 'calc(50% - 15px)',
    height: '360px',
    marginLeft: '15px',
  },

  [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},
  [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {},
}));
export default DroppedContainer;
