import { createSlice } from '@reduxjs/toolkit';
import { type RootState } from 'store';

interface ProjectMembersSliceState {
  userProjectsByCategory: any[];
  projectUsers: any[];
  selectedUser: any;
  selectedRole: any;
  selectedProfiles: any[];
  profiles: any[];
  platforms: any[];
}

const initialState: ProjectMembersSliceState = {
  userProjectsByCategory: [],
  projectUsers: [],
  selectedUser: {},
  selectedRole: {},
  selectedProfiles: [],
  profiles: [],
  platforms: [],
};
/* eslint-disable no-param-reassign */
export const projectMembers = createSlice({
  name: 'projectMembers',
  initialState,
  reducers: {
    setSelectedUser: (state, { payload: user }) => {
      state.selectedUser = user;
    },
    setSelectedRole: (state, { payload: role }) => {
      state.selectedRole = role;
    },
    setSelectProfiles: (state, { payload: profiles }) => {
      state.selectedProfiles = profiles;
    },
    setProfiles: (state, { payload: profiles }) => {
      state.profiles = profiles;
    },
    setProjectUsers: (state, { payload: projectUsers }) => {
      state.projectUsers = projectUsers;
    },
    setUserProjectsByCategory: (state, { payload: userProjects }) => {
      state.userProjectsByCategory = userProjects;
    },
    assignUsersToProject: (state, { payload: projectUsers }) => {
      state.projectUsers = projectUsers;
      state.selectedUser = {};
      state.selectedRole = {};
      state.selectedProfiles = [];
    },
    setPlatforms: (state, { payload: platforms }) => {
      state.platforms = platforms;
    },
    resetProjectMembers: () => initialState,
  },
});

export const {
  setSelectedUser,
  setSelectedRole,
  setSelectProfiles,
  setProjectUsers,
  setPlatforms,
  setProfiles,
  setUserProjectsByCategory,
  assignUsersToProject,
  resetProjectMembers,
} = projectMembers.actions;

export const selectProjectsSettingsProjectMembersDomain = (state: RootState): any =>
  state.projectSetting.projectMembers;

export default projectMembers.reducer;
