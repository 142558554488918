import React from "react";
import {Checkbox, FormControlLabel, Typography} from "@mui/material";
import PropTypes from "prop-types";
import {CheckboxWrapper} from "./Wrapper";

const FormCheckbox = ({ref, label, labelColor = "dark.main", ...rest}) => {
  return (
    <CheckboxWrapper>
      <FormControlLabel
        label={
          <Typography variant="subtitle2_Montserrat_Medium" color={labelColor}>
            {label}
          </Typography>
        }
        control={
          <Checkbox
            {...rest}
            inputRef={ref}
            inputProps={{
              "aria-label": "Checkbox"
            }}
          />
        }
      />
    </CheckboxWrapper>
  );
};

FormCheckbox.propTypes = {
  label: PropTypes.string,
  ref: PropTypes.object,
  labelColor: PropTypes.string
};
export default FormCheckbox;
