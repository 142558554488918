import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { Divider, Modal, Switch, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { BugSvg, CloseIcon, UpdateUserSvg } from 'assets/svgs/componentsIcons';
import EditIconProfile from 'assets/svgs/componentsIcons/EditIconProfile';
import { CloseButton, MainButton, MenuButton } from 'components/Buttons';
import theme from 'ui/theme';
import FormSelect from 'components/FormSelect';
import CustomDropDownMenu from 'components/StyledComponents/DropDownMenu';
import CustomModal from 'components/StyledComponents/CustomModal';
import { hasSpecificRoleWithProjectAdminData } from 'utils/UserManagementUtils';
import {
  EnumerationType,
  useGetEnumerationByTypeFromUserManagementQuery,
} from 'services/apis/enumeration';
import {
  useLazyGetUserByIdQuery,
  useUpdateInternalUserMutation,
  useUpdateUserAccessStatusMutation,
} from 'services/apis/user';
import {
  fillUpdatedUserForm,
  setFreezeForm,
  setFreezeSuccess,
  setInternalUserUpdateSuccess,
  setOperation,
  setSubmit,
  setUpdatedUser,
  setUpdateUserResult,
} from 'services/slices/user-management/user-management-option';
import { SETTINGS_USERS_ADD_EXTERNAL } from 'routes/constants';
import FormSwitch from 'components/FormSwitch';
import { PLATFORM_SETTINGS_TYPES } from 'utils/constants/specificRoles';
import { useGetAllQuery } from 'services/apis/platform-settings';
import { OPERATIONS } from './constants';
import {
  makeSelectFreezeForm,
  makeSelectIsSubmit,
  makeSelectOperation,
  makeSelectUpdatedUser,
} from './selectors';

const stateSelector = createStructuredSelector({
  operation: makeSelectOperation,
  fillformfreeze: makeSelectFreezeForm,
  updatedUser: makeSelectUpdatedUser,
  isSubmit: makeSelectIsSubmit,
});

const UserManagementOption = function ({
  openElem,
  anchorEl,
  handleClose,
  isFromUserDetail = false,
  userDetail,
}) {
  const { operation, fillformfreeze, updatedUser, isSubmit } = useSelector(stateSelector);
  const [openModal, setOpenModal] = useState(isFromUserDetail);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [doGetUserById] = useLazyGetUserByIdQuery();
  const [
    doUpdateInternalUser,
    {
      isSuccess: internalUserUpdateSuccess,
      reset: resetInternalUserUpdate,
      data: updateUserResult,
    },
  ] = useUpdateInternalUserMutation();

  const [doUpdateUserAccessStatus, { isSuccess: userAccesssStatusUpdateSuccess }] =
    useUpdateUserAccessStatusMutation();
  const { data: specificRoles } = useGetEnumerationByTypeFromUserManagementQuery(
    EnumerationType.SPECIFIC_ROLE_TYPE,
  );
  const { data: projectAdminData } = useGetAllQuery(
    {
      baseRoute: PLATFORM_SETTINGS_TYPES[updatedUser?.specificRole?.code]?.baseRoute,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const dataValid = () =>
    !updatedUser?.external &&
    (!hasSpecificRoleWithProjectAdminData(updatedUser?.specificRole?.code) ||
      !!updatedUser[PLATFORM_SETTINGS_TYPES[updatedUser?.specificRole?.code].columnName]);

  const handleOpenCloseModal = useCallback(() => {
    dispatch(setSubmit(false));
    if (!isFromUserDetail) {
      resetInternalUserUpdate();
    }
    setOpenModal((modalOpen) => !modalOpen);
  }, [dispatch, resetInternalUserUpdate]);

  useEffect(() => {
    dispatch(setInternalUserUpdateSuccess(internalUserUpdateSuccess));
  }, [dispatch, internalUserUpdateSuccess]);
  useEffect(() => {
    dispatch(setFreezeSuccess(userAccesssStatusUpdateSuccess));
  }, [dispatch, userAccesssStatusUpdateSuccess]);

  useEffect(() => {
    if (userDetail) {
      dispatch(setUpdatedUser(userDetail));
    }
  }, [userDetail]);

  useEffect(() => {
    if (!openModal && isFromUserDetail) {
      dispatch(setOperation(null));
    }
  }, [openModal]);

  useEffect(() => {
    if (isFromUserDetail && updateUserResult) {
      dispatch(setUpdateUserResult(updateUserResult));
    }
  }, [updateUserResult]);

  useEffect(() => {
    if (isFromUserDetail && operation) {
      setOpenModal(true);
      if (operation === OPERATIONS.FREEZE) {
        dispatch(
          setFreezeForm({
            azureDirectoryId: userDetail?.azureDirectoryId,
            frozen: !userDetail?.frozen,
          }),
        );
      }
    }
  }, [operation]);

  const handleOpenModalEdit = () => {
    dispatch(setOperation(OPERATIONS.EDIT));
    doGetUserById(updatedUser?.id).then((result) => {
      dispatch(setUpdatedUser(result.data));
    });
    handleOpenCloseModal();
    handleClose();
  };
  const handleEditOrFreezeAccess = () => {
    if (operation === OPERATIONS.EDIT) {
      dispatch(setSubmit(true));
      if (dataValid()) {
        doUpdateInternalUser(updatedUser);
        handleOpenCloseModal();
      }
    } else {
      doUpdateUserAccessStatus(fillformfreeze);
      handleOpenCloseModal();
    }
  };
  const handleConfirmationMessage = () =>
    fillformfreeze?.frozen
      ? 'Are you sure you want to freeze this user.'
      : 'Are you sure you want to active the access right for this user.';

  const labelData =
    PLATFORM_SETTINGS_TYPES[updatedUser?.specificRole?.code]?.columnName?.toLowerCase();
  const formattedLabelData = `${labelData?.[0]?.toUpperCase()}${labelData?.substring(1)}`;
  return (
    <>
      {!isFromUserDetail && (
        <CustomDropDownMenu
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={updatedUser?.id === openElem}
          anchorEl={anchorEl}
          onClose={handleClose}
        >
          <MenuButton type="button">
            <span>
              <BugSvg />
            </span>

            <span>
              <Typography variant="subtitle1_HelveticaNeue_Light" color="text.grey">
                Freeze access
              </Typography>
            </span>

            <span>
              <Switch
                defaultChecked
                checked={fillformfreeze?.frozen}
                onChange={(e) => {
                  dispatch(
                    setFreezeForm({
                      azureDirectoryId: updatedUser?.azureDirectoryId,
                      frozen: e.target.checked,
                    }),
                  );
                  dispatch(setOperation(OPERATIONS.FREEZE));
                  handleOpenCloseModal();
                  handleClose();
                }}
              />
            </span>
          </MenuButton>
          <Divider />

          {!updatedUser?.external ? (
            <MenuButton
              type="button"
              onClick={() => {
                handleOpenModalEdit();
              }}
            >
              <span>
                <UpdateUserSvg />
              </span>
              <span>
                <Typography
                  variant="subtitle1_HelveticaNeue_Light"
                  color="secondary.blue"
                >
                  User update
                </Typography>
              </span>
            </MenuButton>
          ) : (
            <MenuButton
              className="customHeight"
              type="button"
              onClick={() => {
                navigate(`${SETTINGS_USERS_ADD_EXTERNAL}/${updatedUser.id}`);
              }}
            >
              <div className="editIconContainer">
                <EditIconProfile
                  classname="editIcon"
                  color={theme.palette.secondary.blue}
                  isWithRect={false}
                />
              </div>
              <span>
                <Typography
                  variant="subtitle1_HelveticaNeue_Light"
                  color="secondary.blue"
                >
                  Edit user
                </Typography>
              </span>
            </MenuButton>
          )}
          <Divider />
        </CustomDropDownMenu>
      )}

      <Modal
        open={openModal}
        onClose={() => {
          handleOpenCloseModal();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomModal modalWidth="416px">
          <div className="modal-head-wrapper">
            <div className="modal-head">
              {operation === OPERATIONS.EDIT && (
                <>
                  <h3 className="modal-title">User Management</h3>
                  {!updatedUser?.external && (
                    <div>
                      <FormSelect
                        customClassName="justifyContent-flexend"
                        menuItems={specificRoles || []}
                        label="Specific role*"
                        onChange={(e) => {
                          dispatch(
                            fillUpdatedUserForm({
                              specificRole: e.target.value,
                              sector: null,
                              program: null,
                              discipline: null,
                              bu: null,
                              portfolio: null,
                            }),
                          );
                        }}
                        valueKey="label"
                        value={specificRoles?.find(
                          (item) => item?.code === updatedUser?.specificRole?.code,
                        )}
                        error={isSubmit && !updatedUser?.specificRole}
                      />
                      {hasSpecificRoleWithProjectAdminData(
                        updatedUser?.specificRole?.code,
                      ) && (
                        <FormSelect
                          customClassName="justifyContent-flexend"
                          menuItems={projectAdminData || []}
                          label={`${formattedLabelData}*`}
                          onChange={(e) => {
                            dispatch(
                              fillUpdatedUserForm({
                                [PLATFORM_SETTINGS_TYPES[updatedUser?.specificRole?.code]
                                  ?.columnName]: e.target.value.code,
                              }),
                            );
                          }}
                          valueKey="name"
                          value={projectAdminData?.find(
                            (item) =>
                              item?.code ===
                              updatedUser[
                                PLATFORM_SETTINGS_TYPES[updatedUser?.specificRole?.code]
                                  ?.columnName
                              ],
                          )}
                          error={isSubmit && !dataValid()}
                        />
                      )}
                      <div className="mt-25">
                        <FormSwitch
                          label="Is DPE"
                          checked={updatedUser?.dpe}
                          onChange={() => {
                            dispatch(
                              fillUpdatedUserForm({
                                ...updatedUser,
                                dpe: !updatedUser?.dpe,
                              }),
                            );
                          }}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
              {operation === OPERATIONS.FREEZE && (
                <>
                  <h3 className="modal-title">Freeze access </h3>
                  <p className="modal-description">{handleConfirmationMessage()}</p>
                </>
              )}
            </div>
            <div className="modal-closer">
              <CloseButton
                type="button"
                onClick={() => {
                  handleOpenCloseModal();
                }}
              >
                <CloseIcon />
              </CloseButton>
            </div>
          </div>
          <div className="modal-action-wrapper">
            <MainButton
              secondary
              onClick={() => {
                handleOpenCloseModal();
              }}
            >
              Cancel
            </MainButton>
            <MainButton
              onClick={() => {
                handleEditOrFreezeAccess();
              }}
            >
              Confirm
            </MainButton>
          </div>
        </CustomModal>
      </Modal>
    </>
  );
};

UserManagementOption.propTypes = {
  anchorEl: PropTypes.element,
  handleClose: PropTypes.func,
  openElem: PropTypes.number,
  isFromUserDetail: PropTypes.bool,
  userDetail: PropTypes.shape({
    azureDirectoryId: PropTypes.string,
    frozen: PropTypes.bool,
  }),
};
export default UserManagementOption;
