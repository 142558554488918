import React from "react";

const ProcuredEquipementIcon = () => (
  <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" width="25" height="25">
    <title>ProcuredEquipementIcon</title>
    <defs>
      <image width="25" height="25" id="img9"
             href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAAXNSR0IB2cksfwAAAFRQTFRFAAAAAHz/AHv/AHz/AHz/AHz/AHz/AHv/AHz/AHr/AHz/AHr/AHv/AHv/AHj/AHv/AHz/AHz/AHv/AHz/AID/AHv/AHv/AHv/AHv/AH3/AHv/AHz/rAG5FgAAABx0Uk5TAFDP/++vQPC/MIBgn+AgX9CQoMAQcLDfjz9Pf/5Wl1cAAAE4SURBVHicdVPruoMgDKsWWyrKUBju8v7veQBB57cdfyg2JE0sAkDXoxoIPi4aEIeuLFmLjGxOzPAoYnjKa5l7C8K2YcICdEvlnetGgGVt4LqA51mHuwenCdw99fEN9AQYwJFFwMibD8nWCXaA1m4kDHEM2amo1KnaYRlRrWGMQKUoytiGJaNik0VF+e3xfKmcZN6xfHs9H1jC2dX7XTOIqTrer0e0nQCWWw+4XFXuJ9aM/Mb2CP9hhyb/JFaefKMf/b7QQ7M8PlA7ad14dRNrPclOWqjLe4tmzdvRkkv5w4tNSjXDmZcpj2xifAu3fDUv5ZGh0GzT0C/DhtDPPKg0dOpDwOsxAeNSwYEzwW8qXg8YjH1ZdrOAoWT1mJ9FgeC2EmVVN6G3uhzq91P0vHMjcrz+DpExJlt/+0oQBckccFEAAAAASUVORK5CYII=" />
    </defs>
    <style>
    </style>
    <use id="Background" href="#img9" x="0" y="0" />
  </svg>
);
export default ProcuredEquipementIcon;
