import React, { Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import AppLandingPageDigitalSpace from 'pages/AppLandingPageDigitalSpace';
import NotFoundPage from 'pages/NotFoundPage';
import { ProtectedComponent } from 'providers/auth-provider';
import AppLayout from 'ui/AppLayout';
import AppSplashPage from 'containers/AppSplashPage';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useMsal } from '@azure/msal-react';
import { DIGITAL_SPACE } from './constants';
import SettingsRoutes from './settings';
import ProjectSettingsRoutes from './project-settings';
import ProfileSettingsRoutes from './profile-settings';

const Loading: React.FC = function () {
  return <b>Routes Loading...</b>;
};

const AppRoutes: React.FC = function () {
  const { trackPageView, pushInstruction } = useMatomo();
  const { accounts } = useMsal();

  const location = useLocation();

  useEffect(() => {
    if (accounts.length > 0) {
      pushInstruction('setUserId', accounts[0]?.username);
      pushInstruction('setCustomVariable', 1, 'User Name', accounts[0]?.name, 'visit');
    }
  }, [accounts, pushInstruction]);

  useEffect(() => {
    trackPageView({});
  }, [location, trackPageView]);

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<ProtectedComponent element={AppLayout} />}>
          <Route index element={<AppSplashPage />} />
          <Route path={DIGITAL_SPACE} element={<AppLandingPageDigitalSpace />} />
          {SettingsRoutes}
          {ProjectSettingsRoutes}
          {ProfileSettingsRoutes}
          <Route element={<NotFoundPage />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
