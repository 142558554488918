import React from 'react';
import ActionButton from 'components/ActionButton';
import ProjectSettingsFooterWrapper from './Wrapper';

interface ProjectSettingsFooterProps {
  cancelLabel?: string;
  DraftLabel?: string;
  saveLabel?: string;
  saveLeaveLabel?: string;
  showSaveLeaveBtn?: boolean;
  showDraftBtn?: boolean;
  cancelOnClick?: () => void;
  DraftOnClick?: () => void;
  saveOnClick?: (saveLeave: boolean) => void;
}

const ProjectSettingsFooter: React.FC = function ({
  cancelLabel,
  DraftLabel,
  saveLabel,
  saveLeaveLabel,
  showSaveLeaveBtn = false,
  showDraftBtn = false,
  cancelOnClick,
  DraftOnClick,
  saveOnClick,
}: ProjectSettingsFooterProps) {
  return (
    <ProjectSettingsFooterWrapper>
      <div>
        <ActionButton
          classname="footer--back__button btn--reset"
          label={cancelLabel}
          onClick={cancelOnClick}
        />
      </div>
      <div>
        {showDraftBtn && (
          <ActionButton
            classname="footer--back__button btn--reset mr-15"
            label={DraftLabel}
            onClick={DraftOnClick}
          />
        )}
        {!showDraftBtn && showSaveLeaveBtn && (
          <ActionButton
            classname="footer--back__button btn--reset mr-15"
            label={saveLeaveLabel}
            onClick={() => saveOnClick?.(true)}
          />
        )}
        <ActionButton
          classname="footer--next__button btn--reset"
          label={saveLabel}
          onClick={() => saveOnClick?.(false)}
        />
      </div>
    </ProjectSettingsFooterWrapper>
  );
};
export default ProjectSettingsFooter;
