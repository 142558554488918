import React from 'react';
import PropTypes from 'prop-types';
import {Stack, Typography} from '@mui/material';
import {StatusTagWrapper} from "./Wrapper";

const StatusTag = ({isActive = false}) => {
  const status = isActive ? 'Active' : 'Inactive';
  return (
    <StatusTagWrapper>
      <Stack className={`tag ${status}`}>
        <Typography
          variant="subtitle2_HelveticaNeue_Regular"
          color="light.main"
        >
          {status}
        </Typography>
      </Stack>
    </StatusTagWrapper>
  );
};

StatusTag.propTypes = {
  isActive: PropTypes.bool,
};
export default StatusTag;
