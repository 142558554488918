import React from 'react';

const AccessManagement = (props) => (
  <svg
    data-name="Group 64002"
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    {...props}
  >
    <path data-name="Path 67264" d="M0 0h20v20H0Z" fill="none" />
    <path
      data-name="Line 1713"
      d="M10 10.633a.625.625 0 0 1-.625-.625V10A.625.625 0 0 1 10 9.375a.625.625 0 0 1 .625.625v.008a.625.625 0 0 1-.625.625Z"
      fill="currentColor"
    />
    <path
      data-name="Path 67265"
      d="M12.354 12.981a.625.625 0 0 1-.442-1.067 2.712 2.712 0 0 0 0-3.83.625.625 0 1 1 .884-.884 3.963 3.963 0 0 1 0 5.6.623.623 0 0 1-.442.181Z"
      fill="currentColor"
    />
    <path
      data-name="Path 67266"
      d="M14.714 15.339a.625.625 0 0 1-.442-1.071 6.042 6.042 0 0 0 0-8.544.625.625 0 1 1 .884-.884 7.292 7.292 0 0 1 0 10.312.623.623 0 0 1-.442.187Z"
      fill="currentColor"
    />
    <path
      data-name="Path 67267"
      d="M7.64 12.981a.623.623 0 0 1-.442-.183 3.963 3.963 0 0 1 0-5.6.625.625 0 1 1 .884.884 2.712 2.712 0 0 0 0 3.83.625.625 0 0 1-.442 1.067Z"
      fill="currentColor"
    />
    <path
      data-name="Path 67268"
      d="M5.28 15.339a.623.623 0 0 1-.442-.183 7.292 7.292 0 0 1 0-10.312.625.625 0 1 1 .884.884 6.042 6.042 0 0 0 0 8.544.625.625 0 0 1-.442 1.067Z"
      fill="currentColor"
    />
  </svg>
);

export default AccessManagement;
