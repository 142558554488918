import * as React from 'react';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';

const TabsContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
});

const TabsTitles = styled(Tabs)(({ theme }) => ({
  '& button': {
    textTransform: 'Capitalize',
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  '& .MuiTab-root': {
    fontWeight: 'bold',
    width: '150px',
    fontSize: '14px',
    lineHeight: '17px',
    border: '1px solid',
    borderColor: theme.palette.light.blue,
    color: theme.palette.primary.main,
    height: '17px',
    '&.Mui-selected': {
      color: theme.palette.light.main,
      background: theme.palette.primary.main,
    },
    '&:not(.Mui-selected)': {
      background: theme.palette.appBackground.BlueAlice,
    },
    '&:first-of-type': {
      borderTopLeftRadius: '8px',
      borderBottomLeftRadius: '8px',
    },
    '&:last-of-type': {
      borderTopRightRadius: '8px',
      borderBottomRightRadius: '8px',
    },
  },
}));

export default function CustomizedTabs({
  handleTabChange,
  tabs = {},
  activeTab,
  className = '',
}) {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(activeTab);
  }, [activeTab]);

  const handleChange = (event, newValue) => {
    handleTabChange(newValue);
  };

  return (
    <TabsContainer className={className}>
      <TabsTitles value={value} onChange={handleChange} aria-label="styled tabs example">
        {Object.values(tabs)?.map((tab) => (
          <Tab
            label={tab.label}
            value={tab.code}
            key={tab.code}
            disabled={tab.disabled}
          />
        ))}
      </TabsTitles>
    </TabsContainer>
  );
}
/* eslint-disable react/forbid-prop-types */
CustomizedTabs.propTypes = {
  handleTabChange: PropTypes.func,
  tabs: PropTypes.object,
  activeTab: PropTypes.string,
  className: PropTypes.string,
};
