import { Stack, styled } from '@mui/material';

const FileUploadWrapper = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  '&  .UploadedFileWrapper': {
    backgroundColor: `#F6F7FB`,
    border: `1px solid #DBE6FF`,
    padding: '20px 30px',
    borderRadius: '12px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    '&  .fileInfos': {
      background: `${theme.palette.common.white} `,
      padding: '15px 20px',
      width: '75%',
      borderRadius: '8px',
      flexDirection: 'row',
      alignItems: 'center',
    },
    '&  .exportFile': {
      background: `${theme.palette.primary.main} `,
      minWidth: 'unset',
      width: '40px',
      height: '40px',
      padding: 0,

      '&  .MuiButton-startIcon': {
        width: '100%',
        height: '100%',
        margin: 0,
        '&  svg': {
          transform: 'scale(1.75)',
        },
      },
    },
  },
  '&:not(.upload)  button': {
    cursor: 'auto',
  },

  '&  button:not(.tags)': {
    gap: '5px',
    padding: '15px',
    height: '100px',
    '& , &:hover': {
      border: `1px dashed ${theme.palette.primary.main}`,
    },
  },
  '&  button.tags': {
    border: `1px solid ${theme.palette.border.fileUpload} !important`,
    background: `${theme.palette.light.main} !important`,
    color: `${theme.palette.text.secondary}`,
    '&:hover': {
      color: `${theme.palette.light.main}`,
      background: `${theme.palette.primary.main}  !important`,
      border: `1px dashed ${theme.palette.primary.main}  !important`,
      '& svg line': {
        stroke: `${theme.palette.light.main}`,
      },
    },
  },

  '& .error': {
    '&  .files__uploaded-error': {
      backgroundColor: theme.palette.danger.light,
      border: `${theme.spacing(1)} dashed ${theme.palette.danger.main}`,

      '&  .files__uploaded-error-message-wrapper': {
        flexDirection: 'row',
        width: '90%',
        height: '95%',
        background: theme.palette.light.main,
        padding: '10px 15px',
        borderRadius: '10px',
        gap: '10px',

        '&  .files__uploaded-error-message--rightSide': {
          minWidth: '30px',
          alignItems: 'center',
          justifyContent: 'center',
          '&  svg': {
            cursor: 'pointer',
          },
        },
        '&  .files__uploaded-error-message--leftSide': {
          flex: 1,
          '&  .files__uploaded-error-message-icon': {
            width: '13px',
            height: '13px',
            alignItems: 'center',
          },
          '&  .files__uploaded-error-message-progress': {
            position: 'relative',
            textAlign: 'left',
            '&:after': {
              height: '2px',
              width: '100%',
              content: '""',
              position: 'absolute',
              bottom: 0,
              left: 0,
              backgroundColor: theme.palette.danger.main,
              borderRadius: '3px',
            },
          },
          '&  .files__uploaded-error-message': {
            flexDirection: 'row',
            alignItems: 'center',
            gap: '10px',
            '&  button': {
              '& , &:hover': {
                border: `${theme.spacing(1)} solid ${theme.palette.danger.main}`,
              },
            },
          },
        },
      },
    },
  },

  '& input': {
    position: 'absolute',
    display: 'flex',
    opacity: 0,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  },

  '& .refresh': {
    top: theme.spacing(5),
    right: theme.spacing(7),
    cursor: 'pointer',
    zIndex: '1',
    position: 'absolute',
    width: theme.spacing(35),
    height: theme.spacing(35),
  },

  '& .upload_message': {
    flexDirection: 'column',
    '&.import': {
      '& .MuiTypography-subtitle2_Montserrat_SemiBold': {
        textDecoration: 'underline',
      },
    },
  },

  '&.fileUpload': {
    flexDirection: 'column',
    '& .upload__zone--error': {
      '& button': {
        borderColor: theme.palette.danger.main,
      },
    },
    '& .MuiButton-startIcon': {
      width: '70px',
      height: '70px',
      display: 'flex',
      alignItems: 'center',
      marginRight: '30px',
    },
    '& .files__uploaded': {
      flexFlow: 'row wrap',
      gap: '15px',
    },
    '& .deleteButton': {
      all: 'unset',
      borderRadius: '10px',
      cursor: 'pointer',
      position: 'absolute',
      top: '5px',
      right: '5px',
      background: 'transparent',
      '& > svg rect': {
        fill: 'transparent',
      },
      '&:hover': {
        border: 'none',
      },
    },
  },

  [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},

  [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {},
}));
export default FileUploadWrapper;
