import React from 'react';

function FileExport() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="82" height="82" viewBox="0 0 82 82">
      <g id="Group_67477" data-name="Group 67477" transform="translate(-1101 -2415)">
        <path
          id="Path_70184"
          data-name="Path 70184"
          d="M1678.052,7146.452h0a1.985,1.985,0,0,1-1.486-.6,2.2,2.2,0,0,1-.565-1.551v-.086c0-.891,0-1.8,0-2.7a.76.76,0,0,1,.76-.866.781.781,0,0,1,.776.863c0,.586,0,1.184,0,1.761,0,.327,0,.65,0,.975,0,.382.093.589.564.589q5.791,0,11.576,0c.43,0,.567-.144.569-.585,0-.314,0-.631,0-.948v-.349c0-.481,0-.978,0-1.468a.807.807,0,0,1,.787-.849.781.781,0,0,1,.747.766c0,.233,0,.468,0,.7v.049c0,.782.01,1.589-.008,2.387a2,2,0,0,1-1.965,1.916c-.707,0-1.457,0-2.406,0h-3.416l-3.66,0h-2.277Zm5.2-4.555c-.627-.662-1.261-1.322-1.911-2l-.483-.5c-.521-.54-1.057-1.1-1.58-1.651a1.263,1.263,0,0,1-.307-.535.754.754,0,0,1,.385-.869.719.719,0,0,1,.346-.091.784.784,0,0,1,.564.251c.7.725,1.409,1.462,2.032,2.112l.159.167a1.465,1.465,0,0,1,.113.14l.016.023c.022.028.046.061.068.087l.218.28.268.272,0-1.016c0-.284,0-.567,0-.851v-.889c0-1.615,0-3.287,0-4.931a.793.793,0,0,1,.406-.792.758.758,0,0,1,.377-.1.814.814,0,0,1,.757.882c0,.6,0,1.219,0,1.814v.43c0,.328,0,.656,0,.981v4.464l.19-.2.267-.3c.037-.041.072-.08.105-.119l.075-.083c.1-.121.244-.28.39-.433.567-.6,1.169-1.223,1.787-1.858a.837.837,0,0,1,.6-.277.714.714,0,0,1,.523.233.853.853,0,0,1-.04,1.2c-1.334,1.392-2.661,2.771-4.055,4.213a.859.859,0,0,1-.6.314A.964.964,0,0,1,1683.257,7141.9Z"
          transform="translate(-541.895 -4685.729)"
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.4"
        />
      </g>
    </svg>
  );
}

export default FileExport;
