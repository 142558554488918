import {createSlice} from '@reduxjs/toolkit';
import {type RootState} from 'store';

interface UserManagementOptionSliceState {
  updatedUser: any;
  operation: any;
  freezeForm: any;
  isSubmit: boolean;
  internalUserUpdateSuccess: boolean;
  freezeSuccess: boolean;
  updateUserResult: any;
}

export const initialState: UserManagementOptionSliceState = {
  updatedUser: {},
  operation: {},
  freezeForm: {},
  isSubmit: false,
  internalUserUpdateSuccess: false,
  freezeSuccess: false,
  updateUserResult: null,
};
/* eslint-disable no-param-reassign */
const userManagementOption = createSlice({
  name: 'userManagementOption',
  initialState,
  reducers: {
    fillUpdatedUserForm: (state, {payload: updatedUser}) => {
      state.updatedUser = {...state.updatedUser, ...updatedUser};
    },
    setUpdatedUser: (state, {payload: updatedUser}) => {
      state.updatedUser = updatedUser;
    },
    setOperation: (state, {payload: operation}) => {
      state.operation = operation;
    },
    setFreezeForm: (state, {payload: freezeForm}) => {
      state.freezeForm = freezeForm;
    },
    setSubmit: (state, {payload: isSubmit}) => {
      state.isSubmit = isSubmit;
    },
    setInternalUserUpdateSuccess: (state, {payload: internalUserUpdateSuccess}) => {
      state.internalUserUpdateSuccess = internalUserUpdateSuccess;
    },
    setFreezeSuccess: (state, {payload: freezeSuccess}) => {
      state.freezeSuccess = freezeSuccess;
    },
    setUpdateUserResult: (state, {payload: updateUserResult}) => {
      state.updateUserResult = updateUserResult;
    },
    resetUserManagementOption: () => initialState,
  },
});

export const {
  fillUpdatedUserForm,
  setUpdatedUser,
  setOperation,
  setFreezeForm,
  setSubmit,
  setInternalUserUpdateSuccess,
  setFreezeSuccess,
  setUpdateUserResult,
  resetUserManagementOption,
} = userManagementOption.actions;

export const selectUserManagementOptionDomain = (state: RootState): any =>
  state.userManagement.userManagementOption;

export default userManagementOption.reducer;
