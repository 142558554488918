import { Stack, styled } from '@mui/material';

const ProjectDroppableItemWrapper = styled(Stack)(({ theme }) => ({
  '& .leftDragDropItem': {
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.border.discipline}`,
    borderRadius: '8px',
    height: '38px',
    margin: '8px 10px 0 14px',
  },
  '& .rightDragDropItem': {
    background: theme.palette.primary.main,
    boxShadow: `0 3px 34px ${theme.palette.app_shadow.container}`,
    border: `1px solid ${theme.palette.border.discipline}`,
    borderRadius: '8px',
    height: '38px',
    margin: '8px 10px 0 14px',
  },

  '& .itemContent': {
    margin: '11px 5px 0 24px',
    fontSize: '12px',
    color: theme.palette.text.main,
  },

  '& .rightDragDropItemContent': {
    margin: '11px 5px 0 24px',
    fontSize: '12px',
    color: theme.palette.common.white,
  },

  [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},
  [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {},
}));
export default ProjectDroppableItemWrapper;
