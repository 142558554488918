import { Stack, styled } from '@mui/material';

export const AddAreaForm = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: '30px',
  marginBottom: '30px',
  '& .inputs': {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
  },

  [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},

  [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {},
}));

export const AreaItemsListing = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flexWrap: 'wrap',

  [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},

  [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {},
}));
