import { createStructuredSelector } from 'reselect';
import makeSelectProjectPrivileges from 'services/slices/selectors';
import { useSelector } from 'react-redux';
import { useGetConnectedUserQuery } from 'services/apis/user';

const stateSelector = createStructuredSelector({
  featurePrivileges: makeSelectProjectPrivileges,
});

const usePrivilegeByFeatures = (
  features: string[],
  privilege: string,
): Record<string, boolean> => {
  const { data: connectedUser } = useGetConnectedUserQuery();
  const { featurePrivileges } = useSelector(stateSelector);

  return features.reduce((acc: Record<string, boolean>, feature) => {
    const privilegesForFeature: string[] = featurePrivileges?.[feature];
    acc[feature] =
      connectedUser?.admin === true || privilegesForFeature?.includes(privilege);
    return acc;
  }, {});
};

export default usePrivilegeByFeatures;
