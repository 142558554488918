import { styled } from '@mui/material';
import Menu from '@mui/material/Menu';

const CustomDropDownMenu = styled(Menu)`
  .MuiPaper-root {
    min-width: 120px;
    .MuiMenu-list {
      padding: 0;
    }
  }
  .customHeight {
    height: 44px;
  }
  .editIconContainer {
    width: 20px;
  }
  .editIcon {
    position: relative;
    right: 10px;
  }
`;

export default CustomDropDownMenu;
