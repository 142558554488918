import React from "react";

const MedicalIcon = () => (
  <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 41" width="25" height="41">
    <defs>
      <image width="25" height="41" id="img14"
             href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADcAAABHCAMAAACUJy+1AAAAAXNSR0IB2cksfwAAAFdQTFRFAAAAAID/AHz/AHv/AHz/AHv/AHv/AHj/AHz/AHz/AHz/AHz/AHr/AHv/AHz/AHv/AH3/AHv/AHz/AHz/AHz/AHr/AHz/AHv/AHz/AHv/AHv/AHv/AHv/RAS4/wAAAB10Uk5TABBvz//fjyBQ73/QYLCQoD9PQIDAML/gr/Bwn19tMBZRAAACDUlEQVR4nO2XXZOrIAyGYZsKrLV2EbTa8/9/5wnBzxVUeuH0Yt+ZzijyQBJCoIyFxb8uANdMRD7HJBV4fadhAPlNcFkA3BMwrqBk/PEj2A1AH+cqMMxeAdQNH+vj3AUkM9DkoDgH4HvdxbP2cn3dL0cjc7j0rTGD+RUGtQw5KRRyxdgINsx1kOteuGx319PgaENbibEKqoJq8dpCOXdNu1EOcL+14vj34JjjxGvlBn9p4rzUyyefncJRhWf1M+mxGy4rRbGRfLKz54SqrchwHWect1MbimwFDZ/7J6Ej21oamtbMUpJO/hkoXG7grPaBei0stLhwuVxYe3G9HmiLzwvhHkb/NuLphdO0aAeAa2qNMc0eN3YyI+dM76DeSuHMeeWDsOREC0FVGFx6kGGO6TzGuRGbjkW4I/o4rnRb/LkK8C4HU54lclrnb3H+ewpXko3uO+p6nKP+hcs8zx/nJvvO4bBGZ4zdqHYf54p5XNrjnKvRnuuodh/lBr9S189z7+UZ7eF2dS/4uP2XyOlHWHybmw6dVVHb5HhhwrI7dp4dl5M5G4tnt83FziSQ29zZ/p3NiTqinbi8uw7M6rA+LC5/3JJL0MhpxsMXuqBaS3cUPCNT/uGRpDtLNd18U2QVVWG8rDy/qJbjoSUiZX7Svwzv61T174Ppqj/19mT6w0LcfS1HR7tImZ+ppL3xH3rZQLqSa+rEAAAAAElFTkSuQmCC" />
    </defs>
    <style>
    </style>
    <use id="Background" href="#img14" x="0" y="0" />
  </svg>
);
export default MedicalIcon;
