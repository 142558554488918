import { styled } from '@mui/material';

const AddExternalUsersWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.appBackground.main,
  display: 'flex',
  height: '100vh',
  width: '100%',
  flexDirection: 'column',

  '& .from-wrapper': {
    marginTop: '50px',
  },
  '& .avatar': {
    flexDirection: 'row',
    marginBottom: '50px',
    alignItems: 'center',
    gap: '20px',
  },
  '& .tagStyle': {
    width: '30%',
    background: theme.palette.primary.main,
    color: theme.palette.appBackground.BlueAlice,
    '& .deleteIcon': {
      transition: 'background-color 0.3s ease',
      fill: theme.palette.common.white,
      padding: '3px',
      '&:hover': {
        background: theme.palette.common.white,
        borderRadius: '50%',
        fill: theme.palette.primary.main,
      },
    },
  },
  '& .switchForm': {
    transform: 'translateY(-20px)',
  },

  '& .footer': {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: '20px',
    padding: '15px 30px',
  },

  [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},

  [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {},
}));
export default AddExternalUsersWrapper;
