import { Stack, styled } from '@mui/material';

const ProjectWrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.appBackground.main,
  height: '100vh',
  width: '100%',

  '& .close': {
    width: '10px',
    height: '10px',
  },

  '& .listing_item_row': {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '15px 20px',
    borderRadius: '8px',

    '&.user__item': {
      boxShadow: '0px 1px 5px #0000000F',
      backgroundColor: theme.palette.light.main,
      '&:not(:nth-child(2))': {
        marginTop: '10px',
      },
    },
    '& .MuiAvatar-root': {
      width: '35px',
      height: '35px',
    },
    '& div': {
      flexGrow: 1,
      flexBasis: 0,
    },
  },

  [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},

  [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {},
}));
export default ProjectWrapper;
