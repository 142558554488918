import React from "react";
import { FormControl, FormHelperText, InputLabel, Stack, Typography } from "@mui/material";
import { FormTextAreaWrapper } from "./Wrapper";

type FormTextAreaProps = {
  handleClick?: () => void;
  label?: string;
  placeholder?: string;
  labelColor?: string;
  onChange?: () => void;
  error?: boolean;
  value?: string;
  isValid?: boolean;
}

function FormTextArea({
                        handleClick,
                        label,
                        placeholder,
                        onChange,
                        error,
                        labelColor = "text.secondary",
                        value,
                        isValid = true,
                        ...rest
                      }: FormTextAreaProps) {
  return (
    <Stack className="fomInput__wrapper" onClick={handleClick}>
      <FormControl className="inputs__label" variant="standard" error={error}>
        <InputLabel shrink htmlFor={label}>
          <Typography color={labelColor} sx={{
            fontFamily: "HelveticaNeueMedium",
            fontSize: "16px"
          }}/* TODO remove the "sx" by variant="h5_HelveticaNeue_Regular" after fixing the theme file */ >
            {label}
          </Typography>
        </InputLabel>
        <FormTextAreaWrapper
          minRows={5}
          sx={{
            fontFamily: "HelveticaNeueMedium",
            fontSize: "12px"
          }}
          /*TODO remove the "sx" by variant="subtitle1_HelveticaNeue_Medium" after fixing the theme file */
          label={label}
          placeholder={label}
          onChange={onChange}
          isValid={isValid && !error}
          {...rest}
          value={value || ""} />
        {!isValid && (
          <FormHelperText className="errorMsg mb-0">
            This field is required
          </FormHelperText>
        )}
        {error && <FormHelperText className="errorMsg">{error}</FormHelperText>}
      </FormControl>
    </Stack>
  );
}

export default FormTextArea;
