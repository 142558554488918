import { styled } from '@mui/material';
import stars from 'assets/images/landingPage/stars.png';
import comingSoon from 'assets/svgs/comingSoon.svg';

const Wrapper = styled('div')(({ theme }) => ({
  background: `transparent linear-gradient(299deg, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%) 0% 0% no-repeat padding-box`,
  width: '100%',
  height: '100%',
  display: 'flex',
  position: 'relative',

  '&.loginPageWrapper': {
    '.circleWrapper': {
      '&.zoomOut': {
        opacity: 0.25,
        transform: 'scale(2) !important',
      },
    },
  },

  '.loginPage': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,

    '.logoCardContainer': {
      height: 'inherit',
      width: 'inherit',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '.logo': {
        position: 'absolute',
        left: '3%',
        top: '5%',
        width: '85px',

        img: {
          height: '100%',
          width: '100%',
          objectFit: 'contain',
        },
        '&.fadeOut': {
          opacity: 0,
          transition: 'all 500ms linear',
        },
      },
      '.loginCard': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '15%',
        padding: '30px',
        background: `${theme.palette.light.main} 0% 0% no-repeat padding-box`,
        width: '275px',
        height: '250px',
        borderRadius: '17px',
        position: 'relative',

        '.content': {
          textAlign: 'center',
        },
        '&:before': {
          position: 'absolute',
          top: '-10%',
          left: '-10%',
          width: '120%',
          height: '120%',
          content: "''",
          background: `${theme.palette.light.main} 0% 0% no-repeat padding-box`,
          pointerEvents: 'none',
          opacity: 0.3,
          zIndex: -1,
          borderRadius: '17px',
          boxShadow: '0 0 79px #1E276924',
        },

        '&.zoomOutAnimation': {
          transition: 'all 500ms linear',
          width: '0px',
          height: '0px',
          borderRadius: '50%',
          padding: '0',
          pointerEvents: 'none',
          '& > *': {
            opacity: 0,
          },
          '&:before': {
            transition: 'all 800ms linear',
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
            boxShadow: 'none',
            borderRadius: '50%',
          },
        },
      },
    },
  },

  '&:before': {
    top: theme.spacing(0),
    left: theme.spacing(0),
    width: '100%',
    height: '100%',
    content: "''",
    position: 'absolute',
    backgroundImage: `url("${stars}")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '120% 120%',
    backgroundPosition: 'center',
    pointerEvents: 'none',
  },
  '&.backgroundTranslation': {
    '&:before': {
      transform: 'translateY(-5%)',
      transition: 'all 400ms ease-out',
    },
  },

  '#welcome': {
    transition: 'all 500ms ease-out',
    position: 'absolute',
    top: '5%',
    left: '0%',
    width: '150px',
    height: '68px',
    opacity: 0,
    img: {
      height: '100%',
      width: '100%',
      objectFit: 'contain',
    },
  },

  '#avatar': {
    position: 'absolute',
    top: '5%',
    right: '5%',
    width: '68px',
    height: '68px',
    opacity: 0,

    img: {
      height: '100%',
      width: '100%',
      objectFit: 'contain',
    },
  },

  '#svgCircles': {
    height: '600px',
  },

  '.circleWrapper': {
    display: 'flex',
    position: 'relative',
    height: 'max-content',
    width: 'max-content',
    margin: 'auto',
    transition: ' all 500ms linear',
  },

  '.platforms': {
    // 17% Gap
    '.smallEllipseWrapper': {
      '.smallEllipse': {
        '&:nth-of-type(1)': {
          'offset-distance': 'calc(16.5% + (17% * 0))',
        },
        '&:nth-of-type(2)': {
          'offset-distance': 'calc(16.5% + (17% * 1))',
        },
      },
    },

    // 8.5% Gap
    '.mediumEllipseWrapper': {
      '.mediumEllipse': {
        '&:nth-of-type(1)': {
          'offset-distance': 'calc(12% + (8.5% * 0))',
        },
        '&:nth-of-type(2)': {
          'offset-distance': 'calc(12% + (8.5% * 1))',
        },
        '&:nth-of-type(3)': {
          'offset-distance': 'calc(12% + (8.5% * 2))',
        },
        '&:nth-of-type(4)': {
          'offset-distance': 'calc(12% + (8.5% * 3))',
        },
      },
    },

    // 4.25% Gap
    '.bigEllipseWrapper': {
      '.bigEllipse': {
        '&:nth-of-type(1)': {
          'offset-distance': 'calc(9.75% + (4.25% * 0))',
        },
        '&:nth-of-type(2)': {
          'offset-distance': 'calc(9.75% + (4.25% * 1))',
        },
        '&:nth-of-type(3)': {
          'offset-distance': 'calc(9.75% + (4.25% * 2))',
        },
        '&:nth-of-type(4)': {
          'offset-distance': 'calc(9.75% + (4.25% * 3))',
        },
        '&:nth-of-type(5)': {
          'offset-distance': 'calc(9.75% + (4.25% * 4))',
        },
        '&:nth-of-type(6)': {
          'offset-distance': 'calc(9.75% + (4.25% * 5))',
        },
        '&:nth-of-type(7)': {
          'offset-distance': 'calc(9.75% + (4.25% * 6))',
        },
        '&:nth-of-type(8)': {
          'offset-distance': 'calc(9.75% + (4.25% * 7))',
        },
      },
    },
  },

  '.subPlatform': {
    '.smallEllipseWrapper': {
      '.smallEllipse': {
        '&:nth-of-type(1), &:nth-of-type(2)': {
          'offset-distance': 0,
          opacity: 0,
        },
        '&.showElement': {
          opacity: '1',
          pointerEvents: 'auto',
          '&.subPlatformSE1': {
            'offset-distance': '16.5%',
          },
          '&.subPlatformSE2': {
            'offset-distance': '33.5%',
          },
        },
      },
    },

    '.mediumEllipseWrapper': {
      '.mediumEllipse': {
        '&:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(3)': {
          'offset-distance': 0,
          opacity: 0,
        },
        '&.showElement': {
          opacity: '1',
          pointerEvents: 'auto',
          '&.subPlatformME1': {
            'offset-distance': '14.5%',
          },
          '&.subPlatformME2': {
            'offset-distance': '25%',
          },
          '&.subPlatformME3': {
            'offset-distance': '35.5%',
          },
          '&.subPlatformME4': {
            'offset-distance': '46%',
          },
        },
      },
    },
  },

  '.logoWrapper': {
    transition: 'all 500ms linear',
    width: '100px',
    height: '100px',
    position: 'absolute',
    'offset-rotate': '0deg',
    opacity: 0,
    textDecoration: 'none',
    textAlign: 'center',

    '.platformLogoItem': {
      width: 'inherit',
      height: 'inherit',
      position: 'relative',

      '&.comingSoon': {
        '&:after': {
          height: '75px',
          width: '100px',
          top: '-8px',
          right: '-8px',
          content: "''",
          position: 'absolute',
          backgroundImage: `url("${comingSoon}")`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          zIndex: -1,
        },
      },
    },

    '& .MuiTypography-root': {
      position: 'absolute',
      bottom: '10px',
      fontSize: '10px',
      transform: 'translate(-50% ,50%)',
      left: '50%',
      width: '15ch',
    },
    '&:hover': {
      transform: 'scale(1.45)',
      cursor: 'pointer',
    },
    img: {
      'aspect-ratio': '1/1',
      height: '100%',
    },
    '&.bigEllipse': {
      'offset-path': 'url(#bigEllipse)',
    },
    '&.mediumEllipse': {
      'offset-path': 'url(#mediumEllipse)',
    },
    '&.smallEllipse': {
      'offset-path': 'url(#smallEllipse)',
    },
    '&.hideElement': {
      opacity: '0 !important',
      pointerEvents: 'none !important',
    },
  },
  '.clonedElement': {
    width: '150px !important',
    height: '150px !important',
    img: {
      'aspect-ratio': '1/1',
      height: '100%',
    },
  },
  '.digitalSpaceLogo': {
    width: '150px',
    height: '150px',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50% , -50%)',
    transition: ' all 400ms ease-out',
    img: {
      transition: 'opacity 1000ms linear',
      position: 'absolute',
      left: 0,
      'aspect-ratio': '1/1',
      height: '100%',
      '&:last-child': {
        opacity: 0,
      },
    },

    '&.toNextStepAnimation': {
      img: {
        '&:last-child': {
          opacity: 1,
        },
      },
    },
  },

  '&.last': {
    '.loginPage': {
      display: 'none',
    },

    '#digitalSpaceLogo > img': {
      opacity: 1,
    },
  },

  '.settings--btn': {
    position: 'absolute',
    left: '2%',
    bottom: '3%',
  },
}));

export default Wrapper;
