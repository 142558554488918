import React from 'react';

function EditIcon(props) {
  return (
    <svg
      id="editIcon"
      xmlns="http://www.w3.org/2000/svg"
      width={12.038}
      height={12.038}
      {...props}
    >
      <g
        data-name="edit-svgrepo-com (2)"
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeWidth={1.5}
      >
        <path
          data-name="Path 67259"
          d="M9.059 1.132a1.306 1.306 0 0 1 1.847 1.847l-7.178 7.178a2.635 2.635 0 0 1-1.224.693l-1.754.438.439-1.754a2.635 2.635 0 0 1 .693-1.224Z"
          strokeLinecap="round"
        />
        <path data-name="Path 67260" d="m7.336 2.726 1.976 1.976" />
        <path data-name="Path 67261" d="M6.678 11.288h4.611" strokeLinecap="round" />
      </g>
    </svg>
  );
}

export default EditIcon;
