import {
  type FeaturePrivilege,
  type ModuleFeatures,
} from 'services/apis/access-control/module-feature';
import { type Platform } from 'services/apis/platform';

export enum PrivilegesEnum {
  READ = 'READ',
  WRITE = 'WRITE',
  DELETE = 'DELETE',
  HIDE = 'HIDE',
  VALIDATE = 'VALIDATE',
}

interface ModuleFeaturePrivilege {
  moduleFeaturesData: ModuleFeatures[];
  moduleIndex: number;
  featureIndex: number;
  featurePrivilege: FeaturePrivilege;
}

interface ProfilePrivilegeValidation {
  id: number;
  name: string;
  platform: Platform;
  profilePrivileges: ModuleFeatures[];
  isUpdated: boolean;
}

export const isPrivilegeExist = (
  featurePrivilege: FeaturePrivilege,
  privilege: PrivilegesEnum,
): boolean => {
  return featurePrivilege?.privilegeDtoList?.includes(privilege);
};

export const doPrivilegesExist = (
  featurePrivilege: FeaturePrivilege,
  privileges: string[],
): boolean => {
  return privileges.some(
    (privilege) =>
      featurePrivilege?.privilegeDtoList?.includes(privilege as PrivilegesEnum),
  );
};

export const changePrivilege = (
  moduleFeaturesPrivilege: ModuleFeaturePrivilege,
  privilegeToDelete: PrivilegesEnum | null,
  privilegeToAdd: PrivilegesEnum | null,
): ModuleFeatures[] => {
  let newPrivilegesList: PrivilegesEnum[] = [];
  const existingPrivileges = moduleFeaturesPrivilege.featurePrivilege?.privilegeDtoList;
  if (privilegeToAdd !== null && !existingPrivileges?.includes(privilegeToAdd)) {
    newPrivilegesList = [...existingPrivileges, privilegeToAdd];
  } else if (privilegeToAdd !== null && existingPrivileges?.includes(privilegeToAdd)) {
    newPrivilegesList = existingPrivileges?.filter(
      (privilege) => privilege !== privilegeToAdd,
    );
  }
  if (privilegeToDelete !== null) {
    const filteredPrivileges = existingPrivileges?.filter(
      (privilege) => privilege !== privilegeToDelete,
    );
    newPrivilegesList =
      privilegeToAdd !== null
        ? [...filteredPrivileges, privilegeToAdd]
        : filteredPrivileges;
  }
  const updatedFeaturePrivilege = {
    ...moduleFeaturesPrivilege.featurePrivilege,
    privilegeDtoList: newPrivilegesList,
  };
  const moduleFeature =
    moduleFeaturesPrivilege.moduleFeaturesData[moduleFeaturesPrivilege.moduleIndex];
  const updatedModuleFeature = {
    ...moduleFeature,
    features: [
      ...moduleFeature.features.slice(0, moduleFeaturesPrivilege.featureIndex),
      updatedFeaturePrivilege,
      ...moduleFeature.features.slice(moduleFeaturesPrivilege.featureIndex + 1),
    ],
  };
  return [
    ...moduleFeaturesPrivilege.moduleFeaturesData.slice(
      0,
      moduleFeaturesPrivilege.moduleIndex,
    ),
    updatedModuleFeature,
    ...moduleFeaturesPrivilege.moduleFeaturesData.slice(
      moduleFeaturesPrivilege.moduleIndex + 1,
    ),
  ];
};
export const isNewProfileOrUpdated = (
  profileData: ProfilePrivilegeValidation,
): boolean => {
  if (profileData.id === undefined && Object.keys(profileData).length !== 0) return true;
  return profileData.id !== undefined && profileData.isUpdated;
};
