import React from "react";

const DeleteWL = (props: any) => {
  const { color = "#ff7675" } = props;

  return <svg
    xmlns="http://www.w3.org/2000/svg"
    width="38"
    height="38"
    viewBox="0 0 38 38"
    {...props}
  >
    <g
      id="Groupe_11283"
      data-name="Groupe 11283"
      transform="translate(-139 -583.07)"
    >
      <rect
        id="Rectangle_2536"
        data-name="Rectangle 2536"
        width="38"
        height="38"
        rx="14"
        transform="translate(139 583.07)"
        fill="#e8eaed"
        opacity="0.236"
      />
      <g
        id="Groupe_11272"
        data-name="Groupe 11272"
        transform="translate(151.04 594.01)"
      >
        <path
          id="Tracé_9642"
          data-name="Tracé 9642"
          d="M136.417,197.546a1.257,1.257,0,0,0,1.3,1.125h5.559a1.28,1.28,0,0,0,1.324-1.147l.9-9.442H135.314Z"
          transform="translate(-133.218 -183.228)"
          fill={color}
        />
        <path
          id="Tracé_9643"
          data-name="Tracé 9643"
          d="M99.625,75.124h-4.1v-.706a1.235,1.235,0,0,0-1.19-1.279H91.484a1.235,1.235,0,0,0-1.258,1.212q0,.034,0,.067v.706h-4.1a.441.441,0,1,0,0,.882h13.5a.441.441,0,1,0,0-.882Zm-4.986,0h-3.53v-.706a.353.353,0,0,1,.375-.4h2.78a.353.353,0,0,1,.375.4Z"
          transform="translate(-85.682 -73.138)"
          fill={color}
        />
      </g>
    </g>
  </svg>;
};
export default DeleteWL;
