import {styled} from "@mui/material";

const ActionArea = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  /*min-height: 56px;*/
  border-radius: 8px;
  padding: 20px;
  background: ${(props) => props.theme.GreyColors.white};
  border: 1px solid #dbe6ff;
  gap: 30px;
`;

export default ActionArea;
