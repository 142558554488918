import ProjectsIcon from 'assets/svgs/componentsIcons/ProjectsIcon';
import RiskManagementIcon from 'assets/svgs/componentsIcons/RiskManagementIcon';
import HseIcon from 'assets/svgs/componentsIcons/HseIcon';
import QAIcon from 'assets/svgs/componentsIcons/QAIcon';
import CostIcon from 'assets/svgs/componentsIcons/CostIcon';
import CashFlowIcon from 'assets/svgs/componentsIcons/CashFlowIcon';
import ChangeManagementIcon from 'assets/svgs/componentsIcons/ChangeManagementIcon';
import ProgressIcon from 'assets/svgs/componentsIcons/ProgressIcon';
import HCIcon from 'assets/svgs/componentsIcons/HCIcon';
import ProjectDetailsIcon from 'assets/svgs/componentsIcons/ProjectDetailsIcon';
import SchedulingIcon from 'assets/svgs/componentsIcons/SchedulingIcon';
import ActionIcon from 'assets/svgs/componentsIcons/ActionIcon';
import JpassIcon from 'assets/svgs/componentsIcons/JpassIcon';
import ReportingIcon from 'assets/svgs/componentsIcons/ReportingIcon';
import DashboardIcon from 'assets/svgs/componentsIcons/DashboardIcon';
import EmployeeIcon from 'assets/svgs/componentsIcons/EmployeeIcon';
import MedicalIcon from 'assets/svgs/componentsIcons/MedicalIcon';
import PECertificteIcon from 'assets/svgs/componentsIcons/PECertificteIcon';
import CMCertificteIcon from 'assets/svgs/componentsIcons/CMCertificateIcon';
import SiteOpeningIcon from 'assets/svgs/componentsIcons/SiteOpeningIcon';
import ConstructionMachineryIcon from 'assets/svgs/componentsIcons/ConstructionMachineryIcon';
import ProcuredEquipementIcon from 'assets/svgs/componentsIcons/ProcuredEquipementIcon';
import DeliveriesIcon from 'assets/svgs/componentsIcons/DeliveriesIcon';
import WarehousingIcon from 'assets/svgs/componentsIcons/WarehousingIcon';
import QualificationIcon from 'assets/svgs/componentsIcons/QualificationIcon';
import JpassSettingsIcon from 'assets/svgs/componentsIcons/JpassSettingsIcon';
import TrainingIcon from 'assets/svgs/componentsIcons/TrainingIcon';
import CspIcon from 'assets/svgs/componentsIcons/CspIcon';
import DiscussionIcon from 'assets/svgs/componentsIcons/DiscussionIcon';
import ProjectHealthIcon from 'assets/svgs/componentsIcons/ProjectHealthIcon';
import ControlIcon from 'assets/svgs/componentsIcons/ControlIcon';
import ContractorSafetyDataIcon from 'assets/svgs/componentsIcons/ContractorSafetyDataIcon';
import BonusDataIcon from 'assets/svgs/componentsIcons/BonusDataIcon';
import CspDashboardIcon from 'assets/svgs/componentsIcons/CspDashboardIcon';

const ICONES_MODULE = {
  COLLAB: {
    PROJECTS: { code: 'PROJECTS', icon: <ProjectsIcon /> },
    PROJECT_DETAILS: { code: 'PROJECTS', icon: <ProjectDetailsIcon /> },
    RISK_MGMT: { code: 'RISK', icon: <RiskManagementIcon /> },
    HSE: { code: 'RISK', icon: <HseIcon /> },
    QA: { code: 'QUALITY_ASSURANCE', icon: <QAIcon /> },
    COST: { code: 'COST_ICON', icon: <CostIcon /> },
    CASH: { code: 'CASH_FLOW', icon: <CashFlowIcon /> },
    SCHEDULING: { code: 'SCHEDULING', icon: <SchedulingIcon /> },
    CHANGE_MGMT: { code: 'CHANGE_MANAGEMENT', icon: <ChangeManagementIcon /> },
    PROGRESS: { code: 'PROGRESS', icon: <ProgressIcon /> },
    HUMAN_CAP: { code: 'HUMAN_CAPITAL', icon: <HCIcon /> },
    ACTION: { code: 'ACTION', icon: <ActionIcon /> },
    JPASS: { code: 'JPASS', icon: <JpassIcon /> },
    INTERACT_DISCUSS_SPACE: { code: 'INTERACT_DISCUSS_SPACE', icon: <DiscussionIcon /> },
    REPORTING: { code: 'REPORTING', icon: <ReportingIcon /> },
    PROJECT_MANAGEMENT: { code: 'PROJECT_MANAGEMENT', icon: <ProjectHealthIcon /> },
  },
  JPASS: {
    JPASS_DASHBOARD: { code: 'DASHBOARD', icon: <DashboardIcon /> },
    EMPLOYEE: { code: 'EMPLOYEE', icon: <EmployeeIcon /> },
    MEDICAL: { code: 'MEDICAL', icon: <MedicalIcon /> },
    PE_CERTIFICATE: { code: 'PE_CERTIFICATE', icon: <PECertificteIcon /> },
    CM_CERTIFICATE: { code: 'CM_CERTIFICATE', icon: <CMCertificteIcon /> },
    SITE_OPENING: { code: 'SITE_OPENING', icon: <SiteOpeningIcon /> },
    CONSTRUCTION_MACHINERY: {
      code: 'CONSTRUCTION_MACHINERY',
      icon: <ConstructionMachineryIcon />,
    },
    PROCURED_EQUIP: { code: 'PROCURED_EQUIP', icon: <ProcuredEquipementIcon /> },
    DELIVERIES: { code: 'DELIVERIES', icon: <DeliveriesIcon /> },
    WAREHOUSING: { code: 'WAREHOUSING', icon: <WarehousingIcon /> },
    EQUIP_CONTROL: { code: 'EQUIP_CONTROL', icon: <ControlIcon /> },
    QUALIFICATION: { code: 'QUALIFICATION', icon: <QualificationIcon /> },
    JPASS_SETTINGS: { code: 'JPASS_SETTINGS', icon: <JpassSettingsIcon /> },
    TRAINING: { code: 'TRAINING', icon: <TrainingIcon /> },
  },
  ESP: {
    ESP: { code: 'ESP', icon: <CspIcon /> },
    CONTRACTOR_SAFETY_PERF_DATA: {
      code: 'CONTRACTOR_SAFETY_PERF_DATA',
      icon: <ContractorSafetyDataIcon />,
    },
    CSP_DASHBOARD: { code: 'CSP_DASHBOARD', icon: <CspDashboardIcon /> },
    BONUS_DATA: { code: 'BONUS_DATA', icon: <BonusDataIcon /> },
  },
  DIGITAL_SPACE: {
    PROJECTS: { code: 'PROJECTS', icon: <ProjectsIcon /> },
  },
};
export default ICONES_MODULE;
