import { styled } from '@mui/material';
import InputBase from '@mui/material/InputBase';

export const FormWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 6px;
  position: relative;

  .error {
    color: #d92d20;
  }

  &.search__wrapper {
    width: auto;
    margin-left: auto;
  }
`;
const isInputValid = (isValid) => isValid === undefined || isValid;

export const CustomInput = styled(InputBase)((props) => ({
  background: props.theme.palette.light.main,
  height: '34px',
  border: `1px solid ${
    isInputValid(props.isValid)
      ? props.theme.palette.border.main
      : props.theme.palette.danger.main
  }`,
  borderRadius: '8px',
  padding: '12px',
  width: '100%',
  color: props.theme.palette.text.grey,
  fontFamily: "'HelveticaNeueMedium', sans-serif",
  fontSize: '14px',
  'input::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  'input::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  'input[type=number]': {
    MozAppearance: 'textfield',
  },
  '& .currencyLabel': {
    minWidth: '35px',
  },
}));

export const FormLabel = styled('label')`
  font-family: 'HelveticaNeueMedium', sans-serif;
  color: ${(props) => props.theme.AppColors.CharlestonGreen};
  font-size: 14px;
`;
