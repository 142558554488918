import React from "react";

const ControlIcon = () => (
  <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" width="25" height="25">
    <title>ControlIcon</title>
    <defs>
      <image width="25" height="25" id="img17"
             href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAdCAMAAABhTZc9AAAAAXNSR0IB2cksfwAAAVZQTFRFAAAAAID/AHf/AHz/AHr/AHz/AHz/AHv/AHz/AHr/AHv/AID/AHH/AHv/AHv/AHv/AHv/AHr/AH7/AHz/AHn/AHv/AGb/AHr/AHv/AAD/AG3/AHv/AHr/AHz/AHr/AH3/AHr/AHf/AED/AHT/AHr/AHH/AHn/AHr/AHr/AHz/AHv/AID/AHf/AID/AHr/AHr/AAD/AHX/AHv/AHz/AHr/AHz/AHv/AHT/AHn/AHv/AID/AHv/AHr/AH3/AHr/AHz/AHn/AID/AHj/AHv/AHr/AHn/AHv/AHv/AHb/AHv/AHj/AHz/AFX/AHv/AHv/AHz/AHv/AHr/ADP/AHv/AHv/AHn/AID/AHv/AH3/AHz/AID/AHr/AHr/AFX/AHv/AH3/AHr/AHz/AGD/AHz/AHX/AHz/AHv/AID/AHz/AHv/AHn/AHv/AHb/AH3/AHv/AID/AHv/AH3/Lq1WawAAAHJ0Uk5TAAQte8XF/+LEmFsKLaa2bE1LTZrj4AVtGwEHXeLDGYvyPAQumQmLXBen0hY+GqWKAj2L4eNOqCw9OBzwxJmn724CMXwsOzxfJz5sjANu8cbFewVPmjkI1F5MBl7TBmpc4WsIbTDU3xim00yZHGzDDDY3pXzF/gAAAdBJREFUeJx90Wtb0zAUAODTMdNokXbWtU3QXarRdYhMKHGuOC9bUZDVobKbqANFxNv0/38xLF2fOcR86Tl5c86T9ACMl5KaS19AKr54SYMza/4yWtCNjHHFvJrVrRm0kekoABYQQhevXc/9hflCkbg3Mrmb7NZtBiWvvDRdWbgDy3fRSuUeWV3z18Hl5VKC91EVHqx5tXHCFoM8cTcekhiVukmcR4+fTA4/zTagiVJxlkLLSp2TpBWEqEm4GW80FpTNwEns2XPCeWWrwGSa3oYXfGeCrWAeqpGmoU2ZvzSU9u7pWyWG4mao+Aq/looyNG2A9WZvgtDpGhbuSfX7g7YIt4NqjMCivoVtqW1jp74qvnqUlwgOqnV8Q6q4/Nv9dyJ4H+3JHb07EAdk/GFImXpwGlG5cajq8LEdD0ob6tCLPiUPVvgRpb6eDGFYAvNzGP+cQ144BlulE7XwF5c0kFdllDkH6tGxKIjCpFdO9RRoehHaCFBXH1e1pnjpBDdJRSt+7dcGQFv2DJe+Ib7FqDug339k1aA1w5UU9wOM8T7CdieMZhkIK67v9gzRWkz4LE8vyY2f9D88QqN/q+C50crJObUAv9Ko/Ps8FDMZdQD+AHMdSWgDNy55AAAAAElFTkSuQmCC" />
    </defs>
    <style>
    </style>
    <use id="Background" href="#img17" x="0" y="0" />
  </svg>
);
export default ControlIcon;
