export const PROJECT_DATA_ENUM = [
  'PROJECT_SIZE',
  'PROJECT_SCOPE',
  'PROJECT_TYPE_CONTRACT',
  'PROJECT_COMMERCIAL_CONTRACT',
  'PROJECT_STATE',
  'PROJECT_STATUS',
  'RISK_CLASSIFICATION',
  'PHASE',
  'PROJECT_GREEN_FIELD',
  'WORK_ORDER_TYPE',
];
export const PROJECT_DATA_TYPES = ['PROGRAM', 'SECTOR', 'BU', 'COUNTRY'];

export const REQUIRED_ERROR = 'This field is required';
export const COLLAB_JPASS_PLATFORMS = {
  COLLAB: 'COLLAB',
  JPASS: 'JPASS',
};

export const PROJECT_STATES = {
  CLOSED: 'ARCHIVE',
  ON_HOLD: 'ON_HOLD',
};
export const MOROCCO_CODE: string = 'MOROCCO';
