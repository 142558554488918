import {createSelector} from "reselect";
import {selectUserManagementDomain} from "services/slices/user-management/user-management";
import {selectUserProjectProfileDomain} from "services/slices/user-management/user-project-profile";
import {selectUserManagementOptionDomain} from "services/slices/user-management/user-management-option";

const makeSelectIsExternal = createSelector(
  selectUserManagementDomain,
  (subState) => subState.isExternal,
);
const makeSelectUpdatedItems = createSelector(
  selectUserProjectProfileDomain,
  (subState) => subState.updatedItems,
);
const makeSelectUpdateUserResult = createSelector(
  selectUserManagementOptionDomain,
  (subState) => subState.updateUserResult,
);
const makeSelectAssignManyProjectsToUserSuccess = createSelector(
  selectUserProjectProfileDomain,
  (subState) => subState.assignManyProjectsToUserSuccess
);
const makeSelectSelectedPlatform = createSelector(
  selectUserProjectProfileDomain,
  (subState) => subState.selectedPlatform,
);
export {
  makeSelectIsExternal,
  makeSelectUpdatedItems,
  makeSelectUpdateUserResult,
  makeSelectAssignManyProjectsToUserSuccess,
  makeSelectSelectedPlatform
};
