import { createSlice } from '@reduxjs/toolkit';
import { type RootState } from 'store';

interface ExternalUsersSliceState {
  externalUser: any;
  isSubmit: boolean;
}

export const initialState: ExternalUsersSliceState = {
  externalUser: {},
  isSubmit: false,
};
/* eslint-disable no-param-reassign */
const externalUsers = createSlice({
  name: 'externalUsers',
  initialState,
  reducers: {
    fillExternalUserForm: (state, { payload: externalUser }) => {
      state.externalUser = { ...state.externalUser, ...externalUser };
    },
    setSubmit: (state, { payload: isSubmit }) => {
      state.isSubmit = isSubmit;
    },
    resetExternalUsers: () => initialState,
  },
});

export const { fillExternalUserForm, setSubmit, resetExternalUsers } =
  externalUsers.actions;

export const selectAddExternalUsersDomain = (state: RootState): any =>
  state.userManagement.externalUsers;

export default externalUsers.reducer;
