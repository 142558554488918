import { api } from '.';
import { type PlatformSettingsTypesEnum } from './enumeration';

const projectAdministrationBaseUrl = '/properties-data';

export interface ProjectAdminData {
  id: number;
  name: string;
}

export const projectAdministrationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProjectAdministrationDataByTypes: builder.query<
      Record<PlatformSettingsTypesEnum, ProjectAdminData[]>,
      PlatformSettingsTypesEnum[]
    >({
      query: (types) => ({
        url: `${projectAdministrationBaseUrl}/by-types`,
        params: { types },
      }),
    }),
  }),
});

export const { useGetProjectAdministrationDataByTypesQuery } = projectAdministrationApi;
