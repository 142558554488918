const prepareData = (data: any, pictures: any[]): FormData => {
  const formData = new FormData();
  const dataBlob = new Blob([JSON.stringify(data)], {
    type: 'application/json',
  });
  formData.append('clientCreationDto', dataBlob);
  const fileBlob = new Blob([pictures?.[0]], { type: pictures?.[0]?.type });
  const fileName = `${pictures[0]?.name as string}`;
  if (pictures?.length > 0) {
    formData.append('logoFile', fileBlob, fileName);
  }
  return formData;
};

export default prepareData;
