import { Stack, styled } from "@mui/material";

export const AddProfileWrapper = styled(Stack)(({ theme }) => ({
  flex: "1 1 80%",
  padding: "48px 56px 34px 56px",
  overflowY: "auto",
  "& .top-header": {
    gap: "8px",

    "& .heading-area": {
      flexDirection: "row",
      alignItems: "center",
      gap: "12px"
    }
  },
  "& .addProfileForm": {
    paddingBlock: "30px",
    "& .form-search-area": {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "30px"
    }
  },
  "& .addProfileSwitchForms": {
    display: "flex",
    direction: "column",
    gap: "10px"
  },
  [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},
  [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {}
}));
