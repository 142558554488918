import React from "react";

const CMCertificateIcon = () => (
  <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 24" width="22" height="24">
    <title>iconMachinery</title>
    <defs>
      <image width="22" height="24" id="img10"
             href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAiCAMAAAAJbCvNAAAAAXNSR0IB2cksfwAAAFRQTFRFAAAAAHj/AHv/AHv/AHz/AHz/AHv/AHz/AHr/AHv/AHz/AHz/AHv/AHr/AHv/AID/AHv/AHv/AHz/AHz/AHz/AHv/AHz/AHz/AHz/AHv/AHz/AHv/qRWlEwAAABx0Uk5TACCPz//vn0Bg8JCA4DCwEHBfr8DQ379/UKBvTyDrF2EAAAFqSURBVHiclVPheoMgDAx6VIEKKiC4vf97LlBtu0/tvuUHIHdcLgGJTkM0LeStOwc5eqm00Xc0F/gA68o8YnxsTDOAdnIbPkm/rTQEj65FMKOxiENNvwC86JqJIWV4iOphRgTJQJLKK/6AQk9kWWtRwyboQswttDO8a0B+5clTh8zQ19KIcgyB1UwsPttNYQ3VhVdFf5VFKRcP2ZYKpKGo+QhnccEWiCtxDZYtqeEqwD5LNurB1tFRF7HiVvGvAqqdMFZCnuEFk2OTUgSrk9cFyzTItahA1eYNqwJsLV9LTmu5k6HOdm8nYbsqUfudTXYlw/i6kJ3A/d5XIgY6IZBHqnOWd3FKYA05pznCOzonkA9eeQ+iSwL3gh3+l9CIzwQLOXwkkLsv1wRTDIy4Jjyv71KBaxDRfvAQILE1duCLX1JKN6T0/WL0Znw0VuM9FjpEeSevYxgOhPLsntHh+EP/TfgdR0I27zEe8B8tfhHgWoxTpAAAAABJRU5ErkJggg==" />
    </defs>
    <style>
    </style>
    <use id="Background" href="#img10" x="0" y="0" />
  </svg>
);
export default CMCertificateIcon;
