import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import ContentBody from 'components/StyledComponents/ContentBody';
import ProfileItem from './ProfileItem';

function ProfileItems({ profileItems }) {
  return (
    <>
      <Box className="mt-15 profile_listing_labels">
        <Box className="sub_listing">
          <Typography
            className="ml-50 header_name_label"
            variant="subtitle1_HelveticaNeue_Medium"
          >
            Name
          </Typography>
          <Typography
            className="header_users_label"
            variant="subtitle1_HelveticaNeue_Medium"
          >
            Users
          </Typography>
          <Typography
            className="header_users_label"
            variant="subtitle1_HelveticaNeue_Medium"
          >
            External
          </Typography>
          <Typography
            className="header_users_label"
            variant="subtitle1_HelveticaNeue_Medium"
          >
            Platform
          </Typography>
        </Box>

        <Typography
          className="description_label"
          variant="subtitle1_HelveticaNeue_Medium"
        >
          Description
        </Typography>
      </Box>
      <ContentBody>
        {profileItems?.map((item) => (
          <ProfileItem key={item.id} profile={item} />
        ))}
      </ContentBody>
    </>
  );
}

ProfileItems.propTypes = {
  profileItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      customized: PropTypes.bool,
      name: PropTypes.string,
      usersCount: PropTypes.number,
      forExternalUsers: PropTypes.bool,
      description: PropTypes.string,
    }),
  ),
};

export default ProfileItems;
