import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { generatePath, useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { PlusIcon } from 'assets/svgs/componentsIcons';
import ContentBody from 'components/StyledComponents/ContentBody';
import AppBody from 'components/StyledComponents/AppBody';
import PageHeader from 'components/StyledComponents/PageHeader';
import { MainButton } from 'components/Buttons';
import PageBody from 'components/StyledComponents/PageBody';
import ContentActions from 'components/StyledComponents/ContentActions';
import CustomPagination from 'components/StyledComponents/Pagination';
import {
  PROJECT_SETTINGS_CREATE_PROJECT,
  PROJECT_SETTINGS_EDIT_PROJECT,
} from 'routes/constants';
import { useLazyGetAllProjectsQuery } from 'services/apis/project';
import FullPageLoader from 'components/FullPageLoader';
import { PROJECT_CREATION_STATUS } from 'helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { setProjects } from 'services/slices/project-setting/projects';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectProjects,
  makeSelectProjectCriteria,
} from 'services/slices/project-setting/selectors';
import { getConnectedUserState } from 'services/apis/user';
import ProjectsSettingsFilter from './ProjectsSettingsFilter';
import ProjectWrapper from './Wrapper';
import ProjectsList from './ProjectsList';

const stateSelector = createStructuredSelector({
  projects: makeSelectProjects,
  projectCriteria: makeSelectProjectCriteria,
});

function Projects({ projectType }) {
  const dispatch = useDispatch();

  const { projects, projectCriteria } = useSelector(stateSelector);
  const { data: connectedUser } = getConnectedUserState();

  const [page, setPage] = useState(0);
  const [doSearchProjectsByCriteria, { data: projectsData, isFetching: filterLoading }] =
    useLazyGetAllProjectsQuery();

  const navigate = useNavigate();

  const title =
    projectType === PROJECT_CREATION_STATUS.PUBLISHED
      ? 'Projects'
      : 'Projects in process of creation';

  const changePage = (_, p) => {
    doSearchProjectsByCriteria({
      ...projectCriteria,
      page: p - 1,
      projectType,
    });
    setPage(p - 1);
  };

  const updateProject = (projectId) => {
    const updateProjectPath = generatePath(PROJECT_SETTINGS_EDIT_PROJECT, { projectId });
    navigate(updateProjectPath);
  };

  useEffect(() => {
    if (projectsData) dispatch(setProjects(projectsData));
  }, [projectsData]);

  useEffect(() => {
    doSearchProjectsByCriteria({
      projectType,
    });
  }, [projectType, doSearchProjectsByCriteria]);

  return (
    <ProjectWrapper>
      {filterLoading && <FullPageLoader />}
      <AppBody>
        <PageHeader>
          <Box className="top-header">
            <Box className="page-actions-wrapper">
              <Box className="heading-area">
                <Box className="page-title-area">
                  <Typography variant="h3_HelveticaNeue_Bold" color="primary.main">
                    {title}
                  </Typography>
                </Box>
              </Box>
              {connectedUser?.admin && (
                <Box>
                  <MainButton
                    onClick={() => {
                      navigate(PROJECT_SETTINGS_CREATE_PROJECT);
                    }}
                    type="button"
                  >
                    <PlusIcon viewBox="3 3 10 10" width="10" height="10" />
                    <span>Add</span>
                  </MainButton>
                </Box>
              )}
            </Box>
            <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.main" />
          </Box>
        </PageHeader>

        <ProjectsSettingsFilter projectType={projectType} changePage={setPage} />
        <PageBody>
          <ContentBody className="overflow-auto">
            {projectType && (
              <ProjectsList updateProject={updateProject} projects={projects?.content} />
            )}
          </ContentBody>
          <ContentActions>
            <CustomPagination
              shape="rounded"
              onChange={changePage}
              count={projects?.totalPages}
              page={page + 1}
            />
          </ContentActions>
        </PageBody>
      </AppBody>
    </ProjectWrapper>
  );
}

Projects.propTypes = {
  projectType: PropTypes.string,
};
export default Projects;
