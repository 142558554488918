import { Divider, FormHelperText, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import FormInput from 'components/FormInput';
import ActionButton from 'components/ActionButton';
import { PlusIcon } from 'assets/svgs/componentsIcons';
import {
  fillProjectData,
  resetProjectData,
  setSubmit,
  updateProjectDatas,
} from 'services/slices/project-setting/project-work';
import usePrivilegeByFeature from 'utils/hooks/usePrivilegeByFeature';
import { Features, Privileges } from 'utils/constants/Features';
import {
  makeSelectProjectData,
  makeSelectProjectDatas,
  makeSelectSubmit,
} from '../selectors';
import ProjectAreaItem from './ProjectAreaItem';
import { AddAreaForm, AreaItemsListing } from './Wrapper';
import {
  addProjectDatasToList,
  checkDuplicated,
  checkFieldNameValidation,
  checkFieldNumberValidation,
} from '../helper';
import {
  NAME_FIELD,
  PROJECT_WORK_DIMENSIONS_ALL_TABS,
  PROJECT_WORK_DIMENSIONS_TAB_NAME,
} from '../constants';

const stateSelector = createStructuredSelector({
  projectDatas: makeSelectProjectDatas,
  projectData: makeSelectProjectData,
  submit: makeSelectSubmit,
});

function ProjectArea({ onDelete, errorMessage, type }) {
  const { projectDatas, projectData, submit } = useSelector(stateSelector);

  const dispatch = useDispatch();
  const { projectId } = useParams();

  const addProjectData = () => {
    dispatch(setSubmit(true));
    if (
      projectData?.name?.trim() &&
      checkDuplicated(projectDatas, projectData, NAME_FIELD.NAME) &&
      checkDuplicated(projectDatas, projectData, NAME_FIELD.NUMBER)
    ) {
      const updatedList = addProjectDatasToList(projectDatas, projectData, projectId);
      dispatch(updateProjectDatas(updatedList));
      dispatch(resetProjectData());
      dispatch(setSubmit(false));
    }
  };
  const hasReadPrivilege = usePrivilegeByFeature(
    Features.DS_PROJECT_AREA_SYSTEM,
    Privileges.READ,
  );
  const hasWritePrivilege = usePrivilegeByFeature(
    Features.DS_PROJECT_AREA_SYSTEM,
    Privileges.WRITE,
  );
  const dataList = () => {
    if (projectDatas && Object.keys(projectDatas).length > 0) {
      return projectDatas
        ?.filter((item) => !item?.isDeleted)
        ?.map((projectDataItem, index) => (
          <ProjectAreaItem
            key={projectDataItem?.name}
            projectData={projectDataItem}
            onDelete={onDelete}
            onClickAction={() => {
              dispatch(
                fillProjectData({
                  ...projectDataItem,
                  index,
                }),
              );
            }}
            disabled={!hasWritePrivilege}
          />
        ));
    }
    return null;
  };
  const labelType = type.toLowerCase();
  const placeholder = `${labelType} name`;
  return (
    <>
      {hasWritePrivilege && (
        <Stack className=" mt-30">
          <Typography variant="h6_HelveticaNeue_Bold" color="text.main">
            Add {labelType}
          </Typography>
          {errorMessage && (
            <FormHelperText className="errorMsg">{errorMessage}</FormHelperText>
          )}
          <AddAreaForm>
            <Stack className="inputs">
              {type ===
                PROJECT_WORK_DIMENSIONS_TAB_NAME.WITHOUT_WORK_LOCATIONS.SYSTEM.code && (
                <Stack className="formInputs">
                  <FormInput
                    label="Number"
                    placeholder="System number"
                    onChange={(e) => {
                      dispatch(fillProjectData({ number: e.target.value }));
                    }}
                    value={projectData?.number}
                    error={checkFieldNumberValidation(submit, projectDatas, projectData)}
                  />
                </Stack>
              )}
              <Stack className="formInputs mr-15">
                <FormInput
                  label="Name*"
                  placeholder={placeholder}
                  onChange={(e) => {
                    dispatch(fillProjectData({ name: e.target.value }));
                  }}
                  value={projectData?.name}
                  error={checkFieldNameValidation(
                    submit,
                    projectDatas,
                    projectData,
                    PROJECT_WORK_DIMENSIONS_ALL_TABS[type].label,
                  )}
                />
              </Stack>
            </Stack>
            <Stack className="addEntity">
              <ActionButton
                classname="footer--next__button btn--reset"
                label={
                  <Stack className="flex__row--center">
                    <PlusIcon className="mr-15" />
                    Add
                  </Stack>
                }
                onClick={addProjectData}
              />
            </Stack>
          </AddAreaForm>
        </Stack>
      )}
      <Divider className="divider mb-25" />
      <Typography variant="h6_HelveticaNeue_Bold" color="text.main" className="mb-15">
        {labelType}
      </Typography>
      {hasReadPrivilege && <AreaItemsListing>{dataList()}</AreaItemsListing>}
    </>
  );
}

export default ProjectArea;

ProjectArea.propTypes = {
  onDelete: PropTypes.func,
  errorMessage: PropTypes.string,
  type: PropTypes.string,
};
