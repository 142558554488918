import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store';

export const initialState: any = {};
/* eslint-disable no-param-reassign */
const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setProjectPrivileges: (state, { payload: { projectPrivileges } }) => {
      state.projectPrivileges = projectPrivileges;
    },
  },
});

export const { setProjectPrivileges } = globalSlice.actions;

export const selectGlobalDomain = (state: RootState): any => state.global;

export default globalSlice.reducer;
