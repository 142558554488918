import React from "react";

const QAIcon = () => (
  <svg
    id="Groupe_65032"
    data-name="Groupe 65032"
    xmlns="http://www.w3.org/2000/svg"
    width="28.688"
    height="27.755"
    viewBox="0 0 28.688 27.755"
  >
    <path
      id="Soustraction_3"
      data-name="Soustraction 3"
      d="M13.583,0A13.37,13.37,0,0,1,27.167,13.144,13.37,13.37,0,0,1,13.583,26.287,13.369,13.369,0,0,1,0,13.144,13.369,13.369,0,0,1,13.583,0Z"
      transform="translate(0.764 1.136)"
      fill="#71c6f1"
      opacity="0"
    />
    <g id="Groupe_61757" data-name="Groupe 61757">
      <path
        id="Tracé_51758"
        data-name="Tracé 51758"
        d="M951.026,285.732a.755.755,0,1,1-.755-.732.744.744,0,0,1,.755.732Zm0,0"
        transform="translate(-935.927 -285)"
        fill="#00338d"
      />
      <path
        id="Tracé_51759"
        data-name="Tracé 51759"
        d="M775.777,315.313a14.654,14.654,0,0,0,7.168,2.6h2.531a14.655,14.655,0,0,0,7.169-2.6l1.187-.936a13.675,13.675,0,0,0,4.722-10.31,13.977,13.977,0,0,0-10.786-13.473.757.757,0,0,0-.918.528.73.73,0,0,0,.545.89,12.506,12.506,0,0,1,9.65,12.055,12.84,12.84,0,0,1-25.669,0,12.505,12.505,0,0,1,9.65-12.055.731.731,0,0,0,.545-.89.757.757,0,0,0-.918-.528h0a13.977,13.977,0,0,0-10.786,13.473,13.675,13.675,0,0,0,4.722,10.31"
        transform="translate(-769.867 -290.162)"
        fill="#00338d"
      />
    </g>
    <g
      id="Groupe_61768"
      data-name="Groupe 61768"
      transform="translate(7.349 6.71)"
    >
      <path
        id="Tracé_51783"
        data-name="Tracé 51783"
        d="M864.312,383.484l2.836,2.795-.652,3.928a.732.732,0,0,0,1.059.77l3.534-1.835,3.534,1.835a.732.732,0,0,0,1.059-.77l-.653-3.928,2.836-2.795a.732.732,0,0,0-.4-1.245l-3.937-.592-1.781-3.562a.732.732,0,0,0-1.309,0l-1.781,3.562-3.937.592a.732.732,0,0,0-.4,1.245Z"
        transform="translate(-864.094 -377.68)"
        fill="#016be3"
      />
      <path
        id="Tracé_51780"
        data-name="Tracé 51780"
        d="M864.312,383.484l2.836,2.795-.652,3.928a.732.732,0,0,0,1.059.77l3.534-1.835,3.534,1.835a.732.732,0,0,0,1.059-.77l-.653-3.928,2.836-2.795a.732.732,0,0,0-.4-1.245l-3.937-.592-1.781-3.562a.732.732,0,0,0-1.309,0l-1.781,3.562-3.937.592a.732.732,0,0,0-.4,1.245Zm4.935-.447a.732.732,0,0,0,.546-.4l1.3-2.593,1.3,2.593a.733.733,0,0,0,.545.4l2.865.431-2.065,2.034a.733.733,0,0,0-.208.642L874,389l-2.572-1.336a.733.733,0,0,0-.674,0L868.179,389l.475-2.859a.732.732,0,0,0-.208-.642l-2.065-2.034Zm0,0"
        transform="translate(-864.094 -377.68)"
        fill="#00338d"
      />
    </g>
  </svg>
);
export default QAIcon;
