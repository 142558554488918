const FILTER_INPUTS = {
  projectType: 'projectType',
  name: 'name',
  number: 'number',
  program: 'program',
  sector: 'sector',
  bu: 'bu',
  projectSize: 'projectSize',
  riskClassification: 'riskClassification',
  platform: 'platform',
  projectPmId: 'projectPmId',
  projectDpeId: 'projectDpeId',
  atRisk: 'atRisk',
};
export default FILTER_INPUTS;
