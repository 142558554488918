import { Stack, styled } from '@mui/material';

const UserDetailedViewWrapper = styled(Stack)`
  width: 100%;
  padding: 48px 56px 34px;
  height: 100vh;

  .top-header {
    display: flex;
    gap: 10px;

    .titled-header {
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }
  }

  .sections {
    overflow: auto;
    height: calc(100% - 130px);
    padding: 0 5px 20px;

    .sub-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      .avatar-container {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .avatar-style {
          width: 90px;
          height: 90px;
          font-size: 55px;
          text-transform: uppercase;
        }
      }
    }

    .optionsBtn {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
    }

    .userBtn,
    .deleteBtn,
    .addIcon {
      cursor: pointer;
      padding: 0;
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
    }

    .userBtn {
      background: #f1f5fe;
      border: 1px solid ${({ theme }) => theme.palette.secondary.blue};

      svg path {
        fill: ${({ theme }) => theme.palette.primary.main};
      }

      &:hover {
        background: ${({ theme }) => theme.palette.secondary.blue};

        #Rectangle_2535 {
          fill: ${({ theme }) => theme.palette.secondary.blue};
        }

        #Group_3612 path,
        #Groupe_11271 path {
          fill: ${({ theme }) => theme.palette.common.white};
        }
      }
    }

    .deleteBtn {
      background: #ffe9e9;
      border: 1px solid #ff7675;

      rect {
        display: none;
      }

      &:hover {
        background: ${({ theme }) => theme.palette.danger.main};

        #Rectangle_2536 {
          fill: ${({ theme }) => theme.palette.danger.main};
        }

        #Groupe_11272 path {
          fill: ${({ theme }) => theme.palette.common.white};
        }
      }
    }

    .tabsContainer {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .addIcon {
        position: absolute;
        right: 0;
        padding: 10px 0;
        background: #f1f5fe;
        border: 1px solid #007cff;
        svg {
          transform: scale(1.5);
          #plus {
            fill: ${({ theme }) => theme.palette.primary.main};
            stroke: ${({ theme }) => theme.palette.primary.main};
          }
        }
        &:hover {
          background: ${({ theme }) => theme.palette.secondary.blue};

          #plus {
            fill: ${({ theme }) => theme.palette.common.white};
            stroke: ${({ theme }) => theme.palette.common.white};
          }
        }
      }
    }

    .userDetailsLine {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 10px;

      > div {
        width: calc((100% / 2) - 5px);
      }
    }

    .editUser,
    .saveUser {
      cursor: pointer;
      height: 35px;
      width: 150px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      border: 1px solid ${({ theme }) => theme.palette.secondary.blue};
      svg {
        rect {
          display: none;
        }
        path {
          fill: ${({ theme }) => theme.palette.primary.main};
        }
      }
    }

    .editUser,
    .saveUser {
      background: #f1f5fe;
      svg {
        rect {
          display: none;
        }
        path {
          fill: ${({ theme }) => theme.palette.primary.main};
        }
      }
    }

    .saveUser {
      background: ${({ theme }) => theme.palette.secondary.blue};
    }

    table {
      font-size: 13px;
      padding-bottom: 20px;
      border-collapse: separate;
      border-spacing: 0 10px;
      border: none;

      thead th {
        font-weight: normal !important;
        padding-left: 20px;
      }

      tbody tr {
        td {
          vertical-align: middle;
        }
        td:nth-child(-n + 4) {
          min-width: 90px;
          background: ${({ theme }) => theme.palette.common.white};
          padding: 20px;
          box-shadow: 0 1px 5px #0000000f;
          clip-path: inset(-20px -10px -10px 0px);
        }

        td:nth-child(1) {
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
        }

        td:nth-child(4) {
          width: 200px;
          font-size: 13px;
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
        }

        td:last-child {
          width: 200px;
        }

        .btnManagers {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          gap: 10px;
          margin: auto;
        }
      }
    }
    .profileSelect {
      min-height: unset;
      .MuiSelect-select {
        margin-top: 10px;
      }
      .MuiSelect-icon {
        margin-top: 2px;
      }
    }
    .editPopupActions {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
      button {
        width: 170px !important;
        flex-basis: unset;
        flex-grow: unset;
      }
    }
  }
`;

export default UserDetailedViewWrapper;
