import { Stack, styled } from "@mui/material";

export const FooterWrapper = styled(Stack)(({ theme }) => ({

  position: "fixed",
  bottom: "0",
  right: "0",
  backgroundColor: theme.palette.common.white,
  width: "calc(100% - 20%)",
  boxShadow: "0px -1px 24px #0000000F",
  zIndex: "1050",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  flexDirection: "row",
  minHeight: "65px",
  paddingInline: "50px",
  gap: "15px",
  [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},
  [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {}
}));
