import { Stack, styled } from '@mui/material';

const ProjectDroppableListWrapper = styled(Stack)(({ theme }) => ({
  flex: 1,
  background: theme.palette.common.white,
  border: `1px solid ${theme.palette.border.discipline}`,
  borderRadius: '8px',
  marginTop: '15px',
  height: '300px',
  overflowY: 'scroll',

  [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},
  [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {},
}));
export default ProjectDroppableListWrapper;
