export const arrayToMap = (list = [], keyList) => {
  // eslint-disable-next-line prefer-const
  let map = new Map();
  map = initiateMap(map, keyList);
  list.forEach((elem) => {
    const key = keyList.find((k) => k === elem.role.category);
    map.get(key).push(elem);
  });
  return map;
};
const initiateMap = (map, keyList) => {
  keyList.forEach((key) => {
    map.set(key, []);
  });
  return map;
};
export const setInMap = (map, value, key) => {
  // eslint-disable-next-line prefer-const
  let copyMap = new Map(map);
  const list = map.get(key) || [];
  const newValue = [...list, value];
  copyMap.set(key, newValue);
  return copyMap;
};
export const resetInMap = (map, value, key) => {
  // eslint-disable-next-line prefer-const
  let copyMap = new Map(map);
  const list = map.get(key);
  const newValue = list.filter((up) => up.user.id !== value.user.id);
  newValue.push(value);
  copyMap.set(key, newValue);
  return copyMap;
};
