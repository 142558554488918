import { styled } from "@mui/material";

const Wrapper = styled('nav')`
  > ul {
    display: flex;
    flex-direction: column;
    gap: 5px;

    &:before {
      display: none;
    }
  }
`;

export default Wrapper;
