import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Divider,
  Grid,
  IconButton,
  Modal,
  Stack,
  ThemeProvider,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { CloseIcon, DeleteIcon, EditIcon, PlusIcon } from 'assets/svgs/componentsIcons';
import { CloseButton, MainButton, MenuButton } from 'components/Buttons';
import FormInput from 'components/FormInput';
import FormInputSearch from 'components/FormInputSearch';
import Item from 'components/Item';
import ActionArea from 'components/StyledComponents/ActionArea';
import AppBody from 'components/StyledComponents/AppBody';
import ContentActions from 'components/StyledComponents/ContentActions';
import ContentBody from 'components/StyledComponents/ContentBody';
import CustomModal from 'components/StyledComponents/CustomModal';
import CustomDropDownMenu from 'components/StyledComponents/DropDownMenu';
import { FormWrapper } from 'components/StyledComponents/FormInputs';
import PageBody from 'components/StyledComponents/PageBody';
import PageHeader from 'components/StyledComponents/PageHeader';
import CustomPagination from 'components/StyledComponents/Pagination';
import { contractorManagementItems, PLATFORM_SETTINGS_TYPES } from 'helpers/constants';
import {
  setContractorsNotificationAddresses,
  setEmail,
  setFilterData,
  setLogoFile,
  setOperation,
  setProjectData,
  setProjectDataToDelete,
  setSubmitted,
} from 'services/slices/project-administration';
import AppTheme from 'ui/theme/app-theme';
import {
  useCreateUpdatePlatformSettingsEntityMutation,
  useDeletePlatformSettingsEntityByIdMutation,
  useLazyDownloadLogoQuery,
  useLazyGetFilteredPlatformSettingsEntityQuery,
} from 'services/apis/platform-settings';
import FileUpload from 'components/FileUpload';
import { FILE_TYPE } from 'components/FileUpload/constants';
import { validateEmail } from 'utils/emailUtils';
import FullPageLoader from 'components/FullPageLoader';
import handleBlobReceived from 'utils/blobUtils';
import { DEFAULT_FILE_NAME } from 'utils/constants/messages';
import SmallCloseIcon from 'assets/svgs/componentsIcons/SmallCloseIcon';
import ActionButton from 'components/ActionButton';
import { ChipStyle, ChipStyleWrapper } from 'components/FormSelect/Wrapper';
import { EMAIL_ERRORS, OPERATIONS, PLATFORM_SETTINGS_TYPE } from './constants';
import {
  makeSelectContractorsNotificationAddresses,
  makeSelectEmail,
  makeSelectFilterData,
  makeSelectLogoFile,
  makeSelectOperation,
  makeSelectProjectData,
  makeSelectProjectDataToDelete,
  makeSelectSubmitted,
} from './selectors';
import AppWrapper from './Wrapper';
import prepareData from './helper';

const stateSelector = createStructuredSelector({
  filterData: makeSelectFilterData,
  projectData: makeSelectProjectData,
  operation: makeSelectOperation,
  submitted: makeSelectSubmitted,
  projectDataToDelete: makeSelectProjectDataToDelete,
  logoFile: makeSelectLogoFile,
  email: makeSelectEmail,
  contractorsNotificationAddresses: makeSelectContractorsNotificationAddresses,
});

function ProjectAdministrationPlatformSetting({ type }) {
  const [openElem, setOpenElem] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [page, setPage] = useState(0);
  const [contractorAddressError, setContractorAddressError] = useState(null);
  const [searched, setSearched] = useState(false);
  const [portfolio, setPortfolio] = useState(null);

  const [doDownloadLogo, { data: picture }] = useLazyDownloadLogoQuery();

  const {
    filterData,
    projectData,
    operation,
    projectDataToDelete,
    submitted,
    logoFile,
    email,
    contractorsNotificationAddresses,
  } = useSelector(stateSelector);
  const dispatch = useDispatch();

  const dataType = PLATFORM_SETTINGS_TYPE[type];
  const [
    doGetFilteredPlatformSettingsEntity,
    {
      currentData: searchedResultData,
      error,
      isSuccess: success,
      isFetching: filterLoading,
    },
  ] = useLazyGetFilteredPlatformSettingsEntityQuery();
  const [
    doCreateUpdatePlatformSettingsEntity,
    {
      isSuccess: creationPlatformSettingsEntitySuccess,
      reset: resetPlatformSettingsEntityCreation,
      error: errorPlatformSettingsEntityCreationMessage,
      isLoading: createLoading,
    },
  ] = useCreateUpdatePlatformSettingsEntityMutation();

  const [
    doDeletePlatformSettingsEntityById,
    {
      isSuccess: deletionPlatformSettingsEntitySuccess,
      reset: resetPlatformSettingsEntityDeletion,
      error: deletionPlatformSettingsEntityError,
      isLoading: deleteLoading,
    },
  ] = useDeletePlatformSettingsEntityByIdMutation();

  const handleOpenCloseModal = useCallback(() => {
    dispatch(setSubmitted(false));
    dispatch(setLogoFile([]));
    setPortfolio(null);
    resetPlatformSettingsEntityDeletion();
    resetPlatformSettingsEntityCreation();
    setOpenModal((modalOpen) => !modalOpen);
  }, [
    dispatch,
    resetPlatformSettingsEntityDeletion,
    resetPlatformSettingsEntityCreation,
  ]);
  const handleClick = (e, id) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setOpenElem(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenElem(null);
  };

  const fetchData = useCallback(
    (name) => {
      const pageValue = name !== '' ? 0 : page;
      doGetFilteredPlatformSettingsEntity(
        { type, name, page: pageValue, baseRoute: dataType?.baseRoute },
        false,
      );
    },
    [doGetFilteredPlatformSettingsEntity, page, type],
  );

  useEffect(() => {
    fetchData(filterData);
  }, [
    success,
    deletionPlatformSettingsEntitySuccess,
    creationPlatformSettingsEntitySuccess,
    handleOpenCloseModal,
    fetchData,
  ]);

  useEffect(() => {
    if (creationPlatformSettingsEntitySuccess) {
      dispatch(setOperation(OPERATIONS.SUCCESS_ADD));
    }
  }, [creationPlatformSettingsEntitySuccess]);

  useEffect(() => {
    if (deletionPlatformSettingsEntitySuccess) {
      dispatch(setOperation(OPERATIONS.SUCCESS_DELETE));
    }
  }, [deletionPlatformSettingsEntitySuccess]);

  useEffect(() => {
    if (deletionPlatformSettingsEntityError) {
      dispatch(setOperation(OPERATIONS.ERROR_DELETE));
    }
  }, [deletionPlatformSettingsEntityError]);

  const handleAddToNotifAddresses = () => {
    if (
      contractorsNotificationAddresses
        ?.map((c) => c.email.toLowerCase())
        .filter((c) => c === email.toLowerCase()).length === 0
    ) {
      if (email !== '' && validateEmail(email)) {
        dispatch(
          setContractorsNotificationAddresses([
            ...contractorsNotificationAddresses,
            {
              index: contractorsNotificationAddresses.length + 1,
              email,
            },
          ]),
        );
        dispatch(setEmail(''));
        setContractorAddressError(null);
        return true;
      }
      setContractorAddressError(EMAIL_ERRORS.INVALID_EMAIL);
      return false;
    }
    setContractorAddressError(EMAIL_ERRORS.EMAIL_ALREADY_EXISTS);
    return false;
  };

  const handleCreatePlatformSettingsEntity = () => {
    const emailIsValid = email == null || email === '' || handleAddToNotifAddresses();
    if (projectData?.name?.trim().length > 0 && emailIsValid) {
      let body = {
        ...projectData,
        name: projectData.name?.replace(/\s+/g, ' ').trim(),
      };
      if (type === PLATFORM_SETTINGS_TYPES.CONTRACTOR) {
        body = {
          ...body,
          managementNotificationAddress: contractorsNotificationAddresses
            ?.map((c) => c.email)
            .join(';'),
        };
        if (
          email != null &&
          email !== '' &&
          !contractorsNotificationAddresses?.includes(email)
        ) {
          body = {
            ...body,
            managementNotificationAddress: `${
              body.managementNotificationAddress
                ? `${body.managementNotificationAddress};`
                : ''
            }${email}`,
          };
        }
      }
      let createdEntity = contractorManagementItems.includes(type) ? [body] : body;
      if (PLATFORM_SETTINGS_TYPES.CLIENT === type) {
        createdEntity = prepareData(body, logoFile);
      }
      doCreateUpdatePlatformSettingsEntity({
        body: createdEntity,
        baseRoute: dataType?.baseRoute,
      });
    } else if (!projectData?.name || projectData.name?.trim().length === 0) {
      dispatch(setSubmitted(true));
    }
  };
  useEffect(() => {
    if (projectData?.logo && PLATFORM_SETTINGS_TYPES.CLIENT === type) {
      doDownloadLogo({ url: projectData?.logo });
    }
  }, [projectData]);

  useEffect(() => {
    if (picture) {
      const fullName = projectData?.logo;
      const pathWithoutFileName = fullName?.substring(fullName.lastIndexOf('/') + 1);
      const file = new File([picture], pathWithoutFileName, { type: picture.type });
      dispatch(setLogoFile([file]));
    }
  }, [picture, dispatch]);

  const handleDeletePlatformSettingsEntity = () => {
    doDeletePlatformSettingsEntityById({
      id: projectDataToDelete?.id,
      baseRoute: dataType?.baseRoute,
    });
  };

  const handleDownloadFile = () => {
    handleBlobReceived(picture, DEFAULT_FILE_NAME);
  };

  const handleCreateProjectAdmin = (e) => {
    dispatch(
      setProjectData({
        ...projectData,
        name: e.target.value,
        portfolios: projectData?.portfolios || [],
      }),
    );
  };
  const handleSetEmail = (e) => {
    setContractorAddressError(null);
    dispatch(setEmail(e.target.value));
  };
  const changePage = (_, p) => {
    setPage(p - 1);
  };

  const handleOpenModalEdit = (elem) => {
    const arrayWithIndices = elem?.managementNotificationAddress
      ? elem?.managementNotificationAddress?.split(';')?.map((element, index) => {
          return { index, email: element };
        })
      : [];
    if (elem?.portfolios !== null) {
      const portfolios = elem.portfolios?.map((p, index) => {
        return { ...p, index };
      });
      dispatch(setProjectData({ ...elem, portfolios }));
    } else {
      dispatch(setProjectData(elem));
    }
    dispatch(setOperation(OPERATIONS.EDIT));
    dispatch(setContractorsNotificationAddresses(arrayWithIndices));
    setContractorAddressError(null);
    dispatch(setEmail(null));
    handleOpenCloseModal();
    handleClose();
  };

  const handleClickSearchClear = () => {
    if (filterData !== '') {
      setSearched(!searched);
    }
    if (searched) {
      dispatch(setFilterData(''));
    }
    fetchData(!searched ? filterData : '');
  };

  useEffect(() => {
    setSearched(false);
    dispatch(setFilterData(''));
    fetchData('');
    setPage(0);
  }, [type]);

  useEffect(() => {
    setSearched(false);
    dispatch(setFilterData(''));
    fetchData('');
    setPage(0);
  }, []);

  const lowerCaseLabel = () => dataType?.label?.toLowerCase();

  const handleRemoveFromNotifAddresses = (index) => {
    dispatch(
      setContractorsNotificationAddresses(
        contractorsNotificationAddresses.filter((c) => c.index !== index),
      ),
    );
  };

  const addPortfolio = () => {
    if (portfolio?.name) {
      const portfolios = projectData?.portfolios || [];
      dispatch(
        setProjectData({
          ...projectData,
          portfolios: [
            ...portfolios.filter((p) => p.index !== portfolio?.index),
            portfolio,
          ],
        }),
      );
      setPortfolio(null);
    }
  };

  const removePortfolio = (index) => {
    dispatch(
      setProjectData({
        ...projectData,
        portfolios: projectData.portfolios.filter((p) => p.index !== index),
      }),
    );
  };

  return (
    <ThemeProvider theme={AppTheme}>
      {(filterLoading || deleteLoading || createLoading) && <FullPageLoader />}
      <AppWrapper>
        <AppBody>
          <PageHeader>
            <div className="top-header">
              <div className="page-actions-wrapper">
                <div className="heading-area">
                  <div className="page-title-area">
                    <Typography variant="h3_HelveticaNeue_Bold" color="primary.main">
                      {dataType && `${dataType?.label}s`}
                    </Typography>
                  </div>
                </div>
                <div>
                  <MainButton
                    type="button"
                    onClick={() => {
                      dispatch(setContractorsNotificationAddresses([]));
                      dispatch(setOperation(OPERATIONS.ADD));
                      dispatch(setProjectData({}));
                      handleOpenCloseModal();
                    }}
                  >
                    <PlusIcon viewBox="3 3 10 10" width="10" height="10" />
                    <span>Add</span>
                  </MainButton>
                </div>
              </div>
              {dataType && (
                <Typography
                  variant="subtitle1_HelveticaNeue_Regular"
                  color="primary.main"
                >
                  {`${dataType?.label}s`} are used in industrial settings to control and
                  monitor equipment and processes to control and monitor equipment and
                  processes
                </Typography>
              )}
            </div>
          </PageHeader>
          <PageBody>
            <ActionArea>
              <FormWrapper className="search__wrapper">
                <FormInputSearch
                  placeholder="Search"
                  value={filterData}
                  onChange={(e) => {
                    if (searched) {
                      setSearched(false);
                    }
                    dispatch(setFilterData(e.target.value));
                  }}
                  handleClick={handleClickSearchClear}
                  isSearched={searched}
                />
              </FormWrapper>
            </ActionArea>
            <ContentBody>
              <Grid container spacing={12} rowSpacing={12}>
                {searchedResultData?.content?.map((elem, index) => (
                  <Grid item xs={4} key={elem?.id}>
                    <Item
                      id={elem?.id}
                      title={elem.name}
                      onClick={() => handleOpenModalEdit(elem)}
                      type={dataType?.code}
                      isDownloadCall={creationPlatformSettingsEntitySuccess}
                      url={elem.logo}
                    >
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-haspopup="true"
                        onClick={(e) => {
                          handleClick(e, index);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <CustomDropDownMenu
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        anchorEl={anchorEl}
                        open={openElem === index}
                        onClose={handleClose}
                      >
                        <MenuButton
                          type="button"
                          onClick={() => handleOpenModalEdit(elem)}
                        >
                          <span>
                            <EditIcon />
                          </span>
                          <span>Edit</span>
                        </MenuButton>
                        <Divider />
                        <MenuButton
                          type="button"
                          danger
                          onClick={(e) => {
                            e.stopPropagation();
                            dispatch(setOperation(OPERATIONS.DELETE));
                            dispatch(setProjectDataToDelete(elem));
                            handleOpenCloseModal();
                            handleClose();
                          }}
                        >
                          <span>
                            <DeleteIcon />
                          </span>
                          <span>Delete</span>
                        </MenuButton>
                      </CustomDropDownMenu>
                    </Item>
                  </Grid>
                ))}
              </Grid>
              {/* TODO fix use our modal instead */}
              <Modal
                open={openModal}
                onClose={() => handleOpenCloseModal()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <CustomModal modalWidth="416px">
                  <div className="modal-head-wrapper">
                    <div className="modal-head">
                      {operation === OPERATIONS.ADD && (
                        <h3 className="modal-title">New {lowerCaseLabel()}</h3>
                      )}
                      {operation === OPERATIONS.EDIT && (
                        <h3 className="modal-title">Edit {lowerCaseLabel()}</h3>
                      )}
                      {operation === OPERATIONS.DELETE && (
                        <>
                          <h3 className="modal-title">Delete {lowerCaseLabel()}</h3>
                          <p className="modal-description mt-15">
                            Are you sure you want to delete this {lowerCaseLabel()} ?
                          </p>
                        </>
                      )}
                      {(operation === OPERATIONS.SUCCESS_DELETE ||
                        operation === OPERATIONS.SUCCESS_ADD) && (
                        <h3 className="modal-title">Success</h3>
                      )}
                      {operation === OPERATIONS.ERROR_DELETE && (
                        <h3 className="modal-title">Error</h3>
                      )}
                      {(operation === OPERATIONS.ADD ||
                        operation === OPERATIONS.EDIT) && (
                        <p className="modal-description">Please add a unique name</p>
                      )}
                      {operation === OPERATIONS.SUCCESS_DELETE && (
                        <p className="modal-description mt-30">
                          The {lowerCaseLabel()} has been deleted successfully
                        </p>
                      )}
                      {operation === OPERATIONS.SUCCESS_ADD && (
                        <p className="modal-description mt-30">
                          The {lowerCaseLabel()} has been successfully saved
                        </p>
                      )}
                      {operation === OPERATIONS.ERROR_DELETE && (
                        <p className="modal-description mt-30">
                          The {lowerCaseLabel()} is related to a project or user, and
                          can’t be deleted.
                        </p>
                      )}
                    </div>
                    <div className="modal-closer">
                      <CloseButton type="button" onClick={() => handleOpenCloseModal()}>
                        <CloseIcon />
                      </CloseButton>
                    </div>
                  </div>
                  {(operation === OPERATIONS.ADD || operation === OPERATIONS.EDIT) && (
                    <div className="modal-body-wrapper">
                      <FormInput
                        label={`${dataType?.label} name`}
                        placeholder={`Enter ${lowerCaseLabel()} name`}
                        value={projectData?.name}
                        onChange={handleCreateProjectAdmin}
                        isValid={!submitted}
                        error={
                          errorPlatformSettingsEntityCreationMessage?.data?.detail ||
                          error
                        }
                      />
                      {dataType?.code === PLATFORM_SETTINGS_TYPES.PROGRAM && (
                        <Stack className=" mt-35 ">
                          <Stack className="flex__row--center-space">
                            <FormInput
                              classname="fullWidth mr-10"
                              label="Sub Program (Portfolio) Name"
                              placeholder="Enter sub Program Name"
                              onChange={(e) => {
                                const portfolioToAdd = {
                                  ...portfolio,
                                  name: e.target.value,
                                  index:
                                    portfolio === null
                                      ? projectData?.portfolios?.length
                                      : portfolio.index,
                                };
                                setPortfolio(portfolioToAdd);
                              }}
                              value={portfolio?.name}
                            />
                            <ActionButton
                              label={
                                <PlusIcon viewBox="3 3 10 10" width="10" height="10" />
                              }
                              typoVariant="subtitle1_HelveticaNeue_Medium"
                              className="addWoButton"
                              onClick={addPortfolio}
                            />
                          </Stack>
                          <ChipStyleWrapper>
                            {projectData?.portfolios?.map((p) => {
                              return (
                                <Stack key={p?.index} className="flex__row--center">
                                  <ChipStyle
                                    className="chipStyle"
                                    key={p?.index}
                                    label={p?.name}
                                    onDelete={() => removePortfolio(p?.index)}
                                    onClick={() => {
                                      setPortfolio(p);
                                    }}
                                    deleteIcon={
                                      <Stack>
                                        <SmallCloseIcon classname="deleteIcon" />
                                      </Stack>
                                    }
                                  />
                                </Stack>
                              );
                            })}
                          </ChipStyleWrapper>
                        </Stack>
                      )}
                    </div>
                  )}
                  {dataType.code === PLATFORM_SETTINGS_TYPES.CLIENT &&
                    [OPERATIONS.ADD, OPERATIONS.EDIT].includes(operation) && (
                      <div className="mt-15">
                        <FileUpload
                          label="logo"
                          maxSize={5000000}
                          acceptedExtensions={{ 'image/*': ['.jpeg', '.jpg', '.png'] }}
                          onChoose={(file) => {
                            dispatch(setLogoFile(file));
                          }}
                          uploadedFiles={logoFile}
                          updateFiles={projectData?.logo?.substring(
                            projectData.logo.lastIndexOf('/') + 1,
                          )}
                          handleFileDelete={() => {
                            dispatch(
                              setProjectData({
                                ...projectData,
                                logo: null,
                                fileDeleted: true,
                              }),
                            );
                            dispatch(setLogoFile([]));
                          }}
                          supportsList={[FILE_TYPE.PNG, FILE_TYPE.JPEG]}
                          isDownload={!!(projectData?.logo || logoFile.length !== 0)}
                          handleDownloadFile={() => handleDownloadFile(projectData?.logo)}
                        />
                      </div>
                    )}
                  {type === PLATFORM_SETTINGS_TYPES.CONTRACTOR &&
                    [OPERATIONS.ADD, OPERATIONS.EDIT].includes(operation) && (
                      <div className="mt-15">
                        <div className="notificationAddressContainer">
                          <FormInput
                            label="Email"
                            placeholder="Enter Email"
                            value={email}
                            onChange={handleSetEmail}
                            error={contractorAddressError || error}
                          />
                          <MainButton
                            secondary
                            onClick={() => handleAddToNotifAddresses()}
                          >
                            +
                          </MainButton>
                        </div>
                        <div className="adresseItemContainer">
                          {contractorsNotificationAddresses?.map((address) => (
                            <p className="adresseItem" key={address.index}>
                              {address.email}
                              <button
                                type="button"
                                onClick={() =>
                                  handleRemoveFromNotifAddresses(address.index)
                                }
                              >
                                x
                              </button>
                            </p>
                          ))}
                        </div>
                      </div>
                    )}

                  {![
                    OPERATIONS.SUCCESS_DELETE,
                    OPERATIONS.SUCCESS_ADD,
                    OPERATIONS.ERROR_DELETE,
                  ].includes(operation) && (
                    <div className="modal-action-wrapper">
                      <MainButton secondary onClick={() => handleOpenCloseModal()}>
                        Cancel
                      </MainButton>
                      <MainButton
                        className={operation === OPERATIONS.DELETE ? 'dltBtn' : ''}
                        onClick={() => {
                          // eslint-disable-next-line no-unused-expressions
                          operation === OPERATIONS.DELETE
                            ? handleDeletePlatformSettingsEntity()
                            : handleCreatePlatformSettingsEntity();
                        }}
                      >
                        {operation === OPERATIONS.DELETE ? 'Delete' : 'Confirm'}
                      </MainButton>
                    </div>
                  )}
                </CustomModal>
              </Modal>
            </ContentBody>
            <ContentActions>
              <CustomPagination
                shape="rounded"
                onChange={changePage}
                count={searchedResultData?.totalPages}
                page={page + 1}
              />
            </ContentActions>
          </PageBody>
        </AppBody>
      </AppWrapper>
    </ThemeProvider>
  );
}

ProjectAdministrationPlatformSetting.propTypes = {
  type: PropTypes.string,
};

export default ProjectAdministrationPlatformSetting;
