import { Outlet, useLocation } from 'react-router-dom';
import AppRedirectHover from 'components/AppRedirectHover';
import {
  ACCESS_MANAGEMENT_ADD_PROFILE,
  ACCESS_MANAGEMENT_EDIT_PROFILE,
  ACCESS_MANAGEMENT_EDIT_PROFILE_PRIVILEGE,
  ACCESS_MANAGEMENT_PROFILE_PRIVILEGE,
  PROJECT_SETTINGS_CREATE_PROJECT,
  PROJECT_SETTINGS_EDIT_PROJECT,
  PROJECT_SETTINGS_EDIT_PROJECT_MEMBERS,
  PROJECT_SETTINGS_EDIT_PROJECT_WORK_DIMENSIONS,
  SETTING_USERS_DETAILED_VIEWS,
  SETTINGS_USERS_ADD_EXTERNAL,
  SPLASH_SCREEN,
} from 'routes/constants';

const AppLayout = function (): JSX.Element {
  const location = useLocation();
  const excludedPaths = [
    PROJECT_SETTINGS_CREATE_PROJECT,
    PROJECT_SETTINGS_EDIT_PROJECT,
    PROJECT_SETTINGS_EDIT_PROJECT_MEMBERS,
    PROJECT_SETTINGS_EDIT_PROJECT_WORK_DIMENSIONS,
    SETTINGS_USERS_ADD_EXTERNAL,
    ACCESS_MANAGEMENT_ADD_PROFILE,
    ACCESS_MANAGEMENT_EDIT_PROFILE,
    ACCESS_MANAGEMENT_PROFILE_PRIVILEGE,
    ACCESS_MANAGEMENT_EDIT_PROFILE_PRIVILEGE,
    SPLASH_SCREEN,
    SETTING_USERS_DETAILED_VIEWS,
  ];

  const PlateformsShortcutsOn = !excludedPaths.some((path) => {
    const routeRegex = new RegExp(`${path.replace(/:[^\s/]+/g, '[^/]+')}$`);
    return routeRegex.test(location.pathname);
  });

  return (
    <>
      {PlateformsShortcutsOn && <AppRedirectHover />}
      <Outlet />
    </>
  );
};

export default AppLayout;
