import { api } from '.';

const areaProjectBaseUrl = '/project-area';

export const areaProjectApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAreaProjectsByProjectId: builder.query<any, number>({
      query: (projectId) => `${areaProjectBaseUrl}/${projectId}`,
    }),
    createUpdateAreaProject: builder.mutation<any, { body: any; params: object }>({
      query: ({ body, params }) => ({
        url: `${areaProjectBaseUrl}/create-update`,
        method: 'POST',
        params,
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetAreaProjectsByProjectIdQuery,
  useCreateUpdateAreaProjectMutation,
} = areaProjectApi;
