import React from "react";

const ProjectsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.364"
    height="32.178"
    viewBox="0 0 20.364 32.178"
  >
    <g
      id="Groupe_3793"
      data-name="Groupe 3793"
      transform="translate(-839.243 -347.5)"
    >
      <g
        id="Groupe_3792"
        data-name="Groupe 3792"
        transform="translate(839.742 348)"
      >
        <path
          id="Tracé_67995"
          data-name="Tracé 67995"
          d="M.436-.37,7.611-.448,7,5.723H1.119Z"
          transform="translate(5.744 0.589)"
          fill="#006be3"
        />
        <path
          id="Tracé_11879"
          data-name="Tracé 11879"
          d="M859.107,372.333a.909.909,0,0,0-.08-.35c-.094-.208.326.282-7.163-7.81a3.867,3.867,0,0,0,.283-5.693,3.809,3.809,0,0,0-1.808-1.051v-2.67h1.522a.914.914,0,0,0,.909-.824l.487-4.932a.914.914,0,0,0-.909-1H846.5a.914.914,0,0,0-.909,1l.487,4.932a.913.913,0,0,0,.909.824h1.522v3.471a.913.913,0,0,0,.913.913,2.068,2.068,0,0,1,2.01,2.07,2.01,2.01,0,0,1-3.684,1.112.913.913,0,1,0-1.52,1.013,3.859,3.859,0,0,0,.755.836c-7.463,8.064-7.028,7.582-7.1,7.691a.917.917,0,0,0-.129.33c-.022.122-.015-.36-.015,6.069a.913.913,0,0,0,.913.913h17.537a.913.913,0,0,0,.913-.913c0-6.534,0-5.861,0-5.931Zm-8.98-7.349,5.978,6.459H842.747l5.977-6.458a3.823,3.823,0,0,0,1.4,0Zm0,0"
          transform="translate(-839.742 -348)"
          fill="rgba(255,255,255,0.1)"
          stroke="#00338d"
          strokeWidth="1"
        />
      </g>
    </g>
  </svg>
);
export default ProjectsIcon;
