import { createTheme } from '@mui/material/styles';
import AppTheme from './app-theme';

const theme = createTheme({
  palette: {
    primary: {
      main: AppTheme.AppColors.blueViolet_200,
      blue: AppTheme.AppColors.primary,
    },
    secondary: {
      main: AppTheme.AppColors.bluishCyan_100,
      blue: AppTheme.AppColors.bluishCyan_200,
    },
    text: {
      main: AppTheme.AppColors.blueViolet_100,
      dark: AppTheme.AppColors.dark_100,
      gray: AppTheme.GreyColors[1200],
      lighter: AppTheme.GreyColors[1400],
      electric: AppTheme.AppColors.DarkElectricBlue,
      darker: AppTheme.AppColors.CharlestonGreen,
    },
    appBackground: {
      main: AppTheme.AppColors.appBackground,
      BlueAlice: AppTheme.AppColors.BlueAlice,
    },
    light: {
      main: AppTheme.AppColors.light,
      blue: AppTheme.AppColors.lightBlueShade,
    },
    dark: {
      main: AppTheme.AppColors.dark,
    },
    app_shadow: {
      logo: AppTheme.AppColors.logo_shadow,
      backdrop: AppTheme.AppColors.backdrop_shadow,
      box: AppTheme.AppColors.box_shadow,
      container: AppTheme.AppColors.form_box_shadow,
    },
    button_background: {
      main: AppTheme.AppColors.button_bg,
      secondary: AppTheme.AppColors.btn_secondary,
    },
    placeholder__Input: {
      main: AppTheme.AppColors.placeholder__Input,
    },
    gradient_blue: {
      main: AppTheme.GradientColors.blue,
    },
    danger: {
      main: AppTheme.AppColors.lightDanger,
      secondary: AppTheme.AppColors.mediumDanger,
    },
    success: {
      main: AppTheme.AppColors.success,
    },
    border: {
      main: AppTheme.GreyColors[1100],
      discipline: AppTheme.GreyColors[1000],
      fileUpload: AppTheme.AppColors.fileUpload_border,
    },
    timeline: {
      main: AppTheme.GreyColors[1300],
    },
    background: {
      main: AppTheme.GreyColors.white,
    },
    shadow: {
      main: AppTheme.GreyColors.shadow,
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1367,
      xl: 1537,
      xxl: 1920,
    },
  },
  spacing: 1,
  typography: {
    fontFamily: ['RobotoRegular', 'Arial', 'sans-serif', '-apple-system'].join(','),

    /* Default font */
    heading: {
      fontFamily: 'RobotoRegular',
      fontSize: '44px',
    },
    h1: {
      fontFamily: 'RobotoRegular',
      fontSize: '34px',
    },

    h2: {
      fontFamily: 'RobotoRegular',
      fontSize: '30px',
    },
    h3: {
      fontFamily: 'RobotoRegular',
      fontSize: '24px',
    },
    h4: {
      fontFamily: 'RobotoRegular',
      fontSize: '20px',
    },
    h5: {
      fontFamily: 'RobotoRegular',
      fontSize: '18px',
    },
    h6: {
      fontFamily: 'RobotoRegular',
      fontSize: '16px',
    },
    subtitle1: {
      fontFamily: 'RobotoRegular',
      fontSize: '14px',
    },
    subtitle2: {
      fontFamily: 'RobotoRegular',
      fontSize: '12px',
    },

    /* Font Family Roboto */
    /* Nasalization Regular */
    heading_Nasalization_Regular: {
      fontFamily: 'NasalizationRegular',
      fontSize: '44px',
    },
    h1_Nasalization_Regular: {
      fontFamily: 'NasalizationRegular',
      fontSize: '34px',
    },

    h2_Nasalization_Regular: {
      fontFamily: 'NasalizationRegular',
      fontSize: '30px',
    },
    h3_Nasalization_Regular: {
      fontFamily: 'NasalizationRegular',
      fontSize: '24px',
    },
    h4_Nasalization_Regular: {
      fontFamily: 'NasalizationRegular',
      fontSize: '20px',
    },
    h5_Nasalization_Regular: {
      fontFamily: 'NasalizationRegular',
      fontSize: '18px',
    },
    h6_Nasalization_Regular: {
      fontFamily: 'NasalizationRegular',
      fontSize: '16px',
    },
    subtitle1_Nasalization_Regular: {
      fontFamily: 'NasalizationRegular',
      fontSize: '14px',
    },
    subtitle2_Nasalization_Regular: {
      fontFamily: 'NasalizationRegular',
      fontSize: '12px',
    },
    /* Roboto Bold */
    heading_Roboto_Bold: {
      fontFamily: 'RobotoBold',
      fontSize: '44px',
    },
    h1_Roboto_Bold: {
      fontFamily: 'RobotoBold',
      fontSize: '34px',
    },

    h2_Roboto_Bold: {
      fontFamily: 'RobotoBold',
      fontSize: '30px',
    },
    h3_Roboto_Bold: {
      fontFamily: 'RobotoBold',
      fontSize: '24px',
    },
    h4_Roboto_Bold: {
      fontFamily: 'RobotoBold',
      fontSize: '20px',
    },
    h5_Roboto_Bold: {
      fontFamily: 'RobotoBold',
      fontSize: '18px',
    },
    h6_Roboto_Bold: {
      fontFamily: 'RobotoBold',
      fontSize: '16px',
    },
    subtitle1_Roboto_Bold: {
      fontFamily: 'RobotoBold',
      fontSize: '14px',
    },
    subtitle2_Roboto_Bold: {
      fontFamily: 'RobotoBold',
      fontSize: '12px',
    },
    /* Roboto Regular */
    heading_Roboto_Regular: {
      fontFamily: 'RobotoRegular',
      fontSize: '44px',
    },
    h1_Roboto_Regular: {
      fontFamily: 'RobotoRegular',
      fontSize: '34px',
    },

    h2_Roboto_Regular: {
      fontFamily: 'RobotoRegular',
      fontSize: '30px',
    },
    h3_Roboto_Regular: {
      fontFamily: 'RobotoRegular',
      fontSize: '24px',
    },
    h4_Roboto_Regular: {
      fontFamily: 'RobotoRegular',
      fontSize: '20px',
    },
    h5_Roboto_Regular: {
      fontFamily: 'RobotoRegular',
      fontSize: '18px',
    },
    h6_Roboto_Regular: {
      fontFamily: 'RobotoRegular',
      fontSize: '16px',
    },
    subtitle1_Roboto_Regular: {
      fontFamily: 'RobotoRegular',
      fontSize: '14px',
    },
    subtitle2_Roboto_Regular: {
      fontFamily: 'RobotoRegular',
      fontSize: '12px',
    },
    /* Roboto Light */
    heading_Roboto_Light: {
      fontFamily: 'RobotoLight',
      fontSize: '44px',
    },
    h1_Roboto_Light: {
      fontFamily: 'RobotoLight',
      fontSize: '34px',
    },

    h2_Roboto_Light: {
      fontFamily: 'RobotoLight',
      fontSize: '30px',
    },
    h3_Roboto_Light: {
      fontFamily: 'RobotoLight',
      fontSize: '24px',
    },
    h4_Roboto_Light: {
      fontFamily: 'RobotoLight',
      fontSize: '20px',
    },
    h5_Roboto_Light: {
      fontFamily: 'RobotoLight',
      fontSize: '18px',
    },
    h6_Roboto_Light: {
      fontFamily: 'RobotoLight',
      fontSize: '16px',
    },
    subtitle1_Roboto_Light: {
      fontFamily: 'RobotoLight',
      fontSize: '14px',
    },
    subtitle2_Roboto_Light: {
      fontFamily: 'RobotoLight',
      fontSize: '12px',
    },
    /* Roboto Black */
    heading_Roboto_Black: {
      fontFamily: 'RobotoBlack',
      fontSize: '44px',
    },
    h1_Roboto_Black: {
      fontFamily: 'RobotoBlack',
      fontSize: '34px',
    },

    h2_Roboto_Black: {
      fontFamily: 'RobotoBlack',
      fontSize: '30px',
    },
    h3_Roboto_Black: {
      fontFamily: 'RobotoBlack',
      fontSize: '24px',
    },
    h4_Roboto_Black: {
      fontFamily: 'RobotoBlack',
      fontSize: '20px',
    },
    h5_Roboto_Black: {
      fontFamily: 'RobotoBlack',
      fontSize: '18px',
    },
    h6_Roboto_Black: {
      fontFamily: 'RobotoBlack',
      fontSize: '16px',
    },
    subtitle1_Roboto_Black: {
      fontFamily: 'RobotoBlack',
      fontSize: '14px',
    },
    subtitle2_Roboto_Black: {
      fontFamily: 'RobotoBlack',
      fontSize: '12px',
    },
    /* Roboto Medium */
    heading_Roboto_Medium: {
      fontFamily: 'RobotoMedium',
      fontSize: '44px',
    },
    h1_Roboto_Medium: {
      fontFamily: 'RobotoMedium',
      fontSize: '34px',
    },

    h2_Roboto_Medium: {
      fontFamily: 'RobotoMedium',
      fontSize: '30px',
    },
    h3_Roboto_Medium: {
      fontFamily: 'RobotoMedium',
      fontSize: '24px',
    },
    h4_Roboto_Medium: {
      fontFamily: 'RobotoMedium',
      fontSize: '20px',
    },
    h5_Roboto_Medium: {
      fontFamily: 'RobotoMedium',
      fontSize: '18px',
    },
    h6_Roboto_Medium: {
      fontFamily: 'RobotoMedium',
      fontSize: '16px',
    },
    subtitle1_Roboto_Medium: {
      fontFamily: 'RobotoMedium',
      fontSize: '14px',
    },
    subtitle2_Roboto_Medium: {
      fontFamily: 'RobotoMedium',
      fontSize: '12px',
    },
    /* Font Family Montserrat */
    /* Montserrat Bold */
    heading_Montserrat_Bold: {
      fontFamily: 'MontserratBold',
      fontSize: '44px',
    },
    h1_Montserrat_Bold: {
      fontFamily: 'MontserratBold',
      fontSize: '34px',
    },

    h2_Montserrat_Bold: {
      fontFamily: 'MontserratBold',
      fontSize: '30px',
    },
    h3_Montserrat_Bold: {
      fontFamily: 'MontserratBold',
      fontSize: '24px',
    },
    h4_Montserrat_Bold: {
      fontFamily: 'MontserratBold',
      fontSize: '20px',
    },
    h5_Montserrat_Bold: {
      fontFamily: 'MontserratBold',
      fontSize: '18px',
    },
    h6_Montserrat_Bold: {
      fontFamily: 'MontserratBold',
      fontSize: '16px',
    },
    subtitle1_Montserrat_Bold: {
      fontFamily: 'MontserratBold',
      fontSize: '14px',
    },
    subtitle2_Montserrat_Bold: {
      fontFamily: 'MontserratBold',
      fontSize: '12px',
    },
    /* Montserrat SemiBold */
    heading_Montserrat_SemiBold: {
      fontFamily: 'MontserratSemiBold',
      fontSize: '44px',
    },
    h1_Montserrat_SemiBold: {
      fontFamily: 'MontserratSemiBold',
      fontSize: '34px',
    },

    h2_Montserrat_SemiBold: {
      fontFamily: 'MontserratSemiBold',
      fontSize: '30px',
    },
    h3_Montserrat_SemiBold: {
      fontFamily: 'MontserratSemiBold',
      fontSize: '24px',
    },
    h4_Montserrat_SemiBold: {
      fontFamily: 'MontserratSemiBold',
      fontSize: '20px',
    },
    h5_Montserrat_SemiBold: {
      fontFamily: 'MontserratSemiBold',
      fontSize: '18px',
    },
    h6_Montserrat_SemiBold: {
      fontFamily: 'MontserratSemiBold',
      fontSize: '16px',
    },
    subtitle1_Montserrat_SemiBold: {
      fontFamily: 'MontserratSemiBold',
      fontSize: '14px',
    },
    subtitle2_Montserrat_SemiBold: {
      fontFamily: 'MontserratSemiBold',
      fontSize: '12px',
    },
    /* Montserrat Regular */
    heading_Montserrat_Regular: {
      fontFamily: 'MontserratRegular',
      fontSize: '44px',
    },
    h1_Montserrat_Regular: {
      fontFamily: 'MontserratRegular',
      fontSize: '34px',
    },

    h2_Montserrat_Regular: {
      fontFamily: 'MontserratRegular',
      fontSize: '30px',
    },
    h3_Montserrat_Regular: {
      fontFamily: 'MontserratRegular',
      fontSize: '24px',
    },
    h4_Montserrat_Regular: {
      fontFamily: 'MontserratRegular',
      fontSize: '20px',
    },
    h5_Montserrat_Regular: {
      fontFamily: 'MontserratRegular',
      fontSize: '18px',
    },
    h6_Montserrat_Regular: {
      fontFamily: 'MontserratRegular',
      fontSize: '16px',
    },
    subtitle1_Montserrat_Regular: {
      fontFamily: 'MontserratRegular',
      fontSize: '14px',
    },
    subtitle2_Montserrat_Regular: {
      fontFamily: 'MontserratRegular',
      fontSize: '12px',
    },
    /* Montserrat Light */
    heading_Montserrat_Light: {
      fontFamily: 'MontserratLight',
      fontSize: '44px',
    },
    h1_Montserrat_Light: {
      fontFamily: 'MontserratLight',
      fontSize: '34px',
    },

    h2_Montserrat_Light: {
      fontFamily: 'MontserratLight',
      fontSize: '30px',
    },
    h3_Montserrat_Light: {
      fontFamily: 'MontserratLight',
      fontSize: '24px',
    },
    h4_Montserrat_Light: {
      fontFamily: 'MontserratLight',
      fontSize: '20px',
    },
    h5_Montserrat_Light: {
      fontFamily: 'MontserratLight',
      fontSize: '18px',
    },
    h6_Montserrat_Light: {
      fontFamily: 'MontserratLight',
      fontSize: '16px',
    },
    subtitle1_Montserrat_Light: {
      fontFamily: 'MontserratLight',
      fontSize: '14px',
    },
    subtitle2_Montserrat_Light: {
      fontFamily: 'MontserratLight',
      fontSize: '12px',
    },
    /* Montserrat Black */
    heading_Montserrat_Black: {
      fontFamily: 'MontserratBlack',
      fontSize: '44px',
    },
    h1_Montserrat_Black: {
      fontFamily: 'MontserratBlack',
      fontSize: '34px',
    },

    h2_Montserrat_Black: {
      fontFamily: 'MontserratBlack',
      fontSize: '30px',
    },
    h3_Montserrat_Black: {
      fontFamily: 'MontserratBlack',
      fontSize: '24px',
    },
    h4_Montserrat_Black: {
      fontFamily: 'MontserratBlack',
      fontSize: '20px',
    },
    h5_Montserrat_Black: {
      fontFamily: 'MontserratBlack',
      fontSize: '18px',
    },
    h6_Montserrat_Black: {
      fontFamily: 'MontserratBlack',
      fontSize: '16px',
    },
    subtitle1_Montserrat_Black: {
      fontFamily: 'MontserratBlack',
      fontSize: '14px',
    },
    subtitle2_Montserrat_Black: {
      fontFamily: 'MontserratBlack',
      fontSize: '12px',
    },
    /* Montserrat Medium */
    heading_Montserrat_Medium: {
      fontFamily: 'MontserratMedium',
      fontSize: '44px',
    },
    h1_Montserrat_Medium: {
      fontFamily: 'MontserratMedium',
      fontSize: '34px',
    },

    h2_Montserrat_Medium: {
      fontFamily: 'MontserratMedium',
      fontSize: '30px',
    },
    h3_Montserrat_Medium: {
      fontFamily: 'MontserratMedium',
      fontSize: '24px',
    },
    h4_Montserrat_Medium: {
      fontFamily: 'MontserratMedium',
      fontSize: '20px',
    },
    h5_Montserrat_Medium: {
      fontFamily: 'MontserratMedium',
      fontSize: '18px',
    },
    h6_Montserrat_Medium: {
      fontFamily: 'MontserratMedium',
      fontSize: '16px',
    },
    subtitle1_Montserrat_Medium: {
      fontFamily: 'MontserratMedium',
      fontSize: '14px',
    },
    subtitle2_Montserrat_Medium: {
      fontFamily: 'MontserratMedium',
      fontSize: '12px',
    },
    /* Font Family HelveticaNeue */
    /* HelveticaNeue Bold */
    heading_HelveticaNeue_Bold: {
      fontFamily: 'HelveticaNeueBold',
      fontSize: '44px',
    },
    h1_HelveticaNeue_Bold: {
      fontFamily: 'HelveticaNeueBold',
      fontSize: '34px',
    },

    h2_HelveticaNeue_Bold: {
      fontFamily: 'HelveticaNeueBold',
      fontSize: '30px',
    },
    h3_HelveticaNeue_Bold: {
      fontFamily: 'HelveticaNeueBold',
      fontSize: '24px',
    },
    h4_HelveticaNeue_Bold: {
      fontFamily: 'HelveticaNeueBold',
      fontSize: '20px',
    },
    h5_HelveticaNeue_Bold: {
      fontFamily: 'HelveticaNeueBold',
      fontSize: '18px',
    },
    h6_HelveticaNeue_Bold: {
      fontFamily: 'HelveticaNeueBold',
      fontSize: '16px',
    },
    subtitle1_HelveticaNeue_Bold: {
      fontFamily: 'HelveticaNeueBold',
      fontSize: '14px',
    },
    subtitle2_HelveticaNeue_Bold: {
      fontFamily: 'HelveticaNeueBold',
      fontSize: '12px',
    },
    /* HelveticaNeue Regular */
    heading_HelveticaNeue_Regular: {
      fontFamily: 'HelveticaNeueRegular',
      fontSize: '44px',
    },
    h1_HelveticaNeue_Regular: {
      fontFamily: 'HelveticaNeueRegular',
      fontSize: '34px',
    },

    h2_HelveticaNeue_Regular: {
      fontFamily: 'HelveticaNeueRegular',
      fontSize: '30px',
    },
    h3_HelveticaNeue_Regular: {
      fontFamily: 'HelveticaNeueRegular',
      fontSize: '24px',
    },
    h4_HelveticaNeue_Regular: {
      fontFamily: 'HelveticaNeueRegular',
      fontSize: '20px',
    },
    h5_HelveticaNeue_Regular: {
      fontFamily: 'HelveticaNeueRegular',
      fontSize: '18px',
    },
    h6_HelveticaNeue_Regular: {
      fontFamily: 'HelveticaNeueRegular',
      fontSize: '16px',
    },
    subtitle1_HelveticaNeue_Regular: {
      fontFamily: 'HelveticaNeueRegular',
      fontSize: '14px',
    },
    subtitle2_HelveticaNeue_Regular: {
      fontFamily: 'HelveticaNeueRegular',
      fontSize: '12px',
    },
    /* HelveticaNeue Light */
    heading_HelveticaNeue_Light: {
      fontFamily: 'HelveticaNeueLight',
      fontSize: '44px',
    },
    h1_HelveticaNeue_Light: {
      fontFamily: 'HelveticaNeueLight',
      fontSize: '34px',
    },

    h2_HelveticaNeue_Light: {
      fontFamily: 'HelveticaNeueLight',
      fontSize: '30px',
    },
    h3_HelveticaNeue_Light: {
      fontFamily: 'HelveticaNeueLight',
      fontSize: '24px',
    },
    h4_HelveticaNeue_Light: {
      fontFamily: 'HelveticaNeueLight',
      fontSize: '20px',
    },
    h5_HelveticaNeue_Light: {
      fontFamily: 'HelveticaNeueLight',
      fontSize: '18px',
    },
    h6_HelveticaNeue_Light: {
      fontFamily: 'HelveticaNeueLight',
      fontSize: '16px',
    },
    subtitle1_HelveticaNeue_Light: {
      fontFamily: 'HelveticaNeueLight',
      fontSize: '14px',
    },
    subtitle2_HelveticaNeue_Light: {
      fontFamily: 'HelveticaNeueLight',
      fontSize: '12px',
    },
    /* HelveticaNeue Black */
    heading_HelveticaNeue_Black: {
      fontFamily: 'HelveticaNeueBlack',
      fontSize: '44px',
    },
    h1_HelveticaNeue_Black: {
      fontFamily: 'HelveticaNeueBlack',
      fontSize: '34px',
    },

    h2_HelveticaNeue_Black: {
      fontFamily: 'HelveticaNeueBlack',
      fontSize: '30px',
    },
    h3_HelveticaNeue_Black: {
      fontFamily: 'HelveticaNeueBlack',
      fontSize: '24px',
    },
    h4_HelveticaNeue_Black: {
      fontFamily: 'HelveticaNeueBlack',
      fontSize: '20px',
    },
    h5_HelveticaNeue_Black: {
      fontFamily: 'HelveticaNeueBlack',
      fontSize: '18px',
    },
    h6_HelveticaNeue_Black: {
      fontFamily: 'HelveticaNeueBlack',
      fontSize: '16px',
    },
    subtitle1_HelveticaNeue_Black: {
      fontFamily: 'HelveticaNeueBlack',
      fontSize: '14px',
    },
    subtitle2_HelveticaNeue_Black: {
      fontFamily: 'HelveticaNeueBlack',
      fontSize: '12px',
    },
    /* HelveticaNeue Medium */
    heading_HelveticaNeue_Medium: {
      fontFamily: 'HelveticaNeueMedium',
      fontSize: '44px',
    },
    h1_HelveticaNeue_Medium: {
      fontFamily: 'HelveticaNeueMedium',
      fontSize: '34px',
    },

    h2_HelveticaNeue_Medium: {
      fontFamily: 'HelveticaNeueMedium',
      fontSize: '30px',
    },
    h3_HelveticaNeue_Medium: {
      fontFamily: 'HelveticaNeueMedium',
      fontSize: '24px',
    },
    h4_HelveticaNeue_Medium: {
      fontFamily: 'HelveticaNeueMedium',
      fontSize: '20px',
    },
    h5_HelveticaNeue_Medium: {
      fontFamily: 'HelveticaNeueMedium',
      fontSize: '18px',
    },
    h6_HelveticaNeue_Medium: {
      fontFamily: 'HelveticaNeueMedium',
      fontSize: '16px',
    },
    subtitle1_HelveticaNeue_Medium: {
      fontFamily: 'HelveticaNeueMedium',
      fontSize: '14px',
    },
    subtitle2_HelveticaNeue_Medium: {
      fontFamily: 'HelveticaNeueMedium',
      fontSize: '12px',
    },

    /* Font Family Rubik */
    /* Rubik Bold */
    heading_Rubik_Bold: {
      fontFamily: 'RubikBold',
      fontSize: '44px',
    },
    h1_Rubik_Bold: {
      fontFamily: 'RubikBold',
      fontSize: '34px',
    },

    h2_Rubik_Bold: {
      fontFamily: 'RubikBold',
      fontSize: '30px',
    },
    h3_Rubik_Bold: {
      fontFamily: 'RubikBold',
      fontSize: '24px',
    },
    h4_Rubik_Bold: {
      fontFamily: 'RubikBold',
      fontSize: '20px',
    },
    h5_Rubik_Bold: {
      fontFamily: 'RubikBold',
      fontSize: '18px',
    },
    h6_Rubik_Bold: {
      fontFamily: 'RubikBold',
      fontSize: '16px',
    },
    subtitle1_Rubik_Bold: {
      fontFamily: 'RubikBold',
      fontSize: '14px',
    },
    subtitle2_Rubik_Bold: {
      fontFamily: 'RubikBold',
      fontSize: '12px',
    },
    /* Rubik Regular */
    heading_Rubik_Regular: {
      fontFamily: 'RubikRegular',
      fontSize: '44px',
    },
    h1_Rubik_Regular: {
      fontFamily: 'RubikRegular',
      fontSize: '34px',
    },

    h2_Rubik_Regular: {
      fontFamily: 'RubikRegular',
      fontSize: '30px',
    },
    h3_Rubik_Regular: {
      fontFamily: 'RubikRegular',
      fontSize: '24px',
    },
    h4_Rubik_Regular: {
      fontFamily: 'RubikRegular',
      fontSize: '20px',
    },
    h5_Rubik_Regular: {
      fontFamily: 'RubikRegular',
      fontSize: '18px',
    },
    h6_Rubik_Regular: {
      fontFamily: 'RubikRegular',
      fontSize: '16px',
    },
    subtitle1_Rubik_Regular: {
      fontFamily: 'RubikRegular',
      fontSize: '14px',
    },
    subtitle2_Rubik_Regular: {
      fontFamily: 'RubikRegular',
      fontSize: '12px',
    },
    /* Rubik Light */
    heading_Rubik_Light: {
      fontFamily: 'RubikLight',
      fontSize: '44px',
    },
    h1_Rubik_Light: {
      fontFamily: 'RubikLight',
      fontSize: '34px',
    },

    h2_Rubik_Light: {
      fontFamily: 'RubikLight',
      fontSize: '30px',
    },
    h3_Rubik_Light: {
      fontFamily: 'RubikLight',
      fontSize: '24px',
    },
    h4_Rubik_Light: {
      fontFamily: 'RubikLight',
      fontSize: '20px',
    },
    h5_Rubik_Light: {
      fontFamily: 'RubikLight',
      fontSize: '18px',
    },
    h6_Rubik_Light: {
      fontFamily: 'RubikLight',
      fontSize: '16px',
    },
    subtitle1_Rubik_Light: {
      fontFamily: 'RubikLight',
      fontSize: '14px',
    },
    subtitle2_Rubik_Light: {
      fontFamily: 'RubikLight',
      fontSize: '12px',
    },
    /* Rubik Black */
    heading_Rubik_Black: {
      fontFamily: 'RubikBlack',
      fontSize: '44px',
    },
    h1_Rubik_Black: {
      fontFamily: 'RubikBlack',
      fontSize: '34px',
    },

    h2_Rubik_Black: {
      fontFamily: 'RubikBlack',
      fontSize: '30px',
    },
    h3_Rubik_Black: {
      fontFamily: 'RubikBlack',
      fontSize: '24px',
    },
    h4_Rubik_Black: {
      fontFamily: 'RubikBlack',
      fontSize: '20px',
    },
    h5_Rubik_Black: {
      fontFamily: 'RubikBlack',
      fontSize: '18px',
    },
    h6_Rubik_Black: {
      fontFamily: 'RubikBlack',
      fontSize: '16px',
    },
    subtitle1_Rubik_Black: {
      fontFamily: 'RubikBlack',
      fontSize: '14px',
    },
    subtitle2_Rubik_Black: {
      fontFamily: 'RubikBlack',
      fontSize: '12px',
    },
    /* Rubik Medium */
    heading_Rubik_Medium: {
      fontFamily: 'RubikMedium',
      fontSize: '44px',
    },
    h1_Rubik_Medium: {
      fontFamily: 'RubikMedium',
      fontSize: '34px',
    },

    h2_Rubik_Medium: {
      fontFamily: 'RubikMedium',
      fontSize: '30px',
    },
    h3_Rubik_Medium: {
      fontFamily: 'RubikMedium',
      fontSize: '24px',
    },
    h4_Rubik_Medium: {
      fontFamily: 'RubikMedium',
      fontSize: '20px',
    },
    h5_Rubik_Medium: {
      fontFamily: 'RubikMedium',
      fontSize: '18px',
    },
    h6_Rubik_Medium: {
      fontFamily: 'RubikMedium',
      fontSize: '16px',
    },
    subtitle1_Rubik_Medium: {
      fontFamily: 'RubikMedium',
      fontSize: '14px',
    },
    subtitle2_Rubik_Medium: {
      fontFamily: 'RubikMedium',
      fontSize: '12px',
    },
    /* Font Family Lato */
    /* Lato Bold */
    heading_Lato_Bold: {
      fontFamily: 'LatoBold',
      fontSize: '44px',
    },
    h1_Lato_Bold: {
      fontFamily: 'LatoBold',
      fontSize: '34px',
    },

    h2_Lato_Bold: {
      fontFamily: 'LatoBold',
      fontSize: '30px',
    },
    h3_Lato_Bold: {
      fontFamily: 'LatoBold',
      fontSize: '24px',
    },
    h4_Lato_Bold: {
      fontFamily: 'LatoBold',
      fontSize: '20px',
    },
    h5_Lato_Bold: {
      fontFamily: 'LatoBold',
      fontSize: '18px',
    },
    h6_Lato_Bold: {
      fontFamily: 'LatoBold',
      fontSize: '16px',
    },
    subtitle1_Lato_Bold: {
      fontFamily: 'LatoBold',
      fontSize: '14px',
    },
    subtitle2_Lato_Bold: {
      fontFamily: 'LatoBold',
      fontSize: '12px',
    },
    /* Lato Regular */
    heading_Lato_Regular: {
      fontFamily: 'LatoRegular',
      fontSize: '44px',
    },
    h1_Lato_Regular: {
      fontFamily: 'LatoRegular',
      fontSize: '34px',
    },

    h2_Lato_Regular: {
      fontFamily: 'LatoRegular',
      fontSize: '30px',
    },
    h3_Lato_Regular: {
      fontFamily: 'LatoRegular',
      fontSize: '24px',
    },
    h4_Lato_Regular: {
      fontFamily: 'LatoRegular',
      fontSize: '20px',
    },
    h5_Lato_Regular: {
      fontFamily: 'LatoRegular',
      fontSize: '18px',
    },
    h6_Lato_Regular: {
      fontFamily: 'LatoRegular',
      fontSize: '16px',
    },
    subtitle1_Lato_Regular: {
      fontFamily: 'LatoRegular',
      fontSize: '14px',
    },
    subtitle2_Lato_Regular: {
      fontFamily: 'LatoRegular',
      fontSize: '12px',
    },
    /* Lato Light */
    heading_Lato_Light: {
      fontFamily: 'LatoLight',
      fontSize: '44px',
    },
    h1_Lato_Light: {
      fontFamily: 'LatoLight',
      fontSize: '34px',
    },

    h2_Lato_Light: {
      fontFamily: 'LatoLight',
      fontSize: '30px',
    },
    h3_Lato_Light: {
      fontFamily: 'LatoLight',
      fontSize: '24px',
    },
    h4_Lato_Light: {
      fontFamily: 'LatoLight',
      fontSize: '20px',
    },
    h5_Lato_Light: {
      fontFamily: 'LatoLight',
      fontSize: '18px',
    },
    h6_Lato_Light: {
      fontFamily: 'LatoLight',
      fontSize: '16px',
    },
    subtitle1_Lato_Light: {
      fontFamily: 'LatoLight',
      fontSize: '14px',
    },
    subtitle2_Lato_Light: {
      fontFamily: 'LatoLight',
      fontSize: '12px',
    },
    /* Lato Medium */
    heading_Lato_Medium: {
      fontFamily: 'LatoMedium',
      fontSize: '44px',
    },
    h1_Lato_Medium: {
      fontFamily: 'LatoMedium',
      fontSize: '34px',
    },

    h2_Lato_Medium: {
      fontFamily: 'LatoMedium',
      fontSize: '30px',
    },
    h3_Lato_Medium: {
      fontFamily: 'LatoMedium',
      fontSize: '24px',
    },
    h4_Lato_Medium: {
      fontFamily: 'LatoMedium',
      fontSize: '20px',
    },
    h5_Lato_Medium: {
      fontFamily: 'LatoMedium',
      fontSize: '18px',
    },
    h6_Lato_Medium: {
      fontFamily: 'LatoMedium',
      fontSize: '16px',
    },
    subtitle1_Lato_Medium: {
      fontFamily: 'LatoMedium',
      fontSize: '14px',
    },
    subtitle2_Lato_Medium: {
      fontFamily: 'LatoMedium',
      fontSize: '12px',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
        variant: 'contained',
        color: 'primary',
      },
      styleOverrides: {
        root: ({ theme: baseTheme }) => ({
          textTransform: 'none',
          padding: '6px 16px',
          borderRadius: '12px',
          fontFamily: baseTheme.typography.subtitle2_Montserrat_Regular.fontFamily,
        }),
        containedSecondary: ({ theme: baseTheme }) => ({
          color: baseTheme.palette.light.main,
        }),
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

export default theme;
