import React, { useEffect } from 'react';
import { Stack } from '@mui/material';
import { JesaLogoSvg } from 'assets/svgs/componentsIcons/index';
import { generatePath, useLocation, useParams } from 'react-router-dom';
import TimelineMenu from 'components/TimelineMenu';
import {
  PROJECT_SETTINGS_EDIT_PROJECT,
  PROJECT_SETTINGS_EDIT_PROJECT_MEMBERS,
  PROJECT_SETTINGS_EDIT_PROJECT_WORK_DIMENSIONS,
} from 'routes/constants';

import { useLazyGetUserPrivilegesByProjectIdQuery } from 'services/apis/userPrivileges';
import { useDispatch } from 'react-redux';
import { setProjectPrivileges } from 'services/slices/global';
import { ProjectsSettingsMenuWrapper } from './Wrapper';

function ProjectsSettingsMenu() {
  const location = useLocation();
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const [doGetuserPrivilegesByProject] = useLazyGetUserPrivilegesByProjectIdQuery();

  useEffect(() => {
    if (projectId) {
      doGetuserPrivilegesByProject({ projectId })
        .unwrap()
        .then((result) => {
          dispatch(setProjectPrivileges({ projectPrivileges: result }));
        });
    }
  }, [projectId]);

  return (
    <ProjectsSettingsMenuWrapper>
      <Stack alignItems="center">
        <JesaLogoSvg />
      </Stack>

      <Stack className="create__project--timeline">
        <TimelineMenu
          items={[
            {
              title: 'General information',
              subtitle: 'From the project handover to the project location',
              selected: location.pathname.includes('create-project'),
              path:
                projectId && generatePath(PROJECT_SETTINGS_EDIT_PROJECT, { projectId }),
            },
            {
              title: 'Project members',
              subtitle:
                'The associations of the project members to be added to the project',
              selected: location.pathname.includes('project-members'),
              path:
                projectId &&
                generatePath(PROJECT_SETTINGS_EDIT_PROJECT_MEMBERS, { projectId }),
            },
            {
              title: 'Project work dimensions',
              subtitle: 'Synergistically productize intermandated web-readiness whereas',
              selected: location.pathname.includes('project-work-dimensions'),
              path:
                projectId &&
                generatePath(PROJECT_SETTINGS_EDIT_PROJECT_WORK_DIMENSIONS, {
                  projectId,
                }),
            },
          ]}
        />
      </Stack>
    </ProjectsSettingsMenuWrapper>
  );
}

export default ProjectsSettingsMenu;
