import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import theme from 'ui/theme';
import { CloseIcon } from 'assets/svgs/componentsIcons';
import ProjectEntitiesItemWrapper from './Wrapper';

function ProjectEntitiesItem({ item, target, onDelete, disabled }) {
  return (
    // eslint-disable-next-line react/prop-types
    !item[target].hideItem && (
      <ProjectEntitiesItemWrapper>
        <Stack className="inner flex__column--center">
          <Stack className="content">
            <Typography
              variant="subtitle1_HelveticaNeue_Bold"
              color={theme.palette.primary.main}
            >
              {item[target].name}
            </Typography>
          </Stack>
          {!disabled && (
            <Stack className="closeIcon mr-20 pointer" onClick={onDelete}>
              <CloseIcon />
            </Stack>
          )}
        </Stack>
      </ProjectEntitiesItemWrapper>
    )
  );
}

export default ProjectEntitiesItem;

ProjectEntitiesItem.propTypes = {
  item: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  onDelete: PropTypes.func,
  target: PropTypes.string,
  disabled: PropTypes.bool,
};
