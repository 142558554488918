import { api } from '.';

const COUNTRIES_BASE_URL = '/countries';

export const countriesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    getCountriesListByName: builder.query<any, { name: string }>({
      query: (params) => ({
        url: `${COUNTRIES_BASE_URL}/search-by-name`,
        params,
      }),
    }),
    getRegionsByCountry: builder.query<any, { countryCode: string }>({
      query: (params) => ({
        url: `${COUNTRIES_BASE_URL}/regions/by-country`,
        params,
      }),
    }),
  }),
});

export const { useLazyGetCountriesListByNameQuery, useLazyGetRegionsByCountryQuery } =
  countriesApi;
