import React from "react";

const FilterIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14.441" height="10.293" viewBox="0 0 14.441 10.293">
    <g id="Groupe_64131" data-name="Groupe 64131" transform="translate(-64.781 -14.501)">
      <g id="Groupe_63963" data-name="Groupe 63963" transform="translate(51.281 2)">
        <path id="Tracé_67305" data-name="Tracé 67305"
              d="M-19535.064-3286.706a1,1,0,0,1-1-1,1,1,0,0,1,1-1h4a1,1,0,0,1,1,1,1,1,0,0,1-1,1Zm-2.217-4.149a1,1,0,0,1-1-1,1,1,0,0,1,1-1h8.439a1,1,0,0,1,1,1,1,1,0,0,1-1,1Zm-2-4.144a1,1,0,0,1-1-1,1,1,0,0,1,1-1h12.441a1,1,0,0,1,1,1,1,1,0,0,1-1,1Z"
              transform="translate(19553.781 3309.5)" fill="#00338d" />
      </g>
    </g>
  </svg>
);
export default FilterIcon;
