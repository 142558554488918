import React from 'react';
import PropTypes from 'prop-types';
import UserProjectProfileItem from './UserProjectProfileItem';

function UserProjectProfileTable({
  userProjectProfiles,
  handleGetUserProjectProfiles,
  profiles,
}) {
  return (
    <table className="mt-20 p">
      <thead>
        <tr>
          <th>Project Name</th>
          <th>Project Number</th>
          <th>Project role</th>
          <th>Project profile</th>
          <th />
          <th />
        </tr>
      </thead>
      <tbody>
        {userProjectProfiles?.content?.map((item) => (
          <UserProjectProfileItem
            key={item?.id}
            item={item}
            handleGetUserProjectProfiles={handleGetUserProjectProfiles}
            profiles={profiles}
            hideDelete
          />
        ))}
      </tbody>
    </table>
  );
}

UserProjectProfileTable.propTypes = {
  profiles: PropTypes.arrayOf(PropTypes.shape({})),
  handleGetUserProjectProfiles: PropTypes.func,
  userProjectProfiles: PropTypes.shape({
    content: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      }),
    ),
  }),
};
export default UserProjectProfileTable;
