import { createSelector } from 'reselect';
import { selectProjectsSettingsProjects } from './projects';

const makeSelectProjects = createSelector(
  selectProjectsSettingsProjects,
  (subState) => subState.projects,
);

const makeSelectProjectCriteria = createSelector(
  selectProjectsSettingsProjects,
  (subState) => subState.projectCriteria,
);
export { makeSelectProjects, makeSelectProjectCriteria };
