import { Stack, styled } from '@mui/material';

const ProjectEntitiesItemWrapper = styled(Stack)(({ theme }) => ({
  background: theme.palette.common.white,
  border: '1px solid #EBEBEB',
  borderRadius: '8px',
  width: '30%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginRight: '15px',
  marginBottom: '15px',

  '& .inner': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    height: '55px',
  },
  '& .content': {
    padding: '19px 0 19px 30px',
  },
  '& .closeIcon': {
    margin: '10px',
  },

  [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},

  [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {},
}));
export default ProjectEntitiesItemWrapper;
