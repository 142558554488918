import React from "react";

const CostIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26.238"
    height="24.926"
    viewBox="0 0 26.238 24.926"
  >
    <g
      id="Groupe_65033"
      data-name="Groupe 65033"
      transform="translate(18759 -4753)"
    >
      <path
        id="Tracé_51781"
        data-name="Tracé 51781"
        d="M1328.885,526.256a6.5,6.5,0,1,0,6.83,6.489A6.674,6.674,0,0,0,1328.885,526.256Z"
        transform="translate(-20081.055 4238.692)"
        fill="#016be3"
      />
      <g
        id="Groupe_61761"
        data-name="Groupe 61761"
        transform="translate(-18759 4753)"
      >
        <g
          id="Groupe_61760"
          data-name="Groupe 61760"
          transform="translate(0 0)"
        >
          <path
            id="Tracé_51782"
            data-name="Tracé 51782"
            d="M1451.921,410.433h-9.542a1.8,1.8,0,0,0-1.843,1.751v2.575a1.8,1.8,0,0,0,1.843,1.75h9.542a1.8,1.8,0,0,0,1.843-1.75v-2.575A1.8,1.8,0,0,0,1451.921,410.433Z"
            transform="translate(-1430.888 -407.446)"
            fill="#016be3"
          />
          <path
            id="Tracé_51760"
            data-name="Tracé 51760"
            d="M1507.272,629.431h-1.627a.515.515,0,1,0,0,1.029h1.627a.515.515,0,1,0,0-1.029Z"
            transform="translate(-1488.843 -609.5)"
            fill="#00338d"
          />
          <path
            id="Tracé_51761"
            data-name="Tracé 51761"
            d="M1328.885,526.256a6.5,6.5,0,1,0,6.83,6.489A6.674,6.674,0,0,0,1328.885,526.256Zm0,11.948a5.467,5.467,0,1,1,5.747-5.46A5.616,5.616,0,0,1,1328.885,538.2Z"
            transform="translate(-1322.054 -514.308)"
            fill="#00338d"
          />
          <path
            id="Rectangle_7064"
            data-name="Rectangle 7064"
            d="M.915,0h0A.915.915,0,0,1,1.83.915V7.324a.915.915,0,0,1-.915.915h0A.915.915,0,0,1,0,7.324V.915A.915.915,0,0,1,.915,0Z"
            transform="translate(21.046 12.721)"
            fill="#00338d"
          />
          <path
            id="Tracé_51762"
            data-name="Tracé 51762"
            d="M1513.388,582.834H1512.3a.515.515,0,1,0,0,1.029h1.084a.515.515,0,1,0,0-1.029Z"
            transform="translate(-1494.959 -566.508)"
            fill="#00338d"
          />
          <path
            id="Tracé_51763"
            data-name="Tracé 51763"
            d="M1451.921,410.433h-9.542a1.8,1.8,0,0,0-1.843,1.751v2.575a1.8,1.8,0,0,0,1.843,1.75h9.542a1.8,1.8,0,0,0,1.843-1.75v-2.575A1.8,1.8,0,0,0,1451.921,410.433Zm.76,4.326a.742.742,0,0,1-.76.721h-9.542a.742.742,0,0,1-.759-.721v-2.575a.742.742,0,0,1,.759-.722h9.542a.742.742,0,0,1,.76.722Z"
            transform="translate(-1430.887 -407.446)"
            fill="#00338d"
          />
          <path
            id="Tracé_51764"
            data-name="Tracé 51764"
            d="M1383.434,557.716l-.211-.075v-.215a.542.542,0,1,0-1.083,0v.215l-.211.075a1.879,1.879,0,0,0,.047,3.593l1.06.347a.862.862,0,0,1,.609.977.9.9,0,0,1-.911.734h-.1a.9.9,0,0,1-.926-.88.542.542,0,0,0-1.083,0,1.926,1.926,0,0,0,1.307,1.788l.212.075v.215a.542.542,0,1,0,1.083,0v-.215l.211-.075a1.88,1.88,0,0,0-.047-3.593l-1.06-.347a.862.862,0,0,1-.609-.977.9.9,0,0,1,.911-.734h.1a.9.9,0,0,1,.926.88.542.542,0,0,0,1.083,0A1.925,1.925,0,0,0,1383.434,557.716Z"
            transform="translate(-1375.85 -542.559)"
            fill="#fff"
          />
          <path
            id="Tracé_51765"
            data-name="Tracé 51765"
            d="M1507.272,536.242h-1.627a.515.515,0,1,0,0,1.029h1.627a.515.515,0,1,0,0-1.029Z"
            transform="translate(-1488.843 -523.521)"
            fill="#00338d"
          />
          <path
            id="Tracé_51766"
            data-name="Tracé 51766"
            d="M1416.941,371.827h-15.4a2.224,2.224,0,0,0-2.276,2.162v7.8a.542.542,0,0,0,1.083,0v-7.8a1.166,1.166,0,0,1,1.193-1.134h15.4a1.166,1.166,0,0,1,1.193,1.134v20.6a1.166,1.166,0,0,1-1.193,1.134h-10.382a.515.515,0,1,0,0,1.029h10.382a2.224,2.224,0,0,0,2.276-2.163v-20.6A2.224,2.224,0,0,0,1416.941,371.827Z"
            transform="translate(-1392.979 -371.827)"
            fill="#00338d"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default CostIcon;
