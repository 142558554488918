import { Stack, styled } from '@mui/material';

const ProjectWorkLocationWrapper = styled(Stack)(({ theme }) => ({
  '& .projectCreationForm': {
    paddingBlock: '30px',
    '& .form-search-area': {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '30px',
    },
  },

  '& .rowForms': {
    gap: '20px',
    display: 'flex',
    flexDirection: 'row',
  },

  '& .columnForms': {
    gap: '30px',
    width: '50%',
  },

  '& .card-list': {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },

  [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},

  [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {},
}));
export default ProjectWorkLocationWrapper;
