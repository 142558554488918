import { createSelector } from 'reselect';
import { selectProjectsSettingsProjectWorkDimensionsDomain } from 'services/slices/project-setting/project-work';

/**
 * specific selectors
 */
const makeSelectContractorsByProject = createSelector(
  selectProjectsSettingsProjectWorkDimensionsDomain,
  (substate) => substate.contractorsByProject,
);
const makeSelectContractors = createSelector(
  selectProjectsSettingsProjectWorkDimensionsDomain,
  (substate) => substate.contractors,
);

const makeSelectSubContractorsByProject = createSelector(
  selectProjectsSettingsProjectWorkDimensionsDomain,
  (substate) => substate.subContractorsByProject,
);
const makeSelectSubContractors = createSelector(
  selectProjectsSettingsProjectWorkDimensionsDomain,
  (substate) => substate.subContractors,
);

const makeSelectVendorsByProject = createSelector(
  selectProjectsSettingsProjectWorkDimensionsDomain,
  (substate) => substate.vendorsByProject,
);
const makeSelectVendors = createSelector(
  selectProjectsSettingsProjectWorkDimensionsDomain,
  (substate) => substate.vendors,
);

const makeSelectIsEntityDelete = createSelector(
  selectProjectsSettingsProjectWorkDimensionsDomain,
  (substate) => substate.isEntityDelete,
);

export {
  makeSelectContractorsByProject,
  makeSelectContractors,
  makeSelectSubContractorsByProject,
  makeSelectSubContractors,
  makeSelectVendorsByProject,
  makeSelectIsEntityDelete,
  makeSelectVendors,
};
