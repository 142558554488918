import { createSelector } from 'reselect';
import { selectUserManagementOptionDomain } from 'services/slices/user-management/user-management-option';

/**
 * specific selectors
 */
const makeSelectOperation = createSelector(
  selectUserManagementOptionDomain,
  (substate) => substate.operation,
);
const makeSelectFreezeForm = createSelector(
  selectUserManagementOptionDomain,
  (substate) => substate.freezeForm,
);
const makeSelectUpdatedUser = createSelector(
  selectUserManagementOptionDomain,
  (substate) => substate.updatedUser,
);
const makeSelectSuccessUpdateInternalUser = createSelector(
  selectUserManagementOptionDomain,
  (substate) => substate.internalUserUpdateSuccess,
);
const makeSelectSuccessFreeze = createSelector(
  selectUserManagementOptionDomain,
  (substate) => substate.freezeSuccess,
);
const makeSelectIsSubmit = createSelector(
  selectUserManagementOptionDomain,
  (substate) => substate.isSubmit,
);

export {
  makeSelectOperation,
  makeSelectFreezeForm,
  makeSelectUpdatedUser,
  makeSelectSuccessUpdateInternalUser,
  makeSelectSuccessFreeze,
  makeSelectIsSubmit,
};
