import React, { useState } from 'react';
import DropDown from 'components/DropDown';
import MenuItemLink from 'components/StyledComponents/MenuItemLink';
import {
  AccessManagementIcon,
  ArrowDownIcon,
  PlatformSettingIcon,
  ProductSettingIcon,
  ProjectsSettingsIcon,
  UserManagementIcon,
} from 'assets/svgs/componentsIcons/index';

import {
  ACCESS_MANAGEMENT_LISTING,
  SETTINGS_BU,
  SETTINGS_CLIENTS,
  SETTINGS_CONTRACTORS,
  SETTINGS_DISCIPLINES,
  SETTINGS_PROGRAMS,
  SETTINGS_PROJECTS,
  SETTINGS_IN_PROCESS_PROJECTS,
  SETTINGS_SECTORS,
  SETTINGS_SUB_CONTRACTORS,
  SETTINGS_USERS,
  SETTINGS_VENDORS,
} from 'routes/constants';
import { getConnectedUserState } from 'services/apis/user';
import Wrapper from './Wrapper';

function NavBar(props) {
  const initialOpenMenu = {};
  const [isOpened, setIsOpened] = useState(initialOpenMenu);
  const [active, setActive] = useState(null);
  const { data: connectedUser } = getConnectedUserState();
  function handleClick(id) {
    setIsOpened((prevState) => ({ ...prevState, [id]: !prevState[id] }));
  }

  // TODO replace const
  const navItems = [
    {
      id: 1,
      title: 'Product Settings',
      url: '/',
      icon: <ProductSettingIcon />,
    },
    {
      id: 2,
      title: 'Platform Settings',
      url: '/',
      icon: <PlatformSettingIcon />,
      submenu: [
        {
          title: 'Programs',
          url: SETTINGS_PROGRAMS,
        },
        {
          title: 'Business Units',
          url: SETTINGS_BU,
        },
        {
          title: 'Sectors',
          url: SETTINGS_SECTORS,
        },
        {
          title: 'Engineering Disciplines',
          url: SETTINGS_DISCIPLINES,
        },
        {
          title: 'Clients',
          url: SETTINGS_CLIENTS,
        },
        {
          title: 'Vendors',
          url: SETTINGS_VENDORS,
        },
        {
          title: 'Contractors',
          url: SETTINGS_CONTRACTORS,
        },
        {
          title: 'SubContractors',
          url: SETTINGS_SUB_CONTRACTORS,
        },
      ],
    },
    {
      id: 3,
      title: 'User Management',
      url: SETTINGS_USERS,
      icon: <UserManagementIcon />,
    },
    {
      id: 4,
      title: 'Access Management',
      url: ACCESS_MANAGEMENT_LISTING,
      icon: <AccessManagementIcon />,
    },
    {
      id: 5,
      title: 'Project Settings',
      url: '/',
      icon: <ProjectsSettingsIcon />,
      hasAccess: true,
      submenu: [
        {
          title: 'In process of creation',
          url: SETTINGS_IN_PROCESS_PROJECTS,
          hasAccess: true,
        },
        {
          title: 'List of projects',
          url: SETTINGS_PROJECTS,
          hasAccess: true,
        },
      ],
    },
  ];
  return (
    <Wrapper {...props}>
      <ul>
        {navItems
          .filter((menuItem) => menuItem.hasAccess || connectedUser?.admin)
          .map((menuItem) => (
            <li key={menuItem.id}>
              {menuItem.submenu ? (
                <>
                  <MenuItemLink
                    as="button"
                    type="button"
                    onClick={() => handleClick(menuItem.id)}
                    to=""
                  >
                    {menuItem.icon && <span className="link-icon">{menuItem.icon}</span>}
                    <span className="link-text">{menuItem.title}</span>
                    <span className={`link-arrow ${isOpened[menuItem.id] && 'active'}`}>
                      <ArrowDownIcon />
                    </span>
                  </MenuItemLink>
                  <DropDown
                    active={active}
                    setActive={setActive}
                    subMenuItems={menuItem.submenu}
                    isOpened={isOpened[menuItem.id]}
                  />
                </>
              ) : (
                <MenuItemLink
                  activeClassName="active"
                  onClick={() => setActive(null)}
                  to={menuItem.url}
                >
                  {menuItem.icon && <span className="link-icon">{menuItem.icon}</span>}
                  <span className="link-text">{menuItem.title}</span>
                </MenuItemLink>
              )}
            </li>
          ))}
      </ul>
    </Wrapper>
  );
}

NavBar.propTypes = {};
export default NavBar;
