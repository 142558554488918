import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { MainButton } from 'components/Buttons';
import { PlusIcon } from 'assets/svgs/componentsIcons';
import RefreshIcon from '@mui/icons-material/Refresh';
import theme from 'ui/theme';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import FilterIcon from 'assets/svgs/componentsIcons/FilterIcon';
import CloseIcon from 'assets/svgs/componentsIcons/CloseIcon';
import AppBody from 'components/StyledComponents/AppBody';
import PageHeader from 'components/StyledComponents/PageHeader';
import PageBody from 'components/StyledComponents/PageBody';
import ContentBody from 'components/StyledComponents/ContentBody';
import ContentActions from 'components/StyledComponents/ContentActions';
import CustomPagination from 'components/StyledComponents/Pagination';
import FormInput from 'components/FormInput';
import FormCheckbox from 'components/FormCheckbox';
import ActionButton from 'components/ActionButton';
import ConfirmationPopup from 'components/ConfirmationPopin';
import { POPUP_TYPE } from 'components/ConfirmationPopin/constants';
import { ACCESS_MANAGEMENT_ADD_PROFILE } from 'routes/constants';
import {
  useDeleteProfileByIdMutation,
  useLazySearchProfilesByCriteriaQuery,
} from 'services/apis/access-control/profile';
import {
  makeSelectDeletedProfileId,
  makeSelectDeletePopup,
} from 'services/slices/access-management/selectors';
import { showDeletePopup } from 'services/slices/access-management';
import { PlatformEnum, useGetPlatformsByCodesQuery } from 'services/apis/platform';
import FormSelect from 'components/FormSelect';
import FullPageLoader from 'components/FullPageLoader';
import ProfileWrapper from './Wrapper';
import { FILTER_INPUTS } from './constants';
import ProfileItems from '../ProjectAdministrationUserManagement/ProfileItems/ProfileItems';
import ProfileErrorDeleteEditModal from './ProfileErrorDeleteEditModal';

const stateSelector = createStructuredSelector({
  deletedProfileId: makeSelectDeletedProfileId,
  deletePopup: makeSelectDeletePopup,
});

function Profiles() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [doSearchProfilesByCriteria, { data: profiles, isFetching: filterLoading }] =
    useLazySearchProfilesByCriteriaQuery();
  const [
    doDeleteProfileById,
    { isSuccess: successDelete, error: deleteError, isLoading: deleteLoading },
  ] = useDeleteProfileByIdMutation();
  const { data: platforms } = useGetPlatformsByCodesQuery([
    PlatformEnum.COLLAB,
    PlatformEnum.ESP,
    PlatformEnum.JPASS,
  ]);
  const [searchCriteria, setSearchCriteria] = useState({});
  const [page, setPage] = useState(0);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [showSuccessDeletePopup, setSuccessDeletePopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);

  const handleProfileErrorModal = () => {
    setShowErrorPopup((prevState) => !prevState);
  };

  const { deletedProfileId, deletePopup } = useSelector(stateSelector);

  useEffect(() => {
    if (deleteError?.status === 400) {
      handleProfileErrorModal();
      dispatch(showDeletePopup(false));
    }
  }, [deleteError]);

  useEffect(() => {
    if (successDelete && deletePopup) {
      dispatch(showDeletePopup(false));
      setSuccessDeletePopup(true);
    }
  }, [successDelete, deletePopup, dispatch]);

  const deleteProfileById = (profileId) => {
    if (profileId) doDeleteProfileById(profileId);
  };

  useEffect(() => {
    doSearchProfilesByCriteria({ page: 0, size: 20 });
  }, [doSearchProfilesByCriteria]);

  const changePage = (_, p) => {
    setPage(p - 1);
  };

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  useEffect(() => {
    doSearchProfilesByCriteria({ ...searchCriteria, page });
  }, [page, doSearchProfilesByCriteria, searchCriteria]);

  const handleChange = (name, value) => {
    setSearchCriteria({ ...searchCriteria, [name]: value });
  };

  const getValue = (key) => {
    return searchCriteria[key] || false;
  };
  const handleForExternalCheckBoxes = (value, key) => {
    setSearchCriteria({
      ...searchCriteria,
      [key]: value || undefined,
      [FILTER_INPUTS.forExternal]: value || undefined,
    });
  };

  return (
    <ProfileWrapper>
      {(filterLoading || deleteLoading) && <FullPageLoader />}
      <AppBody>
        <PageHeader>
          <Box className="top-header">
            <Box className="page-actions-wrapper">
              <Box className="heading-area">
                <Box className="page-title-area">
                  <Typography variant="h3_HelveticaNeue_Bold" color="primary.main">
                    Access Management
                  </Typography>
                </Box>
              </Box>
              <Box>
                <MainButton
                  type="button"
                  onClick={() => navigate(ACCESS_MANAGEMENT_ADD_PROFILE)}
                >
                  <PlusIcon viewBox="3 3 10 10" width="10" height="10" />
                  <span>Add</span>
                </MainButton>
              </Box>
            </Box>
            <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.main">
              here you can manage the profiles
            </Typography>
          </Box>
        </PageHeader>
        <PageBody>
          <Box>
            <Box
              className={`filter_container flex__column ${
                isFilterOpen ? 'show' : 'hide'
              }`}
            >
              <Box className="filter_headline">
                <Typography variant="h3_HelveticaNeue_Bold" color="primary.main">
                  Filter
                </Typography>
                <Box className="icon_container" onClick={toggleFilter}>
                  <CloseIcon />
                </Box>
              </Box>
              <Box className="mt-45 filter_forms">
                <Box className="form_inputs">
                  <FormInput
                    label="Profile name"
                    valueKey="Enter Profile name"
                    onChange={(e) => handleChange(FILTER_INPUTS.name, e.target.value)}
                    value={getValue(FILTER_INPUTS.name)}
                  />
                </Box>

                <Box className="form_inputs">
                  <FormSelect
                    label="Plateform"
                    valueKey="Enter Platform"
                    menuItems={platforms?.map((p) => p.name)}
                    onChange={(e) => {
                      const selected = platforms?.find((p) => p.name === e.target.value);
                      handleChange(FILTER_INPUTS.platform, selected?.name);
                    }}
                    value={getValue(FILTER_INPUTS.platform)}
                  />
                </Box>

                <Box className="form_inputs checkbox_container">
                  <Typography
                    color={theme.palette.text.gray}
                    variant="subtitle2_HelveticaNeue_Medium"
                  >
                    For external :
                  </Typography>
                  <Box className="flex__row--center-space-around">
                    <FormCheckbox
                      label="Client"
                      labelColor={theme.palette.text.gray}
                      onChange={(e) => {
                        handleForExternalCheckBoxes(
                          e.target.checked,
                          FILTER_INPUTS.forClient,
                        );
                      }}
                      checked={getValue(FILTER_INPUTS.forClient)}
                    />
                    <FormCheckbox
                      label="Contractor"
                      labelColor={theme.palette.text.gray}
                      onChange={(e) => {
                        handleForExternalCheckBoxes(
                          e.target.checked,
                          FILTER_INPUTS.forContractor,
                        );
                      }}
                      checked={getValue(FILTER_INPUTS.forContractor)}
                    />
                  </Box>
                </Box>
              </Box>
              <Box className="filter_buttons">
                <ActionButton
                  classname="footer--back__button btn--reset"
                  label="Undo"
                  onClick={() => {
                    setSearchCriteria({});
                    doSearchProfilesByCriteria({ page });
                  }}
                />
                <ActionButton
                  classname="footer--next__button btn--reset .padding-sides-10"
                  label="Apply filter"
                  onClick={() => {
                    doSearchProfilesByCriteria({ ...searchCriteria });
                    toggleFilter();
                  }}
                />
              </Box>
            </Box>
            <Box
              className={`filter_container padding-20 mt-15 flex__row--center-space ${
                !isFilterOpen ? 'show' : 'hide'
              }`}
            >
              <Box>
                <Typography variant="h3_HelveticaNeue_Bold" color="primary.main">
                  Profile listing
                </Typography>
              </Box>
              <Box className="icons_container">
                <Typography
                  variant="subtitle2_HelveticaNeue_Regular"
                  color={theme.palette.text.gray}
                >
                  We’ve found{' '}
                  <span style={{ color: '#007CFF' }}>{profiles?.totalElements}</span>{' '}
                  results
                </Typography>
                <Box className="icon_container" onClick={toggleFilter}>
                  <FilterIcon />
                </Box>
                <Box className="icon_container ">
                  <RefreshIcon
                    sx={{ color: '#00338D' }}
                    onClick={() => {
                      setSearchCriteria({});
                      doSearchProfilesByCriteria({ page });
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <ContentBody>
            <ProfileItems profileItems={profiles?.content} />
          </ContentBody>
          <ContentActions>
            <CustomPagination
              shape="rounded"
              onChange={changePage}
              count={profiles?.totalPages}
            />
          </ContentActions>
        </PageBody>
        {deletePopup && (
          <ConfirmationPopup
            toOpen={deletePopup}
            popupType={POPUP_TYPE.CONFIRMATION_DELETE_PROFILE}
            onDelete={() => deleteProfileById(deletedProfileId)}
            onCancel={() => dispatch(showDeletePopup(false))}
            onClose={() => dispatch(showDeletePopup(false))}
          />
        )}
        {deleteError?.status === 400 && (
          <ProfileErrorDeleteEditModal
            isOpen={showErrorPopup}
            handleProfileModal={handleProfileErrorModal}
            linkedUsers={deleteError?.data?.extendedProperties}
          />
        )}
        {showSuccessDeletePopup && (
          <ConfirmationPopup
            toOpen={showSuccessDeletePopup}
            popupType={POPUP_TYPE.SUCCESS_DELETE_PROFILE}
            onClose={() => {
              setSuccessDeletePopup(false);
              doSearchProfilesByCriteria({ page: 0, size: 20 });
            }}
          />
        )}
      </AppBody>
    </ProfileWrapper>
  );
}

export default Profiles;
