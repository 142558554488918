import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import SettingsPage from 'pages/Settings';
import ProjectAdministrationPlatformSettings from 'containers/ProjectAdministrationPlatformSettings';
import { PLATFORM_SETTINGS_TYPES, PROJECT_CREATION_STATUS } from 'helpers/constants';
import ProjectAdministrationUserManagement from 'containers/ProjectAdministrationUserManagement';
import AddExternalUsers from 'containers/ProjectAdministrationUserManagement/AddExternalUsers';
import UserDetailedView from 'containers/UserDetailedView';
import Projects from 'containers/Projects';
import Profiles from 'containers/Profiles';
import {
  ACCESS_MANAGEMENT_LISTING,
  SETTINGS,
  SETTINGS_BU,
  SETTINGS_CLIENTS,
  SETTINGS_CONTRACTORS,
  SETTINGS_DISCIPLINES,
  SETTINGS_IN_PROCESS_PROJECTS,
  SETTINGS_PROGRAMS,
  SETTINGS_PROJECTS,
  SETTINGS_SECTORS,
  SETTINGS_SUB_CONTRACTORS,
  SETTINGS_USERS,
  SETTINGS_USERS_ADD_EXTERNAL,
  SETTINGS_USERS_EDIT_EXTERNAL,
  SETTINGS_VENDORS,
  SETTING_USERS_DETAILED_VIEWS,
} from './constants';

const SettingsRoutes = (
  <Route path={SETTINGS} element={<SettingsPage />}>
    <Route index element={<Navigate to={SETTINGS_PROJECTS} />} />
    <Route
      path={SETTINGS_PROGRAMS}
      element={
        <ProjectAdministrationPlatformSettings type={PLATFORM_SETTINGS_TYPES.PROGRAM} />
      }
    />
    <Route
      path={SETTINGS_SECTORS}
      element={
        <ProjectAdministrationPlatformSettings type={PLATFORM_SETTINGS_TYPES.SECTOR} />
      }
    />
    <Route
      path={SETTINGS_CONTRACTORS}
      element={
        <ProjectAdministrationPlatformSettings
          type={PLATFORM_SETTINGS_TYPES.CONTRACTOR}
        />
      }
    />
    <Route
      path={SETTINGS_SUB_CONTRACTORS}
      element={
        <ProjectAdministrationPlatformSettings
          type={PLATFORM_SETTINGS_TYPES.SUB_CONTRACTOR}
        />
      }
    />
    <Route
      path={SETTINGS_BU}
      element={
        <ProjectAdministrationPlatformSettings type={PLATFORM_SETTINGS_TYPES.BU} />
      }
    />
    <Route
      path={SETTINGS_DISCIPLINES}
      element={
        <ProjectAdministrationPlatformSettings
          type={PLATFORM_SETTINGS_TYPES.DISCIPLINE}
        />
      }
    />
    <Route path={SETTINGS_USERS} element={<ProjectAdministrationUserManagement />} />
    <Route path={SETTINGS_USERS_ADD_EXTERNAL} element={<AddExternalUsers />} />
    <Route path={SETTINGS_USERS_EDIT_EXTERNAL} element={<AddExternalUsers />} />
    <Route path={SETTING_USERS_DETAILED_VIEWS} element={<UserDetailedView />} />
    <Route
      path={SETTINGS_CLIENTS}
      element={
        <ProjectAdministrationPlatformSettings type={PLATFORM_SETTINGS_TYPES.CLIENT} />
      }
    />
    <Route
      path={SETTINGS_VENDORS}
      element={
        <ProjectAdministrationPlatformSettings type={PLATFORM_SETTINGS_TYPES.VENDOR} />
      }
    />
    <Route
      path={SETTINGS_PROJECTS}
      element={<Projects projectType={PROJECT_CREATION_STATUS.PUBLISHED} />}
    />
    <Route
      path={SETTINGS_IN_PROCESS_PROJECTS}
      element={<Projects projectType={PROJECT_CREATION_STATUS.DRAFT} />}
    />
    <Route path={ACCESS_MANAGEMENT_LISTING} element={<Profiles />} />
  </Route>
);

export default SettingsRoutes;
