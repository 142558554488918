import React from 'react';

const CloseSearchIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={15} height={15} {...props}>
    <g opacity="0.6">
      <path
        id="Path_67307"
        data-name="Path 67307"
        d="M-18281.221-1693.875l-4.582-4.577-4.576,4.577a.942.942,0,0,1-.67.275.953.953,0,0,1-.676-.275.953.953,0,0,1,0-1.34l4.582-4.582-4.578-4.582a.95.95,0,0,1,0-1.341.957.957,0,0,1,.676-.28.947.947,0,0,1,.67.28l4.576,4.577,4.582-4.577a.947.947,0,0,1,.67-.28.927.927,0,0,1,.67.28.944.944,0,0,1,.281.67.944.944,0,0,1-.281.67l-4.576,4.582,4.576,4.582a.918.918,0,0,1,.281.666.954.954,0,0,1-.281.675.923.923,0,0,1-.67.275A.942.942,0,0,1-18281.221-1693.875Z"
        transform="translate(18292 1706)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default CloseSearchIcon;
