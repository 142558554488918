import { Switch, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import FormSwitchWrapper from './Wrapper';

function FormSwitch({
  classname,
  label,
  labelColor = 'text.secondary',
  onChange,
  checked,
  disabled,
}) {
  return (
    <FormSwitchWrapper className={`${classname} flex__row--center-space`}>
      <Typography color={labelColor} variant="subtitle2_HelveticaNeue_Medium">
        {label}
      </Typography>
      <Switch
        className="antSwitch"
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
    </FormSwitchWrapper>
  );
}

FormSwitch.propTypes = {
  classname: PropTypes.string,
  label: PropTypes.string,
  labelColor: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};
export default FormSwitch;
