import { createSelector } from 'reselect';
import { selectProjectsSettingsProjectMembersDomain } from 'services/slices/project-setting/project-members';

/**
 * specific selectors
 */
const makeSelectProjectUsers = createSelector(
  selectProjectsSettingsProjectMembersDomain,
  (substate) => substate.projectUsers,
);
const makeSelectSelectedUser = createSelector(
  selectProjectsSettingsProjectMembersDomain,
  (substate) => substate.selectedUser,
);
const makeSelectSelectedRole = createSelector(
  selectProjectsSettingsProjectMembersDomain,
  (substate) => substate.selectedRole,
);
const makeSelectSelectedProfiles = createSelector(
  selectProjectsSettingsProjectMembersDomain,
  (substate) => substate.selectedProfiles,
);
const makeSelectProfiles = createSelector(
  selectProjectsSettingsProjectMembersDomain,
  (substate) => substate.profiles,
);
const makeSelectPlatforms = createSelector(
  selectProjectsSettingsProjectMembersDomain,
  (substate) => substate.platforms,
);
const makeSelectUserProjectsByCategory = createSelector(
  selectProjectsSettingsProjectMembersDomain,
  (substate) => substate.userProjectsByCategory,
);

export {
  makeSelectProjectUsers,
  makeSelectSelectedUser,
  makeSelectSelectedRole,
  makeSelectSelectedProfiles,
  makeSelectPlatforms,
  makeSelectUserProjectsByCategory,
  makeSelectProfiles,
};
