const prepareData = (body: BodyWrapper, files: FileFormat[]): FormData => {
  const formData = new FormData();
  const dataBlob = new Blob([JSON.stringify(body.data)], {
    type: 'application/json',
  });
  formData.append(body?.name, dataBlob);
  files.forEach((file) => {
    const fileBlob = new Blob(file.blobFile, { type: file.blobFile?.[0]?.type });
    const fileName = `${file.blobFile?.[0]?.name as string}`;
    if (file.blobFile?.length > 0) {
      formData.append(file?.fileName, fileBlob, fileName);
    }
  });
  return formData;
};
export default prepareData;

interface FileFormat {
  fileName: string;
  blobFile: any;
}

interface BodyWrapper {
  name: string;
  data: object;
}
