import { api } from '.';

const userPrivilegeBaseUrl = '/user-privileges';

export const userPrivilegesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUserPrivilegesByProjectId: builder.query<string, number>({
      query: (idProject) => ({
        url: `${userPrivilegeBaseUrl}/features-privileges`,
        params: idProject,
      }),
    }),
  }),
});

export const { useLazyGetUserPrivilegesByProjectIdQuery } = userPrivilegesApi;
