import React from 'react';
import { FormControl, InputLabel, Stack, Typography } from '@mui/material';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import PropTypes from 'prop-types';
import { TruncateText } from 'utils/TextUtils';
import { isNotNullUndef } from 'utils/ValidatorsUtils';
import ActionButton from '../ActionButton';
import FormInput from '../FormInput';
import { FormInputPlusMinusWrapper } from './Wrapper';

function FormInputPlusMinus({
  fullLabelWidth,
  label,
  value = 0,
  error,
  max,
  min,
  onChange,
  disabled,
}) {
  if (max < min) {
    console.warn(`min value: ${min} is greater than max: ${max}`);
  }
  return (
    <FormInputPlusMinusWrapper className="fomInput__wrapper">
      <FormControl className="inputs__label" variant="standard" error={error}>
        <InputLabel shrink htmlFor={label}>
          <Typography color="text.secondary" variant="h5_HelveticaNeue_Regular">
            {TruncateText(label, fullLabelWidth ? label.length : 17)}
          </Typography>
        </InputLabel>
      </FormControl>
      <Stack className="FormInputPlusMinus">
        <ActionButton
          classname="minus btn--reset small__button"
          startIcon={<RemoveRoundedIcon />}
          onClick={() => {
            if (min > max) return;
            if (value > min || !isNotNullUndef(min)) onChange(value - 1);
          }}
          disabled={disabled}
        />
        <FormInput value={value || 0} error={error} disabled={disabled} />

        <ActionButton
          classname="plus btn--reset small__button"
          startIcon={<AddRoundedIcon />}
          onClick={() => {
            if (min > max) return;
            if (value < max || !isNotNullUndef(max)) onChange(value + 1);
          }}
          disabled={disabled}
        />
      </Stack>
    </FormInputPlusMinusWrapper>
  );
}

FormInputPlusMinus.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.number,
  error: PropTypes.bool,
  fullLabelWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
};

export default FormInputPlusMinus;
