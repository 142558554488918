import React from 'react';

const ExternalLogo1 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="146" height="146" viewBox="0 0 146 146">
    <defs>
      <filter id="Ellipse_1312" x="0" y="0" width="146" height="146" filterUnits="userSpaceOnUse">
        <feOffset input="SourceAlpha"/>
        <feGaussianBlur stdDeviation="10" result="blur"/>
        <feFlood flood-color="#1e2769" flood-opacity="0.09"/>
        <feComposite operator="in" in2="blur"/>
        <feComposite in="SourceGraphic"/>
      </filter>
      <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#0881ff"/>
        <stop offset="1" stop-color="#00afff"/>
      </linearGradient>
    </defs>
    <g id="Group_63882" data-name="Group 63882" transform="translate(-1051 -347)">
      <g id="Group_63829" data-name="Group 63829" transform="translate(950.371 26.917)">
        <g transform="matrix(1, 0, 0, 1, 100.63, 320.08)" filter="url(#Ellipse_1312)">
          <circle id="Ellipse_1312-2" data-name="Ellipse 1312" cx="43" cy="43" r="43" transform="translate(30 30)"
                  fill="#fcfcff"/>
        </g>
      </g>
      <g id="Iconly_Bold_Activity" data-name="Iconly/Bold/Activity" transform="translate(1103.454 397.298)">
        <g id="Activity" transform="translate(2 2)">
          <path id="Path_67228" data-name="Path 67228"
                d="M27.555,3.647a9.711,9.711,0,0,0-.123,1.537A8.326,8.326,0,0,0,35.75,13.52a9.486,9.486,0,0,0,1.516-.141h0V29.91c0,6.947-4.1,11.065-11.063,11.065H11.065C4.1,40.975,0,36.857,0,29.91H0V14.751c0-6.966,4.1-11.1,11.065-11.1h16.49ZM27.967,16.1a1.574,1.574,0,0,0-1.436.635h0l-4.956,6.413L15.9,18.685a1.618,1.618,0,0,0-1.168-.328,1.587,1.587,0,0,0-1.024.613h0l-6.062,7.89-.125.184a1.531,1.531,0,0,0,.43,1.946,1.722,1.722,0,0,0,.942.307,1.452,1.452,0,0,0,1.209-.615h0l5.142-6.619,5.839,4.386.184.121a1.533,1.533,0,0,0,1.946-.43h0l5.921-7.64-.082.041a1.534,1.534,0,0,0,.164-1.537A1.511,1.511,0,0,0,27.967,16.1ZM36.037,0A4.937,4.937,0,1,1,31.1,4.937,4.94,4.94,0,0,1,36.037,0Z"
                transform="translate(0 0)" fill="url(#linear-gradient)"/>
        </g>
      </g>
    </g>
  </svg>

);

export default ExternalLogo1;
