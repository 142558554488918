import { css } from '@emotion/react';
import MontserratBoldWoff from './files/Montserrat-Bold.woff';
import MontserratBoldWoffTwo from './files/Montserrat-Bold.woff2';

import MontserratSemiBoldWoff from './files/Montserrat-SemiBold.woff';
import MontserratSemiBoldWoffTwo from './files/Montserrat-SemiBold.woff2';

import MontserratMediumWoff from './files/Montserrat-Medium.woff';
import MontserratMediumWoffTwo from './files/Montserrat-Medium.woff2';

import MontserratRegularWoff from './files/Montserrat-Regular.woff';
import MontserratRegularWoffTwo from './files/Montserrat-Regular.woff2';

import MontserratLightWoff from './files/Montserrat-Light.woff';
import MontserratLightWoffTwo from './files/Montserrat-Light.woff2';

import HelveticaNeueBoldWoff from './files/HelveticaNeue-Bold.woff';
import HelveticaNeueBoldWoffTwo from './files/HelveticaNeue-Bold.woff2';

import HelveticaNeueMediumWoff from './files/HelveticaNeue-Medium.woff';
import HelveticaNeueMediumWoffTwo from './files/HelveticaNeue-Medium.woff2';

import HelveticaNeueRegularWoff from './files/HelveticaNeue-Regular.woff';
import HelveticaNeueRegularWoffTwo from './files/HelveticaNeue-Regular.woff2';

import HelveticaNeueLightWoff from './files/HelveticaNeue-Light.woff';
import HelveticaNeueLightWoffTwo from './files/HelveticaNeue-Light.woff2';

import RobotoBlackWoff from './files/Roboto-Black.woff';
import RobotoBlackWoffTwo from './files/Roboto-Black.woff2';

import RobotoBoldWoff from './files/Roboto-Bold.woff';
import RobotoBoldWoffTwo from './files/Roboto-Bold.woff2';

import RobotoMediumWoff from './files/Roboto-Medium.woff';
import RobotoMediumWoffTwo from './files/Roboto-Medium.woff2';

import RobotoRegularWoff from './files/Roboto-Regular.woff';
import RobotoRegularWoffTwo from './files/Roboto-Regular.woff2';

import RobotoLightWoff from './files/Roboto-Light.woff';
import RobotoLightWoffTwo from './files/Roboto-Light.woff2';

import RobotoThinWoff from './files/Roboto-Thin.woff';
import RobotoThinWoffTwo from './files/Roboto-Thin.woff2';

import RubikBlackWoff from './files/Rubik-Black.woff';
import RubikBlackWoffTwo from './files/Rubik-Black.woff2';

import RubikBoldWoff from './files/Rubik-Bold.woff';
import RubikBoldWoffTwo from './files/Rubik-Bold.woff2';

import RubikMediumWoff from './files/Rubik-Medium.woff';
import RubikMediumWoffTwo from './files/Rubik-Medium.woff2';

import RubikRegularWoff from './files/Rubik-Regular.woff';
import RubikRegularWoffTwo from './files/Rubik-Regular.woff2';

import RubikLightWoff from './files/Rubik-Light.woff';
import RubikLightWoffTwo from './files/Rubik-Light.woff2';

import LatoBoldWoff from './files/Lato-Bold.woff';
import LatoBoldWoffTwo from './files/Lato-Bold.woff2';

import LatoMediumWoff from './files/Lato-Medium.woff';
import LatoMediumWoffTwo from './files/Lato-Medium.woff2';

import LatoRegularWoff from './files/Lato-Regular.woff';
import LatoRegularWoffTwo from './files/Lato-Regular.woff2';

import LatoLightWoff from './files/Lato-Light.woff';
import LatoLightWoffTwo from './files/Lato-Light.woff2';

import NasalizationRegular from './files/Nasalization-Regular.woff';
import NasalizationRegularTwo from './files/Nasalization-Regular.woff2';

export const GlobalFontStyle = css`
  @font-face {
    font-family: 'NasalizationRegular';
    src:
      url('${NasalizationRegular}') format('woff'),
      url('${NasalizationRegularTwo}') format('woff2');
  }

  @font-face {
    font-family: 'MontserratBold';
    src:
      url('${MontserratBoldWoffTwo}') format('woff2'),
      url('${MontserratBoldWoff}') format('woff');
  }

  @font-face {
    font-family: 'MontserratSemiBold';
    src:
      url('${MontserratSemiBoldWoffTwo}') format('woff2'),
      url('${MontserratSemiBoldWoff}') format('woff');
  }

  @font-face {
    font-family: 'MontserratMedium';
    src:
      url('${MontserratMediumWoffTwo}') format('woff2'),
      url('${MontserratMediumWoff}') format('woff');
  }

  @font-face {
    font-family: 'MontserratRegular';
    src:
      url('${MontserratRegularWoffTwo}') format('woff2'),
      url('${MontserratRegularWoff}') format('woff');
  }

  @font-face {
    font-family: 'MontserratLight';
    src:
      url('${MontserratLightWoffTwo}') format('woff2'),
      url('${MontserratLightWoff}') format('woff');
  }

  @font-face {
    font-family: 'HelveticaNeueBold';
    src:
      url('${HelveticaNeueBoldWoffTwo}') format('woff2'),
      url('${HelveticaNeueBoldWoff}') format('woff');
  }

  @font-face {
    font-family: 'HelveticaNeueMedium';
    src:
      url('${HelveticaNeueMediumWoffTwo}') format('woff2'),
      url('${HelveticaNeueMediumWoff}') format('woff');
  }

  @font-face {
    font-family: 'HelveticaNeueRegular';
    src:
      url('${HelveticaNeueRegularWoffTwo}') format('woff2'),
      url('${HelveticaNeueRegularWoff}') format('woff');
  }

  @font-face {
    font-family: 'HelveticaNeueLight';
    src:
      url('${HelveticaNeueLightWoffTwo}') format('woff2'),
      url('${HelveticaNeueLightWoff}') format('woff');
  }

  @font-face {
    font-family: 'RobotoBlack';
    src:
      url('${RobotoBlackWoffTwo}') format('woff2'),
      url('${RobotoBlackWoff}') format('woff');
  }

  @font-face {
    font-family: 'RobotoBold';
    src:
      url('${RobotoBoldWoffTwo}') format('woff2'),
      url('${RobotoBoldWoff}') format('woff');
  }

  @font-face {
    font-family: 'RobotoMedium';
    src:
      url('${RobotoMediumWoffTwo}') format('woff2'),
      url('${RobotoMediumWoff}') format('woff');
  }

  @font-face {
    font-family: 'RobotoRegular';
    src:
      url('${RobotoRegularWoffTwo}') format('woff2'),
      url('${RobotoRegularWoff}') format('woff');
  }

  @font-face {
    font-family: 'RobotoLight';
    src:
      url('${RobotoLightWoffTwo}') format('woff2'),
      url('${RobotoLightWoff}') format('woff');
  }

  @font-face {
    font-family: 'RobotoThin';
    src:
      url('${RobotoThinWoffTwo}') format('woff2'),
      url('${RobotoThinWoff}') format('woff');
  }

  @font-face {
    font-family: 'RubikBlack';
    src:
      url('${RubikBlackWoffTwo}') format('woff2'),
      url('${RubikBlackWoff}') format('woff');
  }

  @font-face {
    font-family: 'RubikBold';
    src:
      url('${RubikBoldWoffTwo}') format('woff2'),
      url('${RubikBoldWoff}') format('woff');
  }

  @font-face {
    font-family: 'RubikMedium';
    src:
      url('${RubikMediumWoffTwo}') format('woff2'),
      url('${RubikMediumWoff}') format('woff');
  }

  @font-face {
    font-family: 'RubikRegular';
    src:
      url('${RubikRegularWoffTwo}') format('woff2'),
      url('${RubikRegularWoff}') format('woff');
  }

  @font-face {
    font-family: 'RubikLight';
    src:
      url('${RubikLightWoffTwo}') format('woff2'),
      url('${RubikLightWoff}') format('woff');
  }

  @font-face {
    font-family: 'LatoBold';
    src:
      url('${LatoBoldWoffTwo}') format('woff2'),
      url('${LatoBoldWoff}') format('woff');
  }

  @font-face {
    font-family: 'LatoMedium';
    src:
      url('${LatoMediumWoffTwo}') format('woff2'),
      url('${LatoMediumWoff}') format('woff');
  }

  @font-face {
    font-family: 'LatoRegular';
    src:
      url('${LatoRegularWoffTwo}') format('woff2'),
      url('${LatoRegularWoff}') format('woff');
  }

  @font-face {
    font-family: 'LatoLight';
    src:
      url('${LatoLightWoffTwo}') format('woff2'),
      url('${LatoLightWoff}') format('woff');
  }
`;
