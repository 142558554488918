import { api } from '.';

export const avatarApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAvatarUrl: builder.query<string, string>({
      query: (fullPath) => ({
        url: fullPath,
        responseType: 'blob',
        cache: 'no-cache',
      }),
    }),
  }),
});
export const { useLazyGetAvatarUrlQuery } = avatarApi;
