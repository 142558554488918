import { format } from 'date-fns';

export const formatDate = (date) =>
  !date ? '-' : format(new Date(date), 'dd MMM, yyyy');

export const formatDateFromString = (date) =>
  date &&
  `${date.getFullYear()}-${date.getMonth() + 1 > 9 ? '' : '0'}${
    date.getMonth() + 1
  }-${date.getDate() > 9 ? '' : '0'}${date.getDate()}`;
