export const USER_ROLE = {
  ADMIN: 'Super admin',
  USER: 'User',
};
export const YES_NO_OPTIONS = {
  YES: 'Yes',
  NO: 'No',
};

export const LAST_ACTION_TYPE = {
  TAB: 'Tab',
  PAGER: 'Pager',
  BACK_BTN: 'Back_btn',
};

export const TABS_NAME = {
  COLLAB: {label: 'Collab', code: 'COLLAB'},
  JPASS: {label: 'J-Pass', code: 'JPASS'},
  ESP: {label: 'ESP', code: 'ESP'},
};
