import React from "react";

const QualificationIcon = () => (
  <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 21" width="29" height="21">
    <title>QualificationIcon</title>
    <defs>
      <image width="29" height="21" id="img12"
             href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAVCAMAAACNHhVQAAAAAXNSR0IB2cksfwAAAddQTFRFAAAAAGb/AHr/AHv/AHz/AH3/AHz/AHz/AHz/AHv/AHr/AIT/AG3/AHr/AHn/AID/AHz/AHn/AHr/AHb/AH3/AHn/AH7/AHz/AHz/AID/AHr/AHv/AHj/AID/AHb/AHv/AHz/AHz/AHz/AHv/AID/AHv/AHz/AHr/AHr/AHb/AHv/AHv/AHr/AHz/AHr/AH3/AHz/AHX/AHr/AHv/AHf/AHj/AGn/AHv/AHn/AHz/AHr/AHn/AHr/AHD/AHz/AHj/AID/AHv/AHn/AHv/AHr/AHr/AHv/AHH/AHr/AID/AHv/AHv/AID/AHz/AH3/AHj/AID/AHr/AID/AHz/AHH/AID/AHv/AHv/AHj/AID/AGb/AHv/AHv/AHn/AHz/AH3/AHn/AHv/AHz/AGr/AHv/AHr/AHv/AHv/AED/AHr/AH3/AHv/AHv/AHv/AHz/AHv/AHn/AHv/AHj/AHr/AHv/AHz/AHn/AHv/AHj/AH7/AHr/AH3/AHv/AHv/AHn/AHr/AHr/AHr/AHv/AHz/AHz/AHz/AGz/AHr/AHv/AHn/AG3/AHv/AHT/AHf/AHn/AHr/AHr/AHz/AHv/AHz/AHv/AHz/AHv/AHr/AHv/AHn/AHT/AHP/AHj/eptZMQAAAJ10Uk5TAAWdz83M0MvM0bQfB6uPHGXoQRpq50HmVBqE2yIeOJWMom+5Bp+CrmscdJyv5Rct2hgZ3B5CEVHMr6Gk6BDRJArFUunRn50JYAKi0CTT1zEMQwRjEiLS6BEgCvQbkf8xE1PDDIGCjaAExHKe1Gae82ddW3OH8nhNUVvPdM7y0tnm2scl0pAaLmqlDqQLD2WjYqHNyJOzw5b/8jdJZF8g4WUAAAFfSURBVHicY2BgZGJmYWWDAHYOTlYOCJOZi5uBgYGHl49fQFCIHwSERUTFRMQlwGxJKWkZBgZZOaASPnkGCFBQZFBSVoGwVdUYGNQ1NLW0dXT1ICL6BoZGxiYQtqm0GQObuYWllbWNDETE1s7ewdEJwjZ05mdwcXVjYHBnM4eI8Ch7eHoJQFV6+zD4+vkzMASwBUJEHBw9gryCIewQl1CGsHBNBoYIzkioes7QKE6oOXrRMQyxcUArhaQjICLx4QkmxsIQdmJSMoN/XArQ8alQH7nFMqSlQ5gZmVlAMjsnNy+/oBAEivLyi0tKy8oZKgoLK92q3EGKqjNrmDlBoLauvsGlsYGzqTmspcW71R3q0GQwaHNp7+js6uju6mEISE7uYUAFvd7Nff2efYqeDNgAQdkCfLITOCfilp00mUu1B5dsxhTZvqnTpuOQZWBkKJgxcWY6DlkGhllaDLPnIPEBGE1VJjVREM8AAAAASUVORK5CYII=" />
    </defs>
    <style>
    </style>
    <use id="Background" href="#img12" x="0" y="0" />
  </svg>
);
export default QualificationIcon;
