import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { FormHelperText, Stack, ThemeProvider, Typography } from '@mui/material';
import AppTheme from 'ui/theme/app-theme';
import { ArrowLeftIcon } from 'assets/svgs/componentsIcons';
import { BackButton } from 'components/Buttons';
import ConfirmationPopup from 'components/ConfirmationPopin';
import { POPUP_TYPE } from 'components/ConfirmationPopin/constants';
import TabTitles from 'components/StyledComponents/TabTitles';
import {
  PROJECT_SETTINGS_EDIT_PROJECT_MEMBERS,
  SETTINGS_IN_PROCESS_PROJECTS,
  SETTINGS_PROJECTS,
} from 'routes/constants';
import {
  useLazyGetProjectByWorkLocationQuery,
  useLazyGetProjectWorkDimensionByIdQuery,
  useUpdateProjectCreationStatusMutation,
} from 'services/apis/project';
import {
  useCreateUpdateWorkLocationMutation,
  useLazyGetWorkLocationsByProjectIdQuery,
} from 'services/apis/work-location';
import {
  setProjectCreationStatus,
  setWithWorkLocation,
} from 'services/slices/project-setting/project-creation';
import {
  makeSelectProjectCreationStatus,
  makeSelectProjectWithWorkLocation,
} from 'pages/AppProjectsSettings/ProjectsSettingsProjectCreation/selectors';
import ProjectsSettingsProjectCreationWrapper from 'pages/AppProjectsSettings/ProjectsSettingsProjectCreation/Wrapper';
import {
  fillProjectData,
  resetContractorDeletedStatus,
  resetProjectData,
  resetProjectWork,
  resetSubContractorDeletedStatus,
  setActiveTab,
  setIsDataUpdated,
  setIsEntityDelete,
  setSubmit,
  updateProjectDatas,
  updateSelectedEntityInit,
  updateWorkOrders,
} from 'services/slices/project-setting/project-work';
import {
  useCreateUpdateAreaProjectMutation,
  useLazyGetAreaProjectsByProjectIdQuery,
} from 'services/apis/project-area';
import {
  useCreateUpdateProjectSystemMutation,
  useLazyGetProjectSystemByProjectIdQuery,
} from 'services/apis/project-system';

import {
  useCreateUpdateProjectDisciplineMutation,
  useLazyGetProjectDisciplineByProjectIdQuery,
} from 'services/apis/project-discipline';
import ProjectWorkDimensionEntities from 'pages/AppProjectsSettings/ProjectSettingProjectWorkDimensions/ProjectEntities';
import {
  makeSelectContractorsByProject,
  makeSelectSubContractorsByProject,
  makeSelectVendorsByProject,
} from 'pages/AppProjectsSettings/ProjectSettingProjectWorkDimensions/ProjectEntities/selectors';
import {
  useLazyGetContractorsByProjectQuery,
  useLazyGetSubContractorsByProjectQuery,
  useLazyGetVendorsByProjectQuery,
  useReassignContractorToProjectMutation,
  useReassignSubContractorToProjectMutation,
  useReassignVendorToProjectMutation,
} from 'services/apis/project-work-dimensions';
import FullPageLoader from 'components/FullPageLoader';
import ProjectSettingsFooter from 'pages/AppProjectsSettings/ProjectSettingsFooter';
import ProjectCreationPopup from 'containers/ProjectCreationPopup';
import { PROJECT_CREATION_STATUS } from 'helpers/constants';
import makeSelectProjectPrivileges from 'services/slices/selectors';
import usePrivilegeByFeatures from 'utils/hooks/usePrivilegeByFeatures';
import { Privileges } from 'utils/constants/Features';
import {
  emptyListError,
  PROJECT_WORK_DIMENSIONS_ALL_TABS,
  PROJECT_WORK_DIMENSIONS_TAB_NAME,
  SHARED_ENTITIES,
} from './constants';
import ProjectWorkLocation from './ProjectWorkLocation';
import ProjectArea from './ProjectArea';
import ProjectDiscipline from './ProjectDiscipline';
import {
  areListsNotEqual,
  existNewDataOrUpdated,
  prepareFormatData,
  searchDuplicatedName,
} from './helper';
import {
  makeSelectActiveTab,
  makeSelectIsDataUpdated,
  makeSelectPictures,
  makeSelectProjectDatas,
  makeSelectSelectedDisciplines,
  makeSelectSelectedEntityInit,
} from './selectors';

const stateSelector = createStructuredSelector({
  pictures: makeSelectPictures,
  withWorkLocation: makeSelectProjectWithWorkLocation,
  activeTab: makeSelectActiveTab,
  projectDatas: makeSelectProjectDatas,
  selectedEntityInit: makeSelectSelectedEntityInit,
  selectedDisciplines: makeSelectSelectedDisciplines,
  contractorsByProject: makeSelectContractorsByProject,
  subContractorsByProject: makeSelectSubContractorsByProject,
  vendorsByProject: makeSelectVendorsByProject,
  isDataUpdated: makeSelectIsDataUpdated,
  projectCreationStatus: makeSelectProjectCreationStatus,
  featurePrivileges: makeSelectProjectPrivileges,
});

function ProjectSettingProjectWorkDimensions() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const {
    pictures,
    withWorkLocation,
    activeTab,
    projectDatas,
    selectedEntityInit,
    selectedDisciplines,
    contractorsByProject,
    subContractorsByProject,
    vendorsByProject,
    isDataUpdated,
    projectCreationStatus,
  } = useSelector(stateSelector);
  const [errorDuplicatedName, setDuplicatedName] = useState('');
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [confirmationBackPopup, setConfirmationBackPopup] = useState(false);
  const [confirmationTabPopup, setConfirmationTabPopup] = useState(false);
  const [confirmationDeletePopup, setConfirmationDeletePopup] = useState(false);
  const [deleteEntityFunc, setDeleteEntityFunc] = useState(null);
  const [futureActiveTab, setFutureActiveTab] = useState(null);
  const [projectDataToDelete, setProjectDataToDelete] = useState(null);
  const [errorEmptyList, setErrorEmptyList] = useState(null);
  const [openDraftModal, setOpenDraftModal] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [publishProject, setPublishProject] = useState(false);
  const [openErrorPopUp, setOpenErrorPopUp] = useState(null);
  const projectWithWorkLocation = withWorkLocation && projectId;
  const tabName = SHARED_ENTITIES.CONTRACTOR.code;

  const [doGetProjectByWorkLocation] = useLazyGetProjectByWorkLocationQuery();
  const [doGetWorkLocationsByProjectId] = useLazyGetWorkLocationsByProjectIdQuery();

  const [doGetAreaProjectsByProjectId, { isFetching: fetchAreasLoading }] =
    useLazyGetAreaProjectsByProjectIdQuery();

  const [doGetProjectSystemsByProjectId, { isFetching: fetchSystemsLoading }] =
    useLazyGetProjectSystemByProjectIdQuery();
  const [doGetProjectDisciplineByProjectId, { isFetching: fetchDisciplinesLoading }] =
    useLazyGetProjectDisciplineByProjectIdQuery();
  const [doGetProjectWorkDimensionById] = useLazyGetProjectWorkDimensionByIdQuery();
  const [doGetContractorsByProject, { isFetching: fetchContractorsByProject }] =
    useLazyGetContractorsByProjectQuery();
  const [doGetSubContractorsByProject, { isFetching: fetchSubContractorsByProject }] =
    useLazyGetSubContractorsByProjectQuery();
  const [doGetVendorsByProject, { isFetching: fetchVendorsByProject }] =
    useLazyGetVendorsByProjectQuery();

  const [
    doCreateUpdateWorkLocation,
    { isSuccess: successCreation, isLoading: saveLoading },
  ] = useCreateUpdateWorkLocationMutation();
  const [
    doCreateUpdateProjectSystem,
    { isSuccess: successCreationSystem, isLoading: loadingCreationSystem },
  ] = useCreateUpdateProjectSystemMutation();
  const [
    doCreateUpdateAreaProject,
    { isSuccess: successCreationArea, isLoading: loadingCreationArea },
  ] = useCreateUpdateAreaProjectMutation();
  const [
    doCreateUpdateProjectDiscipline,
    { isSuccess: successCreationDiscipline, isLoading: loadingCreationDiscipline },
  ] = useCreateUpdateProjectDisciplineMutation();
  const [
    doReassignContractorToProject,
    {
      error: errorReassignContractor,
      isSuccess: successReassignContractor,
      isLoading: loadingReassignContractor,
    },
  ] = useReassignContractorToProjectMutation();
  const [
    doReassignSubContractorToProject,
    {
      error: errorReassignSubContractor,
      isSuccess: successReassignSubContractor,
      isLoading: loadingReassignSubContractor,
    },
  ] = useReassignSubContractorToProjectMutation();
  const [
    doReassignVendorToProject,
    {
      error: errorReassignVendor,
      isSuccess: successReassignVendor,
      isLoading: loadingReassignVendor,
    },
  ] = useReassignVendorToProjectMutation();
  const [
    doUpdateProjectCreationStatus,
    { isSuccess: successUpdateProjectStatus, isLoading: loadingUpdateProjectStatus },
  ] = useUpdateProjectCreationStatusMutation();

  const entityCreationSuccess =
    (successUpdateProjectStatus &&
      (successCreation ||
        successCreationArea ||
        successCreationSystem ||
        successCreationDiscipline ||
        successReassignVendor ||
        successReassignSubContractor ||
        successReassignContractor)) ||
    (successUpdateProjectStatus && publishProject);

  const entityCreationLoading =
    loadingUpdateProjectStatus ||
    saveLoading ||
    loadingCreationArea ||
    loadingCreationSystem ||
    loadingCreationDiscipline ||
    loadingReassignVendor ||
    loadingReassignSubContractor ||
    loadingReassignContractor;

  const navigateToProjectMembersEdition = () => {
    const updateProjectMembersPath = generatePath(PROJECT_SETTINGS_EDIT_PROJECT_MEMBERS, {
      projectId,
    });
    navigate(updateProjectMembersPath);
  };
  const privilegesFeatures = usePrivilegeByFeatures(
    Object.values(PROJECT_WORK_DIMENSIONS_ALL_TABS)?.map((tab) => tab.feature),
    Privileges.READ,
  );

  const fetchData = () => {
    if (activeTab === PROJECT_WORK_DIMENSIONS_ALL_TABS.WORK_LOCATION.code) {
      doGetWorkLocationsByProjectId({ projectId })
        .unwrap()
        .then((result) => {
          dispatch(updateProjectDatas(result));
        });
    } else if (activeTab === PROJECT_WORK_DIMENSIONS_ALL_TABS.AREA.code) {
      doGetAreaProjectsByProjectId(projectId)
        .unwrap()
        .then((result) => {
          dispatch(updateProjectDatas(result));
        });
    } else if (activeTab === PROJECT_WORK_DIMENSIONS_ALL_TABS.SYSTEM.code) {
      doGetProjectSystemsByProjectId(projectId)
        .unwrap()
        .then((result) => {
          dispatch(updateProjectDatas(result));
        });
    } else if (activeTab === PROJECT_WORK_DIMENSIONS_ALL_TABS.DISCIPLINE.code) {
      doGetProjectDisciplineByProjectId(projectId)
        .unwrap()
        .then((data) => {
          dispatch(updateSelectedEntityInit(data));
        });
    } else if (activeTab === PROJECT_WORK_DIMENSIONS_ALL_TABS.CONTRACTOR.code) {
      doGetContractorsByProject({ projectId })
        .unwrap()
        .then((result) => {
          dispatch(updateSelectedEntityInit(result));
        });
    } else if (activeTab === PROJECT_WORK_DIMENSIONS_ALL_TABS.SUB_CONTRACTOR.code) {
      doGetSubContractorsByProject({ projectId })
        .unwrap()
        .then((result) => {
          dispatch(updateSelectedEntityInit(result));
        });
    } else if (activeTab === PROJECT_WORK_DIMENSIONS_ALL_TABS.VENDOR.code) {
      doGetVendorsByProject({ projectId })
        .unwrap()
        .then((result) => {
          dispatch(updateSelectedEntityInit(result));
        });
    }
  };

  useEffect(() => {
    dispatch(fillProjectData({ projectId }));
    if (projectId) {
      doGetProjectByWorkLocation(projectId).then(({ data }) => {
        dispatch(updateWorkOrders(data?.workOrders));
        dispatch(setWithWorkLocation(data?.withWorkLocation));
        dispatch(setProjectCreationStatus(data?.projectCreationStatus));
      });
      dispatch(setActiveTab(tabName));
    }
    return () => {
      dispatch(resetProjectWork());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const getCurrentDataListByTabName = {
    [PROJECT_WORK_DIMENSIONS_ALL_TABS.DISCIPLINE.code]: selectedDisciplines,
    [PROJECT_WORK_DIMENSIONS_ALL_TABS.CONTRACTOR.code]: contractorsByProject,
    [PROJECT_WORK_DIMENSIONS_ALL_TABS.SUB_CONTRACTOR.code]: subContractorsByProject,
    [PROJECT_WORK_DIMENSIONS_ALL_TABS.VENDOR.code]: vendorsByProject,
    [PROJECT_WORK_DIMENSIONS_ALL_TABS.WORK_LOCATION.code]: projectDatas,
    [PROJECT_WORK_DIMENSIONS_ALL_TABS.AREA.code]: projectDatas,
    [PROJECT_WORK_DIMENSIONS_ALL_TABS.SYSTEM.code]: projectDatas,
  };

  useEffect(() => {
    const tabsUseProjectDatasToCheckForUpdates = [
      PROJECT_WORK_DIMENSIONS_ALL_TABS.WORK_LOCATION.code,
      PROJECT_WORK_DIMENSIONS_ALL_TABS.AREA.code,
      PROJECT_WORK_DIMENSIONS_ALL_TABS.SYSTEM.code,
    ];

    const isTabsUseProjectDatasUpdated =
      tabsUseProjectDatasToCheckForUpdates.includes(activeTab);

    const isDataUpdatedCheck = isTabsUseProjectDatasUpdated
      ? existNewDataOrUpdated(getCurrentDataListByTabName[activeTab])
      : areListsNotEqual(getCurrentDataListByTabName[activeTab], selectedEntityInit);

    dispatch(setIsDataUpdated(isDataUpdatedCheck));
  }, [
    dispatch,
    projectDatas,
    getCurrentDataListByTabName,
    selectedEntityInit,
    activeTab,
  ]);

  useEffect(() => {
    if (activeTab) {
      fetchData();
      setErrorEmptyList('');
      dispatch(setSubmit(false));
      dispatch(resetProjectData());
    }
  }, [dispatch, activeTab]);

  const onBtnBackClick = () => {
    setConfirmationBackPopup(false);
    navigateToProjectMembersEdition();
  };

  const onTabConfirmation = () => {
    dispatch(setActiveTab(futureActiveTab ?? tabName));
    setConfirmationTabPopup(false);
  };

  useEffect(() => {
    if (entityCreationSuccess && !confirmationBackPopup && !confirmationTabPopup) {
      setShowSuccessPopup(true);
    } else if (projectId && entityCreationSuccess && confirmationBackPopup) {
      onBtnBackClick();
    } else if (projectId && entityCreationSuccess && confirmationTabPopup) {
      onTabConfirmation();
    }
    if (projectId && entityCreationSuccess) {
      resetProjectWork();
    }
  }, [entityCreationSuccess]);
  /* eslint-disable no-shadow */
  const onClickDeleteProjectData = (projectDataToDelete) => {
    if (!projectDataToDelete?.id) {
      dispatch(
        updateProjectDatas(
          projectDatas?.filter((obj) => obj.name !== projectDataToDelete?.name),
        ),
      );
    } else {
      dispatch(
        updateProjectDatas(
          projectDatas?.map((obj) => {
            return obj.id === projectDataToDelete?.id ? { ...obj, isDeleted: true } : obj;
          }),
        ),
      );
    }
  };

  const onClickCreateUpdate = (projectCreationStatus) => {
    if (
      projectDatas.length > 0 ||
      selectedDisciplines.length > 0 ||
      contractorsByProject.length > 0 ||
      subContractorsByProject.length > 0 ||
      vendorsByProject.length > 0 ||
      isDraft
    ) {
      if (!isDataUpdated) setPublishProject(true);
      else if (!searchDuplicatedName(projectDatas, setDuplicatedName)) {
        switch (activeTab) {
          case PROJECT_WORK_DIMENSIONS_ALL_TABS.WORK_LOCATION.code:
            {
              const payloadToSave = prepareFormatData(projectDatas, pictures);
              doCreateUpdateWorkLocation({ body: payloadToSave, params: { projectId } });
            }
            break;

          case PROJECT_WORK_DIMENSIONS_ALL_TABS.DISCIPLINE.code:
            doCreateUpdateProjectDiscipline({
              projectId: parseInt(projectId, 10),
              body: selectedDisciplines,
            });
            break;

          case PROJECT_WORK_DIMENSIONS_ALL_TABS.CONTRACTOR.code:
            doReassignContractorToProject({
              body: contractorsByProject,
              params: { projectId: parseInt(projectId, 10) },
            });
            break;

          case PROJECT_WORK_DIMENSIONS_ALL_TABS.SUB_CONTRACTOR.code:
            doReassignSubContractorToProject({
              body: subContractorsByProject,
              params: { projectId: parseInt(projectId, 10) },
            });
            break;

          case PROJECT_WORK_DIMENSIONS_ALL_TABS.VENDOR.code:
            doReassignVendorToProject({
              body: vendorsByProject,
              params: { projectId: parseInt(projectId, 10) },
            });
            break;

          case PROJECT_WORK_DIMENSIONS_ALL_TABS.AREA.code:
            doCreateUpdateAreaProject({
              body: projectDatas,
              params: { isDraft, projectId },
            });
            break;

          case PROJECT_WORK_DIMENSIONS_ALL_TABS.SYSTEM.code:
            doCreateUpdateProjectSystem({
              body: projectDatas,
              params: { isDraft, projectId },
            });
            break;

          default:
            break;
        }
      }
      doUpdateProjectCreationStatus({ projectId, projectCreationStatus });
    } else {
      setErrorEmptyList(emptyListError(activeTab.toString().toLowerCase()));
    }
  };

  const onCancelClick = () => {
    setShowCancelPopup(false);
    doGetProjectWorkDimensionById(projectId).then(({ data }) => {
      if (
        ((withWorkLocation && data?.workLocations?.length > 0) ||
          (data?.projectAreas?.length > 0 && data?.projectSystems?.length > 0)) &&
        selectedDisciplines?.length > 0
      ) {
        dispatch(resetProjectWork());
      }
      navigate(
        isDraft && projectCreationStatus === PROJECT_CREATION_STATUS.DRAFT
          ? SETTINGS_IN_PROCESS_PROJECTS
          : SETTINGS_PROJECTS,
      );
    });
  };

  const handleTabChange = (value) => {
    setFutureActiveTab(value);
    if (isDataUpdated && value !== activeTab) {
      setConfirmationTabPopup(true);
    } else if (!isDataUpdated) {
      dispatch(setActiveTab(value));
    }
  };

  const deleteProjectData = (projectDataItem) => {
    setProjectDataToDelete(projectDataItem);
    setConfirmationDeletePopup(true);
  };

  const deleteEntityById = () => {
    deleteEntityFunc();
  };
  const handleDeleteEntity = (callbackFun) => {
    setConfirmationDeletePopup(true);
    setDeleteEntityFunc(() => callbackFun);
  };

  const handleDeleteEntityFunc = () => {
    const tabsBaseOnProjectData = [
      PROJECT_WORK_DIMENSIONS_ALL_TABS.SYSTEM.code,
      PROJECT_WORK_DIMENSIONS_ALL_TABS.AREA.code,
      PROJECT_WORK_DIMENSIONS_ALL_TABS.WORK_LOCATION.code,
    ];

    if (tabsBaseOnProjectData.includes(activeTab)) {
      onClickDeleteProjectData(projectDataToDelete);
    } else {
      deleteEntityById();
    }
  };

  const projectDataPart = () => {
    switch (activeTab) {
      case PROJECT_WORK_DIMENSIONS_ALL_TABS.WORK_LOCATION.code:
        return (
          <ProjectWorkLocation
            onDelete={deleteProjectData}
            errorMessage={errorDuplicatedName}
          />
        );
      case PROJECT_WORK_DIMENSIONS_ALL_TABS.DISCIPLINE.code:
        return <ProjectDiscipline />;
      case PROJECT_WORK_DIMENSIONS_ALL_TABS.CONTRACTOR.code:
        return (
          <ProjectWorkDimensionEntities
            handleDeleteEntity={handleDeleteEntity}
            entityName={PROJECT_WORK_DIMENSIONS_ALL_TABS.CONTRACTOR.code}
          />
        );
      case PROJECT_WORK_DIMENSIONS_ALL_TABS.SUB_CONTRACTOR.code:
        return (
          <ProjectWorkDimensionEntities
            handleDeleteEntity={handleDeleteEntity}
            entityName={PROJECT_WORK_DIMENSIONS_ALL_TABS.SUB_CONTRACTOR.code}
          />
        );
      case PROJECT_WORK_DIMENSIONS_ALL_TABS.VENDOR.code:
        return (
          <ProjectWorkDimensionEntities
            handleDeleteEntity={handleDeleteEntity}
            entityName={PROJECT_WORK_DIMENSIONS_ALL_TABS.VENDOR.code}
          />
        );
      case PROJECT_WORK_DIMENSIONS_ALL_TABS.SYSTEM.code:
      case PROJECT_WORK_DIMENSIONS_ALL_TABS.AREA.code:
        return (
          <ProjectArea
            type={activeTab}
            onDelete={deleteProjectData}
            errorMessage={errorDuplicatedName}
          />
        );
      default:
        return null;
    }
  };

  const handleOpenModal = () => {
    setShowCancelPopup(true);
  };

  const handleOpenDraftModal = () => {
    setOpenDraftModal(true);
    setIsDraft(true);
  };

  useEffect(() => {
    if (errorReassignContractor) {
      setConfirmationTabPopup(false);
      setOpenErrorPopUp(errorReassignContractor?.data?.detail);
    }
  }, [errorReassignContractor]);

  useEffect(() => {
    if (errorReassignSubContractor) {
      setConfirmationTabPopup(false);
      setOpenErrorPopUp(errorReassignSubContractor?.data?.detail);
    }
  }, [errorReassignSubContractor]);

  useEffect(() => {
    if (errorReassignVendor) {
      setConfirmationTabPopup(false);
      setOpenErrorPopUp(errorReassignVendor?.data?.detail);
    }
  }, [errorReassignVendor]);

  return (
    <ThemeProvider theme={AppTheme}>
      {(fetchContractorsByProject ||
        fetchSubContractorsByProject ||
        fetchVendorsByProject ||
        fetchDisciplinesLoading ||
        fetchAreasLoading ||
        fetchSystemsLoading ||
        saveLoading) && <FullPageLoader />}
      <ProjectsSettingsProjectCreationWrapper>
        <Stack className="top-header">
          <Stack className="heading-area">
            <BackButton
              type="button"
              onClick={() => {
                if (!isDataUpdated) {
                  navigateToProjectMembersEdition();
                } else {
                  setConfirmationBackPopup(true);
                }
              }}
            >
              <ArrowLeftIcon />
            </BackButton>
            <Stack className="page-title-area">
              <Typography variant="h3_HelveticaNeue_Bold" color="primary.main">
                Project work dimensions
              </Typography>
            </Stack>
          </Stack>

          <Typography
            className="mb-25"
            variation="subtitle1_HelveticaNeue_Regular"
            color="text.main"
          >
            Add project work dimensions
          </Typography>
          {errorEmptyList && (
            <FormHelperText className="errorMsg">{errorEmptyList}</FormHelperText>
          )}
        </Stack>
        <Stack>
          <TabTitles
            handleTabChange={handleTabChange}
            tabs={Object.values(
              projectWithWorkLocation
                ? PROJECT_WORK_DIMENSIONS_TAB_NAME.WITH_WORK_LOCATIONS
                : PROJECT_WORK_DIMENSIONS_TAB_NAME.WITHOUT_WORK_LOCATIONS,
            ).map((tab) => ({
              ...tab,
              disabled: !privilegesFeatures?.[tab.feature],
            }))}
            activeTab={activeTab}
          />
        </Stack>
        {projectDataPart()}
        <ProjectSettingsFooter
          cancelLabel="Cancel"
          cancelOnClick={handleOpenModal}
          DraftLabel="Save as Draft"
          DraftOnClick={handleOpenDraftModal}
          saveLabel="Save"
          saveOnClick={() => {
            setIsDraft(false);
            onClickCreateUpdate(PROJECT_CREATION_STATUS.PUBLISHED);
          }}
          showDraftBtn={projectCreationStatus === PROJECT_CREATION_STATUS.DRAFT}
        />
        <ConfirmationPopup
          width="500px"
          toOpen={showCancelPopup}
          popupType={POPUP_TYPE.CONFIRMATION_CANCEL__POPUP}
          onConfirm={onCancelClick}
          onCancel={() => setShowCancelPopup(false)}
          onClose={() => setShowCancelPopup(false)}
        />
        {openDraftModal && (
          <ProjectCreationPopup
            projectId={projectId}
            toOpen={openDraftModal}
            onConfirm={() => onClickCreateUpdate(PROJECT_CREATION_STATUS.DRAFT)}
            saveSuccess={entityCreationSuccess}
            saveLoading={entityCreationLoading}
            setOpenDraftModal={setOpenDraftModal}
            setShowSuccessPopup={setShowSuccessPopup}
          />
        )}
        {showSuccessPopup && (
          <ConfirmationPopup
            toOpen={showSuccessPopup}
            popupType={POPUP_TYPE.SUCCESS__POPUP}
            onCancel={() => {
              setShowSuccessPopup(false);
              onCancelClick();
            }}
            onClose={() => {
              setShowSuccessPopup(false);
              onCancelClick();
            }}
          />
        )}
        {isDataUpdated && confirmationBackPopup && (
          <ConfirmationPopup
            toOpen={confirmationBackPopup}
            entityName={activeTab.toString().toLowerCase()}
            popupType={POPUP_TYPE.CONFIRMATION_BACK__POPUP}
            onConfirm={() => onClickCreateUpdate(PROJECT_CREATION_STATUS.DRAFT)}
            onCancel={() => setConfirmationBackPopup(false)}
            onClose={() => setConfirmationBackPopup(false)}
          />
        )}
        {isDataUpdated && confirmationTabPopup && (
          <ConfirmationPopup
            toOpen={confirmationTabPopup}
            entityName={futureActiveTab.toString().toLowerCase()}
            popupType={POPUP_TYPE.CONFIRMATION_TAB__POPUP}
            onConfirm={() => onClickCreateUpdate(PROJECT_CREATION_STATUS.DRAFT)}
            onCancel={() => setConfirmationTabPopup(false)}
            onClose={() => setConfirmationTabPopup(false)}
          />
        )}
        {confirmationDeletePopup && (
          <ConfirmationPopup
            toOpen={confirmationDeletePopup}
            entityName={activeTab.toString().toLowerCase()}
            popupType={POPUP_TYPE.CONFIRMATION_DELETE__POPUP}
            onDelete={() => {
              dispatch(setIsEntityDelete(true));
              handleDeleteEntityFunc();
              setConfirmationDeletePopup(false);
            }}
            onClose={() => setConfirmationDeletePopup(false)}
            onCancel={() => setConfirmationDeletePopup(false)}
          />
        )}
        {!!openErrorPopUp && (
          <ConfirmationPopup
            toOpen={!!openErrorPopUp}
            errorMsg={openErrorPopUp}
            popupType={POPUP_TYPE.ERROR_DELETE_PROFILE}
            onClose={() => {
              setOpenErrorPopUp(null);
              if (activeTab === PROJECT_WORK_DIMENSIONS_ALL_TABS.CONTRACTOR.code) {
                dispatch(resetContractorDeletedStatus());
              } else if (
                activeTab === PROJECT_WORK_DIMENSIONS_ALL_TABS.SUB_CONTRACTOR.code
              ) {
                dispatch(resetSubContractorDeletedStatus());
              }
            }}
          />
        )}
      </ProjectsSettingsProjectCreationWrapper>
    </ThemeProvider>
  );
}

export default ProjectSettingProjectWorkDimensions;
