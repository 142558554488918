import React from "react";

const Bookmark = () => (
  <svg
    className="/*rotate180*/"
    xmlns="http://www.w3.org/2000/svg"
    width="17.508"
    height="10.657"
    viewBox="0 0 17.508 10.657"
  >
    <path
      fill="#007CFF"
      d="M0,0H17.508L12.481,5.4l5.027,5.261H0Z"
    />
  </svg>
);
export default Bookmark;
