import React from 'react';
import { Droppable } from '@hello-pangea/dnd';
import ProjectDroppableItem from '../ProjectDroppableItem';
import ProjectDroppableListWrapper from './Wrapper';

interface ProjectDroppableListProps {
  classname?: string;
  droppableId: string;
  disabled: boolean;
  items: Array<{
    id: string;
    content: string;
  }>;
  isRightList?: boolean;
}

const ProjectDroppableList = function ({
  classname,
  droppableId,
  items,
  isRightList,
  disabled = false,
}: ProjectDroppableListProps): JSX.Element {
  return (
    <Droppable droppableId={droppableId} isDropDisabled={disabled}>
      {(provided) => (
        <ProjectDroppableListWrapper
          /* eslint-disable react/jsx-props-no-spreading */
          {...provided.droppableProps}
          ref={provided.innerRef}
          className={` ${classname ?? ''}`}
        >
          {items?.map((item, index) => (
            <ProjectDroppableItem
              key={item.id}
              id={item.id}
              content={item.content}
              index={index}
              isRightList={isRightList}
            />
          ))}
          {provided.placeholder}
        </ProjectDroppableListWrapper>
      )}
    </Droppable>
  );
};

export default ProjectDroppableList;
