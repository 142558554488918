import {api} from "..";
import {PrivilegesEnum} from "pages/AccessManagementProfile/helper";

const featureBaseUrl = "/features";

export type Module = {
  id: number,
  code: string,
  label: string,
  platformEnum: PrivilegesEnum,
}

export type Feature = {
  id: number,
  code: string,
  label: string,
}

export type FeaturePrivilege = {
  feature: Feature,
  privilegeDtoList: PrivilegesEnum[],
}
export type ModuleFeatures = {
  module: Module,
  features: FeaturePrivilege[],
}

export const featureModuleApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getModuleFeaturesByPlatform: builder.query<ModuleFeatures[], number>({
      query: (platformCode) => ({
        url: `${featureBaseUrl}/${platformCode}`,
      }),
    }),
  }),
});

export const {
  useGetModuleFeaturesByPlatformQuery,
} = featureModuleApi;
