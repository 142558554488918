import React from "react";

const HideIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="38"
    height="38"
    viewBox="0 0 38 38"
  >
    <g
      id="Groupe_11286"
      data-name="Groupe 11286"
      transform="translate(-139 -427)"
    >
      <rect
        id="Rectangle_2539"
        data-name="Rectangle 2539"
        width="38"
        height="38"
        rx="14"
        transform="translate(139 465) rotate(-90)"
        fill="#e8eaed"
        opacity="0.236"
      />
      <path
        id="Tracé_28783"
        data-name="Tracé 28783"
        d="M29.482,66.963a3.705,3.705,0,0,1,3.7,3.7,3.647,3.647,0,0,1-.263,1.352l2.167,2.167a8.8,8.8,0,0,0,2.545-3.519,8.767,8.767,0,0,0-8.153-5.556,8.664,8.664,0,0,0-2.952.519l1.6,1.6a3.722,3.722,0,0,1,1.352-.263Zm-7.408-2.019,1.689,1.689.337.337a8.774,8.774,0,0,0-2.767,3.7,8.751,8.751,0,0,0,11.4,4.93l.315.315,2.16,2.163.945-.941L23.018,64Zm4.1,4.093,1.145,1.145a2.347,2.347,0,0,0-.056.485,2.224,2.224,0,0,0,2.222,2.222,2.283,2.283,0,0,0,.482-.056l1.145,1.145a3.644,3.644,0,0,1-1.626.393,3.68,3.68,0,0,1-3.311-5.334Zm3.189-.582,2.334,2.334.011-.122a2.224,2.224,0,0,0-2.222-2.222Z"
        transform="translate(128.364 375.043)"
        fill="#5f6a7e"
      />
    </g>
  </svg>
);
export default HideIcon;
