import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from '@hello-pangea/dnd';
import { Typography, Stack } from '@mui/material';
import ProjectDroppableItemWrapper from './Wrapper';

function ProjectDroppableItem({ id, content, index, isRightList }) {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <ProjectDroppableItemWrapper
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <Stack className={isRightList ? 'rightDragDropItem' : 'leftDragDropItem'}>
            <Typography
              variant="subtitle2_HelveticaNeue_Medium"
              color="text.primary"
              className={isRightList ? 'rightDragDropItemContent' : 'itemContent'}
            >
              {content}
            </Typography>
          </Stack>
        </ProjectDroppableItemWrapper>
      )}
    </Draggable>
  );
}

ProjectDroppableItem.propTypes = {
  id: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isRightList: PropTypes.bool,
};

export default ProjectDroppableItem;
