import {combineReducers} from "@reduxjs/toolkit";
import userManagement from "./user-management";
import externalUsers from "./external-users";
import userManagementOption from "./user-management-option";
import userProjectProfile from "./user-project-profile";

export default combineReducers({
  userManagement,
  externalUsers,
  userManagementOption,
  userProjectProfile
});
