import React from 'react';

const SeniorManagement = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 50 50"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.158"
        y1="0.148"
        x2="0.847"
        y2="0.831"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#007cff" />
        <stop offset="1" stopColor="#0af" />
      </linearGradient>
    </defs>
    <g
      id="Group_64076"
      data-name="Group 64076"
      transform="translate(-826 -409.936)"
    >
      <circle
        id="Ellipse_3102"
        data-name="Ellipse 3102"
        cx="25"
        cy="25"
        r="25"
        transform="translate(826 409.936)"
        fill="url(#linear-gradient)"
      />
      <path
        id="Path_67233"
        data-name="Path 67233"
        d="M-16.116-4.283c4.886,0,7.34-2.432,7.34-5.313,0-5.809-8.713-4.233-8.713-6.124,0-.54.5-.991,2.049-.991a9.723,9.723,0,0,1,4.39,1.171l1.6-3.85A12.51,12.51,0,0,0-15.4-20.764c-4.886,0-7.34,2.387-7.34,5.313,0,5.876,8.713,4.278,8.713,6.259,0,.54-.54.856-2.049.856a10.812,10.812,0,0,1-5.2-1.441L-22.982-5.9A14.122,14.122,0,0,0-16.116-4.283Zm27.828-.36L11.667-20.4H7.3l-5,8.4-5.133-8.4H-7.2v15.76h4.841v-6.934L1.063-6.062H3.4l3.422-5.764.045,7.182Z"
        transform="translate(855.821 447.459)"
        fill="#fff"
      />
    </g>
  </svg>
);

export default SeniorManagement;
