export enum Features {
  DS_PROJECT_GENERAL_INFORMATION = 'DS_PROJECT_GENERAL_INFORMATION',
  DS_PROJECT_MEMBERS_COLLAB = 'DS_PROJECT_MEMBERS_COLLAB',
  DS_PROJECT_MEMBERS_JPASS = 'DS_PROJECT_MEMBERS_JPASS',
  DS_PROJECT_MEMBERS_ESP = 'DS_PROJECT_MEMBERS_ESP',
  DS_PROJECT_DISCIPLINE = 'DS_PROJECT_DISCIPLINE',
  DS_PROJECT_CONTRACTOR_SUB_CONTRACTOR = 'DS_PROJECT_CONTRACTOR_SUB_CONTRACTOR',
  DS_PROJECT_VENDOR = 'DS_PROJECT_VENDOR',
  DS_PROJECT_AREA_SYSTEM = 'DS_PROJECT_AREA_SYSTEM',
  DS_PROJECT_WORK_LOCATION = 'DS_PROJECT_WORK_LOCATION',
}
export enum Privileges {
  READ = 'R',
  WRITE = 'W',
  HIDE = 'H',
}
export enum PlatformEnum {
  COLLAB = 'COLLAB',
  JPASS = 'JPASS',
  ESP = 'ESP',
}
