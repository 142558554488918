import { css } from '@emotion/react';
import normalize from './theme/styled-normalize';
import AppTheme from './theme/app-theme';
import { GlobalFontStyle } from './theme/fonts/style';

const GlobalStyle = css`
  ${normalize}
  ${GlobalFontStyle}
  body {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    text-align: left;
    color: ${AppTheme.AppColors.blueViolet_100};
    background-color: ${AppTheme.GreyColors[100]};

    font-family: 'HelveticaNeueRegular', sans-serif;

    ${AppTheme.Fonts.Weight[400]}
    ${AppTheme.Fonts.Scale.Display}
    #root {
      width: inherit;
      height: inherit;
    }
  }

  &::-webkit-scrollbar {
    height: 5px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${AppTheme.AppColors.primary};
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${AppTheme.AppColors.secondary};
  }

  &::-webkit-scrollbar-thumb:active {
    background: ${AppTheme.AppColors.info};
  }

  .flex__column {
    display: flex;
    flex-direction: column;
  }

  .flex__column--center {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .flex__row {
    display: flex;
    flex-direction: row;
  }

  .flex__row--center {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: center;
  }

  .flex__row--center-space {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between;
  }

  .flex__row--center-space-around {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: space-around;
  }

  .flex__row--baseline {
    display: flex;
    flex-direction: row !important;
    align-items: baseline;
  }

  .pointer {
    cursor: pointer;
  }

  .inputs__label > label {
    top: -24px;
    transform: translate(0) scale(0.75) !important;

    & > span {
      font-family: 'HelveticaNeueMedium', sans-serif;
      font-size: 16px;
      color: ${AppTheme.AppColors.DarkElectricBlue};
    }
  }

  .form-split {
    column-gap: 30px;
    flex-direction: row !important;
    flex-wrap: wrap;

    & > div:not(.fullWidth) {
      min-height: 80px;
      width: calc(50% - 15px) !important;
    }
  }

  .hide {
    display: none !important;
  }

  .show {
    display: flex;
  }

  .opc-4 {
    opacity: 0.4;
  }

  .fullWidth {
    width: 100%;
  }

  .mb-0 {
    margin-bottom: 0 !important;
  }

  .mb-15 {
    margin-bottom: 15px !important;
  }
  .mb-20 {
    margin-bottom: 20px !important;
  }

  .mb-25 {
    margin-bottom: 25px !important;
  }

  .mb-40 {
    margin-bottom: 40px;
  }

  .mt-45 {
    margin-top: 45px !important;
  }

  .mt-60 {
    margin-top: 60px !important;
  }

  .mt-35 {
    margin-top: 35px !important;
  }

  .mt-30 {
    margin-top: 30px !important;
  }

  .mt-25 {
    margin-top: 25px;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .mt-15 {
    margin-top: 15px !important;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .mt-8 {
    margin-top: 8px !important;
  }

  .mt-5 {
    margin-top: 5px !important;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .mr-15 {
    margin-right: 15px !important;
  }

  .mr-20 {
    margin-right: 20px !important;
  }

  .mr-30 {
    margin-right: 30px;
  }

  .ml-50 {
    margin-left: 50px !important;
  }

  .ml-45 {
    margin-left: 45px !important;
  }

  .ml-35 {
    margin-left: 35px !important;
  }

  .ml-30 {
    margin-left: 30px !important;
  }

  .ml-20 {
    margin-left: 20px !important;
  }

  .ml-10 {
    margin-left: 10px !important;
  }

  .ml-auto {
    margin-left: auto !important;
  }

  .gap-10 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  .no-padding-right {
    padding-right: 0;
  }

  .no-padding-top {
    padding-top: 0;
  }

  .no-padding-bottom {
    padding-bottom: 0 !important;
  }

  .padding-sides-10 {
    padding: 0 10px;
  }

  .padding-20 {
    padding: 20px;
  }

  .overflow-auto {
    overflow: auto;
  }

  .form-one-item {
    & > div {
      width: 50%;
      padding-right: 15px;
    }
  }

  .justifyContent-flexend {
    justify-content: flex-end;
  }

  .justify-center {
    justify-content: center;
  }

  .localisationContent {
    padding: 0;
    width: 100%;
    height: 350px;
    overflow: hidden;

    .leaflet-container {
      width: 100%;
      height: 100%;
    }

    .inputs__label > label {
      top: 0;
    }

    .map {
      border: 1px solid;
      margin-top: 30px;
      width: inherit;
      height: 300px;
    }

    .leaflet-container-big {
      width: 100%;
      height: 60vh;
    }

    .leaflet-touch .leaflet-bar a {
      margin-bottom: 10px;
      border-radius: 10px !important;
    }

    .leaflet-touch .leaflet-bar a,
    .leaflet-touch .leaflet-bar {
      border: none;
      color: var(--clr-gray-500);
    }

    .leaflet-popup-content-wrapper,
    .leaflet-popup-tip {
      background: var(--clr-blue-200);
      color: var(--clr-white-100);
      box-shadow: none;
    }

    .leaflet-popup-content {
      width: max-content !important;
    }

    .leaflet-container a.leaflet-popup-close-button {
      display: none;
    }

    .leaflet-pane {
      z-index: 0;
    }
  }

  .errorMsg,
  .MuiFormHelperText-root {
    color: ${AppTheme.AppColors.lightDanger} !important;
    padding-top: 5px;
    margin-bottom: 35px;
  }

  .loader {
    border: 8px solid #f4f4f6;
    border-radius: 50%;
    border-top: 8px solid #00aaff;
    width: 40px;
    height: 40px;
    animation: spin 0.9s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -40px;
  }

  .loaderContainer {
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    background: rgba(50, 53, 5, 0.1) !important;
    backdrop-filter: blur(7px) !important;
    z-index: 9999 !important;

    &.isCenter {
      position: fixed;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    }
  }

  .addEntity {
    .footer--next__button {
      max-height: 34.5px;
    }
  }

  .resetGrid {
    width: 100% !important;
    margin-left: 0 !important;
  }

  .circlesContainer {
    position: relative;

    &:after {
      top: 50%;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      position: absolute;
      border-top: 1px dashed red;
      pointer-events: none;
    }

    &:before {
      top: 0;
      left: 50%;
      width: 100%;
      height: 100%;
      content: '';
      position: absolute;
      transform: translate(-100%);
      border-right: 1px dashed yellow;
      pointer-events: none;
    }
  }

  .flipCircleWrapper {
    border: 1px dashed;
  }

  .logoItem {
    /*offset-path: ellipse(41% 29% at 50% 55%);*/
    offset-path: url(#bigCircle);
    offset-rotate: 0deg;
    offset-distance: 1%;
    width: 100px;
    height: 100px;
    border: 1px solid red;
    position: absolute;
  }

  .logoItem > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .flipCircle,
  .svgContainer {
    /* This Transition is Used Only when we want do circle rotation*/
    transition: all 1s ease-out;
    transform-origin: center;
  }

  .orbits {
    opacity: 0;
  }

  .clonedElement {
    .platformLogoItem {
      img {
        width: 100%;
      }
    }

    .MuiTypography-root {
      display: none;
    }
  }

  .btnIcon {
    width: 38px;
    height: 38px;
    border-radius: 14px;
    background: #e8eaed42;
    align-items: center;
    justify-content: center;
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default GlobalStyle;
