import React from 'react';
import PropTypes from 'prop-types';

interface EditIconProfileProps {
  color?: string;
  classname?: string;
  isWithRect?: boolean;
}
const EditIconProfile: React.FC<EditIconProfileProps> = function ({
  color,
  classname,
  isWithRect = true,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={classname}
      width="38"
      height="38"
      viewBox="0 0 38 38"
    >
      <g id="Groupe_11284" data-name="Groupe 11284" transform="translate(-139 -531.07)">
        {isWithRect && (
          <rect
            id="Rectangle_2535"
            data-name="Rectangle 2535"
            width="38"
            height="38"
            rx="14"
            transform="translate(139 531.07)"
            fill="#e8eaed"
            opacity="0.236"
          />
        )}
        <g
          id="Groupe_11271"
          data-name="Groupe 11271"
          transform="translate(152.042 544.109)"
        >
          <path
            id="Tracé_11954"
            data-name="Tracé 11954"
            d="M944.324,508.549l-1.766,3.785a.669.669,0,0,0,.889.889l3.785-1.766c.15-.07-.3.354,5.3-5.179l-3-3c-5.556,5.555-5.14,5.115-5.211,5.268Zm0,0"
            transform="translate(-942.495 -500.871)"
            fill={color}
          />
          <path
            id="Tracé_11955"
            data-name="Tracé 11955"
            d="M1079.749,463.152a1.34,1.34,0,0,0-1.791-.092c-.076.062-.017.007-1.174,1.164l3,3,1.079-1.066a1.339,1.339,0,0,0,0-1.892Zm0,0"
            transform="translate(-1068.799 -462.761)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

EditIconProfile.propTypes = {
  color: PropTypes.string,
  classname: PropTypes.string,
  isWithRect: PropTypes.bool,
};
export default EditIconProfile;
