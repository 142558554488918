import { createStructuredSelector } from 'reselect';
import makeSelectProjectPrivileges from 'services/slices/selectors';
import { useSelector } from 'react-redux';
import { useGetConnectedUserQuery } from 'services/apis/user';

const stateSelector = createStructuredSelector({
  featurePrivileges: makeSelectProjectPrivileges,
});

const usePrivilegeByFeature = (feature: string, privilege: string): boolean => {
  const { data: connectedUser } = useGetConnectedUserQuery();

  const { featurePrivileges } = useSelector(stateSelector);
  if (connectedUser?.admin === true) {
    return true;
  }
  const privilegesForFeature = featurePrivileges?.[feature];
  if (Array.isArray(privilegesForFeature)) {
    return privilegesForFeature.includes(privilege);
  }

  return false;
};

export default usePrivilegeByFeature;
