import React from 'react';
import AppTheme from 'ui/theme/app-theme';
import { ThemeProvider } from '@mui/material';
import CustomMainButton from './Wrapper';

function MainButton({ ...rest }) {
  return (
    <ThemeProvider theme={AppTheme}>
      <CustomMainButton {...rest} />
    </ThemeProvider>
  );
}

MainButton.propTypes = {};
export default MainButton;
