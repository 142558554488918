import React from "react";

const CashFlowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 26.603 26.589"
  >
    <g
      id="Groupe_65036"
      data-name="Groupe 65036"
      transform="translate(18625.1 -4941.9)"
    >
      <path
        id="Tracé_51793"
        data-name="Tracé 51793"
        d="M789.082,356.889l.73-2.722a12.8,12.8,0,0,0-14.43,18.816.1.1,0,0,1-.016.124l-1.154,1.157a.235.235,0,0,0,.106.393l5.794,1.543a.353.353,0,0,0,.432-.433l-1.56-5.79a.235.235,0,0,0-.393-.1l-1,1a.1.1,0,0,1-.166-.027,9.981,9.981,0,0,1,11.653-13.955Zm0,0"
        transform="translate(-19398.252 4588.66)"
        fill="#016be3"
      />
      <path
        id="Tracé_51794"
        data-name="Tracé 51794"
        d="M911.94,418.762l-.729,2.722a12.8,12.8,0,0,0,14.43-18.816.1.1,0,0,1,.016-.124l1.154-1.157a.235.235,0,0,0-.106-.393l-5.794-1.543a.353.353,0,0,0-.432.433l1.56,5.79a.235.235,0,0,0,.393.1l1-1a.1.1,0,0,1,.166.027,9.981,9.981,0,0,1-11.653,13.955Zm0,0"
        transform="translate(-19526.369 4546.079)"
        fill="#016be3"
      />
      <path
        id="Tracé_51791"
        data-name="Tracé 51791"
        d="M9.817,26.118a.474.474,0,0,1-.33-.576l.73-2.722a.469.469,0,0,1,.58-.332,9.5,9.5,0,0,0,9.608-2.808,9.4,9.4,0,0,0,2.376-7.369.471.471,0,0,1,.937-.1,10.314,10.314,0,0,1-2.6,8.084,10.4,10.4,0,0,1-10.108,3.217l-.486,1.813a12.25,12.25,0,0,0,11.736-3.508,12.173,12.173,0,0,0,3.391-9.038,12.4,12.4,0,0,0-1.682-5.693.574.574,0,0,1,.089-.7l.865-.868-5.2-1.384,1.4,5.2.707-.709a.581.581,0,0,1,.5-.16.571.571,0,0,1,.421.317,10.487,10.487,0,0,1,.6,1.573.472.472,0,0,1-.32.585.463.463,0,0,1-.132.019.473.473,0,0,1-.452-.339,9.654,9.654,0,0,0-.334-.949l-.617.619a.713.713,0,0,1-.5.207.693.693,0,0,1-.181-.023.709.709,0,0,1-.5-.5l-1.56-5.79a.818.818,0,0,1,.14-.714.823.823,0,0,1,.868-.3l5.795,1.543A.706.706,0,0,1,25.876,5.9l-.955.958a13.378,13.378,0,0,1,1.669,5.88A13.262,13.262,0,0,1,13.315,26.589,13.13,13.13,0,0,1,9.817,26.118Zm-2.977-2.7L1.046,21.873a.706.706,0,0,1-.318-1.181l.955-.958A13.274,13.274,0,0,1,4.842,3.017a.471.471,0,0,1,.6.728,12.333,12.333,0,0,0-2.8,15.761.573.573,0,0,1-.089.7l-.865.867,5.2,1.385-1.4-5.2-.707.71a.585.585,0,0,1-.5.16.574.574,0,0,1-.421-.317,10.554,10.554,0,0,1-1.021-4.923A10.451,10.451,0,0,1,13.249,2.812a10.545,10.545,0,0,1,2.347.267l.486-1.813a12.219,12.219,0,0,0-2.8-.324,12.377,12.377,0,0,0-6.428,1.8.471.471,0,0,1-.244.069.471.471,0,0,1-.245-.874A13.315,13.315,0,0,1,13.286,0a13.129,13.129,0,0,1,3.5.474.473.473,0,0,1,.33.576l-.729,2.722a.473.473,0,0,1-.581.332,9.565,9.565,0,0,0-2.552-.35A9.461,9.461,0,0,0,3.774,12.92a9.664,9.664,0,0,0,.715,4l.617-.619a.712.712,0,0,1,.5-.208.69.69,0,0,1,.181.024.707.707,0,0,1,.5.5l1.56,5.79a.815.815,0,0,1-.14.714.828.828,0,0,1-.652.325A.839.839,0,0,1,6.84,23.417Z"
        transform="translate(-18625.1 4941.9)"
        fill="#00338d"
      />
      <path
        id="Tracé_51795"
        data-name="Tracé 51795"
        d="M1383.844,557.838l-.242-.086v-.246a.621.621,0,1,0-1.241,0v.246l-.242.086a2.153,2.153,0,0,0,.054,4.116l1.214.4a.988.988,0,0,1,.7,1.119,1.03,1.03,0,0,1-1.044.841h-.117a1.037,1.037,0,0,1-1.061-1.008.621.621,0,0,0-1.241,0,2.207,2.207,0,0,0,1.5,2.049l.243.086v.246a.62.62,0,1,0,1.241,0v-.246l.242-.086a2.153,2.153,0,0,0-.053-4.116l-1.214-.4a.988.988,0,0,1-.7-1.12,1.029,1.029,0,0,1,1.044-.841h.117a1.037,1.037,0,0,1,1.061,1.008.621.621,0,0,0,1.241,0A2.206,2.206,0,0,0,1383.844,557.838Z"
        transform="translate(-19994.742 4393.599)"
        fill="#00338d"
      />
    </g>
  </svg>
);
export default CashFlowIcon;
