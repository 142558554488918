import { createInstance } from '@datapunt/matomo-tracker-react';

const matomoEnvConfig = window.matomoConfig || {
  MATOMO_HOST: 'https://analytics.jesagroup.com/',
  MATOMO_SITE_ID: 29,
};

const matomo = createInstance({
  urlBase: matomoEnvConfig.MATOMO_HOST,
  siteId: matomoEnvConfig.MATOMO_SITE_ID,
  linkTracking: false, // optional, default value: true
});
export default matomo;
