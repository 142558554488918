export const SPECIFIC_ROLES_WITH_PROJECT_ADMIN_DATA = {
  BUSINESS_UNIT_MANAGER: 'BU',
  DISCIPLINE_HEAD: 'DISCIPLINE',
  SECTOR_DIRECTOR: 'SECTOR',
  PROGRAM_DIRECTOR: 'PROGRAM',
  HSE_SECTOR_MANAGER: 'SECTOR',
  PROGRAM_CONTROL_MANAGER: 'PROGRAM',
  PORTFOLIO_MANAGER: 'PORTFOLIO',
};

export const PLATFORM_SETTINGS_TYPES = {
  BUSINESS_UNIT_MANAGER: { baseRoute: 'bus', columnName: 'bu', labelColumn: 'buLabel' },
  DISCIPLINE_HEAD: {
    baseRoute: 'disciplines',
    columnName: 'discipline',
    labelColumn: 'disciplineLabel',
  },
  SECTOR_DIRECTOR: {
    baseRoute: 'sectors',
    columnName: 'sector',
    labelColumn: 'sectorLabel',
  },
  PROGRAM_DIRECTOR: {
    baseRoute: 'programs',
    columnName: 'program',
    labelColumn: 'programLabel',
  },
  HSE_SECTOR_MANAGER: {
    baseRoute: 'sectors',
    columnName: 'sector',
    labelColumn: 'sectorLabel',
  },
  PROGRAM_CONTROL_MANAGER: {
    baseRoute: 'programs',
    columnName: 'program',
    labelColumn: 'programLabel',
  },
  PORTFOLIO_MANAGER: {
    baseRoute: 'portfolios',
    columnName: 'portfolio',
    labelColumn: 'portfolioLabel',
  },
};
