import React, { useEffect, useRef } from 'react';
import { DateRange } from 'react-date-range';
import { FormControl, InputLabel, Stack, Typography } from '@mui/material';
import FormInput from 'components/FormInput';
import InputAdornment from '@mui/material/InputAdornment';
import DateRangeIcon from '@mui/icons-material/DateRange';
import theme from 'ui/theme';
import PropTypes from 'prop-types';
import { formatDateFromString } from 'utils/dateUtils';
import { AppCalendarsDateRangePickerWrapper } from './Wrapper';

function AppCalendarsDateRangePicker({ label, onChange, values }) {
  const key = 'selectedDates';

  const ref = useRef();

  const handleDateRange = (e) => {
    e.stopPropagation();
    ref.current?.classList.toggle('hide');
  };
  const handleHideDateRange = (e) => {
    const dateInput = document.getElementsByClassName(label)[0];
    if (
      ref.current &&
      !ref.current?.contains(e.target) &&
      !dateInput?.contains(e.target)
    ) {
      ref.current.classList.add('hide');
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleHideDateRange);
    return () => {
      document.removeEventListener('mousedown', handleHideDateRange);
    };
  }, []);

  const toDisplayValue = () => {
    if (values?.[0] && values?.[1]) {
      return `${formatDateFromString(values?.[0])} ➝ ${formatDateFromString(
        values?.[1],
      )}`;
    }
    return '';
  };

  return (
    <AppCalendarsDateRangePickerWrapper>
      <FormControl className="inputs__label" variant="standard">
        <InputLabel shrink htmlFor={label}>
          <Typography color="text.secondary" variant="h5_HelveticaNeue_Regular">
            {label}
          </Typography>
        </InputLabel>

        <FormInput
          isTextArea
          className={`dateInput ${label}`}
          label={label}
          inputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <DateRangeIcon />
              </InputAdornment>
            ),
          }}
          handleClick={handleDateRange}
          value={toDisplayValue()}
        />

        <Stack className="dateRange hide" ref={ref}>
          <DateRange
            editableDateInputs
            onChange={onChange}
            moveRangeOnFirstSelection={false}
            ranges={[
              {
                startDate: values?.[0] || new Date(),
                endDate: values?.[1] || new Date(),
                key,
              },
            ]}
            rangeColors={[theme.palette.primary.main]}
          />
        </Stack>
      </FormControl>
    </AppCalendarsDateRangePickerWrapper>
  );
}

AppCalendarsDateRangePicker.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
};
export default AppCalendarsDateRangePicker;
