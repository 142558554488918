export const isNotNullUndef = (number) =>
  number !== null && number !== undefined;

export const isArrayNotNull = (array = []) =>
  !array.some(
    (element) =>
      element === null || element === undefined || Number.isNaN(element),
  );

export const isObjectFilled = (object = {}) => Object.keys(object)?.length > 0;
