import {api} from '..';
import {type Platform, type PlatformEnum} from '../platform';
import {type FeaturePrivilege, type ModuleFeatures} from './module-feature';

const profileBaseUrl = '/profiles';

interface ProfilePage {
  name: string;
  platform: string;
  description: string;
  forExternalUsers: boolean;
  forClient: boolean;
  forContractor: boolean;
}

interface ProfileNameCheck {
  profileId: number;
  profileName: string;
  platform: PlatformEnum;
}

interface SearchCriteria {
  page: number;
  size: number;
  name?: string;
  platform?: string;
  isForExternalUsers?: boolean;
  isForClient?: boolean;
  isForContractor?: boolean;
}

export interface Profile {
  id: number;
  name: string;
  description: string;
  isCustomized: boolean;
  forExternalUsers: boolean;
  forClient: boolean;
  forContractor: boolean;
  code: boolean;
  platform: Platform;
  profilePrivilegeList: FeaturePrivilege[];
}

export interface ProfilePrivilegeDetail {
  id: number;
  name: string;
  description: string;
  isCustomized: boolean;
  forExternalUsers: boolean;
  forClient: boolean;
  forContractor: boolean;
  code: boolean;
  platform: Platform;
  profilePrivileges: ModuleFeatures[];
}

enum RoleCategoriesEnum {
  JESA = 'JESA',
  EXTERNAL_CLIENT = 'EXTERNAL_CLIENT',
  EXTERNAL_CONTRACTOR = 'EXTERNAL_CONTRACTOR',
}

export const profileApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createUpdateProfile: builder.mutation<number, Profile>({
      query: (body) => ({
        url: `${profileBaseUrl}/create-update`,
        method: 'POST',
        body,
      }),
    }),
    getProfileById: builder.query<ProfilePrivilegeDetail, number>({
      query: (idProfile) => `${profileBaseUrl}/${idProfile}`,
    }),
    deleteProfileById: builder.mutation<Promise<void>, number>({
      query: (profileId) => ({
        url: `${profileBaseUrl}/${profileId}`,
        method: 'DELETE',
      }),
    }),
    searchProfilesByCriteria: builder.query<ProfilePage[], SearchCriteria>({
      query: (params) => ({
        url: `${profileBaseUrl}/search`,
        params,
      }),
    }),
    checkProfileName: builder.query<boolean, ProfileNameCheck>({
      query: (params) => ({
        url: `${profileBaseUrl}/check-profile-unicity`,
        params,
      }),
    }),
    getProfilesByPlatformAndProject: builder.query<
      Profile,
      { categories: RoleCategoriesEnum[]; projectId: number; platform: string }
    >({
      query: (params) => ({
        url: `${profileBaseUrl}/platform-profiles`,
        params,
      }),
    }),
    getProfilesByPlatformAndIsExternal: builder.query<any, any>({
      query: (params) => ({
        url: `${profileBaseUrl}/by-platform-and-user-type`,
        params,
      }),
    }),
    getProfilesByUserType: builder.query<any, boolean>({
      query: (params) => ({
        url: `${profileBaseUrl}/by-user-type`,
        params,
      }),
    }),
  }),
});

export const {
  useLazySearchProfilesByCriteriaQuery,
  useLazyGetProfilesByPlatformAndProjectQuery,
  useCreateUpdateProfileMutation,
  useGetProfileByIdQuery,
  useGetProfilesByUserTypeQuery,
  useDeleteProfileByIdMutation,
  useLazyCheckProfileNameQuery,
  useLazyGetProfilesByPlatformAndIsExternalQuery,
} = profileApi;
