import React from 'react';

const BugSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.736"
    height="17.382"
    viewBox="0 0 17.736 17.382"
  >
    <g id="Group_3612" data-name="Group 3612" transform="translate(-907 -489)">
      <path
        id="Path_11856"
        data-name="Path 11856"
        d="M924.525,512.28a.681.681,0,0,0-.5-.211h-2.482V508.81l1.917-1.918a.705.705,0,1,0-1-1l-1.918,1.918H911.19l-1.917-1.918a.706.706,0,1,0-1,1l1.917,1.918v3.259H907.71a.71.71,0,0,0,0,1.42h2.483a6.63,6.63,0,0,0,.643,3.037l-2.239,2.517a.7.7,0,0,0-.172.515.713.713,0,0,0,.227.493.722.722,0,0,0,.477.177.71.71,0,0,0,.532-.233l2.028-2.295.166.155a3.9,3.9,0,0,0,.482.36q.328.216.732.432a4.532,4.532,0,0,0,.964.366,4.323,4.323,0,0,0,1.125.15v-9.932h1.419v9.932a4.17,4.17,0,0,0,1.075-.144,5.113,5.113,0,0,0,.909-.322,7.023,7.023,0,0,0,.721-.4c.236-.151.392-.256.466-.316a2.19,2.19,0,0,0,.166-.143l2.195,2.184a.7.7,0,0,0,1,0,.7.7,0,0,0,0-1L920.8,516.7a6.459,6.459,0,0,0,.743-3.215h2.483a.705.705,0,0,0,.5-1.208Zm0,0"
        transform="translate(0 -13.847)"
        fill="#5f6a7e"
      />
      <path
        id="Path_11857"
        data-name="Path 11857"
        d="M944.346,490.036a3.547,3.547,0,0,0-6.057,2.511h7.094a3.42,3.42,0,0,0-1.036-2.511Zm0,0"
        transform="translate(-25.968)"
        fill="#5f6a7e"
      />
    </g>
  </svg>
);

export default BugSvg;
