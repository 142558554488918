import React from 'react';

const UserManagement = (props) => (
  <svg
    data-name="Group 64001"
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    {...props}
  >
    <path data-name="Path 67263" d="M0 0h20v20H0Z" fill="none" />
    <path
      data-name="Ellipse 3141"
      d="M7.5 1.875a3.958 3.958 0 1 1-3.958 3.958A3.963 3.963 0 0 1 7.5 1.875Zm0 6.667a2.708 2.708 0 1 0-2.708-2.709A2.711 2.711 0 0 0 7.5 8.542Z"
      fill="currentColor"
    />
    <path
      data-name="Path 67264"
      d="M12.5 18.125a.625.625 0 0 1-.625-.625v-1.667a2.711 2.711 0 0 0-2.708-2.708H5.833a2.711 2.711 0 0 0-2.708 2.708V17.5a.625.625 0 1 1-1.25 0v-1.667a3.963 3.963 0 0 1 3.958-3.958h3.334a3.963 3.963 0 0 1 3.958 3.958V17.5a.625.625 0 0 1-.625.625Z"
      fill="currentColor"
    />
    <path
      data-name="Path 67265"
      d="M13.333 9.691a.625.625 0 0 1-.155-1.231 2.708 2.708 0 0 0 0-5.247.625.625 0 1 1 .31-1.21 3.958 3.958 0 0 1 0 7.669.626.626 0 0 1-.155.019Z"
      fill="currentColor"
    />
    <path
      data-name="Path 67266"
      d="M17.5 18.125a.625.625 0 0 1-.625-.625v-1.667a2.71 2.71 0 0 0-2.031-2.6.625.625 0 1 1 .312-1.21 3.962 3.962 0 0 1 2.969 3.807v1.67a.625.625 0 0 1-.625.625Z"
      fill="currentColor"
    />
  </svg>
);

export default UserManagement;
