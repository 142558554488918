import React from 'react';

const CspDashboardIcon: React.FC = function () {
  return (
    <svg
      id="Group_74749"
      data-name="Group 74749"
      xmlns="http://www.w3.org/2000/svg"
      width="25.535"
      height="24.942"
      viewBox="0 0 25.535 24.942"
    >
      <path
        id="Rectangle_20349"
        data-name="Rectangle 20349"
        d="M3.312,1.325A1.99,1.99,0,0,0,1.325,3.312V10.94a1.99,1.99,0,0,0,1.987,1.987H9.159a1.99,1.99,0,0,0,1.987-1.987V3.312A1.99,1.99,0,0,0,9.159,1.325H3.312M3.312,0H9.159a3.312,3.312,0,0,1,3.312,3.312V10.94a3.312,3.312,0,0,1-3.312,3.312H3.312A3.312,3.312,0,0,1,0,10.94V3.312A3.312,3.312,0,0,1,3.312,0Z"
        transform="translate(13.065 10.689)"
        fill="#00338d"
      />
      <path
        id="Rectangle_20350"
        data-name="Rectangle 20350"
        d="M3.312,1.325A1.99,1.99,0,0,0,1.325,3.312V6.783A1.99,1.99,0,0,0,3.312,8.771H9.159a1.99,1.99,0,0,0,1.987-1.987V3.312A1.99,1.99,0,0,0,9.159,1.325H3.312M3.312,0H9.159a3.312,3.312,0,0,1,3.312,3.312V6.783A3.312,3.312,0,0,1,9.159,10.1H3.312A3.312,3.312,0,0,1,0,6.783V3.312A3.312,3.312,0,0,1,3.312,0Z"
        transform="translate(13.065 0)"
        fill="#00338d"
      />
      <g
        id="Rectangle_20349-2"
        data-name="Rectangle 20349"
        transform="translate(0 0)"
        fill="#026be3"
      >
        <path
          d="M 8.564997673034668 13.55251312255859 L 3.312097549438477 13.55251312255859 C 1.871777653694153 13.55251312255859 0.69999760389328 12.38073348999023 0.69999760389328 10.94041347503662 L 0.69999760389328 3.312103033065796 C 0.69999760389328 1.871783137321472 1.871777653694153 0.6999931335449219 3.312097549438477 0.6999931335449219 L 8.564997673034668 0.6999931335449219 C 10.00531768798828 0.6999931335449219 11.17709732055664 1.871783137321472 11.17709732055664 3.312103033065796 L 11.17709732055664 10.94041347503662 C 11.17709732055664 12.38073348999023 10.00531768798828 13.55251312255859 8.564997673034668 13.55251312255859 Z"
          stroke="none"
        />
        <path
          d="M 3.312097549438477 1.399992942810059 C 2.257757186889648 1.399992942810059 1.399997711181641 2.257762908935547 1.399997711181641 3.312103271484375 L 1.399997711181641 10.94041347503662 C 1.399997711181641 11.99475288391113 2.257757186889648 12.85251331329346 3.312097549438477 12.85251331329346 L 8.564997673034668 12.85251331329346 C 9.61933708190918 12.85251331329346 10.4770975112915 11.99475288391113 10.4770975112915 10.94041347503662 L 10.4770975112915 3.312103271484375 C 10.4770975112915 2.257762908935547 9.61933708190918 1.399992942810059 8.564997673034668 1.399992942810059 L 3.312097549438477 1.399992942810059 M 3.312097549438477 -6.67572021484375e-06 L 8.564997673034668 -6.67572021484375e-06 C 10.3942174911499 -6.67572021484375e-06 11.87709808349609 1.48287296295166 11.87709808349609 3.312103271484375 L 11.87709808349609 10.94041347503662 C 11.87709808349609 12.76963329315186 10.3942174911499 14.25251293182373 8.564997673034668 14.25251293182373 L 3.312097549438477 14.25251293182373 C 1.482877731323242 14.25251293182373 -2.86102294921875e-06 12.76963329315186 -2.86102294921875e-06 10.94041347503662 L -2.86102294921875e-06 3.312103271484375 C -2.86102294921875e-06 1.48287296295166 1.482877731323242 -6.67572021484375e-06 3.312097549438477 -6.67572021484375e-06 Z"
          stroke="none"
          fill="#00338d"
        />
      </g>
      <path
        id="Rectangle_20350-2"
        data-name="Rectangle 20350"
        d="M3.312,1.325A1.99,1.99,0,0,0,1.325,3.312V6.19A1.99,1.99,0,0,0,3.312,8.177H8.565A1.99,1.99,0,0,0,10.552,6.19V3.312A1.99,1.99,0,0,0,8.565,1.325H3.312M3.312,0H8.565a3.312,3.312,0,0,1,3.312,3.312V6.19A3.312,3.312,0,0,1,8.565,9.5H3.312A3.312,3.312,0,0,1,0,6.19V3.312A3.312,3.312,0,0,1,3.312,0Z"
        transform="translate(0 15.44)"
        fill="#00338d"
      />
    </svg>
  );
};
export default CspDashboardIcon;
