import { Stack, styled } from '@mui/material';

const ProjectEntitiesWrapper = styled(Stack)(({ theme }) => ({
  '& .AddContractorForm': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '30px',
    marginBottom: '30px',
    '& .inputs': {
      width: '40%',
    },
  },
  '& .formInputs': {
    width: '60%',
  },
  '& .ContractorsListing': {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},

  [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {},
}));
export default ProjectEntitiesWrapper;
