import { Grid, styled } from '@mui/material';

const UserManagementItemsWrapper = styled(Grid)(({ theme }) => ({
  width: '100%',
  marginLeft: 0,

  '& .fullWidth': {
    height: 'calc(100vh - 361px)',
    overflow: 'hidden scroll',
    gap: '10px',
    paddingRight: '40px',
    '& > div': {
      marginTop: '0 !important',
    },
  },
  '& .listing_item_row': {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '15px 20px',
    borderRadius: '8px',

    '&.user__item': {
      boxShadow: '0px 1px 5px #0000000F',
      backgroundColor: theme.palette.light.main,
      position: 'relative',
      '& .button-options': {
        top: '50%',
        right: '15px',
        position: 'absolute',
        transform: 'translateY(-50%)',
        padding: '7px',
        borderRadius: '14px',
        '&:hover': {
          backgroundColor: theme.palette.button_background.main,
        },
      },
      '&:not(:nth-child(1))': {
        marginTop: '10px',
      },
    },
    '& .MuiAvatar-root': {
      width: '35px',
      height: '35px',
    },
    '& div': {
      '&:not(.MuiAvatar-root)': {
        flexGrow: 1,
        flexBasis: 0,
      },
    },
  },

  [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},

  [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {},
}));
export default UserManagementItemsWrapper;
