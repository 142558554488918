import { type ComponentType, type FC, useEffect } from 'react';
import { type Location, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { setAuthInfo } from 'services/slices/auth';
import { useDispatch } from 'react-redux';
import { useGetConnectedUserQuery } from 'services/apis/user';
import {
  PROJECT_SETTINGS,
  SETTINGS_IN_PROCESS_PROJECTS,
  SETTINGS_PROJECTS,
} from 'routes/constants';
import { useCustomAuth } from './AuthContext';

const defaultOnRedirecting = (): JSX.Element => <div>Loading...</div>;

export interface WithAuthenticationRequiredOptions {
  onRedirecting?: () => JSX.Element;
}

const defaultReturnTo = (location: Location): string =>
  `${location.pathname}${location.search}`;

export const ProtectedComponent: FC<{ element: ComponentType }> = function ({ element }) {
  const ProtectedElement = withAuthenticationRequired(element);
  const { data: connectedUser } = useGetConnectedUserQuery();
  const navigate = useNavigate();
  const matchProjectSetting = useMatch({ path: PROJECT_SETTINGS, end: false });
  const matchProjectListing = useMatch({ path: SETTINGS_PROJECTS, end: false });
  const matchDraftProjectListing = useMatch({
    path: SETTINGS_IN_PROCESS_PROJECTS,
    end: false,
  });
  useEffect(() => {
    if (
      connectedUser?.admin === false &&
      matchProjectListing == null &&
      matchProjectSetting == null &&
      matchDraftProjectListing == null
    ) {
      navigate('/');
    }
  }, [connectedUser, navigate]);

  return <ProtectedElement />;
};

const withAuthenticationRequired = <P extends object>(
  Component: ComponentType<P>,
  options: WithAuthenticationRequiredOptions = {},
): FC<P> => {
  return function WithAuthenticationRequired(props: P): JSX.Element {
    const { onRedirecting = defaultOnRedirecting } = options;
    const { isAuthenticated, isLoading, loginWithRedirect, user } = useCustomAuth();
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
      if (isAuthenticated) {
        dispatch(setAuthInfo({ isAuthenticated, user }));
        return;
      }
      if (isLoading) return;
      const opts = {
        redirectUri: defaultReturnTo(location),
      };
      void (async (): Promise<void> => {
        await loginWithRedirect(opts);
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, isAuthenticated, loginWithRedirect, user]);

    return isAuthenticated ? <Component {...props} /> : onRedirecting();
  };
};

export default withAuthenticationRequired;
