import React from 'react';

const SettingsBtnSvg = () => (
  <svg
    id="Component_12_1"
    data-name="Component 12 – 1"
    xmlns="http://www.w3.org/2000/svg"
    width="51.729"
    height="51.729"
    viewBox="0 0 51.729 51.729"
  >
    <path
      id="Path_77176"
      data-name="Path 77176"
      d="M36.443,0c9.13,0,15.286,5.874,15.286,14.639h0V37.064c0,8.768-6.156,14.665-15.286,14.665H15.312C6.156,51.729,0,45.832,0,37.064H0V14.639C0,5.874,6.156,0,15.312,0H36.443Z"
      transform="translate(0 0)"
      fill="#fff"
    />
    <g id="Setting" transform="translate(13.264 12.326)">
      <path
        id="Combined-Shape"
        d="M13.417,1A3.475,3.475,0,0,1,16.9,4.58a1.4,1.4,0,0,0,.2.666,1.506,1.506,0,0,0,2.069.542,3.49,3.49,0,0,1,4.757,1.277l.814,1.41a1.111,1.111,0,0,1,.055.112,3.493,3.493,0,0,1-1.32,4.632,1.492,1.492,0,0,0-.554.55,1.534,1.534,0,0,0-.157,1.154,1.492,1.492,0,0,0,.706.919A3.468,3.468,0,0,1,24.731,20.6l-.868,1.445a3.482,3.482,0,0,1-4.74,1.253,1.556,1.556,0,0,0-.729-.2h-.008a1.512,1.512,0,0,0-1.048.442,1.494,1.494,0,0,0-.44,1.073,3.489,3.489,0,0,1-3.481,3.472H11.779A3.484,3.484,0,0,1,8.3,24.6a1.407,1.407,0,0,0-.2-.7,1.5,1.5,0,0,0-2.059-.563A3.48,3.48,0,0,1,1.281,22L.464,20.593a3.473,3.473,0,0,1,1.268-4.737,1.52,1.52,0,0,0,0-2.633A3.487,3.487,0,0,1,.463,8.475l.886-1.454A3.478,3.478,0,0,1,6.082,5.758a1.43,1.43,0,0,0,.721.205,1.524,1.524,0,0,0,1.508-1.5A3.464,3.464,0,0,1,11.779,1Zm0,1.96H11.779a1.5,1.5,0,0,0-1.508,1.515,3.5,3.5,0,0,1-3.48,3.449,3.372,3.372,0,0,1-1.7-.473,1.522,1.522,0,0,0-2.059.57L2.15,9.475a1.517,1.517,0,0,0,.565,2.052,3.48,3.48,0,0,1,0,6.027,1.51,1.51,0,0,0-.55,2.058l.825,1.422a1.524,1.524,0,0,0,2.083.606,3.465,3.465,0,0,1,2.62-.346,3.487,3.487,0,0,1,2.105,1.622,3.349,3.349,0,0,1,.464,1.673,1.524,1.524,0,0,0,1.521,1.53h1.637a1.522,1.522,0,0,0,1.521-1.512,3.467,3.467,0,0,1,3.485-3.475,3.559,3.559,0,0,1,1.675.463,1.53,1.53,0,0,0,2.078-.55l.868-1.447a1.51,1.51,0,0,0-.557-2.054,3.446,3.446,0,0,1-1.623-2.114,3.49,3.49,0,0,1,.354-2.638,3.449,3.449,0,0,1,1.269-1.266,1.524,1.524,0,0,0,.545-2.062.729.729,0,0,1-.046-.09L22.22,8.044a1.522,1.522,0,0,0-2.073-.561,3.467,3.467,0,0,1-4.741-1.241,3.338,3.338,0,0,1-.469-1.68,1.559,1.559,0,0,0-.43-1.142A1.526,1.526,0,0,0,13.417,2.961ZM12.6,10.114A4.425,4.425,0,1,1,8.179,14.54,4.431,4.431,0,0,1,12.6,10.114Zm0,1.96a2.465,2.465,0,1,0,2.465,2.466A2.468,2.468,0,0,0,12.6,12.074Z"
        transform="translate(0 -1)"
        fill="#00338d"
        fill-rule="evenodd"
      />
    </g>
  </svg>
);

export default SettingsBtnSvg;
