import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import theme from 'ui/theme';
import { CloseIcon } from 'assets/svgs/componentsIcons';
import ProjectItemWrapper from './Wrapper';

function ProjectAreaItem({ projectData, onDelete, onClickAction, disabled }) {
  return (
    <ProjectItemWrapper onClick={onClickAction} disabled={disabled}>
      <Stack className="inner flex__column--center">
        <Stack className="content">
          <Typography
            variant="subtitle1_HelveticaNeue_Bold"
            color={theme.palette.primary.main}
          >
            {projectData?.name}
          </Typography>
          <Typography
            className="opc-4"
            variant="subtitle1_HelveticaNeue_Regular"
            color="text.gray"
          >
            {projectData?.number}
          </Typography>
        </Stack>
        {!disabled && (
          <Stack
            className="closeIcon pointer"
            onClick={(e) => {
              e.stopPropagation();
              onDelete(projectData);
            }}
          >
            <CloseIcon />
          </Stack>
        )}
      </Stack>
    </ProjectItemWrapper>
  );
}

export default ProjectAreaItem;

ProjectAreaItem.propTypes = {
  projectData: PropTypes.shape({ name: PropTypes.string, number: PropTypes.number }),
  onDelete: PropTypes.func,
  onClickAction: PropTypes.func,
  disabled: PropTypes.bool,
};
