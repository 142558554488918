import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { format } from 'date-fns';
import { FormHelperText, Stack, Typography } from '@mui/material';
import { ArrowLeftIcon } from 'assets/svgs/componentsIcons';
import HideIcon from 'assets/svgs/componentsIcons/HideIcon';
import ShowIcon from 'assets/svgs/componentsIcons/ShowIcon';
import EditIconProfile from 'assets/svgs/componentsIcons/EditIconProfile';
import DeleteWL from 'assets/svgs/componentsIcons/DeleteWL';
import { BackButton } from 'components/Buttons';
import ConfirmationPopup from 'components/ConfirmationPopin';
import SettingsFooterPage from 'pages/SettingsFooterPage';
import {
  ACCESS_MANAGEMENT_ADD_PROFILE,
  ACCESS_MANAGEMENT_LISTING,
} from 'routes/constants';
import { useCreateUpdateProfileMutation } from 'services/apis/access-control/profile';

import { useGetModuleFeaturesByPlatformQuery } from 'services/apis/access-control/module-feature';
import {
  resetAccessManagement,
  setProfileData,
  showCancelPopup,
  showSuccessPopup,
} from 'services/slices/access-management';
import {
  makeSelectCancelPopup,
  makeSelectProfileData,
  makeSelectSuccessPopup,
} from 'services/slices/access-management/selectors';
import theme from 'ui/theme';
import AppTheme from 'ui/theme/app-theme';
import { POPUP_TYPE } from 'components/ConfirmationPopin/constants';
import FullPageLoader from 'components/FullPageLoader';
import ProfileErrorDeleteEditModal from 'containers/Profiles/ProfileErrorDeleteEditModal';
import ValidateIcon from 'assets/svgs/componentsIcons/ValidateIcon';
import ICONES_MODULE from './constants';
import { ProfileMenuWrapper, ProfileWrapper } from './Wrapper';
import {
  changePrivilege,
  doPrivilegesExist,
  isPrivilegeExist,
  PrivilegesEnum,
} from '../helper';

const stateSelector = createStructuredSelector({
  profileData: makeSelectProfileData,
  cancelPopup: makeSelectCancelPopup,
  successPopup: makeSelectSuccessPopup,
});

function AccessManagementProfilePrivilege() {
  const [moduleFeaturesData, setModuleFeaturesData] = useState([]);
  const featureRefs = useRef({});
  const { profileId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showErrorPopup, setShowErrorPopup] = useState(false);

  const { profileData, cancelPopup, successPopup } = useSelector(stateSelector);
  const privilegesExceptHide = Object.values(PrivilegesEnum).filter(
    (privilege) => privilege !== PrivilegesEnum.HIDE,
  );

  const { data: moduleFeatures } = useGetModuleFeaturesByPlatformQuery(
    profileData?.platform?.code,
    {
      skip:
        profileId !== undefined &&
        profileData?.platform?.code ===
          profileData?.profilePrivileges[0]?.module?.platformEnum,
    },
  );
  const [
    doCreateUpdateProfile,
    { isSuccess: successCreation, error: creationError, isLoading: saveLoading },
  ] = useCreateUpdateProfileMutation();

  useEffect(() => {
    if (moduleFeatures || profileData?.profilePrivileges !== undefined) {
      setModuleFeaturesData(profileData?.profilePrivileges || moduleFeatures);
    }
  }, [moduleFeatures, profileData?.profilePrivileges]);

  useEffect(() => {
    if (successCreation) {
      dispatch(showSuccessPopup(true));
    }
  }, [dispatch, successCreation]);

  const createUpdateProfile = () => {
    const profile = {
      ...profileData,
      profilePrivilegeList: moduleFeaturesData?.flatMap(
        (moduleFeature) => moduleFeature.features,
      ),
    };
    doCreateUpdateProfile(profile);
  };
  const navigateToBackBtn = () => {
    dispatch(
      setProfileData({
        profilePrivilegeList: moduleFeaturesData?.flatMap(
          (moduleFeature) => moduleFeature.features,
        ),
      }),
    );
    if (profileId) {
      navigate(`${ACCESS_MANAGEMENT_ADD_PROFILE}/${profileId}`);
    } else {
      navigate(ACCESS_MANAGEMENT_ADD_PROFILE);
    }
  };
  const cleanUpAndNavigateToListing = () => {
    dispatch(resetAccessManagement());
    navigate(ACCESS_MANAGEMENT_LISTING);
  };
  // eslint-disable-next-line
    const handlePrivilege = (moduleFeaturesData, moduleIndex, featureIndex, featurePrivilege, privilegeToDelete, privilegeToAdd) => {
    const newPrivilege = changePrivilege(
      {
        moduleFeaturesData,
        moduleIndex,
        featureIndex,
        featurePrivilege,
      },
      privilegeToDelete,
      privilegeToAdd,
    );
    setModuleFeaturesData(newPrivilege);
    if (profileId !== undefined && newPrivilege.length !== 0) {
      dispatch(setProfileData({ isUpdated: true }));
    }
  };

  const handleReadValidatePrivileges = (moduleIndex, featureIndex, featurePrivilege) => {
    let newPrivilege = null;
    if (featurePrivilege?.privilegeDtoList?.length !== 0) {
      if (
        featurePrivilege.privilegeDtoList.includes(PrivilegesEnum.VALIDATE) &&
        !featurePrivilege.privilegeDtoList.includes(PrivilegesEnum.READ)
      ) {
        newPrivilege = changePrivilege(
          {
            moduleFeaturesData,
            moduleIndex,
            featureIndex,
            featurePrivilege,
          },
          null,
          PrivilegesEnum.READ,
        );
      } else if (
        featurePrivilege.privilegeDtoList.includes(PrivilegesEnum.READ) &&
        !featurePrivilege.privilegeDtoList.includes(PrivilegesEnum.VALIDATE)
      ) {
        newPrivilege = changePrivilege(
          {
            moduleFeaturesData,
            moduleIndex,
            featureIndex,
            featurePrivilege,
          },
          PrivilegesEnum.READ,
          PrivilegesEnum.HIDE,
        );
      } else if (
        [PrivilegesEnum.READ, PrivilegesEnum.VALIDATE].every((privilege) =>
          featurePrivilege.privilegeDtoList.includes(privilege),
        )
      ) {
        newPrivilege = changePrivilege(
          {
            moduleFeaturesData,
            moduleIndex,
            featureIndex,
            featurePrivilege,
          },
          PrivilegesEnum.READ,
          null,
        );
      } else if (
        !featurePrivilege.privilegeDtoList.includes(PrivilegesEnum.READ) &&
        !featurePrivilege.privilegeDtoList.includes(PrivilegesEnum.VALIDATE)
      ) {
        newPrivilege = changePrivilege(
          {
            moduleFeaturesData,
            moduleIndex,
            featureIndex,
            featurePrivilege,
          },
          null,
          PrivilegesEnum.READ,
        );
      }

      setModuleFeaturesData(newPrivilege);
    }
    if (profileId !== undefined && newPrivilege?.length !== 0) {
      dispatch(setProfileData({ isUpdated: true }));
    }
  };
  const scrollToFeature = (moduleLabel) => {
    const featureElement = featureRefs.current[moduleLabel];
    if (featureElement) {
      featureElement.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleProfileErrorModal = () => {
    setShowErrorPopup((prevState) => !prevState);
  };

  useEffect(() => {
    if (creationError?.status === 400) {
      handleProfileErrorModal();
    }
  }, [creationError]);
  return (
    <ProfileWrapper className="mb-40">
      {saveLoading && <FullPageLoader />}
      <Stack className="top-header">
        <Stack className="heading-area">
          <BackButton type="button" onClick={navigateToBackBtn}>
            <ArrowLeftIcon />
          </BackButton>
          <Stack className="page-title-area">
            <Typography variant="h3_HelveticaNeue_Bold" color="primary.main">
              Access Management
            </Typography>
          </Stack>
        </Stack>

        <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.main">
          Add new profiles and set the access management
        </Typography>
        {creationError && !creationError?.data?.extendedProperties && (
          <FormHelperText className="errorMsg">
            {creationError?.data?.detail}
          </FormHelperText>
        )}
      </Stack>
      <Stack className="ProfilSetting">
        <Stack className="leftSide">
          <Typography
            className="mb-25"
            variant="h4_Montserrat_Bold"
            color={theme.palette.secondary.main}
          >
            Profile
          </Typography>
          <Typography
            className="mb-15"
            variant="subtitle1_HelveticaNeue_Bold"
            color={theme.palette.text.main}
          >
            {profileData?.name}
          </Typography>
          <Typography
            className="mb-15"
            variant="subtitle2_HelveticaNeue_Regular"
            color={theme.palette.text.main}
            align="justify"
          >
            {profileData?.description}
          </Typography>
          <Stack className="mt-45">
            <Stack className="iconList">
              <Stack className="mr-15">
                <HideIcon />
              </Stack>
              <Typography
                variant="subtitle2_HelveticaNeue_Regular"
                color={theme.palette.text.main}
              >
                Hide Content
              </Typography>
            </Stack>
            <Stack className="iconList">
              <Stack className="mr-15">
                <ShowIcon />
              </Stack>
              <Typography
                variant="subtitle2_HelveticaNeue_Regular"
                color={theme.palette.text.main}
              >
                Show content
              </Typography>
            </Stack>
            <Stack className="iconList">
              <Stack className="mr-15">
                <EditIconProfile color="#00cec9" />
              </Stack>
              <Typography
                variant="subtitle2_HelveticaNeue_Regular"
                color={theme.palette.text.main}
              >
                Edit & Extract
              </Typography>
            </Stack>
            <Stack className="iconList">
              <Stack className="mr-15">
                <DeleteWL />
              </Stack>
              <Typography
                variant="subtitle2_HelveticaNeue_Regular"
                color={theme.palette.text.main}
              >
                Delete content
              </Typography>
            </Stack>
            <Stack className="iconList">
              <Stack className="mr-15 btnIcon">
                <ValidateIcon color="#007cff" />
              </Stack>
              <Typography
                variant="subtitle2_HelveticaNeue_Regular"
                color={theme.palette.text.main}
              >
                Validate content
              </Typography>
            </Stack>
          </Stack>
          <Stack className="mt-30">
            {profileData?.updateDate && (
              <Typography
                style={{ fontSize: '10px' }}
                variant="subtitle2_Roboto_Light"
                color={AppTheme.AppColors.DarkElectricBlue}
              >
                Latest update on{' '}
                {format(new Date(profileData?.updateDate), 'MMM. do, yyyy')}
              </Typography>
            )}
          </Stack>
        </Stack>

        <Stack className="centerSide">
          {moduleFeaturesData &&
            moduleFeaturesData?.map((moduleFeature) => (
              <ProfileMenuWrapper
                onClick={() => scrollToFeature(moduleFeature?.module?.label)}
                key={moduleFeature.id}
              >
                <Stack className="module_icon">
                  {
                    ICONES_MODULE[profileData?.platform?.code][
                      moduleFeature?.module?.code
                    ]?.icon
                  }
                </Stack>
                <Typography
                  className="ml-10"
                  color={theme.palette.primary.main}
                  variant="subtitle1_HelveticaNeue_Bold"
                >
                  {moduleFeature?.module?.label}
                </Typography>
              </ProfileMenuWrapper>
            ))}
        </Stack>
        <Stack className="rightSide">
          {moduleFeaturesData &&
            moduleFeaturesData?.map((moduleFeature, moduleIndex) => (
              <Stack className="mt-5" key={moduleFeature.id}>
                <Typography
                  variant="h6_HelveticaNeue_Bold"
                  ref={(mf) => {
                    featureRefs.current[moduleFeature?.module?.label] = mf;
                  }}
                >
                  {moduleFeature?.module?.label}
                </Typography>
                {moduleFeature?.features.map((featurePrivilege, featureIndex) => (
                  <Stack className="flex__row--center-space" key={featurePrivilege.id}>
                    <Typography
                      variant="subtitle2_HelveticaNeue_Regular"
                      color={theme.palette.text.main}
                    >
                      {featurePrivilege?.feature?.label}
                    </Typography>
                    <Stack className="pointer">
                      {doPrivilegesExist(featurePrivilege, privilegesExceptHide) ? (
                        <Stack className="iconRowList">
                          <Stack
                            className="pointer"
                            onClick={() =>
                              handleReadValidatePrivileges(
                                moduleIndex,
                                featureIndex,
                                featurePrivilege,
                              )
                            }
                          >
                            <ShowIcon
                              color={
                                isPrivilegeExist(featurePrivilege, PrivilegesEnum.READ)
                                  ? '#007cff'
                                  : theme.palette.text.gray
                              }
                            />
                          </Stack>
                          <Stack
                            className="pointer"
                            onClick={() =>
                              handlePrivilege(
                                moduleFeaturesData,
                                moduleIndex,
                                featureIndex,
                                featurePrivilege,
                                null,
                                PrivilegesEnum.WRITE,
                              )
                            }
                          >
                            <EditIconProfile
                              color={
                                isPrivilegeExist(featurePrivilege, PrivilegesEnum.WRITE)
                                  ? '#00cec9'
                                  : theme.palette.text.gray
                              }
                            />
                          </Stack>
                          <Stack
                            className="pointer"
                            onClick={() =>
                              handlePrivilege(
                                moduleFeaturesData,
                                moduleIndex,
                                featureIndex,
                                featurePrivilege,
                                null,
                                PrivilegesEnum.DELETE,
                              )
                            }
                          >
                            <DeleteWL
                              color={
                                isPrivilegeExist(featurePrivilege, PrivilegesEnum.DELETE)
                                  ? '#ff7675'
                                  : theme.palette.text.gray
                              }
                            />
                          </Stack>
                          <Stack
                            className="pointer"
                            onClick={() =>
                              handlePrivilege(
                                moduleFeaturesData,
                                moduleIndex,
                                featureIndex,
                                featurePrivilege,
                                null,
                                PrivilegesEnum.VALIDATE,
                              )
                            }
                          >
                            <Stack className="btnIcon">
                              <ValidateIcon
                                color={
                                  isPrivilegeExist(
                                    featurePrivilege,
                                    PrivilegesEnum.VALIDATE,
                                  )
                                    ? '#007cff'
                                    : theme.palette.text.gray
                                }
                              />
                            </Stack>
                          </Stack>
                        </Stack>
                      ) : (
                        <Stack
                          className="iconHideList pointer"
                          onClick={() =>
                            handlePrivilege(
                              moduleFeaturesData,
                              moduleIndex,
                              featureIndex,
                              featurePrivilege,
                              PrivilegesEnum.HIDE,
                              PrivilegesEnum.READ,
                            )
                          }
                        >
                          <HideIcon />
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            ))}
        </Stack>
      </Stack>
      {cancelPopup && (
        <ConfirmationPopup
          toOpen={cancelPopup}
          popupType={POPUP_TYPE.CONFIRMATION_CANCEL__POPUP}
          onConfirm={cleanUpAndNavigateToListing}
          onCancel={() => dispatch(showCancelPopup(false))}
          onClose={() => dispatch(showCancelPopup(false))}
        />
      )}
      {successPopup && (
        <ConfirmationPopup
          toOpen={successPopup}
          popupType={POPUP_TYPE.PROFILE_SUCCESS__POPUP}
          onClose={() => {
            dispatch(showSuccessPopup(false));
            cleanUpAndNavigateToListing();
          }}
        />
      )}
      <SettingsFooterPage
        handelCancelClick={() => dispatch(showCancelPopup(true))}
        handelSaveClick={createUpdateProfile}
        labelSave="Save"
      />
      {creationError?.status === 400 && creationError?.data?.extendedProperties && (
        <ProfileErrorDeleteEditModal
          isOpen={showErrorPopup}
          handleProfileModal={handleProfileErrorModal}
          linkedUsers={creationError?.data?.extendedProperties}
          message={creationError?.data?.detail}
        />
      )}
    </ProfileWrapper>
  );
}

export default AccessManagementProfilePrivilege;
