const GreyColors = {
  white: '#fff',
  100: '#fafcff',
  200: '#e9ecef',
  300: '#dee2e6',
  400: '#ced4da',
  500: '#adb5bd',
  600: '#6c757d',
  700: '#495057',
  800: '#343a40',
  900: '#212529',
  1000: '#E6E6E6',
  1100: '#EBEBEB',
  1200: '#5F6A7E',
  1300: '#E3E3E3',
  1400: '#9e9f9f',
  greySimple: '#7a7a7a',
  black: '#000',
  shadow: '#0000000f',
};
export default GreyColors;
