import React from 'react';

const BoldCloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23.003"
    height="23.001"
    viewBox="0 0 23.003 23.001"
  >
    <g
      id="Group_64072"
      data-name="Group 64072"
      transform="translate(-948.357 -68.358)"
    >
      <line
        id="Line_1709"
        data-name="Line 1709"
        y2="24.528"
        transform="translate(968.531 71.187) rotate(45)"
        fill="none"
        stroke="#00338d"
        strokeLinecap="round"
        strokeWidth="4"
      />
      <line
        id="Line_1710"
        data-name="Line 1710"
        x2="24.528"
        transform="translate(951.186 71.187) rotate(45)"
        fill="none"
        stroke="#00338d"
        strokeLinecap="round"
        strokeWidth="4"
      />
    </g>
  </svg>
);

export default BoldCloseIcon;
