/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { api } from '.';

const userBaseUrl = '/users';

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    searchUsers: builder.query<any, any>({
      query: (params) => ({
        url: `${userBaseUrl}/search`,
        params,
      }),
    }),
    getUsersByFullName: builder.query<any, string>({
      query: (string) => ({
        url: `${userBaseUrl}/find`,
        params: { string },
      }),
    }),
    getUserById: builder.query<any, string>({
      query: (id) => `${userBaseUrl}/internal-user/${id}`,
    }),
    getUserCompanyById: builder.query<any, string>({
      query: (id) => `${userBaseUrl}/external-user/${id}`,
    }),
    getUserAvatarById: builder.query<any, string>({
      query: (userId) => ({
        url: `${userBaseUrl}/avatar/${userId}`,
        responseType: 'blob',
        cache: 'no-cache',
      }),
    }),
    getConnectedUser: builder.query<{ admin: boolean; [key: string]: any }, void>({
      query: () => `${userBaseUrl}/get-connected-user`,
    }),
    hasAccessToSeniorManagementView: builder.query<boolean, void>({
      query: () => `${userBaseUrl}/has-access-to-senior-management`,
    }),
    createExternalUser: builder.mutation<number, any>({
      query: (body) => ({
        url: `${userBaseUrl}/create-external`,
        method: 'POST',
        body,
      }),
    }),
    updateInternalUser: builder.mutation<any, any>({
      query: (body) => ({
        url: `${userBaseUrl}/update-internal-user`,
        method: 'POST',
        body,
      }),
    }),
    updateUserAccessStatus: builder.mutation<any, any>({
      query: (body) => ({
        url: `${userBaseUrl}/update-access-status`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useLazySearchUsersQuery,
  useLazyGetUsersByFullNameQuery,
  useLazyGetUserByIdQuery,
  useLazyGetUserCompanyByIdQuery,
  useHasAccessToSeniorManagementViewQuery,
  useGetUserAvatarByIdQuery,
  useLazyGetUserAvatarByIdQuery,
  useCreateExternalUserMutation,
  useUpdateInternalUserMutation,
  useUpdateUserAccessStatusMutation,
  useGetConnectedUserQuery,
  endpoints: {
    getConnectedUser: { useQueryState: getConnectedUserState },
  },
} = userApi;
// Query => use{Endpoint}Query + useLazy{Endpoint}Query
// Mutation => use{Endpoint}Mutation

/**
 * { data, isSuccess, isLoading, isError, error } = useQuery(args, {skip: arg == undefined});   call the endpoint directly
 * [doSearch, invokeResult] = useLazyQuery(); // does not call endpoint until invoked
 * doSearch(args);
 * [doCreate, { data: createdUser }] = useMutation();
 * doCreate();
 *
 * caching:
 * containerA = useGetConnectedUser();
 * Aside = useGetConnectedUser();
 *
 */
