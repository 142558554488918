import React from 'react';
import PORTFOLIO_VIEW from 'assets/images/landingPage/PORTFOLIO_VIEW.png';
import CLIENT_PROGRAM_VIEW from 'assets/images/landingPage/CLIENT_PROGRAM_VIEW.png';
import COLLAB_MOBILE from 'assets/images/landingPage/COLLAB_MOBILE.png';
import COLLAB_WEB from 'assets/images/landingPage/COLLAB_WEB.png';
import SENIOR_MANAGEMENT from 'assets/images/landingPage/SENIOR_MANAGEMENT.png';

import JPASS_MOBILE from 'assets/images/landingPage/JPASS_MOBILE.png';
import JPASS from 'assets/images/landingPage/JPASS.png';
import JPASS_SMART_BADGE from 'assets/images/landingPage/JPASS_SMART_BADGE.png';
import JPASS_WORK_PERMITTING from 'assets/images/landingPage/JPASS_WORK_PERMITTING.png';
import MATERIAL_MANAGEMENT from 'assets/images/landingPage/MATERIAL_MANAGEMENT.png';
import SITE_OPENING from 'assets/images/landingPage/SITE_OPENING.png';
import urlPlatform from 'endpoints.config';

export const PLATFORMS = {
  COLLAB: 'COLLAB',
  JPASS: 'JPASS',
  TIQAD: 'TIQAD',
  BLOOM: 'BLOOM',
  ESP: 'ESP',
  ACONEX: 'ACONEX',
  SAFRAN: 'SAFRAN',
};
export const DOWNLOAD_APK = 'DOWNLOAD_APK';
export const SUB_PLATFORMS = {
  SMALL_ORBITS: {
    COLLAB: {
      LOGO1: {
        IMG: <img src={PORTFOLIO_VIEW} alt="Portfolio View" />,
        TITLE: 'Portfolio View',
        IS_COMING_SOON: true,
        URL: '#',
      },
      LOGO2: {
        IMG: <img src={CLIENT_PROGRAM_VIEW} alt="Program View" />,
        TITLE: 'Program View',
        IS_COMING_SOON: false,
        URL: urlPlatform.COLLAB_CLIENT_PROGRAM_VIEW,
      },
    },

    JPASS: {
      LOGO1: {
        IMG: <img src={JPASS_MOBILE} alt="J-Pass mobile" />,
        TITLE: 'J-Pass mobile',
        IS_COMING_SOON: false,
        URL: DOWNLOAD_APK,
      },
      LOGO2: {
        IMG: <img src={JPASS} alt="J-Pass Web" />,
        TITLE: 'J-Pass Web',
        IS_COMING_SOON: false,
        URL: urlPlatform.JPASS_URL,
      },
    },
  },

  MEDIUM_ORBITS: {
    COLLAB: {
      LOGO1: {
        IMG: <img src={COLLAB_MOBILE} alt="Collab Android" />,
        TITLE: 'Collab Android',
        IS_COMING_SOON: false,
        URL: DOWNLOAD_APK,
      },
      LOGO2: {
        IMG: <img src={COLLAB_WEB} alt="Collab" />,
        TITLE: 'Collab',
        IS_COMING_SOON: false,
        URL: urlPlatform.COLLAB_URL,
      },
      LOGO3: {
        IMG: <img src={SENIOR_MANAGEMENT} alt="Senior Management" />,
        TITLE: 'Senior Management View',
        IS_COMING_SOON: false,
        URL: urlPlatform.COLLAB_SENIOR_MANAGEMENT_VIEW_URL,
      },
    },

    JPASS: {
      LOGO1: {
        IMG: <img src={JPASS_SMART_BADGE} alt="J-Pass Smart badge" />,
        TITLE: 'J-Pass Smart badge',
        IS_COMING_SOON: true,
        URL: '#',
      },
      LOGO2: {
        IMG: <img src={JPASS_WORK_PERMITTING} alt="J-Pass Work Permitting" />,
        TITLE: 'J-Pass Work Permitting',
        IS_COMING_SOON: true,
        URL: '#',
      },
      LOGO3: {
        IMG: <img src={MATERIAL_MANAGEMENT} alt="Material Management" />,
        TITLE: 'Material Management',
        IS_COMING_SOON: true,
        URL: urlPlatform.MATERIAL_MANAGEMENT_URL,
      },
      LOGO4: {
        IMG: <img src={SITE_OPENING} alt="Site Opening" />,
        TITLE: 'Site Opening',
        IS_COMING_SOON: false,
        URL: '#',
      },
    },
  },
};
export const PROGRAM_VIEW_ROLES = {
  MANAGING_DIRECTOR: 'MANAGING_DIRECTOR',
  BUSINESS_UNIT_MANAGER: 'BUSINESS_UNIT_MANAGER',
  PROGRAM_DIRECTOR: 'PROGRAM_DIRECTOR',
  PROJECT_MANAGEMENT_OFFICE: 'PROJECT_MANAGEMENT_OFFICE',
};
