import React from "react";

const PECertificteIcon = () => (
  <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 22" width="25" height="22">
    <defs>
      <image width="25" height="22" id="img7"
             href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAfCAMAAAC1fe+DAAAAAXNSR0IB2cksfwAAAFFQTFRFAAAAAHj/AHz/AHz/AHz/AHz/AHv/AHv/AHz/AHr/AHv/AHv/AHz/AID/AHv/AHv/AHv/AHz/AHv/AHr/AHv/AHz/AHz/AHz/AHz/AHz/AHv/LQzx4QAAABt0Uk5TACB/v+//359AMHDgrxCwoM9Q8GCP0IDAkG9PN/ZXZgAAAVtJREFUeJx1k4uSgyAMRWEF5NEISrHs/v+HbkJEbauMI0iON8lFhdiH/BmUNqMV90Mrh5wPD7hF5GQczTF9M6C0fpD+vGR6zkkxfBr4Xg5PEioj3l1wWV0wUGYSLCsRqHRiZGekaUnDT2jBE2PGNk2rKBxattDB6JZevCxdtJh7E2baVguXqHvQby1D4vYoQ4YyJF0FlwkpNAZSzANwpVYBrGy6C3ZMsVmHBPagEteT01661ROpxySJEOJRPDOzlcOQWVy4JWMurQcMjQWwL3YFLFnfxJExUWTAEBEil/3A3OJZHLO1qZoPgiJxa5btiF8EpJ3gA6jY1w3xSjZsn83vNWHQhM7caOBp7Iy4IqbFXzIHQc4hA8a6ekc0f4pqC3dDEJPbobojV30nGsNzkNuOke/EwdR122jsmdgZ2z/MOn4SnYm+lzuZQa1CfDDPv/FkrrMX//Aafd/9BxtCFbnSD2bSAAAAAElFTkSuQmCC" />
    </defs>
    <style>
    </style>
    <use id="Background" href="#img7" x="0" y="0" />
  </svg>
);
export default PECertificteIcon;
