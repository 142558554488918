import MenuItemLink from 'components/StyledComponents/MenuItemLink';
import PropTypes from 'prop-types';
import CustomDropDown from './Wrapper';

function DropDown({ subMenuItems = [], isOpened, active, setActive, ...props }) {
  return (
    <CustomDropDown isOpened={isOpened}>
      <ul {...props}>
        {subMenuItems.map((submenuItem, index) => (
          <li key={submenuItem.title}>
            <MenuItemLink
              to={submenuItem.url}
              onClick={() => setActive(index)}
              className={index === active ? 'active' : ''}
            >
              <span className="link-text">{submenuItem.title}</span>
            </MenuItemLink>
          </li>
        ))}
      </ul>
    </CustomDropDown>
  );
}
DropDown.propTypes = {
  subMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  isOpened: PropTypes.bool,
  active: PropTypes.string,
  setActive: PropTypes.func,
};
export default DropDown;
