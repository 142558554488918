import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {Box, Stack, Typography} from '@mui/material';
import {CloseIcon, FilterSvg} from 'assets/svgs/componentsIcons';
import {FormWrapper} from 'components/StyledComponents/FormInputs';
import AppBody from 'components/StyledComponents/AppBody';
import PageHeader from 'components/StyledComponents/PageHeader';
import PageBody from 'components/StyledComponents/PageBody';
import ActionArea from 'components/StyledComponents/ActionArea';
import ContentBody from 'components/StyledComponents/ContentBody';
import ContentActions from 'components/StyledComponents/ContentActions';
import CustomPagination from 'components/StyledComponents/Pagination';

import {AppButton, MainButton} from 'components/Buttons';
import FormInput from 'components/FormInput';
import AppCalendarsDateRangePicker from 'components/AppCalendarsDateRangePicker';
import FormSelect from 'components/FormSelect';
import {
  makeSelectSuccessFreeze,
  makeSelectSuccessUpdateInternalUser,
} from 'containers/ProjectAdministrationUserManagement/UserManagementItems/UserManagementItem/UserManagementOption/selectors';
import {useLazySearchUsersQuery} from 'services/apis/user';
import {EnumerationType, useGetEnumerationByTypeFromUserManagementQuery,} from 'services/apis/enumeration';
import FullPageLoader from 'components/FullPageLoader';
import UserManagementItems from './UserManagementItems';
import {USER_STATUS, USER_TYPE} from './constants';
import {ProjectAdministrationUserManagementWrapper} from './Wrapper';
import {SETTING_USERS_DETAILED_VIEWS} from "routes/constants";
import {generatePath, useNavigate} from "react-router-dom";
import {setExternal} from "services/slices/user-management/user-management";

const stateSelector = createStructuredSelector({
  successUpdateInternalUser: makeSelectSuccessUpdateInternalUser,
  successFreeze: makeSelectSuccessFreeze,
});

function ProjectAdministrationUserManagement() {
  const {successUpdateInternalUser, successFreeze} = useSelector(stateSelector);

  const [page, setPage] = useState(0);
  const [isFilter, setIsFilter] = useState(false);
  const [userFilter, setUserFilter] = useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [doSearchUsers, {data: users, isFetching: filterLoading}] =
    useLazySearchUsersQuery();
  const {data: specificRoles} = useGetEnumerationByTypeFromUserManagementQuery(
    EnumerationType.SPECIFIC_ROLE_TYPE,
  );

  const searchUsers = useCallback(
    (params) => {
      doSearchUsers({
        ...params,
        name: params?.name?.trim(),
        email: params?.email?.trim(),
        jobTitle: params?.jobTitle?.trim(),
      });
    },
    [doSearchUsers],
  );

  useEffect(() => {
    searchUsers();
  }, [searchUsers]);

  useEffect(() => {
    if (successFreeze || successUpdateInternalUser) {
      searchUsers({...userFilter});
    }
  }, [searchUsers, successUpdateInternalUser, successFreeze]);

  useEffect(() => {
    searchUsers({...userFilter, page});
  }, [searchUsers, page]);

  const changePage = (_, p) => {
    setPage(p - 1);
  };

  const toggleFilter = () => {
    setIsFilter(!isFilter);
  };


  const handleUserDetails = (userId, isExternal) => {
    dispatch(setExternal(isExternal));
    const updateUserPath = generatePath(SETTING_USERS_DETAILED_VIEWS, {userId});
    navigate(updateUserPath);
  };

  return (
    <ProjectAdministrationUserManagementWrapper>
      {filterLoading && <FullPageLoader/>}
      <AppBody>
        <PageHeader>
          <Box className="top-header">
            <Box className="page-actions-wrapper">
              <Box className="heading-area">
                <Box className="page-title-area">
                  <Typography variant="h3_HelveticaNeue_Bold" color="primary.main">
                    User management
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.main">
              Here you can manage users and their settings
            </Typography>
          </Box>
        </PageHeader>
        <PageBody>
          <ActionArea className="filterWrapper">
            <FormWrapper>
              <Typography variant="h4_HelveticaNeue_Medium" color="primary.main">
                {isFilter ? 'Filter' : 'User listing'}
              </Typography>
              <AppButton
                placeholder="Search"
                customClass="filter"
                label=""
                startIcon={isFilter ? <CloseIcon/> : <FilterSvg/>}
                labelVariant="subtitle1_HelveticaNeue_Medium"
                labelColor="text.main"
                onClick={toggleFilter}
              />
            </FormWrapper>

            <Stack
              className={`filter__inputs--container   ${isFilter ? 'show' : 'hide'}`}
            >
              <FormInput
                label="Name"
                placeholder="Name"
                onChange={(e) => {
                  setUserFilter({...userFilter, name: e.target.value});
                }}
                value={userFilter?.name}
                max="100"
              />
              <FormInput
                label="Email"
                placeholder="Enter email"
                value={userFilter?.email}
                onChange={(e) => {
                  setUserFilter({...userFilter, email: e.target.value});
                }}
              />
              <AppCalendarsDateRangePicker
                label="Creation date from ➝ to"
                values={[userFilter?.creationDateFrom, userFilter?.creationDateTo]}
                onChange={(item) => {
                  setUserFilter({
                    ...userFilter,
                    creationDateFrom: item.selectedDates.startDate,
                    creationDateTo: item.selectedDates.endDate,
                  });
                }}
              />
              <FormInput
                label="Job title"
                placeholder="Enter job title"
                value={userFilter?.jobTitle}
                onChange={(e) => {
                  setUserFilter({
                    ...userFilter,
                    jobTitle: e.target.value,
                  });
                }}
              />
              <FormSelect
                menuItems={Object.values(USER_TYPE)}
                label="User type"
                valueKey="label"
                value={Object.values(USER_TYPE)?.find(
                  (item) => item?.code === userFilter?.userType,
                )}
                onChange={(e) => {
                  setUserFilter({...userFilter, userType: e.target.value.code});
                }}
              />
              <FormSelect
                menuItems={specificRoles || []}
                label="Specific role"
                onChange={(e) => {
                  setUserFilter({...userFilter, specificRoleCode: e.target.value.code});
                }}
                valueKey="label"
                value={specificRoles?.find(
                  (item) => item?.code === userFilter?.specificRoleCode,
                )}
              />
              <FormSelect
                menuItems={Object.values(USER_STATUS)}
                label="Status"
                valueKey="label"
                value={Object.values(USER_STATUS)?.find(
                  (item) => item?.code === userFilter?.userStatus,
                )}
                onChange={(e) => {
                  setUserFilter({...userFilter, userStatus: e.target.value.code});
                }}
              />

              <AppCalendarsDateRangePicker
                label="Last edited date from ➝ to"
                values={[userFilter?.updateDateFrom, userFilter?.updateDateTo]}
                onChange={(item) => {
                  setUserFilter({
                    ...userFilter,
                    updateDateFrom: item.selectedDates.startDate,
                    updateDateTo: item.selectedDates.endDate,
                  });
                }}
              />

              <Stack className="form-split">
                <Stack>
                  <MainButton
                    secondary
                    type="button"
                    onClick={() => {
                      setUserFilter({});
                      searchUsers({page});
                      setIsFilter(false);
                    }}
                  >
                    <span>Undo</span>
                  </MainButton>
                </Stack>
                <Stack>
                  <MainButton
                    type="button"
                    onClick={() => {
                      searchUsers({
                        ...userFilter,
                      });
                      setIsFilter(false);
                    }}
                  >
                    <span>Apply</span>
                  </MainButton>
                </Stack>
              </Stack>
            </Stack>
          </ActionArea>
          <ContentBody>
            <UserManagementItems
              userItems={users?.content}
              userDetails={handleUserDetails}
            />
          </ContentBody>
          <ContentActions>
            <CustomPagination
              shape="rounded"
              onChange={changePage}
              count={users?.totalPages}
            />
          </ContentActions>
        </PageBody>
      </AppBody>
    </ProjectAdministrationUserManagementWrapper>
  );
}

ProjectAdministrationUserManagement.propTypes = {};

export default ProjectAdministrationUserManagement;
