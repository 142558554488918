import {Navigate, Route} from "react-router-dom";
import AppProfileSettingsPage, {
  AccessManagementProfile,
  AccessManagementProfilePrivilege
} from "pages/AppProfileSettings";

import {
  ACCESS_MANAGEMENT,
  ACCESS_MANAGEMENT_ADD_PROFILE,
  ACCESS_MANAGEMENT_EDIT_PROFILE,
  ACCESS_MANAGEMENT_EDIT_PROFILE_PRIVILEGE,
  ACCESS_MANAGEMENT_PROFILE_PRIVILEGE
} from "./constants";

const ProfileSettingsRoutes = (
  <Route path={ACCESS_MANAGEMENT} element={<AppProfileSettingsPage/>}>
    <Route index element={<Navigate to={ACCESS_MANAGEMENT_ADD_PROFILE}/>}/>
    <Route path={ACCESS_MANAGEMENT_ADD_PROFILE} element={<AccessManagementProfile/>}/>
    <Route path={ACCESS_MANAGEMENT_EDIT_PROFILE} element={<AccessManagementProfile/>}/>
    <Route path={ACCESS_MANAGEMENT_PROFILE_PRIVILEGE} element={<AccessManagementProfilePrivilege/>}/>
    <Route path={ACCESS_MANAGEMENT_EDIT_PROFILE_PRIVILEGE} element={<AccessManagementProfilePrivilege/>}/>
  </Route>
);

export default ProfileSettingsRoutes;
