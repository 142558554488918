import { Navigate, Route } from 'react-router-dom';
import AppProjectsSettingsPage, {
    ProjectsSettingsProjectCreation,
    ProjectsSettingsProjectMembers,
    ProjectSettingProjectWorkDimensions
} from 'pages/AppProjectsSettings';
import {
  PROJECT_SETTINGS,
  PROJECT_SETTINGS_CREATE_PROJECT,
  PROJECT_SETTINGS_EDIT_PROJECT,
  PROJECT_SETTINGS_EDIT_PROJECT_MEMBERS,
  PROJECT_SETTINGS_EDIT_PROJECT_WORK_DIMENSIONS
} from './constants';

const ProjectSettingsRoutes = (
    <Route path={PROJECT_SETTINGS} element={<AppProjectsSettingsPage />}>
        <Route index element={<Navigate to={PROJECT_SETTINGS_CREATE_PROJECT} />} />
        <Route path={PROJECT_SETTINGS_CREATE_PROJECT} element={<ProjectsSettingsProjectCreation />} />
        <Route path={PROJECT_SETTINGS_EDIT_PROJECT} element={<ProjectsSettingsProjectCreation />} />
        <Route path={PROJECT_SETTINGS_EDIT_PROJECT_MEMBERS} element={<ProjectsSettingsProjectMembers />} />
        <Route path={PROJECT_SETTINGS_EDIT_PROJECT_WORK_DIMENSIONS} element={<ProjectSettingProjectWorkDimensions />} />
    </Route>
);

export default ProjectSettingsRoutes;
