import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import theme from 'ui/theme';
import { useNavigate } from 'react-router-dom';
import UsersIconGrey from 'assets/svgs/componentsIcons/UsersIconGrey';
import UsersIcon from 'assets/svgs/componentsIcons/UsersIcon';
import DeleteWL from 'assets/svgs/componentsIcons/DeleteWL';
import Bookmark from 'assets/svgs/componentsIcons/Bookmark';
import { ACCESS_MANAGEMENT_ADD_PROFILE } from 'routes/constants';
import { useDispatch } from 'react-redux';
import { setDeleteProfileId, showDeletePopup } from 'services/slices/access-management';

function ProfileItem({ profile }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Box
      className="mt-8 listing_item_row"
      onClick={() => navigate(`${ACCESS_MANAGEMENT_ADD_PROFILE}/${profile?.id}`)}
    >
      <Box className="sub_listing">
        <Box className="bookmark_container">{profile?.customized && <Bookmark />}</Box>
        <Typography
          className="ml-50 name_label"
          variant="subtitle2_HelveticaNeue_Regular"
          color={theme.palette.text.gray}
        >
          {profile.name}
        </Typography>
        <Box className="users_label gap-10">
          <Box className="users_icon">
            <UsersIcon />
          </Box>
          <Box className="users_grey_icon">
            <UsersIconGrey />
          </Box>
          <Typography
            className="users_number"
            variant="subtitle1_Montserrat_Medium"
            color={theme.palette.primary.main}
          >
            {profile.linksCount || 0}
          </Typography>
        </Box>
        <Typography
          className="users_label"
          variant="subtitle2_HelveticaNeue_Regular"
          color={theme.palette.text.gray}
        >
          {profile.forExternalUsers ? 'Yes' : 'No'}
        </Typography>
        <Typography
          className="users_label"
          variant="subtitle2_HelveticaNeue_Light"
          color={theme.palette.text.gray}
        >
          {profile.platform}
        </Typography>
      </Box>
      <Box className="description_container">
        <Typography
          className="description_label"
          variant="subtitle2_HelveticaNeue_Light"
          color={theme.palette.text.gray}
        >
          {profile.description}
        </Typography>
        {profile?.customized && (
          <Box
            className="mr-10 item_icon"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(showDeletePopup(true));
              dispatch(setDeleteProfileId(profile?.id));
            }}
          >
            <DeleteWL color={theme.palette.text.main} />
          </Box>
        )}
      </Box>
    </Box>
  );
}

ProfileItem.propTypes = {
  profile: PropTypes.shape({
    id: PropTypes.number,
    customized: PropTypes.bool,
    name: PropTypes.string,
    usersCount: PropTypes.number,
    forExternalUsers: PropTypes.bool,
    description: PropTypes.string,
    platform: PropTypes.string,
    linksCount: PropTypes.number,
  }),
};

export default ProfileItem;
