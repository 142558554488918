import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  isAuthenticated: false
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthInfo: (state, { payload: { user, isAuthenticated } }) => {
      state.user = user;
      state.isAuthenticated = isAuthenticated;
    },
    resetAuth: () => {
      return initialState;
    },
  }
});

export const { setAuthInfo, resetAuth } = authSlice.actions;

export default authSlice.reducer;
