import { Avatar, Divider, IconButton, ThemeProvider, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MenuButton } from 'components/Buttons';
import CustomDropDownMenu from 'components/StyledComponents/DropDownMenu';
import { useMsal } from '@azure/msal-react';
import { useLazyGetUserAvatarByIdQuery } from 'services/apis/user';
import AppTheme from 'ui/theme/app-theme';
import PropTypes from 'prop-types';
import LogoutIcon from 'assets/svgs/componentsIcons/LogoutIcon';
import { api } from '../../services/apis';

function Logout({ isLanding }) {
  const [openElem, setOpenElem] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { instance } = useMsal();
  const { data: connectedUser, isSuccess: connectedUserSuccess } =
    api.endpoints.getConnectedUser.useQueryState();
  const [doGetUserAvatar, { data: avatar, isSuccess: avatarSuccess }] =
    useLazyGetUserAvatarByIdQuery();
  const [userAvatar, setUserAvatar] = useState(null);
  useEffect(() => {
    if (connectedUserSuccess) {
      doGetUserAvatar(connectedUser?.id);
    }
  }, [connectedUser, connectedUserSuccess, doGetUserAvatar]);

  useEffect(() => {
    if (avatarSuccess) {
      const userAvatarPic = avatar?.size > 0 && window.URL.createObjectURL(avatar);
      setUserAvatar(userAvatarPic);
    }
  }, [avatarSuccess, avatar]);
  const logOutHandler = () =>
    instance.logoutRedirect().catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e);
    });
  const handleOpenCloseElement = () => {
    setOpenElem(!openElem);
  };

  const handleClose = () => {
    handleOpenCloseElement();
  };
  const handleClick = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    handleOpenCloseElement();
  };

  const leftOffset = anchorEl ? anchorEl.getBoundingClientRect().left : null;

  return (
    <ThemeProvider theme={AppTheme}>
      <div className="user-container">
        <IconButton
          aria-label="more"
          id="long-button"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <Avatar width="48" alt={connectedUser?.firstName} src={userAvatar} />
        </IconButton>
        <CustomDropDownMenu
          style={{ left: !isLanding && leftOffset + 50 }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          anchorEl={anchorEl}
          open={openElem}
          onClose={handleClose}
        >
          <MenuButton type="button">
            <Typography variant="subtitle2_HelveticaNeue_Medium" color="text.gray">
              {connectedUser?.fullName}
            </Typography>
          </MenuButton>
          <Divider />
          <MenuButton
            type="button"
            danger
            onClick={(e) => {
              e.stopPropagation();
              handleClose();
              logOutHandler();
            }}
          >
            <span>
              <LogoutIcon />
            </span>
            <Typography variant="subtitle2_HelveticaNeue_Medium" color="danger.main">
              Log Out
            </Typography>
          </MenuButton>
        </CustomDropDownMenu>
      </div>
    </ThemeProvider>
  );
}

Logout.propTypes = {
  isLanding: PropTypes.bool,
};
export default Logout;
