import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Stack,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { CustomInput } from 'components/StyledComponents/FormInputs';

function FormInput({
  handleClick,
  label,
  classname,
  onChange,
  error,
  haveCurrency,
  labelColor = 'text.secondary',
  value,
  isValid = true,
  number = false,
  disabled = false,
  ...rest
}) {
  return (
    <Stack className={`fomInput__wrapper ${classname}`} onClick={handleClick}>
      <FormControl className="inputs__label" variant="standard" error={error}>
        <InputLabel shrink htmlFor={label}>
          <Typography color={labelColor} variant="h5_HelveticaNeue_Regular">
            {label}
          </Typography>
        </InputLabel>
        <CustomInput
          type={number ? 'number' : 'text'}
          label={label}
          placeholder={label}
          disabled={disabled}
          onChange={onChange}
          isValid={isValid && !error}
          {...rest}
          value={value || ''}
          endAdornment={
            haveCurrency && (
              <Stack className="currencyLabel">
                <Typography color="primary.main" variant="subtitle1_HelveticaNeue_Medium">
                  M
                  <Typography
                    color="primary.main"
                    variant="subtitle1_HelveticaNeue_Light"
                  >
                    MAD
                  </Typography>
                </Typography>
              </Stack>
            )
          }
        />
        {!isValid && (
          <FormHelperText className="errorMsg">This field is required</FormHelperText>
        )}
        {error && <FormHelperText className="errorMsg">{error}</FormHelperText>}
      </FormControl>
    </Stack>
  );
}

FormInput.propTypes = {
  handleClick: PropTypes.func,
  label: PropTypes.string,
  classname: PropTypes.string,
  labelColor: PropTypes.string,
  haveCurrency: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  value: PropTypes.string,
  isValid: PropTypes.bool,
  number: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default FormInput;
