import React, { forwardRef } from "react";
import { Typography } from "@mui/material";
import ActionBtn from "./Wrapper";

type ActionButtonProps = {
  label?: string;
  classname?: string;
  typoVariant?: "h1" | "h2" | "h3" | "subtitle1" | "subtitle2" | "body1" | "body2";
  typoColor?: string;
  hidden?: boolean;
  onClick?: () => void;
}
const ActionButton = forwardRef<HTMLButtonElement, ActionButtonProps>(
  (
    {
      label,
      classname,
      typoVariant = "subtitle1", /*TODO replace the "subtitle1 by "subtitle1_Montserrat_Medium" after fixing the theme file */
      typoColor,
      hidden,
      ...rest
    },
    ref
  ) => {

    return (
      <ActionBtn
        className={`${classname}`}
        hidden={hidden}
        {...rest}
        ref={ref}
      >
        <Typography variant={typoVariant} color={typoColor}>
          {label}
        </Typography>
      </ActionBtn>
    );
  }
);

export default ActionButton;
