import { styled } from "@mui/material";

const CustomDropDown = styled('div')`
  display: flex;
  max-height: ${(props) => (props.isOpened ? '100rem' : '0')};
  overflow: hidden;
  transition: all 0.2s cubic-bezier(0.95, 0.05, 0.795, 0.035);
  padding-left: 20px;
  padding-top: 5px;

  > ul {
    width: 100%;
    padding-left: 8px;
    border-left: 2px solid ${(props) => props.theme.GreyColors[1000]};
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .active {
    color: #fff;
    background: #007cff;
  }
`;

export default CustomDropDown;
