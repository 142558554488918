const handleBlobReceived = (receivedBlob, fileName) => {
  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(receivedBlob);
  downloadLink.download = fileName;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  URL.revokeObjectURL(downloadLink.href);
};
export default handleBlobReceived;
