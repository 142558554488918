import { styled } from '@mui/material';

const ProgramComponentItem = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.theme.GreyColors.white};
  box-shadow: 0 1px 5px #ebebebcc;
  border-radius: 8px;
  height: 50px;
  padding: 10px 12px 10px 24px;

  .program-title {
    font-family: 'HelveticaNeueMedium', sans-serif;
    font-size: 14px;
    color: ${(props) => props.theme.AppColors.blueViolet_100};
    flex-grow: 1;
    flex-basis: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: ${(props) => props.theme.GreyColors.white};
    justify-content: start;
    padding-left: 0;

    &:hover {
      background: transparent;
    }
  }

  .itemDisplay {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .logoStyle {
    max-height: 50px;
    max-width: 70px;
    border-radius: 5px;
    margin-right: 10px;
  }
`;

export default ProgramComponentItem;
