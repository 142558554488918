import React from "react";

const ReportingIcon = () => (
  <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 22" width="25" height="25">
    <title>iconReporting</title>
    <defs>
      <image width="25" height="25" id="img1"
             href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAdCAMAAABhTZc9AAAAAXNSR0IB2cksfwAAAE5QTFRFAHr/AHz/AAAAAHz/AHz/AHz/AID/AHj/AHv/AHz/AHv/AHz/AHz/AHz/AHz/AHv/AHr/AHv/AHv/AHz/AHv/AHz/AHv/AHv/AHz/AHv/SUBANgAAABp0Uk5TYP8AUL9vECCf0HDA70Cv4DDPsIDfkPCgf18XIJeFAAABDUlEQVR4nJXSi46EIAwFUFoQlcL4qKL7/z+6hZERNbPJ3sRochDbilJwi8IzCjRecmEFeFNVcVENprFZseJD26535ENSW3FW+4KBEB2MiDQZ40sxWfvZsijyIisdMw98KvdyJcXheIcq7XXRdX1q+hx7I1noqVFqtSEEDbp96rwdz33pU9Tt+26TjjH3N+Z+Dw1AFDl3pOOr+eniOUGnA6Q171m1KxG39bhrvWSW4pVVRTfzSdocNiayTdE0uyNpVhBkNue7t2TFUDTKeWFcFbLs3b31rKqVOVmUf08uOLjrJxRSJ08Nec9vysQM35XTefu32r+0n/KhWIwB4yczgYHJLF7uxrOy1RzvGX8BhLgUGyb74rwAAAAASUVORK5CYII=" />
    </defs>
    <style>
    </style>
    <use id="Background" href="#img1" x="0" y="0" />
  </svg>
);
export default ReportingIcon;
