import React from 'react';
import {BackBtn} from './Wrapper';

const BackButton = ({...rest}) => {

  return <BackBtn type="submit" {...rest} />;
};

BackButton.propTypes = {};
export default BackButton;
