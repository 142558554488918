import { Stack, styled } from '@mui/material';

const ProjectsSettingsFilterWrapper = styled(Stack)(({ theme }) => ({
  '& .filterCriteria': {
    '& > div': {
      minHeight: '80px',
      justifyContent: 'center',
      '& > .MuiTypography-root': {
        marginRight: 'auto',
      },
    },
  },
  '& .filter__inputs--container': {
    width: '100%',
  },

  [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},
  [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},
  [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {},
}));
export default ProjectsSettingsFilterWrapper;
