import {Outlet} from 'react-router-dom';
import ProjectsSettingsMenu from './ProjectsSettingsMenu';
import {AppProjectsSettingsWrapper} from "./Wrapper";

const AppProjectsSettings = () => {

  return (
    <AppProjectsSettingsWrapper>
      <ProjectsSettingsMenu/>
      <Outlet/>
    </AppProjectsSettingsWrapper>
  );
};

export default AppProjectsSettings;
export {default as ProjectsSettingsProjectCreation} from './ProjectsSettingsProjectCreation';
export {default as ProjectsSettingsProjectMembers} from './ProjectsSettingsProjectMembers';
export {
  default as ProjectSettingProjectWorkDimensions
} from './ProjectSettingProjectWorkDimensions';
