import React from 'react';
import {Stack, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import UserManagementItem from './UserManagementItem';
import UserManagementItemsWrapper from './Wrapper';

function UserManagementItems({userItems, userDetails}) {
  return (
    <UserManagementItemsWrapper container spacing={12} rowSpacing={12}>
      <Stack className="listing_item_row">
        <Stack>
          <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.gray">
            Name
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.gray">
            Creation date
          </Typography>
        </Stack>{' '}
        <Stack>
          <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.gray">
            Job title
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.gray">
            Type
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.gray">
            Specific role
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.gray">
            Status
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.gray">
            Last update
          </Typography>
        </Stack>
      </Stack>
      <Stack className="fullWidth">
        {userItems?.map((elem) => (
          <UserManagementItem user={elem} key={{elem}} userDetails={userDetails}/>
        ))}
      </Stack>
    </UserManagementItemsWrapper>
  );
}

UserManagementItems.propTypes = {
  userItems: PropTypes.arrayOf(PropTypes.string),
  userDetails: PropTypes.func,
};

export default UserManagementItems;
