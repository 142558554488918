import React from "react";

const TrainingIcon = () => (
  <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 29" width="29" height="29">
    <title>iconTraining</title>
    <defs>
      <image width="28" height="28" id="img15"
             href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAAXNSR0IB2cksfwAAAEVQTFRFAHv/AHz/AHz/AAAAAHz/AHv/AHv/AHz/AHz/AHr/AHv/AHv/AHz/AHj/AH3/AHv/AID/AHv/AHz/AHz/AHz/AHz/AHr/RyRfJgAAABd0Uk5T8P/vAH9woIBAMOCwwCA/zxBfUJBvr2DQPDdgAAAAr0lEQVR4nI2R4Q6DIAyE290UOsAxnb7/o45pNySByP3hmi9cCyXipm7EaIov4L0ppoFHU9XIhsC2HmoZ11B+swMPuN3KH1qv49VunmCYdkkVeu3QG/s8whKUw00xT+sPOcCplb53Rv3MFzCrzbFBwxZg0QahLzZo1hsY1OabUbc0JKg2dsS6tdx/CDNsOlefYDxt33tjdvgtQoJnGVOUBdyYtyYkEW7DWagJN6Ii9gOPug56zTmj6gAAAABJRU5ErkJggg==" />
    </defs>
    <style>
    </style>
    <use id="Background" href="#img15" x="0" y="0" />
  </svg>
);
export default TrainingIcon;
