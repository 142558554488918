import { Chip, Stack, styled } from '@mui/material';

export const SelectWrapper = styled(Stack)(({ theme, ...props }) => ({
  minHeight: '80px',

  '&.noHeight': {
    minHeight: 'unset',
  },
  '& fieldset': {
    borderColor: props.isValid ? theme.palette.border.main : theme.palette.danger.main,
  },
  '& .select__label': {
    transform: 'scale(0.75)',
    top: '-24px',
  },
  '& .MuiSelect-select': {
    minHeight: 'unset',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  '& .MuiInputBase-root': {
    borderRadius: '8px',
    height: '34px',
    backgroundColor: theme.palette.light.main,
  },
  '& svg': {
    '& path': {
      fill: theme.palette.text.darkBlue,
    },
  },
  '& .Mui-disabled': {
    '& svg': {
      '& path': {
        fill: theme.palette.placeholder__Input.main,
      },
    },
  },
  '& .errorMessage': {
    marginInline: 0,
  },

  [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},

  [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {},
}));

export const ChipStyleWrapper = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '8px',
  marginTop: '8px',

  [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},

  [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {},
}));

export const ChipStyle = styled(Chip)(({ theme }) => ({
  background: theme.palette.appBackground.BlueAlice,
  border: theme.palette.light.blue,
  color: theme.palette.primary.main,
  fontFamily: 'HelveticaNeueMedium',
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '12px',
  width: '100%',
  paddingRight: '5px',
  '& .deleteIcon': {
    transition: 'background-color 0.3s ease',
    '&:hover': {
      background: theme.palette.primary.main,
      padding: '3px',
      borderRadius: '50%',
      fill: theme.palette.common.white,
    },
  },

  [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},

  [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {},
}));
