import { createSelector } from 'reselect';
import { selectProjectsSettingsProjectWorkDimensionsDomain } from 'services/slices/project-setting/project-work';

const makeSelectWorkLocations = createSelector(
  selectProjectsSettingsProjectWorkDimensionsDomain,
  (substate) => substate.workLocations,
);
const makeSelectWorkLocation = createSelector(
  selectProjectsSettingsProjectWorkDimensionsDomain,
  (substate) => substate.workLocation,
);
const makeSelectWorkOrders = createSelector(
  selectProjectsSettingsProjectWorkDimensionsDomain,
  (substate) => substate.workOrders,
);
const makeSelectSubmit = createSelector(
  selectProjectsSettingsProjectWorkDimensionsDomain,
  (substate) => substate.submit,
);

const makeSelectPictures = createSelector(
  selectProjectsSettingsProjectWorkDimensionsDomain,
  (substate) => substate.pictures,
);
const makeSelectActiveTab = createSelector(
  selectProjectsSettingsProjectWorkDimensionsDomain,
  (substate) => substate.activeTab,
);
const makeSelectProjectDatas = createSelector(
  selectProjectsSettingsProjectWorkDimensionsDomain,
  (substate) => substate.projectDatas,
);
const makeSelectProjectData = createSelector(
  selectProjectsSettingsProjectWorkDimensionsDomain,
  (substate) => substate.projectData,
);
const makeSelectSelectedEntityInit = createSelector(
  selectProjectsSettingsProjectWorkDimensionsDomain,
  (substate) => substate.selectedEntityInit,
);
const makeSelectSelectedDisciplines = createSelector(
  selectProjectsSettingsProjectWorkDimensionsDomain,
  (substate) => substate.disciplineSelected,
);

const makeSelectIsDataUpdated = createSelector(
  selectProjectsSettingsProjectWorkDimensionsDomain,
  (substate) => substate.isDataUpdated,
);
export {
  makeSelectWorkLocations,
  makeSelectWorkLocation,
  makeSelectWorkOrders,
  makeSelectSubmit,
  makeSelectPictures,
  makeSelectActiveTab,
  makeSelectProjectDatas,
  makeSelectProjectData,
  makeSelectSelectedEntityInit,
  makeSelectSelectedDisciplines,
  makeSelectIsDataUpdated,
};
