import { createSlice } from '@reduxjs/toolkit';
import { type RootState } from 'store';

export const initialState = {
  projects: [],
  projectCriteria: {},
};
/* eslint-disable no-param-reassign */
const projects = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setProjects: (state, { payload: projectsList }) => {
      state.projects = projectsList;
    },
    appendToProjectCriteria: (state, { payload: searchCriteria }) => {
      state.projectCriteria = searchCriteria;
    },
    resetProjects: () => initialState,
  },
});

export const { setProjects, appendToProjectCriteria } = projects.actions;

export const selectProjectsSettingsProjects = (state: RootState): any =>
  state.projectSetting.projects;

export default projects.reducer;
