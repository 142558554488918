import { createSelector } from 'reselect';
import { selectProjectsSettingsProjectCreationDomain } from 'services/slices/project-setting/project-creation';

/**
 * specific selectors
 */
const makeSelectProjectFormData = createSelector(
  selectProjectsSettingsProjectCreationDomain,
  (substate) => substate.projectFormData,
);
const makeSelectWorkOrder = createSelector(
  selectProjectsSettingsProjectCreationDomain,
  (substate) => substate.workOrder,
);
const makeSelectSubmit = createSelector(
  selectProjectsSettingsProjectCreationDomain,
  (substate) => substate.submit,
);
const makeSelectPicture = createSelector(
  selectProjectsSettingsProjectCreationDomain,
  (substate) => substate.picture,
);
const makeSelectProjectWithWorkLocation = createSelector(
  selectProjectsSettingsProjectCreationDomain,
  (substate) => substate.withWorkLocation,
);
const makeSelectProjectCreationStatus = createSelector(
  selectProjectsSettingsProjectCreationDomain,
  (substate) => substate.projectCreationStatus,
);

export {
  makeSelectProjectFormData,
  makeSelectWorkOrder,
  makeSelectSubmit,
  makeSelectPicture,
  makeSelectProjectWithWorkLocation,
  makeSelectProjectCreationStatus,
};
