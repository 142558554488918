import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react';
import {
  CacheLookupPolicy,
  type Configuration,
  InteractionRequiredAuthError,
  InteractionStatus,
  PublicClientApplication,
  type RedirectRequest,
} from '@azure/msal-browser';
import GlobalConfig from 'global-config';
import {security} from 'services/internals/security';
import LoginPage from 'pages/LoginPage';
import {AuthContext} from '../AuthContext';
import {type AuthContextProps, type User} from '../types';

// *** MSAL Config *** //
const msalConfiguration: Configuration = {
  auth: {
    clientId: GlobalConfig.auth.msal.clientId,
    authority: GlobalConfig.auth.msal.authority,
    redirectUri: GlobalConfig.auth.msal.redirectUri,
    postLogoutRedirectUri: GlobalConfig.auth.msal.redirectUri,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: GlobalConfig.auth.msal.cacheLocation,
  },
};
const msalInstance = new PublicClientApplication(msalConfiguration);
msalInstance.handleRedirectPromise().then((response) => {
  if (response != null) {
    msalInstance.setActiveAccount(response.account);
  }
});

const loginRequest: RedirectRequest = {
  scopes: GlobalConfig.auth.msal.scope?.split(' '),
  loginHint: GlobalConfig.auth.msal.loginHint ?? '',
};

const CustomAuthProvider: React.FC<React.PropsWithChildren> = function ({ children }) {
  const [idToken, setIdToken] = useState<string>();
  const [_user, setUser] = useState<User>();
  const [_isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [_isLoading, setIsLoading] = useState<boolean>(false);
  const { accounts, instance, inProgress } = useMsal();
  const activeAccount = useMemo(
    () => instance.getActiveAccount() || accounts?.[0],
    [instance, accounts],
  );

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    setIsAuthenticated(isAuthenticated);
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated || !activeAccount) return;
    setUser({
      name: activeAccount.idTokenClaims?.name as string,
      email: activeAccount.username ?? '',
    });
  }, [isAuthenticated, activeAccount]);

  const _loginWithRedirect = useCallback(async (): Promise<void> => {
    if (_isLoading || inProgress !== InteractionStatus.None) {
      await Promise.resolve();
      return;
    }

    setIsLoading(true);
    await instance
      .handleRedirectPromise()
      .then((tokenResponse) => {
        if (tokenResponse == null) {
          const accounts = instance.getAllAccounts();
          if (accounts.length === 0) {
            // No user signed in
            instance.loginRedirect({
              ...loginRequest,
              redirectStartPage: window.location.href,
            });
          }
        } else {
          // Do something with the tokenResponse
        }
      })
      .catch((err) => {
        // Handle error
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [instance, inProgress]);

  const _logout = useCallback((): Promise<void> | void => {
    return instance.logoutRedirect({
      idTokenHint: idToken,
    });
  }, [instance, idToken]);

  const _getAccessToken = useCallback(async (): Promise<string> => {
    if (inProgress !== InteractionStatus.None) {
      return await Promise.resolve('');
    }
    let token;
    try {
      const _accounts = instance.getAllAccounts();
      const account =
        _accounts && _accounts.length > 0 ? _accounts[0] : instance.getActiveAccount();
      if (account == null) throw new Error('login_required');
      if (account) {
        try {
          const result = await instance.acquireTokenSilent({
            ...loginRequest,
            account,
            cacheLookupPolicy: CacheLookupPolicy.Default,
          });
          token = result?.accessToken;
          setIdToken(result?.idToken);
        } catch (error) {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              ...loginRequest,
              loginHint: account?.username,
              account,
            });
          }
          throw error;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return token ?? '';
  }, [instance, inProgress]);

  useEffect(() => {
    security.setAccessTokenFunction(async (): Promise<string> => {
      const request = {
        ...loginRequest,
        account: instance.getAllAccounts()?.[0] ?? '',
        cacheLookupPolicy: CacheLookupPolicy.Default,
      }
      const doGetAccessToken = () => instance.acquireTokenSilent(request);
      let response;
      try {
        response = await doGetAccessToken();
      } catch (error) {
        console.error(error);
        await instance.acquireTokenRedirect(request);
        response = await doGetAccessToken();
      }
      return response.accessToken;
    });
  }, [instance]);

  const contextValue = useMemo<AuthContextProps>(() => {
    return {
      isLoading: _isLoading || inProgress !== InteractionStatus.None,
      isAuthenticated: _isAuthenticated,
      user: _user,
      getAccessToken: _getAccessToken,
      loginWithRedirect: _loginWithRedirect,
      logout: _logout,
    };
  }, [
    _isLoading,
    inProgress,
    _isAuthenticated,
    _user,
    _loginWithRedirect,
    _logout,
    _getAccessToken,
  ]);

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

const AuthMsalProvider = function ({ children }: React.PropsWithChildren) {
  return (
    <MsalProvider instance={msalInstance}>
      <CustomAuthProvider>
        <UnauthenticatedTemplate>
          <LoginPage />
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      </CustomAuthProvider>
    </MsalProvider>
  );
};

export default AuthMsalProvider;
