import React from 'react';

const ProjectsSettingsIcon = () => (
  <svg
    id="Group_63935"
    data-name="Group 63935"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <path
      id="Path_67264"
      data-name="Path 67264"
      d="M0,0H20V20H0Z"
      fill="none"
    />
    <path
      id="Path_67432"
      data-name="Path 67432"
      d="M3,16a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0h8a3,3,0,0,1,3,3V13a3,3,0,0,1-3,3ZM1.3,3V13A1.7,1.7,0,0,0,3,14.7h8A1.7,1.7,0,0,0,12.7,13V3A1.7,1.7,0,0,0,11,1.3H3A1.7,1.7,0,0,0,1.3,3ZM3.75,13a1,1,0,0,1-1-1V10a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1v2a1,1,0,0,1-1,1Zm0-1h2V10h-2Zm4.5-.4a.6.6,0,1,1,0-1.2h2a.6.6,0,1,1,0,1.2ZM3.75,7a1,1,0,0,1-1-1V4a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6a1,1,0,0,1-1,1Zm0-1h2V4h-2Zm4.5-.4a.6.6,0,1,1,0-1.2h2a.6.6,0,1,1,0,1.2Z"
      transform="translate(3 2)"
      fill="currentColor"
    />
  </svg>
);

export default ProjectsSettingsIcon;
