import React from 'react';

function FileDownload() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" viewBox="0 0 20 25">
      <path
        id="Union_171"
        data-name="Union 171"
        d="M5.492,25A5.7,5.7,0,0,1,0,19.106V5.638A5.456,5.456,0,0,1,5.251,0h6.22A.551.551,0,0,1,12,.576V4.595A4.06,4.06,0,0,0,15.9,8.765c.874,0,1.58.01,2.112.01.359,0,.959,0,1.457,0A.547.547,0,0,1,20,9.341V19.38A5.441,5.441,0,0,1,14.76,25ZM16.212,6.94a2.463,2.463,0,0,1-2.376-2.516v-3.3a.561.561,0,0,1,.969-.418c1.272,1.4,3.491,3.866,4.735,5.234a.6.6,0,0,1-.4,1.011C18.168,6.953,17.03,6.953,16.212,6.94Z"
        fill="#007cff"
      />
    </svg>
  );
}

export default FileDownload;
