import { Stack, styled } from '@mui/material';

export const AutoCompleteWrapper = styled(Stack)(({ theme, error }) => ({
  '& .MuiAutocomplete-root': {
    padding: 0,
    '& .MuiAutocomplete-inputRoot': {
      '& .MuiAutocomplete-tag': {
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        fontFamily: 'MontserratMedium',
      },
      '& .MuiAutocomplete-input': {
        fontFamily: theme.typography.subtitle1_HelveticaNeue_Medium.fontFamily,
        fontSize: theme.typography.subtitle1_HelveticaNeue_Medium.fontSize,
        color: theme.palette.text.secondary,
        opacity: 0.8,
        width: '100%',
      },
      '& .MuiAutocomplete-popupIndicator': {
        '& svg path': {
          fill: theme.palette.primary.main,
        },
      },
      height: '37px',
      borderRadius: '8px',
      backgroundColor: theme.palette.light.main,
      paddingBlock: 0,
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      position: 'relative',
      '& .autoComplete__tags-list': {
        '&::-webkit-scrollbar': {
          height: '3px',
        },
        '& .tags': {
          height: '30px',
          width: 'max-content',
        },
        position: 'absolute',
        bottom: '-40px',
        left: '0',
        flexDirection: 'row',
        gap: '10px',
        overflow: 'scroll hidden',
        width: '100%',
        height: '37px',
        zIndex: 1,
      },
      '& fieldset': {
        borderColor: error ? theme.palette.error : theme.palette.border.main,
        top: 0,
        marginInline: 0,
        paddingInline: 0,
        paddingBlock: 0,
        height: '37px',
        '& legend': {
          lineHeight: 0,
        },
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.text.darkBlue,
      },
    },
  },

  [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},

  [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {},
}));

export default AutoCompleteWrapper;
