import React from 'react';

const ExternalLogo3 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="146" height="146" viewBox="0 0 146 146">
    <defs>
      <filter id="Ellipse_1312" x="0" y="0" width="146" height="146" filterUnits="userSpaceOnUse">
        <feOffset input="SourceAlpha"/>
        <feGaussianBlur stdDeviation="10" result="blur"/>
        <feFlood flood-color="#1e2769" flood-opacity="0.09"/>
        <feComposite operator="in" in2="blur"/>
        <feComposite in="SourceGraphic"/>
      </filter>
      <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#004aa3"/>
        <stop offset="1" stop-color="#0081d8"/>
      </linearGradient>
    </defs>
    <g id="Group_63884" data-name="Group 63884" transform="translate(-1051 -347)">
      <g id="Group_63829" data-name="Group 63829" transform="translate(950.371 26.917)">
        <g transform="matrix(1, 0, 0, 1, 100.63, 320.08)" filter="url(#Ellipse_1312)">
          <circle id="Ellipse_1312-2" data-name="Ellipse 1312" cx="43" cy="43" r="43" transform="translate(30 30)"
                  fill="#fcfcff"/>
        </g>
      </g>
      <g id="Iconly_Bold_Discovery" data-name="Iconly/Bold/Discovery" transform="translate(1101.999 398)">
        <g id="Discovery" transform="translate(2 2)">
          <path id="Path_67229" data-name="Path 67229"
                d="M20,0A20,20,0,1,1,0,20,20.007,20.007,0,0,1,20,0Zm7.7,13.42a.9.9,0,0,0-1.12-1.14h0l-10.24,3.2a1.336,1.336,0,0,0-.88.88h0l-3.2,10.26a.9.9,0,0,0,1.12,1.12h0l10.2-3.2a1.3,1.3,0,0,0,.88-.88h0Z"
                fill="url(#linear-gradient)"/>
        </g>
      </g>
    </g>
  </svg>

);

export default ExternalLogo3;
