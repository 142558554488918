import {api} from '.';

const userProjectProfileBaseUrl = '/user-project-profile';

export const userProjectProfileApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUserProjectProfilesByPlatformAndUser: builder.query<any, any>({
      query: (params) => ({
        url: `${userProjectProfileBaseUrl}/by-platform-and-user`,
        params,
      }),
    }),
    getUserAccessToProgramView: builder.query<any, number>({
      query: (userId) => `${userProjectProfileBaseUrl}/access-program-view/${userId}`,
    }),
    editUserProjectProfile: builder.mutation<number, any>({
      query: (body) => ({
        url: `${userProjectProfileBaseUrl}/reassign-profile-to-user`,
        method: 'POST',
        body,
      }),
    }),
  }),
});
export const {
  useLazyGetUserProjectProfilesByPlatformAndUserQuery,
  useEditUserProjectProfileMutation,
  useLazyGetUserAccessToProgramViewQuery
} = userProjectProfileApi;

