import React from 'react';

const ArrowLeftIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={8} height={14} {...props}>
    <path
      d="M0,0,6.2,5.6,11.988,0"
      transform="translate(6.604 1.412) rotate(90)"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default ArrowLeftIcon;
