import React from "react";

const HCIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28.587"
    height="26.771"
    viewBox="0 0 28.587 26.771"
  >
    <g
      id="Groupe_65022"
      data-name="Groupe 65022"
      transform="translate(11983 -6524.112)"
    >
      <g
        id="Groupe_61791"
        data-name="Groupe 61791"
        transform="translate(-11983 6524.112)"
      >
        <path
          id="Tracé_51798"
          data-name="Tracé 51798"
          d="M708.838,517.222a.567.567,0,0,0-.394-.16h0a.569.569,0,0,0-.4.173l-3.879,4.026a.563.563,0,0,0,.017.8l6.56,6.246a.562.562,0,0,0,.791-.015l3.842-3.942a.565.565,0,0,0-.011-.8Z"
          transform="translate(-704.002 -501.689)"
          fill="#016be3"
        />
        <path
          id="Union_133"
          data-name="Union 133"
          d="M6.735,15.519.175,9.273a.563.563,0,0,1-.018-.8L4.036,4.448a.57.57,0,0,1,.4-.173h0a.566.566,0,0,1,.393.16l.8.774a8.067,8.067,0,0,1,1.454-1.56,5.985,5.985,0,0,1,5.584-1.182,9.107,9.107,0,0,0,2.432.324h4.45a2.061,2.061,0,0,1,1.61.72,2.131,2.131,0,0,1,.215.307L24.558.672A2.286,2.286,0,0,1,26.314,0a2.592,2.592,0,0,1,1.6.669,1.964,1.964,0,0,1,.122,2.851L23.471,8.892a3.781,3.781,0,0,1-.717.651l-.019.012a5.086,5.086,0,0,1-2.777.715h-6.77a12.13,12.13,0,0,0-1.4.081l-.766.086.334.324a.564.564,0,0,1,.012.8L7.525,15.5a.562.562,0,0,1-.791.015ZM1.36,8.848l5.748,5.474,3.063-3.143L4.456,5.634ZM7.8,4.52A7.013,7.013,0,0,0,6.452,6L9.976,9.423l1.684-.189a13.26,13.26,0,0,1,1.528-.089h6.77a4,4,0,0,0,2.163-.532,2.6,2.6,0,0,0,.493-.45L27.177,2.8a.847.847,0,0,0-.027-1.3,1.317,1.317,0,0,0-1.8-.03L21.691,5.092a2.311,2.311,0,0,1-.53,1.432,2.087,2.087,0,0,1-1.625.719H15.663a.562.562,0,1,1,0-1.125h3.873A.954.954,0,0,0,20.3,5.8a1.214,1.214,0,0,0,.263-.78,1.016,1.016,0,0,0-1.012-1.1H15.1a10.238,10.238,0,0,1-2.733-.364,5.4,5.4,0,0,0-1.444-.2A4.856,4.856,0,0,0,7.8,4.52Zm5.02,2.29,0-.022a.562.562,0,1,1,1.094-.257l.006.023a.563.563,0,0,1-.419.676.57.57,0,0,1-.129.015A.563.563,0,0,1,12.82,6.81Z"
          transform="translate(0 11.097)"
          fill="#00338d"
        />
        <path
          id="Tracé_9245"
          data-name="Tracé 9245"
          d="M748.808,299.474h4.841a1.2,1.2,0,0,1,1.412,0H759.9a1.2,1.2,0,0,1,1.412,0h4.841a1.2,1.2,0,0,0,1.2-1.2v-2.257a2.6,2.6,0,0,0-1.417-2.329,2.866,2.866,0,1,0-4.413,0,.931.931,0,0,1-1.84,0,2.866,2.866,0,1,0-4.412,0,.931.931,0,0,1-1.84,0,2.866,2.866,0,1,0-4.412,0,2.605,2.605,0,0,0-1.417,2.329v2.257a1.2,1.2,0,0,0,1.2,1.2Zm14.926-9.488a1.872,1.872,0,1,1-1.872,1.872,1.874,1.874,0,0,1,1.872-1.872Zm-1.291,4.429a2.855,2.855,0,0,0,2.581,0,1.614,1.614,0,0,1,1.339,1.6v2.257a.21.21,0,0,1-.209.209h-4.841a.209.209,0,0,1-.209-.209v-2.257a1.614,1.614,0,0,1,1.339-1.6Zm-4.962-4.429a1.872,1.872,0,1,1-1.872,1.872,1.874,1.874,0,0,1,1.872-1.872Zm-1.291,4.429a2.856,2.856,0,0,0,2.581,0,1.614,1.614,0,0,1,1.339,1.6v2.257a.21.21,0,0,1-.209.209h-4.841a.209.209,0,0,1-.209-.209v-2.257a1.614,1.614,0,0,1,1.339-1.6Zm-4.962-4.429a1.872,1.872,0,1,1-1.872,1.872,1.874,1.874,0,0,1,1.872-1.872Zm-2.63,6.028a1.614,1.614,0,0,1,1.339-1.6,2.855,2.855,0,0,0,2.581,0,1.614,1.614,0,0,1,1.339,1.6v2.257a.21.21,0,0,1-.209.209h-4.841a.21.21,0,0,1-.209-.209Zm0,0"
          transform="translate(-740.843 -288.992)"
          fill="#00338d"
        />
      </g>
    </g>
  </svg>
);
export default HCIcon;
