import { Avatar, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { CloseIcon } from 'assets/svgs/componentsIcons/index';
import { useGetUserAvatarByIdQuery } from 'services/apis/user';
import { TAB_FILTER_NAME } from '../constants';

function ProjectsSettingsProjectMembersItem({
  userProject,
  onDelete,
  classname,
  isHidden,
  onClick,
  isLinked = false,
  isProjectShown,
  email = '',
  hideRole = false,
  hideDelete = false,
}) {
  const { data: avatar } = useGetUserAvatarByIdQuery(userProject?.user?.id);
  return (
    <Stack className={`member-item ${classname}`}>
      <Avatar
        alt={userProject?.user?.fullName}
        src={avatar ? URL.createObjectURL(avatar) : null}
        onClick={() => onClick({ ...userProject?.user, role: userProject?.role })}
      />
      <Stack className="member-item-info">
        <Typography variant="subtitle1_HelveticaNeue_Medium" color="text.main">
          {userProject?.user?.fullName}
        </Typography>
        <Typography
          variant="subtitle2_HelveticaNeue_Bold"
          color="primary.main"
          className={isHidden ? 'hide' : ''}
        >
          {userProject?.user?.mail}
        </Typography>
        <Typography
          sx={{
            opacity: 0.4,
          }}
          variant="subtitle2_HelveticaNeue_Regular"
          color="text.gray"
          className={hideRole ? 'hide' : ''}
        >
          {userProject?.role?.label}
        </Typography>
        <Typography
          variant="subtitle2_HelveticaNeue_Bold"
          color="primary.main"
          className={isHidden ? 'hide' : ''}
        >
          {TAB_FILTER_NAME[userProject?.role?.category]?.label}
        </Typography>
        <Typography
          variant={
            isLinked ? 'subtitle2_HelveticaNeue_Regular' : 'subtitle2_HelveticaNeue_Bold'
          }
          color={isLinked ? 'theme.palette.text.gray' : 'primary.main'}
          className={isProjectShown ? '' : 'hide'}
        >
          {userProject?.user?.projectName}
        </Typography>
        <Typography
          variant="subtitle2_HelveticaNeue_Regular"
          color="text.main"
          className={email.length !== 0 ? '' : 'hide'}
        >
          {email}
        </Typography>
        <Stack
          onClick={() => {
            if (!hideRole) {
              const newProfile = { ...userProject?.profiles[0], deleted: true };
              onDelete({ ...userProject, profiles: [newProfile] });
            } else {
              onDelete({});
            }
          }}
          className={`close-button ${isHidden || hideDelete ? 'hide' : ''} `}
        >
          <CloseIcon />
        </Stack>
      </Stack>
    </Stack>
  );
}
ProjectsSettingsProjectMembersItem.propTypes = {
  userProject: PropTypes.shape({
    label: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.number,
      fullName: PropTypes.string,
      projectName: PropTypes.string,
      mail: PropTypes.string,
    }),
    role: PropTypes.shape({
      label: PropTypes.string,
      category: PropTypes.string,
    }),
    profiles: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
  classname: PropTypes.string,
  isHidden: PropTypes.bool,
  isLinked: PropTypes.bool,
  isProjectShown: PropTypes.bool,
  email: PropTypes.string,
  hideRole: PropTypes.bool,
  hideDelete: PropTypes.bool,
};

export default ProjectsSettingsProjectMembersItem;
