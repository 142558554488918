import { Fonts } from './fonts';
import AppColors from './colors/app-colors';
import { AppShadows } from './colors/app-shadows';
import { GradientColors } from './colors/gradient-color';
import GreyColors from './colors/grey-colors';

const AppTheme = {
  Fonts,
  AppColors,
  AppShadows,
  GradientColors,
  GreyColors,
};
export default AppTheme;
