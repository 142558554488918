import {api} from '.';

const projectBaseUrl = '/projects';

export const projectApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllProjects: builder.query<
      any,
      { projectType?: string; page?: number; size?: number }
    >({
      query: (params) => ({
        url: `${projectBaseUrl}`,
        params,
      }),
    }),
    getProjectById: builder.query<any, number>({
      query: (idProject) => ({
        url: `${projectBaseUrl}/${idProject}`,
      }),
    }),
    getProjectByWorkLocation: builder.query<any, number>({
      query: (idProject) => ({
        url: `${projectBaseUrl}/${idProject}/project-work-location`,
      }),
    }),
    saveProjectAsDraft: builder.mutation<number, any>({
      query: (body) => ({
        url: `${projectBaseUrl}/save-as-draft`,
        method: 'POST',
        body,
      }),
    }),
    createUpdateProject: builder.mutation<number, any>({
      query: (body) => ({
        url: `${projectBaseUrl}/create-update`,
        method: 'POST',
        body,
      }),
    }),
    updateProjectCreationStatus: builder.mutation<
      any,
      { projectId: number; projectCreationStatus: string }
    >({
      query: (params) => ({
        url: `${projectBaseUrl}/update-creation-status`,
        method: 'POST',
        params,
      }),
    }),
    deleteProjectById: builder.mutation<any, number>({
      query: (idProject) => ({
        url: `${projectBaseUrl}/${idProject}`,
        method: 'DELETE',
      }),
    }),
    getProjectWorkDimensionById: builder.query<any, number>({
      query: (idProject) => ({
        url: `${projectBaseUrl}/${idProject}/project-work-dimension`,
      }),
    }),
    getProjectCreationStatusByIdProject: builder.query<string, number>({
      query: (idProject) => ({
        url: `${projectBaseUrl}/${idProject}/project-creation-status`,
      }),
    }),
    getAllSimplifiedProjects: builder.query<any, any>({
      query: () => ({
        url: `${projectBaseUrl}/all`,
      }),
    }),
  }),
});

export const {
  useGetAllProjectsQuery,
  useLazyGetAllProjectsQuery,
  useDeleteProjectByIdMutation,
  useUpdateProjectCreationStatusMutation,
  useCreateUpdateProjectMutation,
  useSaveProjectAsDraftMutation,
  useGetProjectByIdQuery,
  useGetAllSimplifiedProjectsQuery,
  useLazyGetProjectByIdQuery,
  useLazyGetProjectByWorkLocationQuery,
  useLazyGetProjectWorkDimensionByIdQuery,
  useLazyGetProjectCreationStatusByIdProjectQuery,
} = projectApi;
