import { styled } from '@mui/material';

const AppWrapper = styled('div')`
  background: ${(props) => props.theme.AppColors.appBackground};
  display: flex;
  height: 100vh;
  width: 100%;

  .close {
    width: 10px;
    height: 10px;
  }
`;

export default AppWrapper;
