import { styled } from "@mui/material";

const AsideMenu = styled('div')`
  border-right: 1px solid ${(props) => props.theme.GreyColors[1000]};
  width: 300px;
  padding: 48px 38px 34px 30px;
  display: flex;
  flex-direction: column;
  gap: 35px;
  overflow: auto;
  .logo-container {
    display: flex;
  }
  .navigation-container {
    flex-grow: 1;
    flex-basis: 0;
  }
`;

export default AsideMenu;
