import React from "react";

const SiteOpeningIcon = () => (
  <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 23" width="20" height="23">
    <title>Siteopeningicon</title>
    <defs>
      <image width="20" height="23" id="img16"
             href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAXCAMAAAA8w5+RAAAAAXNSR0IB2cksfwAAAGNQTFRFAAAAAID/AHz/AHz/AH3/AHz/AHz/AHz/AHz/AHz/AHz/AID/AH3/AHz/AH3/AHz/AHv/AH3/AHr/AHz/AHz/AH3/AHz/AHz/AHz/AHz/AHz/AH3/AHz/AHv/AHz/AHz/AHv/gyIsAgAAACF0Uk5TABC//4/PoEBQ0IAgP8Cw73BgMJBri3N/b47ltuqX8OCfHrn//AAAALJJREFUeJxlkNkWgyAMBQNRwdCgpYtt7fb/X1mCKFrn8MIcbhYAAJTGhQomVI2NyVg02bUtgTsIDiYZnWICn8IMiF2swFjrjmAG+2N8jA0RqSJNPsIU91vpUnO3SBXb7uIUc4G0cCoyDmIsCbbITufCq3iIT+B8Ea5FDsMN7o9xHJ+vEn/zPp4ahfQhYTvS/0ayzN/wHz2s+GLaNDSMLc94C/k3Sm8oV95UqDCIDJ7X9PADRLQMKvDMw5QAAAAASUVORK5CYII=" />
    </defs>
    <style>
    </style>
    <use id="Background" href="#img16" x="0" y="0" />
  </svg>
);
export default SiteOpeningIcon;
