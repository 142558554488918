import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  Stack,
  Typography,
} from '@mui/material';
import {
  CloseIcon,
  FileDownload,
  FileExport,
  FileUploadSvg,
} from 'assets/svgs/componentsIcons';
import FileUploadWrapper from './Wrapper';
import { FILE_MAX_SIZE_ERROR } from './constants';

function FileUpload({
  onChoose,
  acceptedExtensions,
  maxSize,
  error,
  label,
  uploadedFiles = [],
  updateFiles,
  handleFileDelete,
  supportsList = [],
  isDownload = false,
  multiFiles = true,
  handleDownloadFile,
  disabled,
}) {
  const [errorMessage, setErrorMessage] = useState();
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (uploadedFiles) {
      if (uploadedFiles[0]?.size > maxSize) {
        setShowError(true);
        setErrorMessage(FILE_MAX_SIZE_ERROR);
      } else {
        setShowError(false);
      }
    }
  }, [uploadedFiles]);
  const handleShowHideError = () => {
    setShowError(!showError);
  };
  const onDropAccepted = (acceptedFiles) => {
    setErrorMessage(null);
    onChoose(acceptedFiles);
  };

  const onDropRejected = useCallback((rejectedFiles) => {
    setErrorMessage(rejectedFiles[0]?.errors?.[0]?.message);
    handleShowHideError();
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedExtensions,
    multiple: multiFiles,
    onDropRejected,
    onDropAccepted,
  });

  const files = uploadedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const getFiles = () => {
    if (errorMessage) {
      return <FormHelperText className="errorMsg">{errorMessage}</FormHelperText>;
    }
    if (updateFiles) {
      return [<li key={updateFiles}>{updateFiles}</li>];
    }
    return files;
  };

  return (
    <FileUploadWrapper className="fileUpload fomInput__wrapper">
      <FormControl className="inputs__label" variant="standard" error={error}>
        <InputLabel shrink htmlFor={label}>
          <Typography color="text.gray" variant="h6_HelveticaNeue_Medium">
            {label}
          </Typography>
        </InputLabel>
      </FormControl>

      {isDownload ? (
        <Stack className="UploadedFileWrapper">
          <Stack className="fileInfos">
            <FileDownload id="FileDownload" />
            <Typography
              className="ml-20"
              color="text.electric"
              variant="subtitle1_HelveticaNeue_Bold"
            >
              {getFiles()}
            </Typography>
            {handleFileDelete && (
              <Stack direction="row" className="ml-auto">
                <Stack className="pointer ml-20" onClick={handleFileDelete}>
                  <CloseIcon />
                </Stack>
              </Stack>
            )}
          </Stack>
          {handleDownloadFile && (
            <Button
              className="exportFile"
              startIcon={<FileExport id="FileExport" />}
              onClick={handleDownloadFile}
            />
          )}
        </Stack>
      ) : (
        <FormControl
          className={`fullWidth mr-15 ${error && 'upload__zone--error'}`}
          variant="standard"
          error={error}
          {...getRootProps()}
          disabled={disabled}
        >
          <Button variant="outlined" startIcon={<FileUploadSvg id="FileUploadSvg" />}>
            <Stack className="upload_message import">
              <Typography color="text.main" variant="subtitle2_HelveticaNeue_Bold">
                Drag and drop Upload
              </Typography>
              <Typography
                color="secondary.main"
                variant="subtitle2_HelveticaNeue_Regular"
              >
                or browse
              </Typography>
              <Typography
                color="text.secondary"
                variant="subtitle2_HelveticaNeue_Regular"
              >
                supports:
                {supportsList.map((fileType) => fileType.toUpperCase()).join(', ')}
              </Typography>
              <Typography
                color="text.secondary"
                variant="subtitle2_HelveticaNeue_Regular"
              >
                Max Size: 5MB
              </Typography>
              <input hidden {...getInputProps()} />
            </Stack>
          </Button>
        </FormControl>
      )}
    </FileUploadWrapper>
  );
}

FileUpload.propTypes = {
  onChoose: PropTypes.func,
  label: PropTypes.string,
  acceptedExtensions: PropTypes.shape({ type: PropTypes.string }),
  maxSize: PropTypes.number,
  error: PropTypes.bool,
  multiFiles: PropTypes.bool,
  isDownload: PropTypes.bool,
  uploadedFiles: PropTypes.arrayOf(PropTypes.string),
  supportsList: PropTypes.arrayOf(PropTypes.string),
  updateFiles: PropTypes.string,
  handleFileDelete: PropTypes.func,
  handleDownloadFile: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default FileUpload;
