import { Stack, Typography } from '@mui/material';

interface FullLoaderProps {
  title?: string;
}

const FullPageLoader = function ({ title }: FullLoaderProps): any {
  return (
    <Stack className="loaderContainer">
      <div className="loader" />
      {title ?? (
        <Typography variant="h4" sx={{ mt: 1 }}>
          {title}
        </Typography>
      )}
    </Stack>
  );
};

export default FullPageLoader;
