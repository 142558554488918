import React from 'react';
import { AppButton } from 'components/Buttons';
import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { BackChevronBtnSvg } from 'assets/svgs/componentsIcons';

function BackToBtn({ handleClick }) {
  return (
    <Stack className="settings--btn flex__row--center">
      <AppButton
        customClass="settings"
        onClick={handleClick}
        startIcon={<BackChevronBtnSvg />}
      />
    </Stack>
  );
}

BackToBtn.propTypes = {
  handleClick: PropTypes.func,
};
export default BackToBtn;
