import React, { useEffect, useMemo, useRef, useState } from 'react';
import { LayersControl, MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { FormControl, InputLabel, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import 'leaflet/dist/leaflet.css';
import 'leaflet-easybutton/src/easy-button.css';
import 'font-awesome/css/font-awesome.min.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

const { BaseLayer } = LayersControl;

function AppMap({ title, location, handleSetLocation }) {
  /* const [map, setMap] = useState(null); */

  /*  useEffect(() => {
      if (!map) return;
      L.easyButton('fa-map-marker', () => {
        map.locate().on('locationfound', (e) => {
          setPosition(e.latlng);
          map.flyTo(e.latlng, map.getZoom());
        });
      }).addTo(map);
    }, [map]); */
  // TODO : to customize
  return (
    <Stack className="localisationContent">
      <FormControl className="inputs__label" variant="standard">
        <InputLabel shrink htmlFor={title}>
          <Typography color="text.secondary" variant="h5_HelveticaNeue_Regular">
            {title}
          </Typography>
        </InputLabel>

        <Stack className="map">
          <MapContainer
            center={[location.latitude || 33.5731104, location.longitude || -7.5898434]}
            zoom={10}
            style={{ height: '100vh' }}
            /* whenCreated={setMap} */
          >
            <LayersControl>
              <BaseLayer checked name="OpenStreetMap">
                <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}" />
                <DraggableMarker
                  center={{
                    lat: location.latitude || 33.5731104,
                    lng: location.longitude || -7.5898434,
                  }}
                  handleSetLocation={(l) => handleSetLocation(l)}
                />
              </BaseLayer>
            </LayersControl>
          </MapContainer>
        </Stack>
      </FormControl>
    </Stack>
  );
}

function DraggableMarker(props) {
  const DefaultIcon = window.L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
  });
  const { center, handleSetLocation } = props;
  const [position, setPosition] = useState(center);

  useEffect(() => {
    setPosition(center);
  }, [center]);

  const markerRef = useRef(null);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          handleSetLocation(marker.getLatLng());
        }
      },
    }),
    [],
  );

  return (
    <Marker
      eventHandlers={eventHandlers}
      icon={DefaultIcon}
      position={position}
      ref={markerRef}
      draggable
    >
      <Popup closeButton={false}>Move to change location</Popup>
    </Marker>
  );
}

AppMap.propTypes = {
  title: PropTypes.string,
  location: PropTypes.object,
  handleSetLocation: PropTypes.func,
};
DraggableMarker.propTypes = {
  center: PropTypes.object,
  handleSetLocation: PropTypes.func,
};
export default AppMap;
