import { Stack, styled } from '@mui/material';

const ProjectsSettingsProjectMembersWrapper = styled(Stack)(({ theme }) => ({
  flex: '1 1 80%',
  padding: '48px 56px 34px 56px',
  overflowY: 'auto',
  '& .top-header': {
    gap: '8px',

    '& .heading-area': {
      flexDirection: 'row',
      alignItems: 'center',
      gap: '12px',
    },
  },
  '& .footer': {
    position: 'fixed',
    bottom: '0',
    right: '0',
    backgroundColor: theme.palette.appBackground.main,
    width: 'calc(100% - 20%)',
    boxShadow: '0px -1px 24px #0000000F',
    zIndex: '1050',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    minHeight: '65px',
    paddingInline: '50px',
    gap: '15px',
  },

  '& .projectMembersContainer': {
    paddingBlock: '30px 60px',
    '& .form-search-area-container': {
      '& .form-search-area': {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '30px',
      },
      '& .divider': {
        marginBlock: '30px',
        borderColor: '#DBE6FF',
      },
      '& .flex__row--center-space': {
        gap: '30px',
        '& > div': {
          flex: 1,
        },
      },
      '& .flex_wrap': {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '35px',
      },
      '& .formWidth': {
        width: '30%',
      },
      '& .associate_size': {
        height: '33px',
      },
      '& .navigations-tabs': {
        width: '75%',
        background: theme.palette.appBackground.BlueAlice,
        margin: 'auto',
        borderRadius: '8px',
        overflow: 'hidden',
        marginBottom: '20px',
        '& > .navigations-tabs-item': {
          flex: 1,
          alignItems: 'center',
          padding: '10px',
          cursor: 'pointer',
          border: '1px solid #DBE6FF',
          '&.active': {
            background: theme.palette.primary.main,
            '& .MuiTypography-root': {
              color: theme.palette.light.main,
            },
          },
        },
      },
      '& .list__members-container': {
        '& .list__members-heading': {
          marginBottom: '20px',
        },
        '& .list__members': {
          flexFlow: 'row wrap',
          gap: '15px',

          '& .member-item': {
            position: 'relative',
            flexDirection: 'row',
            alignItems: 'center',
            width: 'calc(30% - 15px)',
            background: theme.palette.light.main,
            border: '1px solid #DBE6FF',
            borderRadius: '8px',
            padding: '15px',
            gap: '20px',
            '& .member-item-info': {
              gap: '5px',
            },
            '& .close-button': {
              position: 'absolute',
              top: '15px',
              right: '15px',
              cursor: 'pointer',
            },
          },
        },
      },
      '.searchedUser': {
        position: 'relative',
        flexDirection: 'row',
        alignItems: 'center',
        width: 'calc(100% - 30px)',
        background: theme.palette.light.main,
        border: '1px solid #DBE6FF',
        borderRadius: '8px',
        padding: '15px',
        gap: '20px',
        '& .member-item-info': {
          gap: '5px',
        },
        '& .close-button': {
          position: 'absolute',
          top: '15px',
          right: '15px',
          cursor: 'pointer',
        },
      },
    },
  },

  [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},

  [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {},
}));
export default ProjectsSettingsProjectMembersWrapper;

export const ProfileLinksWrapper = styled(Stack)(({ theme }) => ({
  width: '744px',
  height: '343px',
  background: theme.palette.appBackground.main,
  alignItems: 'center',
  borderRadius: '8px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '90%',
  boxShadow: '0 1px 5px #0000000f',
  padding: '32px',
  gap: '15px',
  '& .profileLinksHeader': {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  '& .profileLinksContainer': {
    width: '655px',
    height: '230px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    overflowY: 'auto',
    padding: '5px',
    gap: '10px',
  },
  '& .profileLinksItem': {
    display: 'flex',
    gap: '10px',
    width: '190px',
    height: '42px',
    flexDirection: 'row',
    alignItems: 'center',
    background: theme.palette.common.white,
    boxShadow: '0px 1px 5px #0000000F',
    borderRadius: '8px',
    padding: '10px',
  },
  '& .profileLinksButton': {
    background: theme.palette.common.white,
  },

  [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},

  [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {},
}));
