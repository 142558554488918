import {styled} from "@mui/material";

export const BackBtn = styled("button")(
  ({theme}) => ({

    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '35px',
    height: '35px',
    borderRadius: '8px',
    backgroundColor: theme.palette.appBackground.BlueAlice,
    color: theme.palette.primary.main,
    border: '1px solid #DBE6FF',
    cursor: 'pointer',


    [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},

    [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},

    [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},

    [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},

    [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},

    [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {},

  })
)

