import { styled } from '@mui/material';
import PropTypes from 'prop-types';

const CustomModal = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: ${(props) => props.theme.palette.common.white};
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${(props) => (props.modalWidth ? props.modalWidth : 'auto')};
  max-width: 90%;
  box-shadow: 0 1px 5px ${(props) => props.theme.palette.shadow.main};
  padding: 32px;

  &.bg-grey {
    background-color: #f6f7fb;
  }

  .donwloadLoaderContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 5px;

    .donwloadLoader {
      width: 30px;
      height: 30px;
      animation: spin 0.9s linear infinite;

      img {
        width: inherit;
        height: inherit;
      }
    }

    p {
      color: ${(props) => props.theme.palette.primary.main};
    }
  }

  .modal-header {
    background: ${(props) => props.theme.palette.common.white};
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 50px);
    min-height: 30px;
    padding: 15px 25px;
    border-radius: 8px 8px 0 0;

    .modal-title {
      color: ${(props) => props.theme.palette.primary.main};
    }

    .icon {
      width: 35px;
      height: 35px;

      & > img {
        width: inherit;
        height: inherit;
      }
    }
  }

  .modal-head-wrapper {
    display: flex;
    width: 100%;
    padding-bottom: 15px;

    .modal-head {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-basis: 0;

      .modal-title {
        font-family: 'HelveticaNeueMedium', sans-serif;
        font-size: 18px;
        color: ${(props) => props.theme.palette.primary.main};

        &.dark {
          color: ${(props) => props.theme.palette.text.darker};
        }
      }

      .modal-description {
        margin-top: 5px;
        font-family: 'HelveticaNeueRegular', sans-serif;
        font-size: 14px;
        color: ${(props) => props.theme.palette.text.gray};
      }
    }
  }

  .modal-action-wrapper {
    display: flex;
    gap: 12px;

    & > button.download {
      flex-grow: 0.5;
      margin-right: auto;

      &.disable {
        background: #ced4da;
        pointer-events: none;
        color: #3c485f;
      }
    }

    & > button:not(.download) {
      flex-grow: 1;
      flex-basis: 0;
    }

    .dltBtn {
      background-color: ${(props) => props.theme.palette.danger.secondary};
    }
  }

  .editPopupActions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    button {
      width: 170px;
      flex-basis: unset !important;
      flex-grow: unset !important;
    }
  }

  .addProfileStyle {
    padding-right: 5px;
    overflow: auto;
    min-height: 30vh;
    max-height: 45vh;
  }

  .notificationAddressContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 15px;

    & > .fomInput__wrapper {
      width: 100%;
    }
  }

  .adresseItemContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
  }

  .adresseItem {
    background-color: ${(props) => props.theme.palette.primary.blue};
    padding: 5px 15px;
    border-radius: 10px;
    width: max-content;
    color: ${(props) => props.theme.palette.common.white};
    display: flex;
    align-items: center;

    & > button {
      all: unset;
      font-size: 18px;
      margin-left: 15px;
      cursor: pointer;
    }
  }

  .saveContent {
    .choicesItems {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      width: 80%;
      margin: 30px auto;

      & > .choicesItem {
        height: 150px;
        width: 150px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid ${(props) => props.theme.palette.border.main};
        border-radius: 8px;
        cursor: pointer;

        &.active {
          border: 1px solid ${(props) => props.theme.palette.primary.main};

          svg {
            fill: ${(props) => props.theme.palette.primary.main};
            stroke: ${(props) => props.theme.palette.background.white};
          }

          .forLater {
            stroke: ${(props) => props.theme.palette.primary.main};
            fill: ${(props) => props.theme.palette.common.white};
          }

          & > .checkbox {
            width: 14px;
            height: 14px;
            border: 2px solid ${(props) => props.theme.palette.primary.main};

            & > .dot {
              width: 10px;
              height: 10px;
              border-radius: 50%;
              border: 1px solid ${(props) => props.theme.palette.common.white};
              background: ${(props) => props.theme.palette.primary.main};
              display: block;
            }
          }
        }

        svg {
          fill: ${(props) => props.theme.palette.border.main};
          stroke: ${(props) => props.theme.palette.background.white};
        }

        .forLater {
          stroke: ${(props) => props.theme.palette.border.main};
          fill: ${(props) => props.theme.palette.common.white};
        }

        & > .checkbox {
          position: absolute;
          background: transparent;
          border: 1px solid ${(props) => props.theme.palette.border.main};
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          top: 10px;
          left: 10px;

          & > .dot {
            display: none;
          }
        }
      }
    }

    .selectMember {
      margin: 15px auto 0;
      width: 75%;
    }
  }
`;
CustomModal.propTypes = {
  modalWidth: PropTypes.string,
};
export default CustomModal;
