import React from 'react';

function BackChevronBtnSvg() {
  return (
    <svg
      id="Component_12_1"
      data-name="Component 12 – 1"
      width="51.729"
      height="51.729"
      viewBox="0 0 51.729 51.729"
    >
      <path
        id="Subtraction_67"
        data-name="Subtraction 67"
        d="M-5796.557-442.407h-21.131c-9.159,0-15.312-5.894-15.312-14.665V-479.5c0-8.757,6.154-14.64,15.313-14.64h21.131c9.143,0,15.286,5.884,15.286,14.64v22.424C-5781.271-448.3-5787.414-442.407-5796.557-442.407Zm-11.148-35.7a1.629,1.629,0,0,0-1.157.479l-8.214,8.18a1.7,1.7,0,0,0-.163.182.788.788,0,0,0-.081.119c-.015.024-.029.047-.045.07-.029.053-.053.1-.073.149a.225.225,0,0,1-.018.04,1.177,1.177,0,0,0-.054.158l-.009.033-.018.068a1.613,1.613,0,0,0-.04.357c0,.049,0,.091.006.132,0,.019,0,.037.005.055s0,.031,0,.047a.891.891,0,0,0,.025.126c0,.014.006.028.01.042l.013.057a1.211,1.211,0,0,0,.057.158.212.212,0,0,1,.015.041c.029.061.052.106.076.146l.012.02c.011.018.021.035.031.053a1.5,1.5,0,0,0,.229.283l8.23,8.2a1.631,1.631,0,0,0,1.158.479,1.622,1.622,0,0,0,1.161-.483,1.632,1.632,0,0,0,.479-1.164,1.619,1.619,0,0,0-.483-1.153l-5.415-5.394h13.9a1.642,1.642,0,0,0,1.639-1.641,1.641,1.641,0,0,0-1.639-1.64h-13.9l5.415-5.394a1.614,1.614,0,0,0,.483-1.149,1.636,1.636,0,0,0-.479-1.169A1.625,1.625,0,0,0-5807.705-478.107Z"
        transform="translate(5833 494.136)"
        fill="#fff"
      />
    </svg>
  );
}

export default BackChevronBtnSvg;
