import {Stack, styled} from "@mui/material";
import digitalSpaceBg from 'assets/svgs/digitalSpaceBg.svg';

export const AppLandingPageDigitalSpaceWrapper = styled(Stack)(
  ({theme}) => ({

    position: 'relative',
    width: '100%',
    height: '100%',

    '&:before': {
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      content: "''",
      position: 'absolute',
      backgroundImage: `url("${digitalSpaceBg}")`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      pointerEvents: 'none',
      zIndex: '-1',
    },

    '& .connected__user--avatar': {
      position: 'absolute',
      top: '3%',
      right: '3%',
      '& .MuiAvatar-root': {
        width: '50px',
        height: '50px',
      },
    },

    '& .logo': {
      position: 'absolute',
      top: '3%',
      left: '3%',
      '& img': {
        width: '100px',
        height: '62px',
      },
    },
    '& .active__item': {
      width: '400px',
      height: '400px',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      left: '22.7%',
      '& .close__button': {
        padding: '45px',
        cursor: 'pointer',
      },
      '& .logo__item--active': {
        width: '240px',
        gap: '40px',
        marginInline: 'auto',
        alignItems: 'center',
        textAlign: 'center',
        '& svg': {
          transform: 'scale(1.5)',
        },
      },
    },

    '& .logo__item': {
      width: 'max-content',
      alignItems: 'center',
      position: 'absolute',
      cursor: 'pointer',
      gap: '36px',
      '&.jpass_site_opening': {
        top: '14%',
        right: '21.5%',
      },
      '&.jpass_web': {
        top: '44.8%',
        right: '20.5%',
      },
      '&.jpass_mobile': {
        bottom: '11%',
        right: '23%',
      },
      '&.collab_senior_management_view': {
        top: '13.7%',
        right: '18.5%',
      },
      '&.collab_web': {
        top: '44.8%',
        right: '20.7%',
      },
      '&.collab_mobile': {
        bottom: '11%',
        right: '23.2%',
      },
    },


    [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},

    [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},

    [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},

    [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},

    [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},

    [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {

      '& .logo': {
        position: 'absolute',
        top: '3%',
        left: '3%',
        '& img': {
          width: '100px',
          height: '62px',
        },
      },
      '& .active__item': {
        width: '500px',
        height: '500px',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        left: '22.7%',
        '& .close__button': {
          padding: '60px',
        },
        '& .logo__item--active': {
          gap: '60px',
          marginTop: '25px',
          '& svg': {
            transform: 'scale(2)',
          },
        },
      },

      '& .logo__item': {
        gap: '50px',
        cursor: 'pointer',
        '&.jpass_site_opening': {
          top: '17.5%',
          right: '22.5%',
        },
        '&.jpass_web': {
          top: '45.5%',
          right: '21%',
        },
        '&.jpass_mobile': {
          right: '23.6%',
          bottom: '13.5%',
        },
        '&.collab_senior_management_view': {
          top: '15.8%',
          right: '22.5%',
        },
        '&.collab_web': {
          top: '45.5%',
          right: '21.5%',
        },
        '&.collab_mobile': {
          right: '23.8%',
          bottom: '13.5%',
        },
        '& svg': {
          transform: 'scale(1.2)',
        },
      },
    },

  })
)
