import React from 'react';
import {
  CollabLogo,
  CspLogo,
  ExternalLogo1,
  ExternalLogo2,
  ExternalLogo3,
  JpassLogo,
  JpassMobileLogo,
  MaterialManagementLogo,
  MkLogo,
  QaLogo,
  SeniorManagement,
} from 'assets/svgs/componentsIcons';

export const LOGO_ICONS = {
  JPASS: <JpassLogo />,
  COLLAB: <CollabLogo />,
  CSP: <CspLogo />,
  QA: <QaLogo />,
  ACONEX: <MkLogo />,
  TIQAD: <ExternalLogo1 />,
  COLLAB_PROGRAM_OCP: <ExternalLogo2 />,
  COLLAB_MAADEN: <ExternalLogo3 />,
  COLLAB_WEB: <CollabLogo />,
  COLLAB_MOBILE: <CollabLogo />,
  COLLAB_SENIOR_MANAGEMENT_VIEW: <SeniorManagement />,
  JPASS_WEB: <JpassLogo />,
  JPASS_SITE_OPENING: <MaterialManagementLogo />,
  JPASS_MOBILE: <JpassMobileLogo />,
  EXTERNAL_APP: <ExternalLogo1 />,
};
