import React from 'react';

const LinkSvg = () => (
  <svg
    id="ios-link"
    xmlns="http://www.w3.org/2000/svg"
    width="22.034"
    height="22.023"
    viewBox="0 0 22.034 22.023"
  >
    <path
      id="Path_67548"
      data-name="Path 67548"
      d="M16.782,25.612l-.064.005a.876.876,0,0,0-.508.238l-3.42,3.42a3.856,3.856,0,0,1-5.453-5.453l3.632-3.632a3.833,3.833,0,0,1,.6-.492,3.891,3.891,0,0,1,.784-.4,3.707,3.707,0,0,1,.794-.2,3.754,3.754,0,0,1,.54-.037c.074,0,.148.005.244.011a3.845,3.845,0,0,1,2.478,1.112,3.8,3.8,0,0,1,.905,1.445.833.833,0,0,0,1.022.535c.005,0,.011-.005.016-.005s.011,0,.011-.005a.827.827,0,0,0,.556-1.011,4.8,4.8,0,0,0-1.3-2.171,5.573,5.573,0,0,0-3.049-1.551c-.1-.016-.2-.032-.3-.042a5.464,5.464,0,0,0-.588-.032c-.138,0-.275.005-.408.016a5.339,5.339,0,0,0-.858.132c-.058.011-.111.026-.169.042a5.507,5.507,0,0,0-1.032.386,5.438,5.438,0,0,0-1.466,1.054L6.115,22.615A5.584,5.584,0,0,0,4.5,26.56,5.567,5.567,0,0,0,14,30.493l3.457-3.457A.838.838,0,0,0,16.782,25.612Z"
      transform="translate(-4.5 -10.095)"
      fill="#fff"
    />
    <path
      id="Path_67549"
      data-name="Path 67549"
      d="M30.756,6.125a5.579,5.579,0,0,0-7.872,0L19.511,9.5a.851.851,0,0,0,.535,1.451.86.86,0,0,0,.672-.244L24.1,7.338a3.856,3.856,0,0,1,5.453,5.453l-3.632,3.632a3.833,3.833,0,0,1-.6.492,3.891,3.891,0,0,1-.784.4,3.707,3.707,0,0,1-.794.2,3.754,3.754,0,0,1-.54.037c-.074,0-.154-.005-.244-.011a3.8,3.8,0,0,1-3.346-2.462.846.846,0,0,0-1.011-.519.855.855,0,0,0-.6,1.085,4.836,4.836,0,0,0,1.255,1.985l.011.011a5.574,5.574,0,0,0,3.351,1.594,5.464,5.464,0,0,0,.588.032q.206,0,.413-.016a6.053,6.053,0,0,0,1.022-.169,5.507,5.507,0,0,0,1.032-.386,5.438,5.438,0,0,0,1.466-1.054L30.766,14a5.573,5.573,0,0,0-.011-7.878Z"
      transform="translate(-10.358 -4.5)"
      fill="#fff"
    />
  </svg>
);

export default LinkSvg;
