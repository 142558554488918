import {api} from '.';

const userProjectBaseUrl = '/user-projects';

export const userProjectApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUserProjectByProjectIdAndPlatform: builder.query<
      any,
      { projectId: number; platform: number }
    >({
      query: ({projectId, platform}) => ({
        url: `${userProjectBaseUrl}/${projectId}`,
        params: {
          platform,
        },
      }),
    }),
    getUserProjectByProjectIdAndUser: builder.query<
      any,
      { projectId: number; userId: number }
    >({
      query: (params) => ({
        url: `${userProjectBaseUrl}/user`,
        params,
      }),
    }),
    reassignUserToProject: builder.mutation<
      any,
      { projectId: number; body: any; platform: string }
    >({
      query: ({projectId, body, platform}) => ({
        url: `${userProjectBaseUrl}/reassign-user/${projectId}`,
        method: 'POST',
        body,
        params: {
          platform,
        },
      }),
    }),
    delegateProjectCreationToUser: builder.mutation<
      any,
      { params: { projectId: number; userId: number } }
    >({
      query: ({params}) => ({
        url: `${userProjectBaseUrl}/delegate-creation`,
        method: 'POST',
        params,
      }),
    }),
    assignManyProjectsToUser: builder.mutation<any, any>({
      query: (body) => ({
        url: `${userProjectBaseUrl}/assign-projects`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetUserProjectByProjectIdAndPlatformQuery,
  useLazyGetUserProjectByProjectIdAndUserQuery,
  useReassignUserToProjectMutation,
  useDelegateProjectCreationToUserMutation,
  useAssignManyProjectsToUserMutation
} = userProjectApi;
