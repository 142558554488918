import { Divider, FormHelperText, Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import FormInput from 'components/FormInput';
import ActionButton from 'components/ActionButton';
import { PlusIcon } from 'assets/svgs/componentsIcons';
import CardWorkLocation from 'components/CardWorkLocation/CardWorkLocation';
import FormSelect from 'components/FormSelect';
import FileUpload from 'components/FileUpload';
import MultiselectForm from 'components/MultiSelectForm';
import { useGetEnumerationsByTypesQuery } from 'services/apis/enumeration';
import { PROJECT_DATA_ENUM } from 'pages/AppProjectsSettings/ProjectsSettingsProjectCreation/constants';
import {
  addPictureElement,
  fillProjectData,
  resetProjectData,
  setSubmit,
  updateProjectDatas,
} from 'services/slices/project-setting/project-work';
import { FILE_TYPE } from 'components/FileUpload/constants';
import { useEffect } from 'react';
import { useLazyDownloadLogoQuery } from 'services/apis/platform-settings';
import handleBlobReceived from 'utils/blobUtils';
import { DEFAULT_FILE_NAME } from 'utils/constants/messages';
import usePrivilegeByFeature from 'utils/hooks/usePrivilegeByFeature';
import { Features, Privileges } from 'utils/constants/Features';
import {
  addProjectDatasToList,
  checkDuplicated,
  checkFieldNameValidation,
} from '../helper';
import {
  makeSelectProjectData,
  makeSelectProjectDatas,
  makeSelectSubmit,
  makeSelectWorkOrders,
} from '../selectors';
import { NAME_FIELD, PROJECT_WORK_DIMENSIONS_ALL_TABS } from '../constants';
import ProjectWorkLocationWrapper from './Wrapper';

const stateSelector = createStructuredSelector({
  projectDatas: makeSelectProjectDatas,
  projectData: makeSelectProjectData,
  workOrders: makeSelectWorkOrders,
  submit: makeSelectSubmit,
});

function ProjectWorkLocation({ onDelete, errorMessage }) {
  const { projectDatas, projectData, workOrders, submit } = useSelector(stateSelector);
  const dispatch = useDispatch();

  const { projectId } = useParams();
  const { data: enumerations } = useGetEnumerationsByTypesQuery(PROJECT_DATA_ENUM);
  const [doDownloadLogo, { data: picture }] = useLazyDownloadLogoQuery();

  const checkFormValidity = () =>
    projectData?.statusCode &&
    projectData?.name &&
    checkDuplicated(projectDatas, projectData, NAME_FIELD.NAME);

  const addWorkLocation = () => {
    dispatch(setSubmit(true));
    if (checkFormValidity()) {
      const updatedList = addProjectDatasToList(projectDatas, projectData, projectId);
      dispatch(updateProjectDatas(updatedList));
      dispatch(resetProjectData());
      dispatch(setSubmit(false));
    }
  };
  const hasReadPrivilege = usePrivilegeByFeature(
    Features.DS_PROJECT_WORK_LOCATION,
    Privileges.READ,
  );
  const hasWritePrivilege = usePrivilegeByFeature(
    Features.DS_PROJECT_WORK_LOCATION,
    Privileges.WRITE,
  );
  const workLocationList = () => {
    if (projectDatas && Object.keys(projectDatas).length > 0) {
      return projectDatas
        .filter((item) => !item?.isDeleted)
        .map((workLocationItem, index) => (
          <Stack className="mr-15" key={workLocationItem.name}>
            <CardWorkLocation
              workLocation={workLocationItem}
              onDelete={onDelete}
              onclickAction={() => {
                dispatch(
                  fillProjectData({
                    ...workLocationItem,
                    index,
                  }),
                );
              }}
              enumerationsProjectState={enumerations?.PROJECT_STATE}
            />
          </Stack>
        ));
    }
    return null;
  };

  const fillFormAction = (fieldKey, fieldValue) => {
    dispatch(fillProjectData({ [fieldKey]: fieldValue }));
  };

  const handleDownloadFile = (fileUpload) => {
    doDownloadLogo({ url: fileUpload });
  };

  useEffect(() => {
    if (picture) {
      handleBlobReceived(picture, DEFAULT_FILE_NAME);
    }
  }, [picture]);
  return (
    <ProjectWorkLocationWrapper>
      <Stack className="projectCreationForm">
        {hasWritePrivilege && (
          <Stack>
            <Stack className="form-search-area">
              <Typography variant="h6_HelveticaNeue_Bold" color="text.main">
                Add a work location
              </Typography>
              {errorMessage && (
                <FormHelperText className="errorMsg">{errorMessage}</FormHelperText>
              )}
            </Stack>
            <Stack>
              <Stack className="rowForms">
                <Stack className="columnForms">
                  <FormInput
                    label="Name*"
                    placeholder="Work location Name"
                    onChange={(e) => {
                      fillFormAction('name', e.target.value);
                    }}
                    value={projectData?.name}
                    error={checkFieldNameValidation(
                      submit,
                      projectDatas,
                      projectData,
                      PROJECT_WORK_DIMENSIONS_ALL_TABS.WORK_LOCATION.label,
                    )}
                  />
                  <FormSelect
                    menuItems={enumerations?.PROJECT_STATE}
                    onChange={(e) => {
                      fillFormAction('statusCode', e.target.value?.code);
                    }}
                    value={enumerations?.PROJECT_STATE?.find(
                      (state) => state.code === projectData?.statusCode,
                    )}
                    error={submit && !projectData?.statusCode}
                    label="Status * "
                    valueKey="label"
                  />
                </Stack>
                <Stack className="columnForms no-padding-right">
                  <FileUpload
                    label="Picture"
                    maxSize={5000000}
                    acceptedExtensions={{ 'image/*': ['.jpeg', '.jpg', '.png'] }}
                    onChoose={(file) => {
                      fillFormAction('image', `${projectData?.name}-${file[0]?.name}`);
                      if (file[0]?.type) {
                        const blob = new Blob([file[0]], {
                          type: file[0].type,
                        });
                        const url = URL.createObjectURL(blob);
                        fillFormAction('fileUpload', url);
                      }
                      dispatch(
                        addPictureElement({
                          file: file[0],
                          fileName: `${projectData?.name}-${file[0]?.name}`,
                        }),
                      );
                    }}
                    updateFiles={projectData?.image}
                    isDownload={projectData?.image}
                    supportsList={[FILE_TYPE.PNG, FILE_TYPE.JPEG, FILE_TYPE.JPG]}
                    handleDownloadFile={() => handleDownloadFile(projectData?.image)}
                    handleFileDelete={() => {
                      fillFormAction('image', null);
                    }}
                  />
                </Stack>
              </Stack>
              <Stack className="rowForms">
                <Stack className="columnForms">
                  <MultiselectForm
                    options={workOrders?.map((wo) => wo?.number)}
                    label="Work order"
                    value={projectData?.workOrders?.map((wo) => wo?.number)}
                    onChange={(e) => {
                      const { value } = e.target;
                      const result = workOrders?.filter((wo) =>
                        value?.map((item) => item).includes(wo.number),
                      );
                      fillFormAction('workOrders', result);
                    }}
                  />
                </Stack>
                <Stack className=" columnForms no-padding-right">
                  <FormInput
                    label="Location"
                    placeholder="Location"
                    onChange={(e) => {
                      fillFormAction('location', e.target.value);
                    }}
                    value={projectData?.location}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Grid container justifyContent="flex-end" className="mb-25">
              <Grid item>
                <ActionButton
                  classname="footer--next__button btn--reset"
                  label={
                    <Stack className="flex__row--center">
                      <PlusIcon className="mr-15" />
                      Add work location
                    </Stack>
                  }
                  onClick={addWorkLocation}
                />
              </Grid>
            </Grid>
            <Divider
              variant="middle"
              className="mt-25"
              style={{ border: '1px solid #DBE6FF', opacity: 0.8 }}
            />
          </Stack>
        )}
      </Stack>
      <Stack className="form-search-area mb-40">
        <Typography variant="h6_HelveticaNeue_Bold" color="text.main" className="mb-15">
          Work locations
        </Typography>
        {hasReadPrivilege && <Stack className="card-list">{workLocationList()}</Stack>}
      </Stack>
    </ProjectWorkLocationWrapper>
  );
}

export default ProjectWorkLocation;

ProjectWorkLocation.propTypes = {
  onDelete: PropTypes.func,
  errorMessage: PropTypes.string,
};
