import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import ProjectsSettingsProjectMembersItem from 'pages/AppProjectsSettings/ProjectsSettingsProjectMembers/ProjectsSettingsProjectMembersItem';
import ActionButton from 'components/ActionButton';
import { CloseIcon } from 'assets/svgs/componentsIcons';
import { CloseButton } from 'components/Buttons';
import theme from 'ui/theme';
import { ProfileLinksWrapper } from 'pages/AppProjectsSettings/ProjectsSettingsProjectMembers/Wrapper';
import { defaultMessageOfErrorPopup } from './constants';

function ProfileErrorDeleteEditModal({
  isOpen,
  linkedUsers,
  handleProfileModal,
  message,
}) {
  const getFormattedUser = (user) => {
    return {
      user: {
        id: user?.userId,
        fullName: user?.userName,
        projectName: user?.projectName,
      },
    };
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleProfileModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ProfileLinksWrapper>
        <Stack className="profileLinksHeader">
          <Stack>
            <Typography
              variant="h5_HelveticaNeue_Bold"
              color={theme.palette.primary.main}
            >
              {' '}
              Error Message{' '}
            </Typography>
            <Typography
              className="mt-8"
              variant="subtitle1_HelveticaNeue_Regular"
              color={theme.palette.text.gray}
            >
              {message || defaultMessageOfErrorPopup}
            </Typography>
          </Stack>
          <CloseButton onClick={handleProfileModal} type="button">
            <CloseIcon />
          </CloseButton>
        </Stack>
        <Stack className="profileLinksContainer">
          {linkedUsers?.map((user) => (
            <ProjectsSettingsProjectMembersItem
              classname="profileLinksItem"
              isHidden
              isLinked
              userProject={getFormattedUser(user)}
              key={user?.userId}
              isProjectShown
            />
          ))}
        </Stack>
        <Stack>
          <ActionButton
            onClick={handleProfileModal}
            classname="footer--back__button profileLinksButton"
            label="Cancel"
          />
        </Stack>
      </ProfileLinksWrapper>
    </Modal>
  );
}

ProfileErrorDeleteEditModal.propTypes = {
  isOpen: PropTypes.bool,
  linkedUsers: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.number,
      userName: PropTypes.string,
    }),
  ),
  handleProfileModal: PropTypes.func,
  message: PropTypes.string,
};
export default ProfileErrorDeleteEditModal;
