import { combineReducers } from '@reduxjs/toolkit';
import projectCreation from './project-creation';
import projectMembers from './project-members';
import projectWork from './project-work';
import projects from './projects';

export default combineReducers({
  projectCreation,
  projectMembers,
  projectWork,
  projects,
});
