import React from "react";

const ConstructionMachineryIcon = () => (
  <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
    <title>iconConstructionMachinery</title>
    <defs>
      <image width="28" height="28" id="img6"
             href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAAXNSR0IB2cksfwAAAMZQTFRFAAAAAID/AHv/AHv/AHv/AHr/AHz/AHv/AHz/AHv/AHz/AHj/AHz/AHz/AHz/AHz/AHv/AHz/AHv/AHr/AHv/AHv/AHv/AHz/AHz/AH3/AH3/AHr/AHz/AHz/AHv/AHv/AHv/AHv/AHz/AHv/AHv/AHz/AHz/AHz/AHz/AHv/AHv/AHv/AHv/AHv/AHv/AHz/AHv/AHz/AHv/AHv/AHr/AHz/AHv/AHz/AHv/AHv/AHr/AHz/AHr/AHr/AHv/AHv/AHz/AHv/udqRIgAAAEJ0Uk5TABDt5F8w7+zA4H8gUJCA/59AcGCg8M9MYz9WgMPhvv/T3Nb3/b97xGnisPHn+e+GwdCX+tvz1/fQ2IhvZPTMj6/UfSOURAAAATRJREFUeJyNkm1bgjAYhR9KxhDdcbDN7I2oyLRXK83Usv7/n2oY2VC5rs4HPnDDfbY9Iyrj7e03qCY+C3jImtEu1mJtLqjF0ZFbLEZCFhJFSpsNu9Ldg57pHB75tjuu2L3jk1OkZ9n5xSVg8mbjqv9r9641Bm0f0jNZn2RLDDlwc3t3/2A1evBon4SuGXV56Ypk/JQ9p4zwI8DLyJO8shIJSseMTfIcGLPxa77OxOQGhCkPQyHSKX9DINYJuRBxoRVWB+mzmaNd7Zow73UKuGCJ3IIaQJtsdULbsExqv0FQB2Op3j9qIGSiF3VazLTvdipV6Zz35o5WwhmsPYSRsyCPEu12rqZSJlkdyx90p0KRXn6Gzp/Vqcihcu5acQdMRruTKiHUVw0s6jeG/U+obb8/3Hj5DZdVGz797LtdAAAAAElFTkSuQmCC" />
    </defs>
    <style>
    </style>
    <use id="Background" href="#img6" x="0" y="0" />
  </svg>
);
export default ConstructionMachineryIcon;
