import React from 'react';

const ValidateIcon: React.FC<any> = function (props: any) {
  const { color = '#5f6a7e' } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="9.42"
      viewBox="0 0 13 9.42"
      {...props}
    >
      <path
        id="Combined-Shape"
        d="M18.359,7.209a1.13,1.13,0,0,1,0,1.6L11.2,15.966a1.129,1.129,0,0,1-1.6,0L6.021,12.387a1.13,1.13,0,0,1,1.6-1.6L10.4,13.566,16.76,7.209A1.13,1.13,0,0,1,18.359,7.209Z"
        transform="translate(-5.69 -6.877)"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};
export default ValidateIcon;
