import React from 'react';

const MaterialManagementLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="39.216"
    height="39.9"
    viewBox="0 0 39.216 39.9"
  >
    <path
      id="Path_67232"
      data-name="Path 67232"
      d="M1.425,39.9a1.367,1.367,0,0,1-1-.428,1.367,1.367,0,0,1-.428-1V1.425A1.341,1.341,0,0,1,.428.4a1.416,1.416,0,0,1,1-.4H7.581A2.089,2.089,0,0,1,9.576,1.254L19.608,19.209,29.64,1.254a2.089,2.089,0,0,1,2-1.254h6.1a1.487,1.487,0,0,1,1.055.4,1.341,1.341,0,0,1,.428,1.026v37.05a1.341,1.341,0,0,1-.428,1.026,1.487,1.487,0,0,1-1.055.4H30.951a1.487,1.487,0,0,1-1.054-.4,1.341,1.341,0,0,1-.428-1.026V17.1L23.085,29.013a2.234,2.234,0,0,1-1.995,1.311H18.126a1.945,1.945,0,0,1-1.2-.342,2.951,2.951,0,0,1-.8-.969L9.69,17.1V38.475a1.416,1.416,0,0,1-.4,1,1.341,1.341,0,0,1-1.026.428Z"
      fill="#007cff"
    />
  </svg>
);

export default MaterialManagementLogo;
