import React from 'react';
import {
  Autocomplete,
  Checkbox,
  FormControl,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import PropTypes from 'prop-types';
import ChevronDownSvg from 'assets/svgs/ChevronDownSvg';
import { TruncateText } from 'utils/TextUtils';
import { REQUIRED_ERROR } from 'utils/constants/messages';
import AutoCompleteInputWrapper from './Wrapper';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function AutocompleteInput({
  label,
  placeholder,
  options = [],
  handleOnChange = (obj) => obj,
  handleOnInputChange = (obj) => obj,
  isCleared = false,
  values = [],
  error,
  inputValue,
  valueKey = 'label',
  isWithCheckbox = true,
  withChevronDown = true,
  disabled = false,
  className = '',
}) {
  return (
    <AutoCompleteInputWrapper error={error} className={className}>
      <FormControl className="inputs__label" size="small">
        <InputLabel className="select__label" shrink>
          <Typography color="text.secondary" variant="h5_HelveticaNeue_Regular">
            {label}
          </Typography>
        </InputLabel>
        <Autocomplete
          multiple
          id="checkboxes-tags-demo"
          options={options}
          // disableCloseOnSelect
          popupIcon={withChevronDown && <ChevronDownSvg />}
          onInputChange={handleOnInputChange}
          inputValue={inputValue}
          getOptionLabel={(option) => option[valueKey]}
          filterOptions={(currentOptions) => currentOptions}
          renderOption={(props, option, { selected }) => {
            return (
              <li {...props}>
                {isWithCheckbox && (
                  <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                )}
                {option[valueKey]}
              </li>
            );
          }}
          onChange={handleOnChange}
          renderInput={(params) => (
            <TextField
              className="errorMsg mb-0"
              helperText={error && REQUIRED_ERROR}
              {...params}
              placeholder={values?.length > 0 ? '' : placeholder}
              error={error}
            />
          )}
          renderTags={(tags) =>
            TruncateText(
              tags
                .filter((tag) => tag != null)
                .map((val) => val.label)
                .join(', '),
              20,
            )
          }
          value={values || (isCleared && [])}
          disabled={disabled}
        />
      </FormControl>
    </AutoCompleteInputWrapper>
  );
}

AutocompleteInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  values: PropTypes.arrayOf(PropTypes.shape({})),
  handleOnChange: PropTypes.func,
  handleOnInputChange: PropTypes.func,
  isCleared: PropTypes.bool,
  error: PropTypes.bool,
  inputValue: PropTypes.string,
  valueKey: PropTypes.string,
  isWithCheckbox: PropTypes.bool,
  withChevronDown: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default AutocompleteInput;
