import React from 'react';

const ProductSettingIcon = (props) => (
  <svg
    data-name="Group 64000"
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    {...props}
  >
    <path data-name="Path 67262" d="M0 0h20v20H0Z" fill="none" />
    <path
      data-name="Rectangle 17369"
      d="M4.166 2.708H7.5a1.46 1.46 0 0 1 1.458 1.458V7.5A1.46 1.46 0 0 1 7.5 8.958H4.166A1.46 1.46 0 0 1 2.708 7.5V4.166a1.46 1.46 0 0 1 1.458-1.458Zm3.333 5a.209.209 0 0 0 .208-.208V4.166a.209.209 0 0 0-.207-.208H4.166a.209.209 0 0 0-.208.208V7.5a.209.209 0 0 0 .208.208ZM12.5 2.708h3.334a1.46 1.46 0 0 1 1.458 1.458V7.5a1.46 1.46 0 0 1-1.458 1.458H12.5A1.46 1.46 0 0 1 11.042 7.5V4.166A1.46 1.46 0 0 1 12.5 2.708Zm3.333 5a.209.209 0 0 0 .208-.208V4.166a.209.209 0 0 0-.207-.208H12.5a.209.209 0 0 0-.208.208V7.5a.209.209 0 0 0 .208.208Z"
      fill="currentColor"
    />
    <path
      data-name="Rectangle 17370"
      d="M4.166 11.042H7.5A1.46 1.46 0 0 1 8.958 12.5v3.334A1.46 1.46 0 0 1 7.5 17.292H4.166a1.46 1.46 0 0 1-1.458-1.458V12.5a1.46 1.46 0 0 1 1.458-1.458Zm3.333 5a.209.209 0 0 0 .208-.208V12.5a.209.209 0 0 0-.207-.208H4.166a.209.209 0 0 0-.208.208v3.334a.209.209 0 0 0 .208.208Z"
      fill="currentColor"
    />
    <path
      data-name="Rectangle 17371"
      d="M12.5 11.042h3.334a1.46 1.46 0 0 1 1.458 1.458v3.334a1.46 1.46 0 0 1-1.458 1.458H12.5a1.46 1.46 0 0 1-1.458-1.458V12.5a1.46 1.46 0 0 1 1.458-1.458Zm3.333 5a.209.209 0 0 0 .208-.208V12.5a.209.209 0 0 0-.207-.208H12.5a.209.209 0 0 0-.208.208v3.334a.209.209 0 0 0 .208.208Z"
      fill="currentColor"
    />
  </svg>
);

export default ProductSettingIcon;
