import { Stack, styled } from '@mui/material';

const ProfileWrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.appBackground.main,
  height: '100vh',
  width: '100%',

  '& .filter_container': {
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.light.blue}`,
    borderRadius: '8px',
    '& .filter_headline': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '25px 17px 0 35px',
    },
    '& .filter_forms': {
      marginLeft: '35px',
      display: 'flex',
      gap: '20px',
    },
    '& .form_inputs': {
      width: '30%',
    },
    '& .checkbox_container': {
      top: '-20px',
      position: 'relative',
    },
    '& .filter_buttons': {
      marginRight: '40px',
      position: 'relative',
      top: '-14px',
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '20px',
    },
  },

  '& .icons_container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
  },
  '& .icon_container': {
    width: '32px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.appBackground.BlueAlice,
    border: `1px solid ${theme.palette.light.blue}`,
    borderRadius: '8px',
    cursor: 'pointer',
  },

  '& .profile_listing_labels': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  '& .sub_listing': {
    display: 'flex',
    position: 'relative',
    width: '100%',
  },
  '& .bookmark_container': {
    position: 'absolute',
  },
  '& .header_name_label ': {
    width: '40%',
  },
  '& .name_label': {
    width: '39%',
    marginRight: '5px',
  },
  '& .header_users_label ': {
    width: '14.5%',
  },
  '& .users_label': {
    position: 'relative',
    width: '13.5%',
    marginRight: '5px',
  },
  '& .description_label': {
    width: '80%',
  },
  '& .description_container': {
    width: '80%',
    display: 'flex',
    alignItems: 'center',
    gap: '80px',
  },
  '& .listing_item_row': {
    width: '100%',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.common.white,
    boxShadow: '0px 1px 5px #EBEBEBCC',
    borderRadius: '8px',
    cursor: 'pointer',
    '&:active': {
      boxShadow: '0px 1px 15px #00000029',
    },
    '& .item_icon': {
      height: '38px',
      opacity: 0,
      '&:hover': {
        opacity: 1,
        background: '#adb5bd',
        borderRadius: '14px',
      },
    },
    '&:hover  .item_icon': {
      opacity: 1,
    },
    '& .users_number': {
      position: 'absolute',
      left: '30px',
    },
    '& .users_icon': {
      position: 'absolute',
      opacity: 1,
    },
    '& .users_grey_icon': {
      position: 'absolute',
      opacity: 0,
    },
    '&:hover .users_icon &:active': {
      opacity: 0,
    },
    '&:hover .users_grey_icon ': {
      opacity: 1,
    },
  },

  [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},

  [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {},
}));
export default ProfileWrapper;
