import { api } from '.';

const projectSystemBaseUrl = '/project-system';

export const projectSystemApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProjectSystemByProjectId: builder.query<any, number>({
      query: (projectId) => `${projectSystemBaseUrl}/${projectId}`,
    }),
    createUpdateProjectSystem: builder.mutation<any, { body: any; params: object }>({
      query: ({ body, params }) => ({
        url: `${projectSystemBaseUrl}/create-update`,
        method: 'POST',
        params,
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetProjectSystemByProjectIdQuery,
  useCreateUpdateProjectSystemMutation,
} = projectSystemApi;
