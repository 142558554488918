import { createSelector } from 'reselect';
import { selectProjectAdministrationPlatformSettingDomain } from 'services/slices/project-administration';

/**
 * specific selectors
 */
const makeSelectProjectData = createSelector(
  selectProjectAdministrationPlatformSettingDomain,
  (substate) => substate.projectData,
);
const makeSelectProjectDataToDelete = createSelector(
  selectProjectAdministrationPlatformSettingDomain,
  (substate) => substate.projectDataToDelete,
);
const makeSelectFilterData = createSelector(
  selectProjectAdministrationPlatformSettingDomain,
  (substate) => substate.filterData,
);
const makeSelectOperation = createSelector(
  selectProjectAdministrationPlatformSettingDomain,
  (substate) => substate.operation,
);
const makeSelectSubmitted = createSelector(
  selectProjectAdministrationPlatformSettingDomain,
  (substate) => substate.submitted,
);
const makeSelectEmail = createSelector(
  selectProjectAdministrationPlatformSettingDomain,
  (substate) => substate.email,
);
const makeSelectContractorsNotificationAddresses = createSelector(
  selectProjectAdministrationPlatformSettingDomain,
  (substate) => substate.contractorsNotificationAddresses,
);

const makeSelectLogoFile = createSelector(
  selectProjectAdministrationPlatformSettingDomain,
  (substate) => substate.logoFile,
);

export {
  makeSelectProjectData,
  makeSelectProjectDataToDelete,
  makeSelectFilterData,
  makeSelectOperation,
  makeSelectSubmitted,
  makeSelectLogoFile,
  makeSelectEmail,
  makeSelectContractorsNotificationAddresses
};
