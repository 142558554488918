import { styled } from "@mui/material";

const ContentBody = styled('div')`
  display: flex;
  flex-direction: column;
  flex-wrap: 1;
  flex-grow: 1;
  flex-basis: 0;
`;

export default ContentBody;
