import { createSlice } from '@reduxjs/toolkit';
import { type RootState } from 'store';

interface AccessManagementSliceState {
  profileData: any;
  submitted: boolean;
  backPopup: boolean;
  cancelPopup: boolean;
  successPopup: boolean;
  deletePopup: boolean;
  deletedProfileId: number;
}

const initialState: AccessManagementSliceState = {
  profileData: {},
  submitted: false,
  backPopup: false,
  cancelPopup: false,
  successPopup: false,
  deletePopup: false,
  deletedProfileId: 0,
};
/* eslint-disable no-param-reassign */
const index = createSlice({
  name: 'accessManagement',
  initialState,
  reducers: {
    setProfileData: (state, { payload: profileData }) => {
      state.profileData = { ...state.profileData, ...profileData };
    },
    setSubmitted: (state, { payload: submitted }) => {
      state.submitted = submitted;
    },
    showBackPopup: (state, { payload: backPopup }) => {
      state.backPopup = backPopup;
    },
    showCancelPopup: (state, { payload: cancelPopup }) => {
      state.cancelPopup = cancelPopup;
    },
    showSuccessPopup: (state, { payload: successPopup }) => {
      state.successPopup = successPopup;
    },
    showDeletePopup: (state, { payload: successPopup }) => {
      state.deletePopup = successPopup;
    },
    setDeleteProfileId: (state, { payload: profileId }) => {
      state.deletedProfileId = profileId;
    },
    resetAccessManagement: () => initialState,
    resetPopupState: (state, action) => {
      state.cancelPopup = false;
      state.successPopup = false;
      state.backPopup = false;
    },
  },
});

export const {
  setProfileData,
  setSubmitted,
  resetAccessManagement,
  showBackPopup,
  showCancelPopup,
  showSuccessPopup,
  showDeletePopup,
  setDeleteProfileId,
} = index.actions;

export const selectAccessManagementDomain = (state: RootState): any =>
  state.accessManagement;

export default index.reducer;
