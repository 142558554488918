import { Button, styled } from '@mui/material';

const ActionBtn = styled(Button)(({ theme }) => ({
  position: 'relative',
  minWidth: 50,
  cursor: 'pointer',
  borderRadius: '8px',
  '&.btn--reset': {
    padding: 0,
    margin: 0,
    border: 'none',
    minWidth: 'unset',

    '& > *': {
      margin: 0,
    },
  },
  '&.btn__edit': {
    padding: '5px 20px',
    height: 'fit-content',
    background: theme.palette.light.main,
    border: `1px solid ${theme.palette.border.main}`,
    color: theme.palette.text.main,
  },
  '&.small__button': {
    width: 40,
    height: 40,
  },
  '&.plus , &.minus': {
    width: 37,
    height: 37,
  },
  '&.back__button': {
    '& svg': {
      transform: 'scale(1.25) rotate(90deg)',
    },
  },
  '&.button-with-icon': {
    backgroundColor: theme.palette.button_background.main,
    color: theme.palette.text.main,

    '&.active': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.light.main,
      zIndex: 2,
      '& svg path': {
        fill: theme.palette.light.main,
      },
    },
  },
  '&.tags': {
    padding: '10px 20px',
    gap: '10px',
    height: '40px',
    /* justifyContent: 'space-between', */
    '&:is(:not(.filtertBtn__action))': {
      cursor: 'auto',
    },

    '& .MuiButton-endIcon': {
      cursor: 'pointer',
    },
  },
  '&.filterBtn': {
    '&:hover': {
      background: theme.palette.gradient_blue.main,
      color: theme.palette.light.main,
      '& svg': {
        '& path , & circle': {
          fill: theme.palette.light.main,
        },
      },
    },
  },

  '&.delete': {
    backgroundColor: theme.palette.danger.main,
    color: theme.palette.light.main,
  },
  '&.plus , &.primary': {
    background: theme.palette.gradient_blue.main,
    color: theme.palette.light.main,
  },
  '&.minus': {
    background: theme.palette.border.main,
    color: theme.palette.text.main,
  },
  '&.secondary': {
    backgroundColor: theme.palette.button_background.secondary,
    color: theme.palette.light.main,
  },
  '&.footer--back__button': {
    minWidth: '225px',
    height: '100%',
    gap: '10px',
    padding: '5px 50px',
    background: theme.palette.appBackground.main,
    border: `1px solid ${theme.palette.border.main}`,
    color: theme.palette.text.main,
    '& .MuiTypography-root': {
      fontFamily: theme.typography.subtitle1_HelveticaNeue_Bold.fontFamily,
      fontSize: theme.typography.subtitle1_HelveticaNeue_Bold.fontSize,
    },
    '&.cancel': {
      background: theme.palette.light.main,
    },
  },
  '&.footer--next__button': {
    background: theme.palette.gradient_blue.main,
    color: theme.palette.light.main,
    minWidth: '225px',
    height: '100%',
    padding: '5px 50px',
    '& .MuiTypography-root': {
      fontFamily: theme.typography.subtitle1_HelveticaNeue_Bold.fontFamily,
      fontSize: theme.typography.subtitle1_HelveticaNeue_Bold.fontSize,
    },
  },
  '&.associate': {
    height: '37px',
  },
  '&.danger': {
    backgroundColor: theme.palette.danger.main,
    color: theme.palette.light.main,
  },
  '&.link': {
    minWidth: '200px',

    '& .MuiTypography-root': {
      fontFamily: theme.typography.h5_HelveticaNeue_Bold.fontFamily,
      fontSize: theme.typography.h5_HelveticaNeue_Bold.fontSize,
      color: `${theme.palette.primary.main} !important`,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:margin-left': {
      width: '225px',
      marginLeft: 'auto',
    },
  },

  [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {
    minWidth: 40,
  },

  [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},

  [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {},
}));

export default ActionBtn;
