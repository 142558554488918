import React from "react";

const JpassSettingsIcon = () => (
  <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30" height="30">
    <title>JpassSettingsicon</title>
    <defs>
      <image width="30" height="30" id="img18"
             href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAAXNSR0IB2cksfwAABOBJREFUeJytl2uIVVUUx7f2NnuZOWla9CCjJ2lSfSmtKYKKKBqi+iKVmhNDjxmv996zz5mTo1YEPehF9NCKwhwHmg+NKX1yoIwiTYRSKwiqGelTOX1e/dY693XOueOdGVuw2efutfd67f9aa1/nslSQ+S6UD5yX35h3M692JbnMBdLpyvIl848ukm3wrsqdnTKJTEPwFoR+hcK7+C6i5Bu+he/9jD73rCyDv4O1Qdcjp/5/yiO5Gw8vr/2OZbYrykVug7Q1rC1C8RiGXHBsyhbLCQg7HkH34lFHy/2xXcdn7N9hyp+WUyavVIX0ygCeduDFfdxjFzGfVuGdbAatlrNYn5s5dzXKB83zUPonf+dePkTpTgRfmSzIdJvKhDOSITx6GKHPuG75xIRH8gRKZ1WMmg//BtZ3Mgb4PdMMVV5LCmUfSh7KrF2LgkMYtdm9CIBUUEFOY74O3i/wtnJmk4EvlnnMN7F3P9/3s/42e7YZEMsNuMhRIMNsfK72W5GapNNm86CRYrmD/WPwtjDeZd8hxmHG54y/LexetjM+Nl6ZKJTknPFCfTvjdwSeZ7975XwO6L1dk9rXJSexb4B9WxF2duU62hiPsfYe8ytE4laLjFJJbjYj18n1zRVrqDRPNWWqHgdywNKokQrcpZcjZuhESKOl3ofyBt/T8xsCQ/NBt14WVCJwMWtSi0AsJ2LUEgRoCIfdGvgTJcWOGqs6FJApKtr91O9YPfWyl9FN0VjG7085+KcpDQBXlhLQzXGdhuiZOZ4CMJB/MWIXV7HO9lY8/AHlnakDkTyAMWNmbcidetBavYq6gXPgPY7QIfjfc+bbCrBWpdCsEfNyoSkNAFwAaNfKGar4I/MwknuYF9rmN61gDFrKdDVBZVI8drF/hPlVgLTKjFDhoaXhcA6cSpqmiqeSXOqsIunhUEb53s28xL1ECVTrFa35e2urKFXgXNHEqHmVs9tzeVzAMY8eL+3JQoccZwZobnprhQsRWmDek8vDAM8ST/NK68bNtbOh9KTWvawgXUdqwM0cGCVkt8FcYMVhDfdd9+Z08zYgQq0oQKl6HsuZ9nsjuR/Jd5zvs/abIvW8V97i0B4ULjckBvJ+jb8SxIfyk5XGVqR9W+VUm0tS10eYlzY/oBsDGkfISyOWf7ByY63oP4X1Xv6At7yl4gjFCtqq4kSuAu/J8Q9pedQ2mdTl9tq6hlpruwe9rUg7mqK7GuoXyOlQ3rFCVC2rOVrJw6BE4fA0i1hmZDxJ8jHbnxtJc1zD7GV9xpjFlk6xXDLewRkI7ufwyzleHbFD7Dt3HKVfmOIsepXXst6HssFquPbarNfJm2uvGRAQUgWMeqPfIVUsCfGi1BlNSy8xe0aP/lZLPOu3FlnGu0juTL0qk8LTx7pWqSMVT4YtvI2eJmnZwzjIXlX6YD6dstRtDaOd8boVDX1b69Mm7f0se4ursiKltpFUaWTG7LO87qOztVSaFq6F/lGs/sseChOlUF4zpc2wMAnlsw3N2TKoqdFD99HupWlYJb3TJG+7p660Sl7W2iNQO48+GPTZE8nXDG2fP6NoE+tL3fNERYHkKblB5YFxTFQFXPIv4gDjsBWTWG5B+QpLMU/T9/Kr1fwiQGr67JkKKYC0dZZBecyfuUbB2gZ75Eb4j1jKHAVI/wFJ3G5bm5/UTwAAAABJRU5ErkJggg==" />
    </defs>
    <style>
    </style>
    <use id="Background" href="#img18" x="0" y="0" />
  </svg>
);
export default JpassSettingsIcon;
