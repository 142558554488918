import {Stack, styled} from "@mui/material";

export const FormInputPlusMinusWrapper = styled(Stack)(
  ({theme}) => ({
    "& .FormInputPlusMinus": {
      flexDirection: 'row',
      alignItems: 'flex-start',
      gap: '7px',
      '& .fomInput__wrapper': {
        minWidth: '165px',
        minHeight: 'unset',
      },
      '& .MuiInputBase-root': {
        marginTop: '0px',
      },
      '& .MuiInputBase-input': {
        width: '70px',
        marginInline: 'auto',
        textAlign: 'center',
      },
      '& .small__button': {
        width: '37px',
        height: '37px',
        minWidth: 'unset',
        borderRadius: '15px',
      },
    },


    [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},

    [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},

    [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},

    [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},

    [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},

    [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {},

  })
)
