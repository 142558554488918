import React from "react";

const DashboardIcon = () => (
  <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" width="25" height="25">
    <title>iconDashboard</title>
    <defs>
      <image width="25" height="25" id="img13"
             href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAdCAMAAABhTZc9AAAAAXNSR0IB2cksfwAAAQVQTFRFAHv/AHz/AHz/AHr/AHn/AAAAAHj/AHv/AHv/AHv/AH3/AHr/AHv/AHz/AHj/AHr/AGr/AGb/AHz/AHn/AHr/AHv/AHj/AH3/AHz/AHr/AID/AHb/AHv/AHv/AHr/AHv/AHb/AHj/AHr/AHv/AID/AHn/AHv/AID/AID/AHb/AH7/AHj/AID/AHz/AHz/AHj/AHr/AHn/AHH/AGr/AHr/AHP/AHn/AAD/AHv/AHv/AHv/AHv/AHr/AFX/AHz/AHv/AHr/AH3/AH7/AHr/AHT/AID/AHv/AHv/AHn/AHz/AHv/AF3/AHr/AHz/AHf/AHj/AHz/AHr/AHr/AG3/AHj/AID/AHf/6BpubAAAAFd0Uk5TTcrMyl8AM7/My4HYtWWb5BgKs7+Q/yJkYf8cDZ5dy2gctuPBCIGBGgJDYVEE/6l3dfIbDJ8qTgHxzdfmFwNzj0FgUXELDKobPUDzC74hWkCCXvIHMSBe7MZyMQAAANVJREFUeJzNksUOAkEQBQdp3NldZGFxd3d3d/7/U+DSA8wmXKFuL5V0ppIhRKFEVGoAjVaHU28AYjSZEYvVZnc46eR4gehdgLg9XlHpo1Pg/T+zUkBEzHyQtaEw7bVGRNZGYz4kngDWwgesTabSSCYrs1zuRT4re3PBixRL5e+9FalKA2t8nbGNarOFge1Ol7HQ69PAgQCs/URmhyMMHNtldvIWGJkydibNFxi49Kz+9edwAlJcJzbb/A7nPncgx7dADuB0fk3HhcDVj1wbz3u3O87DBR6sazxZCNHQ1wAAAABJRU5ErkJggg==" />
    </defs>
    <style>
    </style>
    <use id="Background" href="#img13" x="0" y="0" />
  </svg>
);
export default DashboardIcon;
