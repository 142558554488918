import {Stack, styled} from "@mui/material";

export const StatusTagWrapper = styled(Stack)(
  ({theme}) => ({


    width: 'max-content',
    '& .tag': {
      padding: '3px 15px 3px 25px',
      borderRadius: '15px',
      width: 'auto',

      '&.Inactive': {
        backgroundColor: theme.palette.danger.main,
      },
      '&.Active': {
        backgroundColor: theme.palette.success.main,
      },
      '& .MuiTypography-root': {
        position: 'relative',
        '&:before': {
          content: "''",
          position: 'absolute',
          top: '50%',
          left: '-13px',
          width: '7px',
          height: '7px',
          borderRadius: '50%',
          transform: 'translateY(-50%)',
          backgroundColor: theme.palette.light.main,
        },
      },
    },


    [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},

    [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},

    [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},

    [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},

    [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},

    [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {},

  })
)
