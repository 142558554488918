import React from 'react';

const UpdateUserSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.894"
    height="16.772"
    viewBox="0 0 19.894 16.772"
  >
    <path
      id="Path_67452"
      data-name="Path 67452"
      d="M-2653.546-4000.727a8.412,8.412,0,0,1-3.9-.948,8.63,8.63,0,0,1-2.866-2.458l1.814-1.259a6.006,6.006,0,0,0,2.077,1.754,6.062,6.062,0,0,0,2.87.7,6.156,6.156,0,0,0,4.384-1.811,6.254,6.254,0,0,0,1.62-2.854l.078-.311h-1.349a.076.076,0,0,1-.068-.044l-.009-.02a.064.064,0,0,1,.012-.067q1.146-1.385,2.27-2.748l.29-.351a.071.071,0,0,1,.065-.026.076.076,0,0,1,.065.025l2.558,3.1.016.017a.067.067,0,0,1,0,.061c-.032.053-.064.053-.083.053h-1.537l-.035.208a8.263,8.263,0,0,1-2.348,4.521A8.294,8.294,0,0,1-2653.546-4000.727Zm-7.306-6.328a.075.075,0,0,1-.065-.025l-2.559-3.087-.005-.005a.078.078,0,0,1-.012-.088c.032-.051.062-.051.082-.051h1.536l.035-.209a8.334,8.334,0,0,1,2.349-4.521,8.352,8.352,0,0,1,5.945-2.46,8.3,8.3,0,0,1,3.883.962,8.462,8.462,0,0,1,2.865,2.444l-1.815,1.259a6.263,6.263,0,0,0-2.065-1.754,6.132,6.132,0,0,0-2.868-.7,6.159,6.159,0,0,0-4.384,1.81,6.229,6.229,0,0,0-1.634,2.853l-.083.313h1.353a.077.077,0,0,1,.067.044l.005.01a.082.082,0,0,1-.012.095c-.272.33-.548.662-.823.992l-.054.065-.1.117-.3.366-.232.28c-.346.417-.7.842-1.045,1.261A.071.071,0,0,1-2660.852-4007.055Z"
      transform="translate(2663.5 4017.5)"
      fill="#007cff"
    />
  </svg>
);

export default UpdateUserSvg;
