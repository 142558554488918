import { api } from '.';

const workLocationBaseUrl = '/work-location';

export const workLocationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getWorkLocationsByProjectId: builder.query<any, { projectId: number }>({
      query: ({ projectId }) => ({
        url: `${workLocationBaseUrl}/${projectId}`,
      }),
    }),
    createUpdateWorkLocation: builder.mutation<any, { body: any; params: object }>({
      query: ({ body, params }) => ({
        url: `${workLocationBaseUrl}/create-update`,
        method: 'POST',
        body,
        params,
      }),
    }),
  }),
});

export const {
  useLazyGetWorkLocationsByProjectIdQuery,
  useCreateUpdateWorkLocationMutation,
} = workLocationApi;
