import { api } from '.';

const projectDisciplineBaseUrl = '/project-discipline';

export const projectDisciplineApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProjectDisciplineByProjectId: builder.query<any, number>({
      query: (projectId) => ({
        url: `${projectDisciplineBaseUrl}/list-selected`,
        params: { projectId },
      }),
    }),
    createUpdateProjectDiscipline: builder.mutation<
      Promise<void>,
      { projectId: number; body: any }
    >({
      query: ({ projectId, body }) => ({
        url: `${projectDisciplineBaseUrl}/create-update/${projectId}`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetProjectDisciplineByProjectIdQuery,
  useCreateUpdateProjectDisciplineMutation,
} = projectDisciplineApi;
