import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStyles, ThemeProvider } from '@mui/material';
import { AuthProvider } from 'providers/auth-provider';
import AppRoutes from 'routes';
import store from 'store';
import theme from 'ui/theme';
import GlobalStyle from 'ui/global-styles';
import './App.css';
import { MatomoProvider } from '@datapunt/matomo-tracker-react';
import React from 'react';
import { type MatomoProviderProps } from '@datapunt/matomo-tracker-react/lib/MatomoProvider';
import matomo from './matomo.config';

const App: React.FC = function () {
  const CustomMatomoProvider: React.FC<
    MatomoProviderProps & { children: React.ReactNode }
  > = MatomoProvider;

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CustomMatomoProvider value={matomo}>
          <AuthProvider>
            <BrowserRouter>
              <AppRoutes />
            </BrowserRouter>
          </AuthProvider>
        </CustomMatomoProvider>
      </ThemeProvider>
      <GlobalStyles styles={GlobalStyle} />
    </Provider>
  );
};

export default App;
