import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import ActionButton from 'components/ActionButton';
import CustomModal from 'components/StyledComponents/CustomModal';
import { CloseButton } from 'components/Buttons';
import { CloseIcon } from 'assets/svgs/componentsIcons';
import { FooterWrapper } from './Wrapper';

function SettingsFooterPage({ handelSaveClick, handelCancelClick, labelSave }) {
  const [openModal] = useState(false);
  return (
    <FooterWrapper>
      <ActionButton
        classname="footer--back__button btn--reset"
        label="Cancel Creation"
        onClick={handelCancelClick}
      />
      <ActionButton
        classname="footer--next__button btn--reset"
        onClick={handelSaveClick}
        label={labelSave}
      />
      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomModal modalWidth="550px">
          <div className="modal-head-wrapper">
            <div className="modal-head">
              <h3 className="modal-title">Confirmation message</h3>
              <p className="modal-description">
                Are you sure you want to cancel the creation of this project ?
              </p>
            </div>
            <div className="modal-closer">
              <CloseButton type="button">
                <CloseIcon />
              </CloseButton>
            </div>
          </div>
          <div className="modal-action-wrapper">
            <ActionButton
              classname="footer--back__button btn--reset"
              label="Cancel Creation"
            />
            <ActionButton
              classname="footer--next__button btn--reset .padding-sides-10"
              label="Save As Draft"
            />
          </div>
        </CustomModal>
      </Modal>
      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomModal modalWidth="400px">
          <div className="modal-head-wrapper">
            <div className="modal-head">
              <h3 className="modal-title">test</h3>
              <p className="modal-description">test</p>
            </div>
            <div className="modal-closer">
              <CloseButton type="button">
                <CloseIcon />
              </CloseButton>
            </div>
          </div>
        </CustomModal>
      </Modal>
    </FooterWrapper>
  );
}

SettingsFooterPage.propTypes = {
  handelSaveClick: PropTypes.func,
  handelCancelClick: PropTypes.func,
  labelSave: PropTypes.string,
};
export default SettingsFooterPage;
