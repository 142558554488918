import { api } from '.';

const contractorBaseUrl = '/contractor';
const projectContractorWorkDimensions = `/project-contractor-work-dimension`;

const subContractorBaseUrl = '/sub-contractor';
const projectSubContractorWorkDimensions = `/project-sub-contractor-work-dimension`;

const VendorBaseUrl = '/vendor';
const projectVendorWorkDimensions = `/project-vendor-work-dimension`;
export const contractorApi = api.injectEndpoints({
  endpoints: (builder) => ({
    searchContractors: builder.query<any, any>({
      query: (params) => ({
        url: `${contractorBaseUrl}/search`,
        params,
      }),
    }),

    reassignContractorToProject: builder.mutation<any, { body: any; params: any }>({
      query: ({ body, params }) => ({
        url: `${projectContractorWorkDimensions}/massive-save`,
        method: 'POST',
        params,
        body,
      }),
    }),

    getContractorsByProject: builder.query<any, any>({
      query: (params) => ({
        url: `${projectContractorWorkDimensions}/by-project`,
        params,
      }),
    }),

    searchSubContractors: builder.query<any, any>({
      query: (params) => ({
        url: `${subContractorBaseUrl}/search`,
        params,
      }),
    }),

    reassignSubContractorToProject: builder.mutation<any, { body: any; params: any }>({
      query: ({ body, params }) => ({
        url: `${projectSubContractorWorkDimensions}/massive-save`,
        method: 'POST',
        params,
        body,
      }),
    }),

    getSubContractorsByProject: builder.query<any, any>({
      query: (params) => ({
        url: `${projectSubContractorWorkDimensions}/by-project`,
        params,
      }),
    }),

    searchVendors: builder.query<any, any>({
      query: (params) => ({
        url: `${VendorBaseUrl}/search`,
        params,
      }),
    }),

    reassignVendorToProject: builder.mutation<any, { body: any; params: any }>({
      query: ({ body, params }) => ({
        url: `${projectVendorWorkDimensions}/massive-save`,
        method: 'POST',
        params,
        body,
      }),
    }),

    getVendorsByProject: builder.query<any, any>({
      query: (params) => ({
        url: `${projectVendorWorkDimensions}/by-project`,
        params,
      }),
    }),
  }),
});

export const {
  useLazySearchContractorsQuery,
  useReassignContractorToProjectMutation,
  useLazyGetContractorsByProjectQuery,
  useLazySearchSubContractorsQuery,
  useReassignSubContractorToProjectMutation,
  useLazyGetSubContractorsByProjectQuery,
  useLazySearchVendorsQuery,
  useReassignVendorToProjectMutation,
  useLazyGetVendorsByProjectQuery,
} = contractorApi;
