import React from "react";

const SchedulingIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26.531"
    height="26.53"
    viewBox="0 0 26.531 26.53"
  >
    <g
      id="Groupe_65034"
      data-name="Groupe 65034"
      transform="translate(18768.75 -4815.25)"
    >
      <path
        id="Soustraction_5"
        data-name="Soustraction 5"
        d="M12.746,25.491A12.745,12.745,0,0,1,3.733,3.733,12.755,12.755,0,0,1,19.711,2.067L18.138,3.641a.9.9,0,0,0-.144.333c0,.016,0,.054,0,.054l.255,2.25a8.508,8.508,0,1,0,.837.819l2.192.215h.07l.028,0,.018,0,.023,0,.01,0,.015,0h0l.018-.006.01,0,.021-.008.025-.011,0,0a.061.061,0,0,0,.016-.007.071.071,0,0,0,.018-.01l.009-.005.018-.011.017-.012.012-.009.008-.005c.015-.013.039-.036.04-.037l1.61-1.609a12.749,12.749,0,0,1-10.53,19.931Z"
        transform="translate(-18768.113 4815.887)"
        fill="#016be3"
      />
      <circle
        id="Ellipse_2494"
        data-name="Ellipse 2494"
        cx="3.399"
        cy="3.399"
        r="3.399"
        transform="translate(-18758.766 4825.234)"
        fill="#016be3"
      />
      <path
        id="Tracé_51784"
        data-name="Tracé 51784"
        d="M13.765,27.031A13.265,13.265,0,0,1,4.385,4.385a13.27,13.27,0,0,1,16.3-1.938l.143.088,1.425-1.426a.577.577,0,0,1,.983.36l.219,2.6,2.6.219a.577.577,0,0,1,.36.983L25,6.7l.088.143A13.266,13.266,0,0,1,13.765,27.031Zm0-25.376A12.111,12.111,0,0,0,5.2,22.329,12.112,12.112,0,0,0,24.3,7.781l-.138-.242L23.071,8.627a.459.459,0,0,1-.044.04l0,0-.011.008-.033.024-.021.013-.011.006-.021.012-.019.008,0,0-.03.013-.024.008-.033.011-.023.006-.013,0-.026,0-.021,0-.034,0h-.039l-.044,0-1.691-.143.235.363a8.8,8.8,0,1,1-2.641-2.641l.363.235-.143-1.69a.6.6,0,0,1,0-.064A.572.572,0,0,1,18.9,4.46l1.087-1.088-.242-.138A12.105,12.105,0,0,0,13.765,1.654Zm0,4.475a7.645,7.645,0,1,0,5.941,2.844l-.148-.184-2.547,2.547.1.145a4.058,4.058,0,1,1-1.061-1.061l.145.1,2.547-2.547-.184-.148A7.647,7.647,0,0,0,13.765,6.13Zm0,4.738a2.9,2.9,0,1,0,2.56,1.542l-.135-.254-2.017,2.017a.577.577,0,0,1-.816-.817l2.017-2.017-.254-.135A2.9,2.9,0,0,0,13.765,10.868ZM22.192,2.8h0L19.91,5.087l.2,2.337,2.337.2,2.282-2.282-1.851-.156a.576.576,0,0,1-.527-.527L22.192,2.8Z"
        transform="translate(-18769.25 4814.75)"
        fill="#00338d"
      />
    </g>
  </svg>
);
export default SchedulingIcon;
