import React from 'react';

interface ComponentProps {
  className?: string;
}
const SaveForLaterIcon: React.FC<ComponentProps> = function ({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="45.526"
      height="34.905"
      viewBox="0 0 45.526 34.905"
    >
      <g id="Group_67390" data-name="Group 67390" transform="translate(-232.033 -220.98)">
        <path
          id="Path_70142"
          data-name="Path 70142"
          d="M32.673,7.659H71.1c.073,0,.144-.206.213-.194V4.29a1.276,1.276,0,0,0-1.276-1.276H47.829L45.294.39A1.276,1.276,0,0,0,44.376,0H33.685a1.276,1.276,0,0,0-1.276,1.276V7.443c.085-.018.174.216.264.216"
          transform="translate(202.936 221.979)"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <path
          id="Path_70141"
          data-name="Path 70141"
          d="M1824.918,268.32h-37.183a1.277,1.277,0,0,1-1.272-1.17l-1.894-22.694a1.276,1.276,0,0,1,1.272-1.383h40.971a1.276,1.276,0,0,1,1.272,1.383l-1.894,22.694a1.277,1.277,0,0,1-1.272,1.17"
          transform="translate(-1551.53 -13.434)"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <g
          id="Path_70143"
          data-name="Path 70143"
          transform="translate(261.297 229.522)"
          fill="#fff"
        >
          <path
            d="M 6.958984375 11.60936737060547 L 3.686254262924194 7.750260353088379 L 3.102104425430298 7.061450481414795 L 2.532334327697754 7.762200355529785 L -0.541015625 11.54206371307373 L -0.541015625 0.4033203125 L 6.958984375 0.4033203125 L 6.958984375 11.60936737060547 Z"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
};
export default SaveForLaterIcon;
