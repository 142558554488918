import React from 'react';

const ContractorSafetyDataIcon: React.FC = function () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.298"
      height="24.804"
      viewBox="0 0 28.298 24.804"
    >
      <g
        id="Group_74751"
        data-name="Group 74751"
        transform="translate(14758.582 5383.83)"
      >
        <path
          id="Subtraction_151"
          data-name="Subtraction 151"
          d="M13.459,22.77H6.647l-3.662,0a1.349,1.349,0,0,1-1.077-.517A1.24,1.24,0,0,1,1.7,21.11a1.35,1.35,0,0,1,1.343-.918H13.459v2.578Zm0-4.229H3.1c0-.319,0-.635,0-.942-.005-.9-.008-1.7.011-2.51a13.572,13.572,0,0,1,.168-2.015,10.417,10.417,0,0,1,2.31-4.9,12.724,12.724,0,0,1,5.632-3.861c.182-.065.366-.122.553-.18l.021.266c.077,1.018.141,1.876.2,2.749a.908.908,0,0,0,.259.6.82.82,0,0,0,.59.232c.023,0,.047,0,.071,0a.826.826,0,0,0,.589-.293.892.892,0,0,0,.187-.641l-.2-2.854c-.037-.541-.081-1.169-.137-1.788A.741.741,0,0,1,13.5,1.83a.776.776,0,0,1,.557-.184H17.54c.136,0,.281,0,.426.009a.522.522,0,0,1,.446.3.835.835,0,0,1,.061.508c-.017.193-.031.386-.044.566L18.251,5.37c-.044.592-.087,1.171-.124,1.75a.82.82,0,0,0,.783.86c.022,0,.045,0,.067,0a.823.823,0,0,0,.843-.762c.044-.566.087-1.141.128-1.7l.078-1.037c.009-.118.019-.236.028-.349a13.418,13.418,0,0,1,2.6,1.1,12.18,12.18,0,0,1,4.219,3.793,11.144,11.144,0,0,1,.9,1.583A15.394,15.394,0,0,0,22,9.6a15.37,15.37,0,0,0-5.785,1.018A5.972,5.972,0,0,0,14.3,11.821a3.1,3.1,0,0,0-.6.825,2.252,2.252,0,0,0-.234.991v4.9Z"
          transform="translate(-14759.717 -5384.975)"
          fill="#026be3"
          stroke="#00338d"
          strokeWidth="1"
        />
        <path
          id="Path_78117"
          data-name="Path 78117"
          d="M21.219,26.975a14.282,14.282,0,0,1-5.564-1.017,5.749,5.749,0,0,1-1.842-1.208,3.406,3.406,0,0,1-.806-1.955V13.662a2.326,2.326,0,0,1,.225-.991,3.093,3.093,0,0,1,.581-.825,5.732,5.732,0,0,1,1.842-1.207,14.281,14.281,0,0,1,5.564-1.018,14.3,14.3,0,0,1,5.556,1.013,10.719,10.719,0,0,1,2.241,1.7,2.4,2.4,0,0,1,.417,1.332v9.272a2.616,2.616,0,0,1-.806,1.816,5.753,5.753,0,0,1-1.843,1.208A14.282,14.282,0,0,1,21.219,26.975ZM14.6,20.755v2.179a1.066,1.066,0,0,0,.36.71,4.23,4.23,0,0,0,1.337.856,12.679,12.679,0,0,0,4.926.884,12.689,12.689,0,0,0,4.927-.884,4.249,4.249,0,0,0,1.336-.856,1.064,1.064,0,0,0,.359-.71V20.755a7.131,7.131,0,0,1-1.058.567,14.282,14.282,0,0,1-5.564,1.017,14.282,14.282,0,0,1-5.564-1.017A7.22,7.22,0,0,1,14.6,20.755Zm0-4.636V18.3a1.066,1.066,0,0,0,.36.71,4.249,4.249,0,0,0,1.337.856,12.679,12.679,0,0,0,4.926.884,12.689,12.689,0,0,0,4.927-.884,4.249,4.249,0,0,0,1.336-.856,1.064,1.064,0,0,0,.359-.71V16.119a7.131,7.131,0,0,1-1.058.567A14.3,14.3,0,0,1,21.219,17.7a14.3,14.3,0,0,1-5.564-1.017A7.22,7.22,0,0,1,14.6,16.119Zm6.622-4.907a12.679,12.679,0,0,0-4.926.884,4.261,4.261,0,0,0-1.337.856,1.066,1.066,0,0,0-.36.71,1.066,1.066,0,0,0,.36.71,4.249,4.249,0,0,0,1.337.856,12.679,12.679,0,0,0,4.926.884,12.689,12.689,0,0,0,4.927-.884,4.249,4.249,0,0,0,1.336-.856,1.064,1.064,0,0,0,.359-.71,1.064,1.064,0,0,0-.359-.71,4.261,4.261,0,0,0-1.336-.856A12.689,12.689,0,0,0,21.219,11.212Z"
          transform="translate(-14759.717 -5386)"
          fill="#00338d"
        />
      </g>
    </svg>
  );
};
export default ContractorSafetyDataIcon;
