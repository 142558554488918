import { Button, styled } from '@mui/material';

export const AppBtn = styled(Button)(({ theme }) => ({
  position: 'relative',
  minWidth: '50px',
  cursor: 'pointer',
  '& button': {
    cursor: 'pointer',
    minWidth: '44px',
    minHeight: '44px',
    width: '44px',
    height: '44px',
    borderRadius: '15px',
    border: 'none',
    '& .MuiButton-iconSizeMedium': {
      margin: 0,
    },
  },

  '&.btn--reset': {
    padding: 0,
    margin: 0,
    border: 'none',
    minWidth: 'unset',

    '& > *': {
      margin: 0,
    },
  },

  '&.primary': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.light.main,
    borderRadius: '8px',
  },
  '&.secondary': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.light.main,
  },
  '&.filter': {
    backgroundColor: theme.palette.appBackground.BlueAlice,
    color: theme.palette.text.main,
    border: `1px solid #DBE6FF`,
    borderRadius: '8px',
    width: '45px',
    minWidth: 'unset',
    '& span': {
      minHeight: '25px',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 0,
      '& svg': {
        transform: 'scale(0.8)',
      },
    },
  },
  '&.login': {
    background: theme.palette.gradient_blue.main,
    color: theme.palette.light.main,
  },
  '&.settings': {
    minWidth: 'unset',
    minHeight: 'unset',
    width: '44px',
    height: '44px',
    padding: '10px',
    '& > *': {
      margin: 0,
    },
  },

  '&.link': {
    display: 'flex',
    padding: '0',
    border: 'none',
    justifyContent: 'center',
    width: 'max-content',
    marginInline: 'auto',
    background: 'transparent',
    color: theme.palette.primary.main,

    '&.support': {
      '& > span': {
        fontSize: '12px !important',
      },
    },

    '& > span': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },

    '&:hover': {
      backgroundColor: 'unset',
    },
  },

  [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},

  [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {},
}));
