/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { type RootState } from 'store';

interface ProjectAdministrationSliceState {
  projectData: any;
  projectDataToDelete: any;
  filterData: string;
  operation: string;
  submitted: boolean;
  dataType: any;
  email: string;
  contractorsNotificationAddresses: any;
  logoFile: any[];
}

const initialState: ProjectAdministrationSliceState = {
  projectData: {
    portfolios: [],
  },
  projectDataToDelete: {},
  filterData: '',
  operation: '',
  submitted: false,
  dataType: null,
  logoFile: [],
  email: '',
  contractorsNotificationAddresses: [],
};

const projectAdministration = createSlice({
  name: 'projectAdministration',
  initialState,
  reducers: {
    setProjectData: (state, { payload: projectData }) => {
      state.projectData = projectData;
    },
    setFilterData: (state, { payload: filterData }) => {
      state.filterData = filterData;
    },
    setOperation: (state, { payload: operation }) => {
      state.operation = operation;
    },
    setSubmitted: (state, { payload: submitted }) => {
      state.submitted = submitted;
    },
    setProjectDataToDelete: (state, { payload: projectDataToDelete }) => {
      state.projectDataToDelete = projectDataToDelete;
    },
    setDataType: (state, { payload: dataType }) => {
      state.dataType = dataType;
    },
    setLogoFile: (state, { payload: logoFile }) => {
      state.logoFile = logoFile;
    },
    setEmail: (state, { payload: email }) => {
      state.email = email;
    },
    setContractorsNotificationAddresses: (
      state,
      { payload: contractorsNotificationAddresses },
    ) => {
      state.contractorsNotificationAddresses = contractorsNotificationAddresses;
    },
    resetProjectAdministration: () => initialState,
  },
});

export const {
  setProjectData,
  setFilterData,
  setOperation,
  setSubmitted,
  setProjectDataToDelete,
  setDataType,
  resetProjectAdministration,
  setLogoFile,
  setEmail,
  setContractorsNotificationAddresses,
} = projectAdministration.actions;

export const selectProjectAdministrationPlatformSettingDomain = (
  state: RootState,
): any =>
  state?.projectAdministration !== undefined ? state.projectAdministration : initialState;

export default projectAdministration.reducer;
