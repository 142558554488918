import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ChevronDownSvg } from 'assets/svgs/componentsIcons/index';
import FormDateWrapper from './Wrapper';

function FormDate({ onChange, value, myLabel, error, disabled, ...rest }) {
  return (
    <FormDateWrapper className="fomInput__wrapper formdate__Wrapper">
      <FormControl className={` ${error && 'isError'} inputs__label`} size="small">
        <InputLabel className="select__label" shrink>
          <Typography color="text.secondary" variant="h5_HelveticaNeue_Regular">
            {myLabel}
          </Typography>
        </InputLabel>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            inputFormat="MM/dd/yyyy"
            {...rest}
            value={value}
            onChange={(newValue) => {
              onChange(newValue);
            }}
            renderInput={(params) => (
              <TextField fullWidth {...params} error={rest.error} />
            )}
            dateRangeIcon={<ChevronDownSvg id="ChevronDownSvg" />}
            disabled={disabled}
          />
        </LocalizationProvider>
        {error && (
          <FormHelperText className="errorMsg">This field is required</FormHelperText>
        )}
      </FormControl>
    </FormDateWrapper>
  );
}

FormDate.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  myLabel: PropTypes.string,
  disable: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
};
FormDate.defaultProps = {
  value: null,
};

export default FormDate;
