import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Avatar, FormControl, Stack } from '@mui/material';
import { EditIcon } from 'assets/svgs/componentsIcons/index';
import PropTypes from 'prop-types';
import DefaultUser from 'assets/images/DefaultUser.png';
import ActionButton from 'components/ActionButton';

function AvatarUpload({ onChoose, maxSize, HideChangeAvatarButton }) {
  const [uploadedAvatar, setUploadedAvatar] = useState(null);

  const onDropAccepted = (acceptedFiles) => {
    onChoose(acceptedFiles[0]);
    setUploadedAvatar(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDropAccepted,
    maxSize,
  });

  return (
    <Stack className="avatar">
      <Avatar
        alt="Avatar"
        src={uploadedAvatar ? URL.createObjectURL(uploadedAvatar) : DefaultUser}
        sx={{ width: 75, height: 75 }}
      />
      <FormControl variant="standard" {...getRootProps()}>
        <ActionButton
          classname={HideChangeAvatarButton ? 'hide' : 'btn__edit'}
          label="Change"
          startIcon={<EditIcon />}
        >
          <input hidden {...getInputProps()} />
        </ActionButton>
      </FormControl>
    </Stack>
  );
}

AvatarUpload.propTypes = {
  onChoose: PropTypes.func,
  maxSize: PropTypes.number,
  HideChangeAvatarButton: PropTypes.bool,
  error: PropTypes.bool,
};

export default AvatarUpload;
