import React from 'react';

function LogoutIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.415"
      height="13.53"
      viewBox="0 0 14.415 13.53"
    >
      <g
        id="Group_64011"
        data-name="Group 64011"
        transform="translate(8776.15 16377.087)"
      >
        <path
          id="Path_67276"
          data-name="Path 67276"
          d="M1.244.75H1.222A1.288,1.288,0,0,0,0,2.085V12.673a1.289,1.289,0,0,0,1.223,1.334H8.5a1.289,1.289,0,0,0,1.224-1.334V10.03a.442.442,0,1,0-.884,0v2.651s0,.005,0,.008,0,0,0,.005V12.7a.4.4,0,0,1-.375.422H1.257A.4.4,0,0,1,.882,12.7c0-.007,0-.013,0-.02V2.076q0-.01,0-.02a.4.4,0,0,1,.375-.422H8.462a.4.4,0,0,1,.375.422c0,.007,0,.013,0,.02V4.727a.442.442,0,1,0,.884,0V2.085A1.289,1.289,0,0,0,8.5.751H1.244Zm9.933,4.109a.442.442,0,0,1,.625,0l2.21,2.21a.442.442,0,0,1,0,.625L11.8,9.9a.442.442,0,1,1-.625-.625l1.455-1.455H4.418a.442.442,0,0,1,0-.884h8.213L11.176,5.484A.442.442,0,0,1,11.176,4.859Z"
          transform="translate(-8776.012 -16377.7)"
          fill="#e6534e"
          fillRule="evenodd"
        />
        <path
          id="Path_67276_-_Outline"
          data-name="Path 67276 - Outline"
          d="M1.23.6h7.26A1.425,1.425,0,0,1,9.844,2.073v2.64a.578.578,0,1,1-1.156,0V2.062c0-.009,0-.017,0-.026a.268.268,0,0,0-.244-.28H1.249A.268.268,0,0,0,1,2.036c0,.009,0,.018,0,.026V12.668c0,.009,0,.017,0,.026a.268.268,0,0,0,.244.279H8.443a.268.268,0,0,0,.244-.279V10.017a.578.578,0,1,1,1.156,0v2.64a1.425,1.425,0,0,1-1.353,1.473H1.2A1.425,1.425,0,0,1-.151,12.657V2.074A1.424,1.424,0,0,1,1.2.6ZM9.265,5.019a.306.306,0,0,0,.306-.306V2.066A1.152,1.152,0,0,0,8.477.873H1.215A1.152,1.152,0,0,0,.122,2.066v10.6a1.152,1.152,0,0,0,1.093,1.193H8.477a1.152,1.152,0,0,0,1.094-1.193V10.017a.306.306,0,1,0-.611,0v2.667a.541.541,0,0,1-.5.563H1.234a.541.541,0,0,1-.5-.564s0-.009,0-.014V2.062s0-.009,0-.014a.541.541,0,0,1,.5-.564H8.459a.541.541,0,0,1,.5.564s0,.009,0,.014V4.714A.306.306,0,0,0,9.265,5.019Zm2.21-.44a.574.574,0,0,1,.409.169l2.21,2.21a.578.578,0,0,1,0,.818l-2.21,2.21a.578.578,0,1,1-.818-.818l1.222-1.222H4.4a.578.578,0,0,1,0-1.157h7.884L11.066,5.567a.578.578,0,0,1,.409-.987Zm0,5.3a.3.3,0,0,0,.216-.09l2.21-2.21a.305.305,0,0,0,0-.432l-2.21-2.21a.306.306,0,1,0-.432.432l1.688,1.688H4.4a.306.306,0,0,0,0,.611h8.542L11.259,9.361a.306.306,0,0,0,.216.522Z"
          transform="translate(-8775.998 -16377.687)"
          fill="#e6534e"
        />
      </g>
    </svg>
  );
}

export default LogoutIcon;
