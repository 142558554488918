import React, { useEffect, useState } from 'react';
import { bool, number, ReactNode, string } from 'prop-types';
import { Button } from '@mui/material';
import { useLazyDownloadLogoQuery } from 'services/apis/platform-settings';
import defaultClientLogo from 'utils/images/defaultClientLogo.jpg';
import { PLATFORM_SETTINGS_TYPES } from 'helpers/constants';
import ProgramComponentItem from './Wrapper';

function Item({ id, url, title, type, isDownloadCall, children, ...rest }) {
  const [doDownloadLogo, { data: clientLogo, error: downloadError }] =
    useLazyDownloadLogoQuery();
  const [pictureUrl, setPictureUrl] = useState('');
  useEffect(() => {
    if (type === PLATFORM_SETTINGS_TYPES.CLIENT && (id || isDownloadCall)) {
      doDownloadLogo({ url });
    }
  }, [id, isDownloadCall]);

  useEffect(() => {
    if (clientLogo) {
      setPictureUrl(URL.createObjectURL(clientLogo));
    }
    if (downloadError?.status === 404) {
      setPictureUrl(defaultClientLogo);
    }
  }, [clientLogo, downloadError]);

  return (
    <ProgramComponentItem>
      <Button type="button" className="itemDisplay program-title" {...rest}>
        {type === PLATFORM_SETTINGS_TYPES.CLIENT && (
          <img src={pictureUrl} className="logoStyle" alt="" />
        )}
        {title}
      </Button>
      {children}
    </ProgramComponentItem>
  );
}

Item.propTypes = {
  id: number,
  title: string,
  type: string,
  isDownloadCall: bool,
  children: ReactNode,
  url: string,
};
export default Item;
