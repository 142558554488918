import collab from 'assets/images/collab.png';
import qa from 'assets/images/qa.png';
import jpass from 'assets/images/jpass.png';
import csp from 'assets/images/csp.png';

export const URLS = {
  collab: {
    name: 'Collab',
    src: collab,
    url: 'http://localhost:3003',
  },
  jpass: {
    name: 'JPass',
    src: jpass,
    url: 'http://localhost:3004',
  },
  quality: {
    name: 'Quality',
    src: qa,
    url: 'https://quality-dev.eastus.cloudapp.azure.com/',
  },
  csp: {
    name: 'CSP',
    src: csp,
    url: 'http://localhost:3006',
  },
};
