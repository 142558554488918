import { Outlet } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import AppTheme from 'ui/theme/app-theme';
import AppWrapper from 'containers/ProjectAdministrationPlatformSettings/Wrapper';
import AsideMenu from 'components/StyledComponents/AsideMenu';
import jesaDigitalSpace from 'assets/svgs/jesaDigitalSpace.svg';
import NavBar from 'components/NavBar';
import React from 'react';
import { AuthenticatedTemplate } from '@azure/msal-react';
import Logout from 'containers/Logout';

function Settings() {
  return (
    <ThemeProvider theme={AppTheme}>
      <AuthenticatedTemplate>
        <AppWrapper>
          <AsideMenu>
            <div className="logo-container">
              <a href="/">
                <img src={jesaDigitalSpace} alt="JESA Digital Space" />
              </a>
            </div>
            <div className="navigation-container">
              <NavBar />
            </div>
            <Logout />
          </AsideMenu>
          <Outlet />
        </AppWrapper>
      </AuthenticatedTemplate>
    </ThemeProvider>
  );
}

export default Settings;
