import { createSelector } from 'reselect';
import { selectAddExternalUsersDomain } from 'services/slices/user-management/external-users';

const makeSelectExternalUser = createSelector(
  selectAddExternalUsersDomain,
  (subState) => subState.externalUser,
);

const makeSelectIsSubmit = createSelector(
  selectAddExternalUsersDomain,
  (subState) => subState.isSubmit,
);

export {
  makeSelectExternalUser,
  makeSelectIsSubmit,
};
