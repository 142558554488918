export const DIGITAL_SPACE = '/digital-space';

export const SETTINGS = '/settings';
export const SETTINGS_PROGRAMS = `${SETTINGS}/programs`;
export const SETTINGS_SECTORS = `${SETTINGS}/sectors`;
export const SETTINGS_BU = `${SETTINGS}/bu`;
export const SETTINGS_DISCIPLINES = `${SETTINGS}/disciplines`;
export const SETTINGS_USERS = `${SETTINGS}/users`;
export const SETTINGS_USERS_ADD_EXTERNAL = `${SETTINGS_USERS}/add-external`;
export const SETTINGS_USERS_EDIT_EXTERNAL = `${SETTINGS_USERS}/add-external/:userId`;
export const SETTING_USERS_DETAILED_VIEWS = `${SETTINGS_USERS}/detailed-view/:userId`;
export const SETTINGS_CLIENTS = `${SETTINGS}/clients`;
export const SETTINGS_VENDORS = `${SETTINGS}/vendors`;
export const SETTINGS_CONTRACTORS = `${SETTINGS}/contractors`;
export const SETTINGS_SUB_CONTRACTORS = `${SETTINGS}/sub-contractors`;
export const SETTINGS_PROJECTS = `${SETTINGS}/projects`;
export const SETTINGS_IN_PROCESS_PROJECTS = `${SETTINGS}/in-process-projects`;
export const PROJECT_SETTINGS = '/project-settings';
export const PROJECT_SETTINGS_CREATE_PROJECT = `${PROJECT_SETTINGS}/create-project`;
export const PROJECT_SETTINGS_EDIT_PROJECT = `${PROJECT_SETTINGS}/create-project/:projectId`;
export const PROJECT_SETTINGS_EDIT_PROJECT_MEMBERS = `${PROJECT_SETTINGS}/project-members/:projectId`;
export const PROJECT_SETTINGS_EDIT_PROJECT_WORK_DIMENSIONS = `${PROJECT_SETTINGS}/project-work-dimensions/:projectId`;
export const ACCESS_MANAGEMENT = `${SETTINGS}/access-management`;
export const ACCESS_MANAGEMENT_LISTING: string = `${ACCESS_MANAGEMENT}/profiles`;
export const ACCESS_MANAGEMENT_ADD_PROFILE = `${ACCESS_MANAGEMENT}/create-profile`;
export const ACCESS_MANAGEMENT_EDIT_PROFILE = `${ACCESS_MANAGEMENT}/create-profile/:profileId`;
export const ACCESS_MANAGEMENT_PROFILE_PRIVILEGE = `${ACCESS_MANAGEMENT}/profile-privilege`;
export const ACCESS_MANAGEMENT_EDIT_PROFILE_PRIVILEGE = `${ACCESS_MANAGEMENT}/profile-privilege/:profileId`;
export const SPLASH_SCREEN = `/`;
