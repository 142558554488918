import React from "react";

const HseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24.414"
    height="29.154"
    viewBox="0 0 24.414 29.154"
  >
    <g
      id="Groupe_65037"
      data-name="Groupe 65037"
      transform="translate(-937.416 -366.519)"
    >
      <path
        id="Tracé_51748"
        data-name="Tracé 51748"
        d="M973.173,386.053c.309,0,.615.01.919.028v-.22L962.806,381.2l-11.286,4.657v5.563c0,5.754,2.239,8.3,2.239,8.3s1.319-6.389,6.172-9.806S971.006,386.053,973.173,386.053Z"
        transform="translate(-13.447 -13.672)"
        fill="#016be3"
      />
      <path
        id="Tracé_51749"
        data-name="Tracé 51749"
        d="M1030.526,459.832l.239-.105a18.079,18.079,0,0,0,10.783-16.54v-5.25c-.3-.018-.6-.028-.9-.028a14.909,14.909,0,0,0-13.326,8.218l1.892,1.892,5.513-5.513a.593.593,0,1,1,.838.838l-5.932,5.932a.593.593,0,0,1-.838,0l-1.994-1.994a14.961,14.961,0,0,0-.328,10.168,18.039,18.039,0,0,0,3.812,2.276Z"
        transform="translate(-80.903 -65.391)"
        fill="#0af"
        opacity="0"
      />
      <path
        id="Tracé_51750"
        data-name="Tracé 51750"
        d="M961.464,371.386h0l-11.614-4.822a.594.594,0,0,0-.454,0l-11.615,4.822a.59.59,0,0,0-.365.547V377.8A19.254,19.254,0,0,0,949.4,395.629a.589.589,0,0,0,.448,0A19.254,19.254,0,0,0,961.83,377.8v-5.862A.59.59,0,0,0,961.464,371.386Zm-.82,6.409a18.079,18.079,0,0,1-10.783,16.54l-.239.105-.239-.105A18.079,18.079,0,0,1,938.6,377.8v-5.466l11.022-4.576,11.022,4.576Z"
        transform="translate(0 0)"
        fill="#00338d"
      />
      <path
        id="Union_132"
        data-name="Union 132"
        d="M3.9,7.116a.6.6,0,0,1-.422-.172l-1.99-2h0L.174,3.629a.59.59,0,0,1,0-.836A.592.592,0,0,1,.59,2.619a.584.584,0,0,1,.422.174L3.9,5.683,9.414.174a.6.6,0,0,1,.842,0,.59.59,0,0,1,0,.836L4.322,6.944A.612.612,0,0,1,4.254,7a.593.593,0,0,1-.352.114Z"
        transform="translate(944.41 376.943)"
        fill="#00338d"
        stroke="rgba(0,0,0,0)"
        strokeWidth="1"
      />
      <path
        id="Tracé_51752"
        data-name="Tracé 51752"
        d="M1021.224,521.736a.593.593,0,0,0-.419,1.012l1.318,1.318q.236-.591.521-1.155l-1-1A.588.588,0,0,0,1021.224,521.736Z"
        transform="translate(-76.223 -142.175)"
        fill="none"
      />
    </g>
  </svg>
);
export default HseIcon;
