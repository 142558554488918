import { styled } from "@mui/material";

const PageHeader = styled('div')`
  display: flex;
  flex-direction: column;

  .top-header {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .page-actions-wrapper {
      display: flex;

      .heading-area {
        display: flex;
        flex-grow: 1;
        flex-basis: 0;
        align-items: center;
        gap: 12px;

        .page-title-area {
          flex-grow: 1;
          flex-basis: 0;
        }
      }
    }
  }
`;

export default PageHeader;
