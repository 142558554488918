import React from 'react';
import { SpeedDial, SpeedDialAction } from '@mui/material';
import jesa from 'assets/svgs/jesa.svg';
import { openInNewTab } from 'utils/redirectionUtil';
import { URLS } from './constants';

const AppRedirectHover = (props) => {
  const handleRedirectionWithJTM = (link) => {
    openInNewTab(link);
  };
  return (
    <SpeedDial
      {...props}
      ariaLabel=""
      sx={{
        position: 'absolute',
        bottom: 16,
        right: 16,
      }}
      icon={
        <img src={jesa} alt="Jesa" style={{ height: '40px', width: '40px' }} />
      }
    >
      {Object.values(URLS).map((item) => (
        <SpeedDialAction
          key={item.name}
          icon={
            <img
              src={item.src}
              alt={item.name}
              style={{ height: '25px', width: '25px' }}
            />
          }
          tooltipTitle={item.name}
          onClick={() => handleRedirectionWithJTM(item.url)}
        />
      ))}
    </SpeedDial>
  );
};
AppRedirectHover.propTypes = {};

export default AppRedirectHover;
