import React from 'react';

const SaveForUserIcon: React.FC = function () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.743"
      height="48.515"
      viewBox="0 0 32.743 48.515"
    >
      <g transform="translate(-829.418 -348)">
        <g transform="translate(829.418 348)">
          <path
            className="a"
            d="M862.161,390.931v4.776a.808.808,0,0,1-1.617,0v-4.776a6.724,6.724,0,0,0-6.728-6.728,4.964,4.964,0,0,1-4.271-2.4.808.808,0,0,1,1.381-.841,3.359,3.359,0,0,0,2.89,1.622,8.339,8.339,0,0,1,8.344,8.344Zm-14.108-8.1a.809.809,0,0,1-.618.962,7.738,7.738,0,0,1-6.015-1.177,4.9,4.9,0,0,1-3.657,1.591,6.724,6.724,0,0,0-6.728,6.728v4.776a.808.808,0,0,1-1.617,0v-4.776a8.34,8.34,0,0,1,8.344-8.344,3.371,3.371,0,0,0,3.379-3.37V379a10.831,10.831,0,0,1-6.157-9.363,3.3,3.3,0,0,1-2.534-3.2v-4.191c-.044-2.052.261-7.19,4.007-10.8,4.626-4.459,12.045-4.176,17.5-1.55a.81.81,0,0,1,.171,1.346,10.91,10.91,0,0,1,6.677,4.8.892.892,0,0,1,.186.635.813.813,0,0,1-.232.513,5.281,5.281,0,0,0-1.528,3.729v5.515a.808.808,0,0,1-1.617,0v-5.515a6.884,6.884,0,0,1,1.57-4.395,9.338,9.338,0,0,0-6.7-3.935l-1.421-.152a.809.809,0,0,1-.222-1.552l.646-.266c-8.417-2.934-17.418.326-17.418,11.615v4.2a1.67,1.67,0,0,0,.91,1.479v-5.241a5.666,5.666,0,0,1,5.66-5.66h10.308a5.666,5.666,0,0,1,5.659,5.659v6.62a10.828,10.828,0,0,1-13.863,10.314,4.939,4.939,0,0,1-.423,1.651,6.12,6.12,0,0,0,4.773.941.809.809,0,0,1,.962.618Zm-2.263-4.388a9.206,9.206,0,0,0,9.2-9.2v-6.558a4.046,4.046,0,0,0-4.042-4.042H840.636a4.047,4.047,0,0,0-4.042,4.042v6.558a9.207,9.207,0,0,0,9.2,9.2Zm8.186,13.934H851.55a.809.809,0,0,0,0,1.617h2.426a.809.809,0,0,0,0-1.617Zm0,0"
            transform="translate(-829.418 -348)"
          />
        </g>
      </g>
    </svg>
  );
};
export default SaveForUserIcon;
