import React from "react";

const RiskManagementIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31.59"
    height="26.152"
    viewBox="0 0 31.59 26.152"
  >
    <g
      id="Groupe_65031"
      data-name="Groupe 65031"
      transform="translate(18768.75 -4624.099)"
    >
      <g
        id="Groupe_61766"
        data-name="Groupe 61766"
        transform="translate(-18768.75 4624.099)"
      >
        <path
          id="Tracé_51778"
          data-name="Tracé 51778"
          d="M10.389,26.351H4.583a.669.669,0,0,1-.632-.7V8.87H.832A.632.632,0,0,1,.25,8.441a.752.752,0,0,1,.139-.767L7.042.393a.607.607,0,0,1,.888,0l6.654,7.281a.75.75,0,0,1,.138.767.631.631,0,0,1-.582.429H11.021V25.651A.669.669,0,0,1,10.389,26.351Z"
          transform="translate(8.309 -0.2)"
          fill="#016be3"
        />
        <path
          id="Tracé_51776"
          data-name="Tracé 51776"
          d="M10.389,26.351a.669.669,0,0,1-.632-.7V8.17a.669.669,0,0,1,.632-.7H12.6l-5.117-5.6L2.5,7.33l-.129.141H4.583a.669.669,0,0,1,.632.7V25.651a.669.669,0,0,1-.632.7.669.669,0,0,1-.632-.7V8.87H.832A.632.632,0,0,1,.25,8.441a.752.752,0,0,1,.139-.767L7.042.393a.607.607,0,0,1,.888,0l6.654,7.281a.75.75,0,0,1,.138.767.631.631,0,0,1-.582.429H11.021V25.651A.669.669,0,0,1,10.389,26.351Z"
          transform="translate(8.281 -0.2)"
          fill="#00338d"
        />
        <path
          id="Tracé_51775"
          data-name="Tracé 51775"
          d="M8.249,18.246a.656.656,0,0,1-.655-.655V6.095a.656.656,0,0,1,.655-.655h1.2L6.095,2.082,2.737,5.44h1.2a.656.656,0,0,1,.655.655v11.5a.656.656,0,0,1-1.311,0V6.75H1.155A.655.655,0,0,1,.691,5.632L5.631.692a.655.655,0,0,1,.927,0l4.94,4.94a.655.655,0,0,1-.464,1.119H8.906V17.591A.657.657,0,0,1,8.249,18.246Z"
          transform="translate(19.899 7.779)"
          fill="#00338d"
        />
        <path
          id="Tracé_51777"
          data-name="Tracé 51777"
          d="M8.25,18.246a.656.656,0,0,1-.655-.655V6.095A.656.656,0,0,1,8.25,5.44h1.2L6.1,2.082,2.738,5.44h1.2a.656.656,0,0,1,.655.655v11.5a.655.655,0,0,1-1.31,0V6.75H1.155A.655.655,0,0,1,.692,5.631L5.632.692a.653.653,0,0,1,.926,0L11.5,5.63a.656.656,0,0,1-.464,1.12H8.9V17.591A.656.656,0,0,1,8.25,18.246Z"
          transform="translate(-0.5 7.779)"
          fill="#00338d"
        />
      </g>
    </g>
  </svg>
);
export default RiskManagementIcon;
