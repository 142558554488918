import { styled } from "@mui/material";
import Pagination from '@mui/material/Pagination';

const CustomPagination = styled(Pagination)`
  display: flex;
  width: 100%;

  & > ul {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;

    & > li {
      & > button {
        font-family: 'HelveticaNeueBold', sans-serif;
        font-size: 14px;
        color: ${(props) => props.theme.AppColors.blueViolet_200};
        border-radius: 8px;
        background: transparent;

        &:hover,
        &.Mui-selected {
          color: ${(props) => props.theme.GreyColors.white} !important;
          background-color: ${(props) =>
            props.theme.AppColors.bluishCyan_200} !important;
        }
      }

      &:first-child,
      &:last-child {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        & > button {
          font-size: 15px;
          color: ${(props) => props.theme.AppColors.blueViolet_100};
          background-color: ${(props) => props.theme.GreyColors.white};
          height: 36px;
          padding: 12px;
          border: 1px solid ${(props) => props.theme.GreyColors[1100]};
        }
      }

      &:first-child {
        left: 0;
      }

      &:last-child {
        right: 0;
      }
    }
  }
`;

export default CustomPagination;
