import { Card, CardActions, CardContent, CardMedia, styled } from '@mui/material';
import theme from 'ui/theme';

export const Cards = styled(Card)`
  width: 266px;
  position: relative;
  & .SvgWrapper {
    position: absolute;
    top: 0;
    right: 0;
    width: 38px;
    height: 38px;
  }
  & .SvgIcon {
    cursor: pointer;
    width: 80%;
    height: 80%;
    margin-top: 5px;
    background-color: ${theme.palette.common.white};
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
  }
  &:hover .SvgIcon {
    opacity: 1;
  }
`;
export const MediaCard = styled(CardMedia)`
  height: 176px;
`;
export const ContentCard = styled(CardContent)`
  align-items: center;
  padding: 0 8px;

  '& .wlTitleDimension': {
    width: 144px;
    height: 20px;
  },
`;
export const StatusCard = styled('div')`
  text-align: center;
  border-radius: 20px;
  background-color: ${theme.palette.success.main};
  padding: 5px;
  width: 70px;
  color: ${theme.palette.light.main};
`;
export const ActionsCard = styled(CardActions)`
  justify-content: flex-end;
  padding: 10px 16px;
`;
