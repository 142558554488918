import React from "react";

const EmployeeIcon = () => (
  <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 30" width="25" height="30">
    <title>EmployeeIcon</title>
    <defs>
      <image width="25" height="30" id="img11"
             href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAfCAMAAAAhm0ZxAAAAAXNSR0IB2cksfwAAAFdQTFRFAAAAAHv/AHz/AHz/AHv/AHz/AHv/AHz/AHv/AHj/AHz/AHz/AHz/AHz/AHz/AHv/AHr/AID/AHz/AHv/AHr/AHn/AHv/AHv/AHz/AHz/AH3/AHv/AHv/iXiEnQAAAB10Uk5TAHD/QJ/voNDfIJBQgMB/4DAQb/BgSrBfr78/z09tPE8OAAABMElEQVR4nG2TC5KEIAxEY6L4ISgMLsq49z/nRnTRwUkVVoonSdMAQI4K96jgEVQ3qIioxa7uP9GASg8siVHa4HhHZrLu1SUmH/L1hVTr5mZq/xlYz7mXtxpn4MyA0J6sZfdDcGegwoEsOr3P3JnMHfsyxz93Bl6n5Th8YQF3wUhgl3GNsUu+dDHW42KdlqqE4khrmCs6gplNi00tayoM2j0sdOKOBp/MJSmaSmIT19c7SdzArO9lRTSpppNRDUYqLr9bgD6gH2xRUivv1d6JzaOdeJX2B/P2hYnKw9gn6k/PAMt20tCfyTQ/WNYQ+MHy1Behkz4TakrksM9ZKWaecqrWgplLQe9fH0j528lYH6/LbLfpo4cLzXnVbY1DeZ5kcFvHuMmrKFXvXSsOPN8eyh+dpQ2Dh1vZWwAAAABJRU5ErkJggg==" />
    </defs>
    <style>
    </style>
    <use id="Background" href="#img11" x="0" y="0" />
  </svg>
);
export default EmployeeIcon;
