import { Stack, styled } from '@mui/material';

const FormDateWrapper = styled(Stack)(({ theme }) => ({
  minWidth: `150px`,

  '& .isError': {
    '& fieldset': {
      borderColor: theme.palette.danger.secondary,
    },
  },

  '& .MuiInputBase-root': {
    height: '34px',
    background: theme.palette.light.main,
  },
  '& fieldset': {
    borderColor: theme.palette.border.main,
    borderRadius: '8px',
  },
  '& .MuiSvgIcon-root': {
    transform: `translateX(${theme.spacing(-15)})`,
    fill: theme.palette.primary.main,
  },

  [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},

  [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {},
}));
export default FormDateWrapper;
