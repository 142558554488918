import React from "react";

const ProgressIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.661"
    height="25.722"
    viewBox="0 0 25.661 25.722"
  >
    <g
      id="Groupe_65108"
      data-name="Groupe 65108"
      transform="translate(18769.25 -4878.75)"
    >
      <path
        id="Tracé_51789"
        data-name="Tracé 51789"
        d="M.869,0H5.551A.869.869,0,0,1,6.42.869V7.82a.869.869,0,0,1-.869.869H.869A.869.869,0,0,1,0,7.82V.869A.869.869,0,0,1,.869,0Z"
        transform="translate(-18762.951 4895.476)"
        fill="#016be3"
      />
      <g
        id="Groupe_61771"
        data-name="Groupe 61771"
        transform="translate(-18769.25 4878.75)"
      >
        <path
          id="Tracé_51788"
          data-name="Tracé 51788"
          d="M21.4,5.943V.593l.276-.171.88.066L27.568,1.9a1.232,1.232,0,0,1,0,2.372l-6.1,1.606c-.052.015.054-.85,0-.85-.262,0,6.1-.756,6.1-.756L23.387,5.444l-.809.228Z"
          transform="translate(-2.807 -0.056)"
          fill="#016be3"
        />
        <path
          id="Tracé_51787"
          data-name="Tracé 51787"
          d="M21.646,26.94a.588.588,0,0,1-.588-.588c0-.325,2.88-.325,2.88,0a.589.589,0,0,1-.589.588H19.913V10.274A.985.985,0,0,1,20.9,9.29h4.976a.985.985,0,0,1,.985.984V25.957a.985.985,0,0,1-.985.984Zm-1.733-1.176v0Z"
          transform="translate(-2.611 -1.218)"
          fill="#016be3"
        />
        <path
          id="Tracé_51786"
          data-name="Tracé 51786"
          d="M22.51,25.722a.588.588,0,0,1,0-1.176h.559V9.248H18.478v15.3h2.26a.588.588,0,1,1,0,1.176H.985A.985.985,0,0,1,0,24.738v-3.3a.986.986,0,0,1,.985-.985H5.767V17.311a.985.985,0,0,1,.985-.984h4.783V13.183a.986.986,0,0,1,.985-.984H17.3V9.055a.985.985,0,0,1,.985-.984h.309V.588A.588.588,0,0,1,19.75.433l5.01,1.408a1.232,1.232,0,0,1,0,2.372l-2.623.737a.578.578,0,0,1-.16.023.59.59,0,0,1-.565-.429.588.588,0,0,1,.407-.726l2.623-.737c.019-.005.04-.012.04-.054a.046.046,0,0,0-.041-.053L19.771,1.662V4.393l.49-.138a.588.588,0,0,1,.319,1.132l-.809.228V8.072h3.49a.985.985,0,0,1,.985.984V24.738a.985.985,0,0,1-.985.984Zm-9.8-5.387v4.211H17.3V13.376H12.711v5.258a.588.588,0,0,1-1.176,0V17.5H6.944v7.042h4.59V20.335a.588.588,0,0,1,1.177,0ZM1.177,24.546h4.59V21.632H1.177Z"
          fill="#00338d"
        />
      </g>
    </g>
  </svg>
);
export default ProgressIcon;
