export enum PLATFORM_SETTINGS_TYPES {
  PROGRAM = 'PROGRAM',
  SECTOR = 'SECTOR',
  BU = 'BU',
  DISCIPLINE = 'DISCIPLINE',
  CLIENT = 'CLIENT',
  VENDOR = 'VENDOR',
  CONTRACTOR = 'CONTRACTOR',
  SUB_CONTRACTOR = 'SUB_CONTRACTOR',
}

export const contractorManagementItems = ['SUB_CONTRACTOR', 'CONTRACTOR', 'VENDOR'];

export const baseRoutes = {
  workLocation: 'work-location/get-work-locations-avatars',
};

export enum PROJECT_CREATION_STATUS {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}
