import React from "react";

const ChangeManagementIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28.797"
    height="31.693"
    viewBox="0 0 28.797 31.693"
  >
    <g
      id="Groupe_65035"
      data-name="Groupe 65035"
      transform="translate(18769 -5003)"
    >
      <path
        id="Tracé_51796"
        data-name="Tracé 51796"
        d="M717.369,285a1.266,1.266,0,0,1,.9.372l3.1,3.1a1.9,1.9,0,0,1,0,2.692l-3.1,3.1a1.27,1.27,0,0,1-2.167-.9v-6.709s.983.946.633.946a.634.634,0,0,1-.633-.634v-.694A1.271,1.271,0,0,1,717.369,285Zm0,0"
        transform="translate(-19474.707 4718)"
        fill="#016be3"
      />
      <path
        id="Tracé_51797"
        data-name="Tracé 51797"
        d="M720.656,285a1.266,1.266,0,0,0-.9.372l-3.1,3.1a1.9,1.9,0,0,0,0,2.692l3.1,3.1a1.27,1.27,0,0,0,2.167-.9v-6.709s-.984.946-.634.946a.634.634,0,0,0,.634-.634v-.694A1.271,1.271,0,0,0,720.656,285Zm0,0"
        transform="translate(-19472.52 4740.064)"
        fill="#016be3"
      />
      <path
        id="Tracé_51745"
        data-name="Tracé 51745"
        d="M708.558,301.218a1.266,1.266,0,0,1-.9-.372l-3.1-3.1a1.9,1.9,0,0,1,0-2.692l3.1-3.1a1.27,1.27,0,0,1,2.167.9v2.912a9.134,9.134,0,0,0,9.123-9.123v-1.014a.634.634,0,1,1,1.267,0v1.014a10.39,10.39,0,0,1-10.391,10.39,1.269,1.269,0,0,1-1.267-1.267v-2.912h0l-3.1,3.1a.635.635,0,0,0,0,.9l3.1,3.1h0v-.694a.634.634,0,0,1,1.267,0v.694a1.271,1.271,0,0,1-1.271,1.269Zm0,0"
        transform="translate(-19460.422 4733.475)"
        fill="#00338d"
      />
      <path
        id="Tracé_51746"
        data-name="Tracé 51746"
        d="M715.662,285a1.266,1.266,0,0,1,.9.372l3.1,3.1a1.9,1.9,0,0,1,0,2.692l-3.1,3.1a1.27,1.27,0,0,1-2.167-.9v-2.912a9.134,9.134,0,0,0-9.124,9.123v1.013a.634.634,0,1,1-1.267,0v-1.013a10.39,10.39,0,0,1,10.391-10.391,1.269,1.269,0,0,1,1.267,1.267v2.912h0l3.1-3.1a.634.634,0,0,0,0-.9l-3.1-3.1h0v.694a.634.634,0,0,1-1.267,0v-.694A1.271,1.271,0,0,1,715.662,285Zm0,0"
        transform="translate(-19473 4718)"
        fill="#00338d"
      />
      <path
        id="Path_51747"
        data-name="Path 51747"
        d="M-2805.541,1242.115a.642.642,0,0,1-.455-.189.644.644,0,0,1,0-.911l9.783-9.783,2.41,2.41,7.574-7.574a.644.644,0,0,1,.911,0,.644.644,0,0,1,0,.911l-8.485,8.486-2.41-2.41-8.872,8.872A.643.643,0,0,1-2805.541,1242.115Z"
        transform="translate(-15959.205 3785.874)"
        fill="#00338d"
      />
    </g>
  </svg>
);
export default ChangeManagementIcon;
