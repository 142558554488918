import { styled } from '@mui/material';
import { NavLink } from 'react-router-dom';

const MenuItemLink = styled(NavLink)`
  font-family: 'HelveticaNeueMedium', sans-serif;
  color: ${(props) => props.theme.AppColors.blueViolet_100};
  text-decoration: none;
  display: flex;
  min-height: 36px;
  border-radius: 8px;
  border: none;
  background: transparent;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 10px;
  transition: all 0.2s ease;

  &.active {
    color: ${(props) => props.theme.GreyColors.white};
    background: ${(props) => props.theme.AppColors.bluishCyan_200};
  }

  &[type='button'] {
    width: 100%;
  }

  .link-text {
    font-size: 14px;
    text-align: left;
    flex-grow: 1;
    flex-basis: 0;
  }

  .link-arrow {
    transform: rotate(0);
    transition: all 0.2s ease;

    &.active {
      transform: rotate(180deg);
    }
  }

  &:hover {
    color: ${(props) => props.theme.GreyColors.white};
    background: ${(props) => props.theme.AppColors.bluishCyan_200};
  }
`;

export default MenuItemLink;
