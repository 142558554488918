import React from 'react';

const ArrowDown = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={11.826}
    height={6.621}
    {...props}
  >
    <path
      data-name="Path 67266"
      d="m1.412 1.414 4.651 4.207 4.349-4.207"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

export default ArrowDown;
