import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { api } from 'services/apis';
import authReducer from 'services/slices/auth';
import globalReducer from 'services/slices/global';
import projectSetting from './services/slices/project-setting';
import userManagement from './services/slices/user-management';
import projectAdministration from './services/slices/project-administration';
import accessManagement from './services/slices/access-management';

const store = configureStore({
  reducer: combineReducers({
    auth: authReducer,
    projectSetting,
    userManagement,
    projectAdministration,
    global: globalReducer,
    accessManagement,
    [api.reducerPath]: api.reducer,
  }),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
