import * as React from 'react';
import { useEffect, useState } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { FormWrapper } from 'components/StyledComponents/FormInputs';
import { AppButton, MainButton } from 'components/Buttons';
import { CloseIcon, FilterSvg } from 'assets/svgs/componentsIcons';
import ActionArea from 'components/StyledComponents/ActionArea';
import FormInput from 'components/FormInput';
import FormSelect from 'components/FormSelect';
import FormSwitch from 'components/FormSwitch';
import { useGetProjectAdministrationDataByTypesQuery } from 'services/apis/project-administration';
import {
  PROJECT_DATA_ENUM,
  PROJECT_DATA_TYPES,
} from 'pages/AppProjectsSettings/ProjectsSettingsProjectCreation/constants';
import { useGetEnumerationsByTypesQuery } from 'services/apis/enumeration';
import { useGetPlatformsQuery } from 'services/apis/platform';
import PropTypes from 'prop-types';
import { useLazyGetAllProjectsQuery } from 'services/apis/project';
import {
  appendToProjectCriteria,
  setProjects,
} from 'services/slices/project-setting/projects';
import { useDispatch, useSelector } from 'react-redux';
import { useLazySearchUsersQuery } from 'services/apis/user';
import AutocompleteInput from 'components/AutocompleteInput';
import FullPageLoader from 'components/FullPageLoader';
import { createStructuredSelector } from 'reselect';
import { makeSelectProjectCriteria } from 'services/slices/project-setting/selectors';
import ProjectsSettingsFilterWrapper from './Wrapper';
import FILTER_INPUTS from './constants';

const stateSelector = createStructuredSelector({
  projectCriteria: makeSelectProjectCriteria,
});
function ProjectsSettingsFilter({ projectType, changePage }) {
  const dispatch = useDispatch();
  const { projectCriteria } = useSelector(stateSelector);

  const [isFilter, setIsFilter] = useState(false);
  const page = 0;
  const [projectPmName, setProjectPmName] = useState('');
  const [projectDpeName, setProjectDpeName] = useState('');

  const [doSearchProjectsByCriteria, { data: projects, isFetching: filterLoading }] =
    useLazyGetAllProjectsQuery();
  const { data: enumerations } = useGetEnumerationsByTypesQuery(PROJECT_DATA_ENUM);
  const { data: projectData } =
    useGetProjectAdministrationDataByTypesQuery(PROJECT_DATA_TYPES);
  const { data: platforms } = useGetPlatformsQuery();
  const [doSearchUsersPm, { data: usersPm = [] }] = useLazySearchUsersQuery();
  const [doSearchUsersDpe, { data: usersDpe = [] }] = useLazySearchUsersQuery();

  useEffect(() => {
    if (projects) dispatch(setProjects(projects));
  }, [projects]);
  const handleChange = (name, value) => {
    dispatch(appendToProjectCriteria({ ...projectCriteria, [name]: value }));
  };

  const getValue = (key) => {
    return projectCriteria[key] || false;
  };
  const toggleFilter = () => {
    setIsFilter(!isFilter);
  };

  const handelDisplayName = (u) => {
    if (u.firstName && u.lastName) {
      return { ...u, fullName: `${u.firstName} ${u.lastName}` };
    }
    return { ...u, fullName: u.mail };
  };

  useEffect(() => {
    if (projectPmName.length >= 3) {
      doSearchUsersPm({
        name: projectPmName,
        isExternal: false,
      });
    }
  }, [projectPmName, doSearchUsersPm]);

  useEffect(() => {
    if (projectDpeName.length >= 3) {
      doSearchUsersDpe({
        name: projectDpeName,
        isExternal: false,
      });
    }
  }, [projectDpeName, doSearchUsersDpe]);

  return (
    <ProjectsSettingsFilterWrapper>
      {filterLoading && <FullPageLoader />}
      <ActionArea className="filterWrapper">
        <FormWrapper>
          <Typography variant="h4_HelveticaNeue_Medium" color="primary.main">
            {isFilter ? 'Filter' : 'Projects listing'}
          </Typography>
          <AppButton
            placeholder="Search"
            customClass="filter"
            label=""
            startIcon={isFilter ? <CloseIcon /> : <FilterSvg />}
            labelVariant="subtitle1_HelveticaNeue_Medium"
            labelColor="text.main"
            onClick={toggleFilter}
          />
        </FormWrapper>

        <Stack className={`filter__inputs--container   ${isFilter ? 'show' : 'hide'}`}>
          <Grid container spacing={5}>
            <Grid container item spacing={30}>
              <Grid item xs={4}>
                <Stack className="filterCriteria">
                  <FormInput
                    label="Name"
                    placeholder="Name"
                    onChange={(e) => handleChange(FILTER_INPUTS.name, e.target.value)}
                    value={getValue(FILTER_INPUTS.name)}
                    max="100"
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack className="filterCriteria">
                  <FormInput
                    label="Number"
                    placeholder="Number"
                    onChange={(e) => handleChange(FILTER_INPUTS.number, e.target.value)}
                    value={getValue(FILTER_INPUTS.number)}
                    max="100"
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack className="filterCriteria">
                  <FormSelect
                    menuItems={projectData?.BU?.map((bu) => bu.name)}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      const buCode = projectData.BU.find(
                        (bu) => bu.name === selectedValue,
                      )?.code;
                      handleChange(FILTER_INPUTS.bu, buCode);
                    }}
                    value={
                      projectData?.BU?.find(
                        (bu) => bu.code === getValue(FILTER_INPUTS.bu),
                      )?.name
                    }
                    label="BU"
                    valueKey="BU"
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid container item spacing={30}>
              <Grid item xs={4}>
                <Stack className="filterCriteria">
                  <FormSelect
                    menuItems={projectData?.PROGRAM?.map((program) => program.name)}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      const programCode = projectData.PROGRAM?.find(
                        (program) => program.name === selectedValue,
                      )?.code;
                      handleChange(FILTER_INPUTS.program, programCode);
                    }}
                    value={
                      projectData?.PROGRAM?.find(
                        (program) => program.code === getValue(FILTER_INPUTS.program),
                      )?.name
                    }
                    label="Program"
                    valueKey="Program"
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack className="filterCriteria">
                  <FormSelect
                    menuItems={projectData?.SECTOR?.map((sector) => sector.name)}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      const sectorCode = projectData.SECTOR.find(
                        (sector) => sector.name === selectedValue,
                      )?.code;
                      handleChange(FILTER_INPUTS.sector, sectorCode);
                    }}
                    value={
                      projectData?.SECTOR?.find(
                        (sector) => sector.code === getValue(FILTER_INPUTS.sector),
                      )?.name
                    }
                    label="Sector"
                    valueKey="Sector"
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack className="filterCriteria">
                  <FormSelect
                    menuItems={enumerations?.PROJECT_SIZE?.map((size) => size.label)}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      const sizeCode = enumerations?.PROJECT_SIZE.find(
                        (size) => size.label === selectedValue,
                      )?.code;
                      handleChange(FILTER_INPUTS.projectSize, sizeCode);
                    }}
                    value={
                      enumerations?.PROJECT_SIZE?.find(
                        (size) => size.code === getValue(FILTER_INPUTS.projectSize),
                      )?.label
                    }
                    label="Size"
                    valueKey="Size"
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid container item spacing={30}>
              <Grid item xs={4}>
                <Stack className="filterCriteria">
                  <FormSelect
                    menuItems={enumerations?.RISK_CLASSIFICATION?.map(
                      (risk) => risk.label,
                    )}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      const riskCode = enumerations?.RISK_CLASSIFICATION.find(
                        (risk) => risk.label === selectedValue,
                      )?.code;
                      handleChange(FILTER_INPUTS.riskClassification, riskCode);
                    }}
                    value={
                      enumerations?.RISK_CLASSIFICATION?.find(
                        (risk) =>
                          risk.code === getValue(FILTER_INPUTS.riskClassification),
                      )?.label
                    }
                    label="Risk Level"
                    valueKey="Risk Level"
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack className="filterCriteria">
                  <FormSwitch
                    label="At-Risk"
                    onChange={() =>
                      handleChange(FILTER_INPUTS.atRisk, !getValue(FILTER_INPUTS.atRisk))
                    }
                    checked={getValue(FILTER_INPUTS.atRisk) || false}
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack className="filterCriteria">
                  <FormSelect
                    menuItems={platforms?.map((platform) => platform?.name)}
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      const platformCode = platforms?.find(
                        (platform) => platform.name === selectedValue,
                      )?.code;
                      handleChange(FILTER_INPUTS.platform, platformCode);
                    }}
                    value={
                      platforms?.find(
                        (platform) => platform.code === getValue(FILTER_INPUTS.platform),
                      )?.name
                    }
                    label="Platform"
                    valueKey="Platform"
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid container item spacing={30}>
              <Grid item xs={4}>
                <Stack className="filterCriteria">
                  <AutocompleteInput
                    className="formWidth"
                    isWithCheckbox={false}
                    label="Project PM"
                    handleOnInputChange={(_, value, reason) => {
                      if (reason === 'input') {
                        setProjectPmName(value);
                      }
                    }}
                    handleOnChange={(_, values) => {
                      handleChange(FILTER_INPUTS.projectPmId, values[0].id);
                      setProjectPmName(values[0].fullName);
                    }}
                    inputValue={projectPmName}
                    placeholder="Type 3 characters to search..."
                    options={usersPm?.content?.map((u) => handelDisplayName(u))}
                    valueKey="fullName"
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack className="filterCriteria">
                  <AutocompleteInput
                    className="formWidth"
                    isWithCheckbox={false}
                    label="Project DPE"
                    handleOnInputChange={(_, value, reason) => {
                      if (reason === 'input') {
                        setProjectDpeName(value);
                      }
                    }}
                    handleOnChange={(_, values) => {
                      handleChange(FILTER_INPUTS.projectDpeId, values[0].id);
                      setProjectDpeName(values[0].fullName);
                    }}
                    inputValue={projectDpeName}
                    placeholder="Type 3 characters to search..."
                    options={usersDpe?.content?.map((u) => handelDisplayName(u))}
                    valueKey="fullName"
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack className="filterCriteria form-split">
                  <Stack>
                    <MainButton
                      secondary
                      type="button"
                      onClick={() => {
                        changePage(0);
                        toggleFilter();
                        dispatch(appendToProjectCriteria({}));
                        setProjectPmName('');
                        setProjectDpeName('');
                        doSearchProjectsByCriteria({ page, projectType });
                      }}
                    >
                      <span>Undo</span>
                    </MainButton>
                  </Stack>
                  <Stack>
                    <MainButton
                      type="button"
                      onClick={() => {
                        changePage(0);
                        toggleFilter();
                        doSearchProjectsByCriteria({
                          ...projectCriteria,
                          page,
                          projectType,
                        });
                      }}
                    >
                      <span>Apply Filter</span>
                    </MainButton>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
      </ActionArea>
    </ProjectsSettingsFilterWrapper>
  );
}

ProjectsSettingsFilter.propTypes = {
  projectType: PropTypes.string,
  changePage: PropTypes.func,
};
export default ProjectsSettingsFilter;
