import React from 'react';

const QaLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="146" height="146" viewBox="0 0 146 146">
    <defs>
      <filter id="Ellipse_1312" x="0" y="0" width="146" height="146" filterUnits="userSpaceOnUse">
        <feOffset input="SourceAlpha"/>
        <feGaussianBlur stdDeviation="10" result="blur"/>
        <feFlood flood-color="#1e2769" flood-opacity="0.09"/>
        <feComposite operator="in" in2="blur"/>
        <feComposite in="SourceGraphic"/>
      </filter>
      <linearGradient id="linear-gradient" x1="0.734" y1="0.34" x2="0.138" y2="0.547" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#59bb8a"/>
        <stop offset="1" stop-color="#59bb8a" stop-opacity="0"/>
      </linearGradient>
      <linearGradient id="linear-gradient-2" x1="0.232" y1="0.633" x2="0.763" y2="0.765"
                      gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#63b3ee"/>
        <stop offset="1" stop-color="#59bb8a"/>
      </linearGradient>
    </defs>
    <g id="Group_63892" data-name="Group 63892" transform="translate(-762.754 -477.813)">
      <g id="Group_63828" data-name="Group 63828" transform="translate(793 508)">
        <g id="Group_66886" data-name="Group 66886" transform="translate(-1081.246 -377.187)">
          <g id="Group_63829" data-name="Group 63829" transform="translate(950.371 26.917)">
            <g transform="matrix(1, 0, 0, 1, 100.63, 320.08)" filter="url(#Ellipse_1312)">
              <circle id="Ellipse_1312-2" data-name="Ellipse 1312" cx="43" cy="43" r="43" transform="translate(30 30)"
                      fill="#fcfcff"/>
            </g>
          </g>
        </g>
        <g id="Group_66880" data-name="Group 66880" transform="translate(21.592 21.809)">
          <g id="Group_66872" data-name="Group 66872" transform="translate(25.637 28.07)">
            <path id="Union_118" data-name="Union 118"
                  d="M16.807,13.941H6.757L4.988,11.615.666,5.924,0,5.047A12.916,12.916,0,0,0,3.491,3.029,13.075,13.075,0,0,0,6.235,0L8.36,2.8l2.374,3.125.551.724,3.262,4.3Z"
                  fill="#59bb8a"/>
          </g>
          <path id="Path_68905" data-name="Path 68905"
                d="M688.694,339.9c0,.032,0,.066,0,.1a20.875,20.875,0,0,1-2.68,10.177l-5.336-7.025v-3.329a13.072,13.072,0,0,0-12.446-13.053l-.01-.016h-.613a13.063,13.063,0,0,0-13.069,13.069v.178h0a13.067,13.067,0,0,0,13.067,12.892h.455l5.464,7.193a21.006,21.006,0,0,1-26.844-20.085c0-.032,0-.066,0-.1a20.887,20.887,0,0,1,2.2-9.372c.282-.57.593-1.126.927-1.664a20.72,20.72,0,0,1,1.5-2.113c.309-.385.631-.758.97-1.12a20.528,20.528,0,0,1,2.262-2.111,21.01,21.01,0,0,1,26.139-.128,20.981,20.981,0,0,1,8.017,16.509Z"
                transform="translate(-646.678 -318.89)" fill="url(#linear-gradient)"/>
          <path id="Path_68908" data-name="Path 68908"
                d="M16436.008-21494.906a20.8,20.8,0,0,1-7.094-1.227,20.715,20.715,0,0,1-6.059-3.391,20.945,20.945,0,0,1-3.359-3.4,20.737,20.737,0,0,1-3.285-5.953,20.87,20.87,0,0,1-1.211-6.937v-.094a20.831,20.831,0,0,1,1.121-6.789,20.85,20.85,0,0,0,4.461,11.82,20.894,20.894,0,0,0,3.359,3.391,21.083,21.083,0,0,0,8.762,4.164,13.015,13.015,0,0,0,2.648.391c.355.031.715.047,1.063.055l5.434,7.148A20.985,20.985,0,0,1,16436.008-21494.906Z"
                transform="translate(-16415 21536.918)" fill="url(#linear-gradient-2)"/>
        </g>
      </g>
    </g>
  </svg>
);

export default QaLogo;
