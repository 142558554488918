type AuthConfig = {
  clientId: string;
  authority: string;
  scope: string;
  redirectUri: string;
  postLogoutRedirectUri?: string;
  cacheLocation: "localStorage" | "sessionStorage" | "memoryStorage";
  loginHint?: string;
};

type GlobalConfigType = {
  api: {
    BACKEND_BASE_URL: string,
  },
  auth: {
    msal: AuthConfig,
  }
}

// clientId per environment
const clientId = window.azureConfig?.clientID ?? process.env.REACT_APP_AUTH_MSAL_CLIENT_ID ?? "";
const scope = `${clientId}/${process.env.REACT_APP_AUTH_MSAL_SCOPE}`;
const tenantId = window.azureConfig?.tenantID ?? process.env.REACT_APP_AUTH_MSAL_TENANT_ID ?? "";

const GlobalConfig: GlobalConfigType = {
  api: {
    BACKEND_BASE_URL: process.env.REACT_APP_BACKEND_BASE_URL ?? '',
  },
  auth: {
    msal: {
      authority: `https://login.microsoftonline.com/${tenantId}`,
      clientId,
      scope,
      redirectUri: window.location.origin,
      cacheLocation: "localStorage",
    }
  }
};

export default GlobalConfig;
