import { Stack, styled } from '@mui/material';

const ProjectsSettingsProjectCreationWrapper = styled(Stack)(({ theme }) => ({
  flex: '1 1 80%',
  padding: '48px 56px 34px',
  overflowY: 'auto',
  '& .top-header': {
    gap: '8px',

    '& .heading-area': {
      flexDirection: 'row',
      alignItems: 'center',
      gap: '12px',
    },
  },

  '& .card-list': {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },

  '& .formInputs': {
    width: '60%',
  },
  '& .customHeight': {
    minHeight: '30px',
  },
  '& .switchForm': {
    display: 'flex',
    justifyContent: 'space-evenly',
    transform: 'translateY(-30px)',
  },

  '& .addWoButton': {
    width: '80px',
    marginRight: '3%',
    background: theme.palette.gradient_blue.main,
  },

  '& .projectCreationForm': {
    paddingBlock: '30px',
    '& .form-search-area': {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '30px',
    },
  },

  '& .atRiskForms': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '5px',
  },

  '& .subProgram': {
    '.chipStyleWrapper': {
      marginBottom: '40px',
    },
  },

  [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},

  [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},

  [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {},
}));
export default ProjectsSettingsProjectCreationWrapper;
