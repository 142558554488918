import React from 'react';
import { useLocation } from 'react-router-dom';
import { Stack } from '@mui/material';
import { JesaLogoSvg } from 'assets/svgs/componentsIcons/index';
import TimelineMenu from 'components/TimelineMenu';
import {
  ACCESS_MANAGEMENT_ADD_PROFILE,
  ACCESS_MANAGEMENT_PROFILE_PRIVILEGE,
} from 'routes/constants';
import { ProfileSettingsMenuWrapper } from './Wrapper';

const ProfileSettingsMenu: React.FC = function () {
  const location = useLocation();
  return (
    <ProfileSettingsMenuWrapper>
      <Stack alignItems="center">
        <JesaLogoSvg />
      </Stack>

      <Stack className="create__profile--timeline">
        <TimelineMenu
          items={[
            {
              title: 'General information',
              subtitle: 'From the project handover to the project location',
              selected: location.pathname.includes(ACCESS_MANAGEMENT_ADD_PROFILE),
            },
            {
              title: 'Profile settings',
              subtitle:
                'The associations of the project members to be added to the project',
              selected: location.pathname.includes(ACCESS_MANAGEMENT_PROFILE_PRIVILEGE),
            },
          ]}
        />
      </Stack>
    </ProfileSettingsMenuWrapper>
  );
};

export default ProfileSettingsMenu;
