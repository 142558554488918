import { useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Stack, Typography } from '@mui/material';
import User from 'assets/images/User.png';
import logo from 'assets/svgs/logo.svg';
import BoldCloseIcon from 'assets/svgs/componentsIcons/BoldCloseIcon';
import { LOGO_ICONS } from 'containers/AppLandingPage/LogoIcons';
import urlPlatform from 'endpoints.config';
import { useHasAccessToSeniorManagementViewQuery } from 'services/apis/user';
import { openInNewTab } from 'utils/redirectionUtil';
import { COLLAB_SENIOR_MANAGEMENT_VIEW } from './constants';
import { AppLandingPageDigitalSpaceWrapper } from './Wrapper';

function AppLandingPageDigitalSpace() {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: hasAccessToSeniorManagementView } =
    useHasAccessToSeniorManagementViewQuery();

  const hasSeniorManagementViewAccess = (platform) =>
    platform?.code !== COLLAB_SENIOR_MANAGEMENT_VIEW || hasAccessToSeniorManagementView;

  return (
    <AppLandingPageDigitalSpaceWrapper>
      <Stack className="logo">
        <img src={logo} alt="logo" />
      </Stack>
      <Stack className="connected__user--avatar">
        <Avatar alt="Avatar" src={User} />
      </Stack>
      <Stack className="active__item">
        <Stack
          className="close__button"
          onClick={() => {
            navigate('/');
          }}
        >
          <BoldCloseIcon />
        </Stack>
        <Stack className="logo__item--active">
          {LOGO_ICONS[location?.state?.platform?.code]}
          <Typography variant="h6_Montserrat_Medium" color="text.gray">
            {location?.state?.platform?.description}
          </Typography>
        </Stack>
      </Stack>
      {(location?.state?.platform?.subPlatforms || []).map(
        (platform) =>
          hasSeniorManagementViewAccess(platform) && (
            <Stack
              className={`logo__item ${platform?.iconName}`}
              onClick={() => openInNewTab(urlPlatform[platform?.link])}
            >
              {LOGO_ICONS[platform?.code]}
              <Typography variant="subtitle1_Montserrat_Medium" color="primary.main">
                {platform?.name}
              </Typography>
            </Stack>
          ),
      )}
    </AppLandingPageDigitalSpaceWrapper>
  );
}

AppLandingPageDigitalSpace.propTypes = {};

export default AppLandingPageDigitalSpace;
