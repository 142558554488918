import React from 'react';

const JpassMobileLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50.352"
    height="56.401"
    viewBox="0 0 50.352 56.401"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0.713"
        y1="-11.408"
        x2="0.454"
        y2="3.062"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#007cff" />
        <stop offset="1" stopColor="#0af" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="0.571"
        y1="-3.462"
        x2="0.485"
        y2="1.353"
        xlinkHref="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-3"
        x1="4.093"
        y1="-186.115"
        x2="-0.741"
        y2="64.308"
        xlinkHref="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-4"
        x1="0.558"
        y1="-1.719"
        x2="0.492"
        y2="0.967"
        xlinkHref="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-5"
        x1="0.563"
        y1="-1.499"
        x2="0.474"
        y2="1.193"
        xlinkHref="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-6"
        x1="0.541"
        y1="-1.429"
        x2="0.472"
        y2="1.63"
        xlinkHref="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-7"
        x1="0.53"
        y1="-1.416"
        x2="0.468"
        y2="2.323"
        xlinkHref="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-8"
        x1="0.559"
        y1="-2.513"
        x2="0.424"
        y2="4.6"
        xlinkHref="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-9"
        x1="0.548"
        y1="-4.252"
        x2="0.395"
        y2="10.303"
        xlinkHref="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-10"
        x1="0.509"
        y1="-1.125"
        x2="0.48"
        y2="4.502"
        xlinkHref="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-11"
        x1="0.502"
        y1="0.137"
        x2="0.43"
        y2="12.999"
        xlinkHref="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-12"
        x1="0.503"
        y1="0.022"
        x2="0.258"
        y2="12.663"
        xlinkHref="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-13"
        x1="0.506"
        y1="0.026"
        x2="0.478"
        y2="3.636"
        xlinkHref="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-14"
        x1="0.504"
        y1="0.05"
        x2="0.465"
        y2="5.754"
        xlinkHref="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-15"
        x1="1.004"
        y1="0.508"
        x2="-0.004"
        y2="0.492"
        xlinkHref="#linear-gradient"
      />
    </defs>
    <g id="LOGO" transform="translate(0 0)">
      <g
        id="Group_10440"
        data-name="Group 10440"
        transform="translate(14.527 14.441)"
      >
        <path
          id="Path_28166"
          data-name="Path 28166"
          d="M1430.933,1395.8a1.071,1.071,0,1,0-.314.759A1.072,1.072,0,0,0,1430.933,1395.8Z"
          transform="translate(-1420.875 -1370.052)"
          fill="url(#linear-gradient)"
        />
        <path
          id="Path_28167"
          data-name="Path 28167"
          d="M1426.4,1389.519a3.223,3.223,0,1,0-3.587,2.815A3.223,3.223,0,0,0,1426.4,1389.519Zm-5.107-.618a1.927,1.927,0,1,1,1.676,2.138,1.927,1.927,0,0,1-1.676-2.138Z"
          transform="translate(-1414.211 -1363.387)"
          fill="url(#linear-gradient-2)"
        />
        <path
          id="Path_28168"
          data-name="Path 28168"
          d="M1417.014,1388.693h.009l.027.122c.025-.042.053-.083.08-.123Z"
          transform="translate(-1411.972 -1365.49)"
          fill="url(#linear-gradient-3)"
        />
        <path
          id="Path_28169"
          data-name="Path 28169"
          d="M1431.2,1382.318a5.911,5.911,0,0,0-4.163-6.36,6.716,6.716,0,0,1-.889,1.123,4.594,4.594,0,1,1-3.337,8.367l.363,1.651a5.9,5.9,0,0,0,8.027-4.781Z"
          transform="translate(-1416.352 -1355.865)"
          fill="url(#linear-gradient-4)"
        />
        <path
          id="Path_28170"
          data-name="Path 28170"
          d="M1443.007,1365.67a6.64,6.64,0,0,1-.329,1.261,7.276,7.276,0,0,1,4.136,10.268,13.577,13.577,0,0,0,2.014-1.976c.019-.12.038-.238.053-.36a8.6,8.6,0,0,0-5.875-9.194Z"
          transform="translate(-1431.374 -1348.087)"
          fill="url(#linear-gradient-5)"
        />
        <path
          id="Path_28171"
          data-name="Path 28171"
          d="M1444.37,1354.362v1.344a9.985,9.985,0,0,1,7.2,8.8,13.4,13.4,0,0,0,.806-1.768c.047-.129.09-.261.135-.391a11.288,11.288,0,0,0-8.139-7.989Z"
          transform="translate(-1432.654 -1339.538)"
          fill="url(#linear-gradient-6)"
        />
        <path
          id="Path_28172"
          data-name="Path 28172"
          d="M1444.37,1343.116v1.334a12.705,12.705,0,0,1,8.7,6.974,13.576,13.576,0,0,0,.124-1.832v-.572A13.983,13.983,0,0,0,1444.37,1343.116Z"
          transform="translate(-1432.654 -1331.035)"
          fill="url(#linear-gradient-7)"
        />
        <path
          id="Path_28173"
          data-name="Path 28173"
          d="M1463.823,1340.38a.652.652,0,0,0,.23.889,15.3,15.3,0,0,1,3.781,3.149v-1.892a16.588,16.588,0,0,0-3.123-2.382A.65.65,0,0,0,1463.823,1340.38Z"
          transform="translate(-1447.292 -1328.726)"
          fill="url(#linear-gradient-8)"
        />
        <path
          id="Path_28174"
          data-name="Path 28174"
          d="M1447.949,1333.627a.651.651,0,0,0-.376-.84,16.824,16.824,0,0,0-3.2-.873v1.32a15.61,15.61,0,0,1,2.74.769.651.651,0,0,0,.84-.377Z"
          transform="translate(-1432.654 -1322.568)"
          fill="url(#linear-gradient-9)"
        />
        <path
          id="Path_28175"
          data-name="Path 28175"
          d="M1402.893,1321.478l.206.91h1.037a17.992,17.992,0,0,1,17.7,3.126v-1.661a19.291,19.291,0,0,0-18.949-2.371Z"
          transform="translate(-1401.297 -1313.562)"
          fill="url(#linear-gradient-10)"
        />
        <path
          id="Path_28176"
          data-name="Path 28176"
          d="M1456.706,1296.031v-1.442c-.771-.358-1.556-.686-2.358-.972H1449a26.512,26.512,0,0,1,3.941.9,25.8,25.8,0,0,1,3.765,1.511Z"
          transform="translate(-1436.155 -1293.617)"
          fill="url(#linear-gradient-11)"
        />
        <path
          id="Path_28177"
          data-name="Path 28177"
          d="M1398.624,1293.618h-1.6q-.323.445-.665.861l.36,1.594c.535-.61,1.037-1.243,1.517-1.9C1398.372,1293.987,1398.494,1293.8,1398.624,1293.618Z"
          transform="translate(-1396.361 -1293.616)"
          fill="url(#linear-gradient-12)"
        />
        <path
          id="Path_28178"
          data-name="Path 28178"
          d="M1400.506,1298.588q.925-.988,1.755-2.061l.034-.04a27.742,27.742,0,0,0,1.927-2.867H1402.7q-.659,1.051-1.409,2.033v.009l-.008.009-.021.025a26.989,26.989,0,0,1-1.814,2.121l.493,2.185.04.175a20.683,20.683,0,0,1,19.265,2.043v-1.549a21.978,21.978,0,0,0-18.731-2.075Z"
          transform="translate(-1398.692 -1293.616)"
          fill="url(#linear-gradient-13)"
        />
        <path
          id="Path_28179"
          data-name="Path 28179"
          d="M1422.065,1295.778a.648.648,0,0,0,.609.311,23.573,23.573,0,0,1,8.743.9,23.035,23.035,0,0,1,4.853,2.075v-1.482a24.4,24.4,0,0,0-4.481-1.833,24.692,24.692,0,0,0-7.974-1.018q.313-.547.6-1.105h-1.471q-.412.75-.871,1.482A.656.656,0,0,0,1422.065,1295.778Z"
          transform="translate(-1415.719 -1293.615)"
          fill="url(#linear-gradient-14)"
        />
      </g>
      <path
        id="Path_28180"
        data-name="Path 28180"
        d="M1362,1290.826a5.6,5.6,0,0,1-2.8-.748l-19.571-11.3a5.621,5.621,0,0,1-2.8-4.853v-22.6a5.621,5.621,0,0,1,2.8-4.854l19.572-11.3a5.618,5.618,0,0,1,5.6,0l19.572,11.3a5.621,5.621,0,0,1,2.8,4.853v22.6a5.621,5.621,0,0,1-2.8,4.853l-19.572,11.3a5.6,5.6,0,0,1-2.8.748Zm0-55.062a4.26,4.26,0,0,0-2.131.569l-19.571,11.3a4.276,4.276,0,0,0-2.131,3.691v22.6a4.275,4.275,0,0,0,2.131,3.692l19.572,11.3a4.271,4.271,0,0,0,4.262,0l19.572-11.3a4.274,4.274,0,0,0,2.131-3.692v-22.6a4.274,4.274,0,0,0-2.131-3.691l-19.572-11.3A4.262,4.262,0,0,0,1362,1235.765Z"
        transform="translate(-1336.823 -1234.425)"
        fill="url(#linear-gradient-15)"
      />
    </g>
  </svg>
);

export default JpassMobileLogo;
