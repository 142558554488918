import GreyColors from './grey-colors';

const AppColors = {
  primary: '#003e7d',
  secondary: GreyColors[600],
  blue: '#86c6fb',
  green: '#28a745',
  success: '#3CC691',
  info: '#30b1ff',
  warning: '#f7b924',
  danger: '#8d0917',
  mediumDanger: '#d92d20',
  lightDanger: '#E6534E',
  light: GreyColors.white,
  dark: GreyColors.black,
  dark_100: '#002F31',
  focus: '#444054',
  alt: '#83588a',
  fileUpload_border: '#CED1D7',
  blueViolet_100: '#3c485f',
  blueViolet_200: '#00338d',
  bluishCyan_100: '#00aaff',
  bluishCyan_200: '#007CFF',
  BlueAlice: '#F1F5FE',
  lightBlueShade: '#DBE6FF',
  appBackground: '#FAFCFF',
  logo_shadow: '#1E276924',
  backdrop_shadow: '#00000040',
  form_box_shadow: '#3235390F',
  placeholder__Input: '#002f3180',
  box_shadow: '#00565C1A',
  DarkElectricBlue: '#5F6A7E',
  CharlestonGreen: '#2C2C2F',
  btn_secondary: '#E5EAF3',
  button_bg: '#E8EAED',
};
export default AppColors;
