import React from "react";

const ProjectHealthIcon = () => (
  <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 20" width="28" height="20">
    <title>ProjectHealthIcon</title>
    <defs>
      <image width="28" height="20" id="img3"
             href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD0AAAA0CAMAAAAOs8ATAAAAAXNSR0IB2cksfwAAAOdQTFRFAAAAAHz/AHz/AHz/AHv/AH3/AID/AHz/AHz/C4H/VKf/IIz/AHr/AHz/sdf/////7vf/AHv/AID/AHz/AHz/AHv/CYH/HYv/UKf/K5L/AHz/u97/8Pr/HYz/4PH/LJP/AHz/AX3/AHz/UKb/PZz/er3/w+L/eLv/S6T/PJz/l8v/4fL/abP/ptP/p9P/pdL/Hoz/Wqv/D4T/HYv/Wav/lsv/SqP/O5v/h8P/aLP/0ur/LJP/tNr/DoP/LZT/ZLH/AHz/AXz/4PH/BH7/AH3/LJT/AH3/AHz/H4z/z+n/ZrL/Eob/AHz/+Xi7zAAAAE10Uk5TAG/P/9+PEIC/2b/JMEDQ//BwIH+vn9nIv8OQ2P/J8MRQ8cDAwcT//////////9D/////////////////////wdDw8eOww2CgyOTAz/B8cI8TAAACPUlEQVR4nN3XW2OTMBgG4G6v66quTuustWNTt0lGQaEUWg5BcQfn6f//HhNOZUBC4dLvpgTy5EtIgHQwaIi9fVTiycGwqWJTHFYtj9GuGnj6rBLPgaPd8Bh4cVyJl8Cr/voYOPz/9OT1yWjUNE2C2B/tTQp80AEWkS+fN+x4+nbWId5NeQcSPmf2VOkYp2fACR8zw+ddMYv3wIdkUXfOzOP8I1/6F5j2wYpyCfBH4qqf/gRMmL6sXVDJtUYWelrQF4Z2TT7XKn0Bxg3atLIpXTKvG3ZasBY7aQNYOa5J1oCtqqylDXE9f5U21qaXAEmrBSFsGyFNz3sRlq3aBNyi4ABhkVAFvDZtgZRKvl/qrQe73PcGTbHKUwVKNcJStxo1gZ83Y9W0D0eu13nzDh4lylrcyPUGNPnV+Qynp0xjZ+1k95VgvUqTB9tbbbZpLxuaBeqmyTW2aIK8aSLXLBOv6vJbnySniNbQ8tGoUk00G5amaRbvbZKczb8eQePxFdAMmS7eeRFfFyy5xzvhlV6GqkSrlLoRLEqTSiy5DZMdUBYsM8nOC8fNW9g+DiGKu7yszb/g+d5yWvS0jkW6xH1XiIW63HkhFusKb8QS/Yg3Y5kucQGW6oKLsFxnXIhbdMLFuE1zLsatmnMhbtdKoIqvfePbxxhn4hqyuOJf4Dm+3/TBN7e4GwyOgPs+mn38f7CNywPw81dn/BuI+Z5pGLN5+dNlwzWb3d8Cf9MN2/Chz27vovizMJnH3Wh8N07gP7TSDhjf8yO1AAAAAElFTkSuQmCC" />
    </defs>
    <style>
    </style>
    <use id="Background" href="#img3" x="0" y="0" />
  </svg>
);
export default ProjectHealthIcon;
