import { Stack, styled } from "@mui/material";

export const ProfileSettingsMenuWrapper = styled(Stack)(
  ({ theme }) => ({

    flex: "1 1 20%",
    borderRight: "1px solid #E6E6E6",
    background:
      "transparent linear-gradient(355deg, #007CFF 0%, #00338D 100%) 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 16px #3235390A",
    borderRadius: "0px 17px 17px 0px",
    opacity: "1",
    color: "#fff", /*TODO replace "#fff" by "theme.palette.light.main" after fixing the theme file*/
    paddingBlock: "48px",
    zIndex: 1051,

    "& .create__profile--timeline": {
      height: "70%",
      position: "relative",
      marginTop: "70px",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      gap: "30px",
      "& .timeline--items": {
        height: "397px",
        width: "20ch",
        "& > div": {
          height: "calc(100% / 7)",
          "&:not(:first-child)": {
            justifyContent: "flex-end"
          }
        }
      }
    },
    "& .create__profile-member": {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      padding: "20px",
      backgroundColor: "transparent",
      cursor: "pointer",
      zIndex: 1,
      "&:hover": {
        background: "#007CFF" /* replace the color code by "theme.palette.secondary.blue" after fixing the theme file*/
      }
    },


    [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},

    [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},

    [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},

    [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},

    [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},

    [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {}

  })
);
