import { Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import ProjectListItem from './ProjectListItem';

function ProjectsList({ projects, updateProject }) {
  return (
    <Grid className="resetGrid" container spacing={12} rowSpacing={12}>
      <Stack className="listing_item_row">
        <Stack>
          <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.gray">
            Name
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.gray">
            Number
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.gray">
            Status
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.gray">
            Start Date
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.gray">
            Forecast End Date
          </Typography>
        </Stack>
      </Stack>
      {projects?.map((elem) => (
        <ProjectListItem key={elem?.id} updateProject={updateProject} project={elem} />
      ))}
    </Grid>
  );
}

ProjectsList.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape({})),
  updateProject: PropTypes.func,
};

export default ProjectsList;
