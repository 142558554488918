import React from 'react';
import PropTypes from 'prop-types';
import { CloseIcon } from 'assets/svgs/componentsIcons';
import CustomModal from 'components/StyledComponents/CustomModal';
import { Modal } from '@mui/material';
import { CloseButton, MainButton } from 'components/Buttons';
import { CONFIRMATION_POPUP } from './constants';

function ConfirmationPopup({
  toOpen,
  popupType,
  onClose,
  onConfirm,
  onCancel,
  onDelete,
  entityName = '',
  errorMsg = '',
  width = '416px',
  actionsClassName,
  children,
}) {
  const POPUP_DATA = CONFIRMATION_POPUP[popupType];
  return (
    <Modal
      open={toOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <CustomModal modalWidth={width}>
        <div className="modal-head-wrapper">
          <div className="modal-head">
            <h3 className="modal-title">{POPUP_DATA.title}</h3>

            <p className="modal-description">
              {entityName !== ''
                ? POPUP_DATA.message(entityName)
                : POPUP_DATA.message(errorMsg)}
            </p>
          </div>
          <div className="modal-closer">
            <CloseButton type="button" onClick={onClose}>
              <CloseIcon />
            </CloseButton>
          </div>
        </div>

        {children === undefined ? '' : children}

        <div className={`modal-action-wrapper ${actionsClassName}`}>
          {POPUP_DATA.cancelBtn && (
            <MainButton secondary onClick={onCancel}>
              {POPUP_DATA.cancelBtn}
            </MainButton>
          )}
          {POPUP_DATA.confirmationBtn && (
            <MainButton onClick={onConfirm}>{POPUP_DATA.confirmationBtn}</MainButton>
          )}
          {POPUP_DATA.deleteBtn && (
            <MainButton danger onClick={onDelete}>
              {POPUP_DATA.deleteBtn}
            </MainButton>
          )}
        </div>
      </CustomModal>
    </Modal>
  );
}

ConfirmationPopup.propTypes = {
  toOpen: PropTypes.bool,
  popupType: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  entityName: PropTypes.string,
  errorMsg: PropTypes.string,
  width: PropTypes.string,
  actionsClassName: PropTypes.string,
  children: PropTypes.node,
};

export default ConfirmationPopup;
