import React from 'react';

const MkLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="146" height="146" viewBox="0 0 146 146">
    <defs>
      <filter id="Ellipse_1312" x="0" y="0" width="146" height="146" filterUnits="userSpaceOnUse">
        <feOffset input="SourceAlpha"/>
        <feGaussianBlur stdDeviation="10" result="blur"/>
        <feFlood flood-color="#1e2769" flood-opacity="0.09"/>
        <feComposite operator="in" in2="blur"/>
        <feComposite in="SourceGraphic"/>
      </filter>
    </defs>
    <g id="Group_63881" data-name="Group 63881" transform="translate(-1051 -347)">
      <g id="Group_63829" data-name="Group 63829" transform="translate(950.371 26.917)">
        <g transform="matrix(1, 0, 0, 1, 100.63, 320.08)" filter="url(#Ellipse_1312)">
          <circle id="Ellipse_1312-2" data-name="Ellipse 1312" cx="43" cy="43" r="43" transform="translate(30 30)"
                  fill="#fcfcff"/>
        </g>
        <path id="Path_67230" data-name="Path 67230"
              d="M28.512,0V-.627c-2.409-.165-2.9-.726-2.9-3.069V-18.15c0-2.475.495-3.036,2.871-3.069v-.627H21.912L14.619-5.28,7-21.846H.462v.627c2.706.165,3.135.594,3.135,3.1V-4.983c0,3.7-.462,4.158-3.2,4.356V0H8.118V-.627C5.577-.759,5.049-1.353,5.049-4.983V-18.117L13.365,0h.462l8.415-18.876V-4.059c0,2.739-.363,3.267-2.97,3.432V0ZM49.137,0V-.627c-1.518,0-2.409-.594-5.148-3.6l-7.722-8.448L42.4-18.546c2.277-2.178,2.9-2.508,5.148-2.673v-.627H39.006v.627l.825.033c1.089.033,1.353.363,1.353,1.023,0,.825-.99,1.782-2.574,3.267l-5.874,5.412v-6.6c0-2.541.231-2.937,2.97-3.135v-.627H26.4v.627c2.607.165,2.97.66,2.97,3.1V-4.125c0,2.871-.4,3.366-3,3.5V0h9.306V-.627c-2.409-.2-2.937-.5-2.937-3.036v-6.1l.858-.693,3.3,3.4c2.607,2.706,4.521,4.719,4.521,5.61,0,.462-.429.759-1.386.792l-.891.033V0Z"
              transform="translate(149.57 402.868)" fill="#00338d"/>
      </g>
    </g>
  </svg>

);

export default MkLogo;
