import {Stack, styled} from "@mui/material";

export const CheckboxWrapper = styled(Stack)(
  ({theme}) => ({

    '& svg path': {
      color: '#00000029',
    },


    [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},

    [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},

    [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},

    [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},

    [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},

    [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {},

  })
)
