import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FormHelperText, Stack, Typography } from '@mui/material';
import { ArrowLeftIcon } from 'assets/svgs/componentsIcons';
import { BackButton } from 'components/Buttons';
import FormSelect from 'components/FormSelect';
import FormSwitch from 'components/FormSwitch';
import FormTextArea from 'components/FormTextArea';
import FormInput from 'components/FormInput';
import ConfirmationPopup from 'components/ConfirmationPopin';
import SettingsFooterPage from 'pages/SettingsFooterPage';
import { createStructuredSelector } from 'reselect';
import {
  ACCESS_MANAGEMENT_LISTING,
  ACCESS_MANAGEMENT_PROFILE_PRIVILEGE,
} from 'routes/constants';
import {
  makeSelectBackPopup,
  makeSelectCancelPopup,
  makeSelectProfileData,
  makeSelectSubmit,
} from 'services/slices/access-management/selectors';
import {
  resetAccessManagement,
  setProfileData,
  setSubmitted,
  showBackPopup,
  showCancelPopup,
} from 'services/slices/access-management';
import {
  useGetProfileByIdQuery,
  useLazyCheckProfileNameQuery,
} from 'services/apis/access-control/profile';
import { PlatformEnum, useGetPlatformsByCodesQuery } from 'services/apis/platform';
import { POPUP_TYPE } from 'components/ConfirmationPopin/constants';
import FullPageLoader from 'components/FullPageLoader';
import { AddProfileWrapper } from './Wrapper';
import { isNewProfileOrUpdated } from '../helper';

const stateSelector = createStructuredSelector({
  profileData: makeSelectProfileData,
  submitted: makeSelectSubmit,
  cancelPopup: makeSelectCancelPopup,
  backPopup: makeSelectBackPopup,
});

function AccessManagementProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { profileId } = useParams();

  const { profileData, submitted, cancelPopup, backPopup } = useSelector(stateSelector);

  const { data: platforms } = useGetPlatformsByCodesQuery([
    PlatformEnum.COLLAB,
    PlatformEnum.ESP,
    PlatformEnum.JPASS,
    PlatformEnum.DIGITAL_SPACE,
  ]);
  const { data: profile, error: profileError } = useGetProfileByIdQuery(profileId, {
    skip: profileId === undefined,
    refetchOnMountOrArgChange: true,
  });
  const [
    deCheckProfileName,
    { isSuccess: checkSuccess, error: checkProfileNameError, isLoading: saveLoading },
  ] = useLazyCheckProfileNameQuery();

  useEffect(() => {
    if (profile) {
      dispatch(setProfileData(profile));
    }
  }, [dispatch, profile]);

  const navigateToBackBtn = useCallback(() => {
    dispatch(resetAccessManagement());
    navigate(ACCESS_MANAGEMENT_LISTING);
  }, [dispatch, navigate]);

  const fillFormAction = (fieldKey, fieldValue) => {
    dispatch(
      setProfileData({
        [fieldKey]: fieldValue,
        isUpdated: profileId !== undefined ? true : profileData.isUpdated,
      }),
    );
  };
  const continueClick = () => {
    dispatch(setSubmitted(true));
    if (profileData?.name && profileData?.platform) {
      deCheckProfileName({
        profileId: profileData?.id,
        profileName: profileData?.name,
        platform: profileData?.platform?.code,
      });
    }
  };

  useEffect(() => {
    if (checkSuccess && profileId) {
      navigate(`${ACCESS_MANAGEMENT_PROFILE_PRIVILEGE}/${profileId}`);
    } else if (checkSuccess) {
      navigate(ACCESS_MANAGEMENT_PROFILE_PRIVILEGE);
    }
  }, [checkSuccess]);

  const onBackClick = () => {
    if (isNewProfileOrUpdated(profileData)) {
      dispatch(showBackPopup(true));
    } else {
      navigateToBackBtn();
    }
  };
  const onCancelClick = () => {
    dispatch(resetAccessManagement());
    navigate(ACCESS_MANAGEMENT_LISTING);
  };

  return (
    <AddProfileWrapper>
      {saveLoading && <FullPageLoader />}
      <Stack className="top-header">
        <Stack className="heading-area">
          <BackButton type="button" onClick={onBackClick}>
            <ArrowLeftIcon />
          </BackButton>
          <Stack className="page-title-area">
            <Typography variant="h3_HelveticaNeue_Bold" color="primary.main">
              Access Management
            </Typography>
          </Stack>
        </Stack>

        <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.main">
          Add new profiles and set the access management
        </Typography>
        {(profileError || checkProfileNameError) && (
          <FormHelperText className="errorMsg">
            {profileError?.data?.detail || checkProfileNameError?.data?.detail}
          </FormHelperText>
        )}
      </Stack>

      <Stack className="addProfileForm">
        <Stack>
          <Stack className="form-search-area">
            <Typography variant="h6_HelveticaNeue_Bold" color="text.main">
              General information
            </Typography>
          </Stack>
          <Stack className="form-split">
            <FormSelect
              label="Plateform*"
              valueKey="Project Name"
              menuItems={platforms?.map((p) => p.name)}
              onChange={(e) => {
                const selected = platforms?.find((p) => p.name === e.target.value);
                fillFormAction('platform', selected);
              }}
              value={profileData?.platform?.name}
              error={submitted && !profileData?.platform}
              disabled={profileId !== undefined && profile}
            />
            <FormInput
              label="Profile name*"
              placeholder="Profile name"
              onChange={(e) => {
                fillFormAction('name', e.target.value);
              }}
              value={profileData?.name}
              isValid={!(submitted && !profileData?.name)}
            />
          </Stack>
          <Stack className="form-split">
            <FormTextArea
              label="Description"
              placeholder="Description"
              onChange={(e) => {
                fillFormAction('description', e.target.value);
              }}
              value={profileData?.description}
            />
            <Stack className="addProfileSwitchForms">
              <FormSwitch
                label="for external users"
                checked={profileData?.forExternalUsers}
                onChange={() => {
                  fillFormAction('forExternalUsers', !profileData?.forExternalUsers);
                }}
              />
              <FormSwitch
                label="for client"
                checked={profileData?.forClient}
                onChange={() => {
                  if (!profileData?.forExternalUsers) {
                    fillFormAction('forExternalUsers', true);
                  }
                  fillFormAction('forClient', !profileData?.forClient);
                }}
              />
              <FormSwitch
                label="for contractor"
                checked={profileData?.forContractor}
                onChange={() => {
                  if (!profileData?.forExternalUsers) {
                    fillFormAction('forExternalUsers', true);
                  }
                  fillFormAction('forContractor', !profileData?.forContractor);
                }}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {cancelPopup && (
        <ConfirmationPopup
          toOpen={cancelPopup}
          popupType={POPUP_TYPE.CONFIRMATION_CANCEL__POPUP}
          onConfirm={onCancelClick}
          onCancel={() => dispatch(showCancelPopup(false))}
          onClose={() => dispatch(showCancelPopup(false))}
        />
      )}
      {isNewProfileOrUpdated(profileData) && backPopup && (
        <ConfirmationPopup
          toOpen={backPopup}
          popupType={POPUP_TYPE.BACK__POPUP}
          onConfirm={onCancelClick}
          onCancel={() => dispatch(showBackPopup(false))}
          onClose={() => dispatch(showBackPopup(false))}
        />
      )}
      <SettingsFooterPage
        handelCancelClick={() => dispatch(showCancelPopup(true))}
        handelSaveClick={continueClick}
        labelSave="Continue"
      />
    </AddProfileWrapper>
  );
}

export default AccessManagementProfile;
