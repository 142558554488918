import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import ChevronDownSvg from 'assets/svgs/ChevronDownSvg';
import SmallCloseIcon from 'assets/svgs/componentsIcons/SmallCloseIcon';
import { ChipStyle, ChipStyleWrapper, SelectWrapper } from '../FormSelect/Wrapper';

function MultiselectForm({
  options = [],
  label,
  value = [],
  isValid = true,
  onChange,
  handleDelete,
  disabled,
  error,
}) {
  const customizedStyleObj = {
    content: `"${label}"`,
    opacity: 0.42,
    fontFamily: 'HelveticaNeueMedium',
    fontSize: '14px',
    color: '#5F6A7E',
    textTransform: 'capitalize',
  };
  const [openSelect, setOpenSelect] = useState(false);

  const handleToggle = () => {
    setOpenSelect(!openSelect);
  };

  return (
    <SelectWrapper isValid={isValid}>
      <FormControl className="inputs__label" size="small" disabled={disabled} error={error}>
        <InputLabel id="multiselect-label">
          <Typography variant="h5_HelveticaNeue_Regular">{label}</Typography>
        </InputLabel>
        <Select
          multiple
          value={value}
          onClick={handleToggle}
          onChange={onChange}
          IconComponent={ChevronDownSvg}
          sx={{
            '& .MuiSelect-select .notranslate::after': !label ? {} : customizedStyleObj,
          }}
          renderValue={() => null}
          open={openSelect}
          disabled={disabled}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option || []}>
              <Typography color="text.secondary" variant="subtitle1_HelveticaNeue_Medium">
                {option}
              </Typography>
            </MenuItem>
          ))}
        </Select>
        {(error || !isValid) && (
          <FormHelperText className="errorMsg">This field is required</FormHelperText>
        )}
      </FormControl>
      <ChipStyleWrapper className="chipStyleWrapper">
        {value?.map((selectedValue) => (
          <Stack key={selectedValue} className="flex__row--center">
            <ChipStyle
              className="chipStyle"
              key={selectedValue}
              disabled={disabled}
              label={selectedValue}
              onDelete={() => handleDelete(selectedValue)}
              deleteIcon={
                <Stack>
                  <SmallCloseIcon classname="deleteIcon" />
                </Stack>
              }
            />
          </Stack>
        ))}
      </ChipStyleWrapper>
    </SelectWrapper>
  );
}

MultiselectForm.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
  isValid: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default MultiselectForm;
