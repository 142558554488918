import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from '@mui/lab';
import { TimelineMenuWrapper } from './Wrapper';
import CustomTimelineContent from './CustomTimelineContent';

function TimelineMenu({ items }) {
  const navigate = useNavigate();
  const handleNavigate = (e) => {
    if (e) {
      navigate(e);
    }
  };
  return (
    <TimelineMenuWrapper>
      {items.map((item, index) => (
        <TimelineItem key={item.id}>
          <TimelineSeparator>
            <TimelineDot className={`timelineDot ${item.selected ? 'selected' : ''}`} />
            {index < items.length - 1 && (
              <TimelineConnector className="timelineConnector" />
            )}
          </TimelineSeparator>
          <TimelineContent
            className="timelineContent pointer"
            onClick={() => handleNavigate(item.path)}
          >
            <CustomTimelineContent title={item.title} subtitle={item.subtitle} />
          </TimelineContent>
        </TimelineItem>
      ))}
    </TimelineMenuWrapper>
  );
}
TimelineMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      selected: PropTypes.bool,
      path: PropTypes.string,
    }),
  ),
};
export default TimelineMenu;
