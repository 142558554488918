import {createSlice} from '@reduxjs/toolkit';
import type {RootState} from "store";

interface UserManagementSliceState {
  userFilter: any;
  isExternal: boolean;
}

const initialState: UserManagementSliceState = {
  userFilter: {},
  isExternal: false,
};
/* eslint-disable no-param-reassign */
const userManagement = createSlice({
  name: 'userManagement',
  initialState,
  reducers: {
    setUserFilter: (state, {payload: {userFilter}}) => {
      state.userFilter = {...state.userFilter, ...userFilter};
    },
    setExternal: (state, {payload: isExternal}) => {
      state.isExternal = isExternal;
    },
    resetUserManagement: () => initialState,
  },
});

export const {setUserFilter, resetUserManagement, setExternal} = userManagement.actions;

export const selectUserManagementDomain = (state: RootState): any =>
  state.userManagement.userManagement;

export default userManagement.reducer;

