import React from 'react';

const DeleteIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={11.856}
    height={13.173}
    {...props}
  >
    <path
      d="M3.952 1.317V.659A.659.659 0 0 1 4.611 0h2.634A.659.659 0 0 1 7.9.659v.659h2.635a1.317 1.317 0 0 1 1.317 1.317v.659a1.317 1.317 0 0 1-1.314 1.316h-.088L10 11.329a1.976 1.976 0 0 1-1.971 1.844H3.837a1.976 1.976 0 0 1-1.971-1.842L1.408 4.61h-.09A1.317 1.317 0 0 1 0 3.293v-.658a1.317 1.317 0 0 1 1.317-1.318Zm6.586 1.317H1.317v.659h9.221ZM2.727 4.61l.452 6.631a.659.659 0 0 0 .657.614h4.196a.659.659 0 0 0 .657-.615l.441-6.63h-6.4Zm1.883.659a.659.659 0 0 1 .659.659v4.61a.659.659 0 0 1-1.317 0v-4.61a.659.659 0 0 1 .659-.659Zm2.635 0a.659.659 0 0 1 .659.659v4.61a.659.659 0 0 1-1.317 0v-4.61a.659.659 0 0 1 .658-.659Z"
      fill="currentColor"
    />
  </svg>
);

export default DeleteIcon;
