import { createSlice } from '@reduxjs/toolkit';
import { type RootState } from 'store';

interface ProjectWorkSliceState {
  submit: boolean;
  isDataUpdated: boolean;
  isEntityDelete: boolean;
  workOrders: any[];
  pictures: any[];
  activeTab: string;
  projectDatas: any[];
  selectedEntityInit: any[];
  disciplineSelected: any[];
  projectData: any;
  contractorsByProject: any[];
  contractors: any[];
  subContractorsByProject: any[];
  subContractors: any[];
  vendorsByProject: any[];
  vendors: any[];
}

export const initialState: ProjectWorkSliceState = {
  submit: false,
  isDataUpdated: false,
  isEntityDelete: false,
  workOrders: [],
  pictures: [],
  activeTab: '',
  projectDatas: [],
  selectedEntityInit: [],
  disciplineSelected: [],
  projectData: {},
  contractorsByProject: [],
  contractors: [],
  subContractorsByProject: [],
  subContractors: [],
  vendorsByProject: [],
  vendors: [],
};
/* eslint-disable no-param-reassign */
const projectWork = createSlice({
  name: 'projectWork',
  initialState,
  reducers: {
    setSubmit: (state, { payload: submit }) => {
      state.submit = submit;
    },
    addPictureElement: (state, { payload: picture }) => {
      state.pictures.push(picture);
    },
    setActiveTab: (state, { payload: activeTab }) => {
      state.activeTab = activeTab;
    },
    updateWorkOrders: (state, { payload: workOrders }) => {
      state.workOrders = workOrders;
    },
    fillProjectData: (state, { payload: data }) => {
      state.projectData = { ...state.projectData, ...data };
    },
    updateProjectDatas: (state, { payload: projectDatas }) => {
      state.projectDatas = projectDatas;
    },
    updateSelectedEntityInit: (state, { payload: selectedEntityInit }) => {
      state.selectedEntityInit = selectedEntityInit;
    },
    updateDisciplineSelected: (state, { payload: disciplineSelected }) => {
      state.disciplineSelected = disciplineSelected;
    },
    resetProjectData: (state) => {
      state.projectData = initialState.projectData;
    },
    setContractorsByProject: (state, { payload: contractorsByProject }) => {
      state.contractorsByProject = contractorsByProject;
    },
    setContractors: (state, { payload: contractors }) => {
      state.contractors = contractors;
    },
    setVendorsByProject: (state, { payload: vendorsByProject }) => {
      state.vendorsByProject = vendorsByProject;
    },
    setVendors: (state, { payload: vendors }) => {
      state.vendors = vendors;
    },
    setSubContractorsByProject: (state, { payload: subContractorsByProject }) => {
      state.subContractorsByProject = subContractorsByProject;
    },
    setIsDataUpdated: (state, { payload: isDataUpdated }) => {
      state.isDataUpdated = isDataUpdated;
    },
    setSubContractors: (state, { payload: subContractors }) => {
      state.subContractors = subContractors;
    },
    setIsEntityDelete: (state, { payload: isEntityDelete }) => {
      state.isEntityDelete = isEntityDelete;
    },
    resetContractorDeletedStatus: (state) => {
      state.contractorsByProject?.forEach((contractorByProject) => {
        contractorByProject.flagDeleted = false;
        contractorByProject.entity.hideItem = false;
      });
    },

    resetSubContractorDeletedStatus: (state) => {
      state.subContractorsByProject?.forEach((subContractorByProject) => {
        subContractorByProject.flagDeleted = false;
        subContractorByProject.entity.hideItem = false;
      });
    },
    resetProjectWork: () => initialState,
  },
});

export const {
  setSubmit,
  addPictureElement,
  setActiveTab,
  updateWorkOrders,
  fillProjectData,
  updateProjectDatas,
  updateSelectedEntityInit,
  updateDisciplineSelected,
  resetProjectData,
  resetProjectWork,
  setContractorsByProject,
  setContractors,
  setSubContractorsByProject,
  setSubContractors,
  setVendorsByProject,
  setVendors,
  setIsDataUpdated,
  setIsEntityDelete,
  resetContractorDeletedStatus,
  resetSubContractorDeletedStatus,
} = projectWork.actions;

export const selectProjectsSettingsProjectWorkDimensionsDomain = (
  state: RootState,
): any => state.projectSetting.projectWork;

export default projectWork.reducer;
