import React from "react";

const DeliveriesIcon = () => (
  <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 21" width="28" height="21">
    <title>DeliveriesIcon</title>
    <defs>
      <image width="28" height="21" id="img8"
             href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAZCAMAAABn0dyjAAAAAXNSR0IB2cksfwAAAFFQTFRFAAAAAID/AHv/AHz/AHz/AHz/AHv/AHv/AHv/AHv/AHr/AHz/AHv/AHv/AHz/AHz/AHz/AHz/AHj/AHz/AHz/AHr/AHv/AHv/AH3/AHz/AHv/AIghmQAAABt0Uk5TABCf73/AcODfX2CA8M9AUND/IJC/MLCgP2+PIB+lfgAAARJJREFUeJx9Utm2gyAMDCKChKJB1Lb//6FNcLlW7Z0Hz2FmJMsAcIGqdK2u9C7X2jTWtb90r61nF4ZHvJM7F3qqklw0UB7PcszEbDRLC2larDtSRXMqrLe6tBANHbs1hDuLwXVymGjmP58LHpZyaayz+qWQD9KRAWhoBaZ5rdoE16aeGYXmUAL/qkoNCkycDeX+mg1vok6IZhYOYco5a1xXFdxb95GiwW2+CB4R7XbmHfEyKVPDP/tziTIp8Yd6GWkcfxoWRSnoWki1wH0bFuAEkwFvBOHWcBnzZBgOhmEPbzdwvIcpYMt5M5R44Quc82sz8DLNzaOSmIoBtb19cxJTRvIu+Fu5lJ6Jg/zn3fMSj/IH4WIO8nnDBfEAAAAASUVORK5CYII=" />
    </defs>
    <style>
    </style>
    <use id="Background" href="#img8" x="0" y="0" />
  </svg>
);
export default DeliveriesIcon;
