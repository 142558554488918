import {Typography} from '@mui/material';
import PropTypes from 'prop-types';
import {AppBtn} from './Wrapper';

const AppButton = ({
                     customClass,
                     label,
                     labelVariant = 'subtitle1_Montserrat_Regular',
                     labelColor = 'light.main',
                     ...rest
                   }) => {
  return (
    <AppBtn
      className={`${customClass}`}
      type="submit"
      {...rest}
    >
      <Typography variant={labelVariant} color={labelColor}>
        {label}
      </Typography>
    </AppBtn>
  );
};

AppButton.propTypes = {
  label: PropTypes.string,
  customClass: PropTypes.string,
  labelVariant: PropTypes.string,
  labelColor: PropTypes.string,
};
export default AppButton;
