const urlPlatform = window.urlPlatform || {
  COLLAB_URL: 'http://localhost:3003',
  JPASS_URL: 'http://localhost:3004',
  QA_URL: 'http://localhost:3005',
  CSP_URL: 'http://localhost:3006',
  HSE_URL: 'https://hse.jesagroup.com/',
  ACONEX_URL:
    'https://constructionandengineering.oraclecloud.com/ui/v1/login/?_ojCoreRouter=page-discovery&target_link_uri=https://constructionandengineering.oraclecloud.com/web/cal/redirect?Provider=babylon&appId=8001&ReturnUrl=https%3A%2f%2fuk1.aconex.co.uk%2fLogon',
  JPASS_WEB_URL: 'http://localhost:3004',
  COLLAB_WEB_URL: 'http://localhost:3003',
  COLLAB_SENIOR_MANAGEMENT_VIEW_URL:
    'http://localhost:3003/collab/senior-management-view',
  COLLAB_CLIENT_PROGRAM_VIEW: 'http://localhost:3003/collab/program-view',
  MATERIAL_MANAGEMENT_URL: 'http://localhost:3007',
  SAFRAN: 'https://safrisk.jesagroup.com/',
  BLOOM: 'http://localhost:3003',
  OPC: 'https://primavera-eu1.oraclecloud.com/',
  WORK_PACKS: 'https://eu.workpacks.com/app/projects',
  PROCORE: 'https://login.procore.com/',
  SMART_COMPLETIONS: 'https://smartcompletions.jesagroup.com/login.aspx',
  AVEVA_ERM: 'https://erm.connect.aveva.com/#/login ',
  AUTODESK_CC: 'https://construction.autodesk.fr/',
};

export default urlPlatform;
