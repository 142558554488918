import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import DropdownCalender from 'assets/svgs/DropdownCalender.svg';
import {Stack, styled} from "@mui/material";

export const AppCalendarsDateRangePickerWrapper = styled(Stack)(
  ({theme}) => ({

    minHeight: '80px',
    position: 'relative',
    '& *:not(& .rdrInRange , & .rdrStartEdge ,& .rdrEndEdge , & .rdrDayHovered .rdrDayNumber:after)':
      {
        borderRadius: theme.spacing(10),
      },
    '& label': {
      top: '-22px',
    },
    '& .dateInput': {
      '& .Mui-disabled': {
        fontFamily:
        theme.typography.subtitle2_HelveticaNeue_Medium.fontFamily,
        fontSize: theme.spacing(14),
        backgroundColor: theme.palette.light.main,
        color: theme.palette.text.secondary,
        opacity: 0.8,
        cursor: 'pointer',
        '&::placeholder': {
          color: theme.palette.placeholder__Input.main,
        },
      },
    },
    '& .dateRange': {
      position: 'absolute',
      top: '44px',
      width: '100%',
      zIndex: '2',
      border: `1px solid ${theme.palette.border.main}`,
      boxShadow: `0px 3px 34px ${theme.palette.app_shadow.container}`,

      overflow: 'hidden',
      borderRadius: theme.spacing(10),
      '& .rdrDateDisplayWrapper': {
        display: 'none',
      },
      '& .rdrDateRangeWrapper': {
        '& .rdrMonth': {
          width: '100%',
          padding: 0,
        },
        '& .rdrWeekDay': {
          color: theme.palette.primary.dark,
          fontSize: theme.spacing(10),
          fontFamily: theme.typography.subtitle2_Montserrat_Bold.fontFamily,
        },
      },
      '& .rdrDay': {
        '& .rdrStartEdge': {
          borderTopLeftRadius: theme.spacing(10),
          borderBottomLeftRadius: theme.spacing(10),
        },
        '& .rdrStartEdge ,& .rdrEndEdge': {
          '& ~ .rdrDayNumber': {
            background: theme.palette.primary.main,
            borderRadius: theme.spacing(10),
            '&  span': {
              color: theme.palette.light.main,
            },
          },
        },
        '& .rdrDayNumber span': {
          color: theme.palette.dark.main,
          fontSize: theme.typography.subtitle2_Montserrat_Medium.fontSize,
          fontFamily: theme.typography.subtitle2_Montserrat_Medium.fontFamily,
        },
        '&.rdrDayToday': {
          '& .rdrDayNumber': {
            '&  span': {
              '&:after': {
                background: theme.palette.primary.main,
              },
            },
          },
        },
      },

      '& .rdrNextPrevButton': {
        display: 'none',
      },
      '& .rdrMonthAndYearPickers': {
        gap: theme.spacing(10),
        '& .rdrMonthPicker ,& .rdrYearPicker': {
          position: 'relative',
          '&:before': {
            top: theme.spacing(6),
            left: theme.spacing(-17),
            width: theme.spacing(25),
            height: theme.spacing(25),
            content: "''",
            position: 'absolute',
            backgroundImage: `url("${DropdownCalender}")`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          },
          '& select': {
            color: theme.palette.primary.main,
            fontFamily: theme.typography.h4_Montserrat_SemiBold.fontFamily,
            fontSize: theme.spacing(15),
            background: `none`,
            padding: theme.spacing(10),
          },
        },
      },
    },

    '& .MuiInputBase-input': {
      transform: 'none',
      fontSize: 'initial',
      paddingBlock: theme.spacing(0),
      height: '30px',
    },
    '& fieldset': {
      top: `${theme.spacing(0)} `,
    },


    [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},

    [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},

    [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},

    [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},

    [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},

    [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {},

  })
)
