import React from 'react';
import { FormControl, InputLabel, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { CustomInput } from 'components/StyledComponents/FormInputs';
import IconButton from '@mui/material/IconButton';
import { CloseSearchIcon, SearchIcon } from 'assets/svgs/componentsIcons';

function FormInputSearch({
  handleClick,
  isSearched,
  label,
  labelText,
  onChange,
  haveCurrency,
  value,
  ...rest
}) {
  return (
    <Stack className="fomInput__wrapper">
      <FormControl className="inputs__label" variant="standard">
        <InputLabel className="select__label" shrink>
          <Typography variant="h5_HelveticaNeue_Regular">{labelText}</Typography>
        </InputLabel>
        <CustomInput
          type="text"
          label={label}
          placeholder={label}
          onChange={onChange}
          {...rest}
          value={value || ''}
          endAdornment={
            <IconButton aria-label="Search" color="primary" onClick={handleClick}>
              {isSearched ? <CloseSearchIcon /> : <SearchIcon />}
            </IconButton>
          }
        />
      </FormControl>
    </Stack>
  );
}

FormInputSearch.propTypes = {
  handleClick: PropTypes.func,
  label: PropTypes.string,
  labelText: PropTypes.string,
  haveCurrency: PropTypes.bool,
  onChange: PropTypes.func,
  isSearched: PropTypes.bool,
  value: PropTypes.string,
};

export default FormInputSearch;
