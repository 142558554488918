import React from "react";

const UsersIconGrey = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21.125" height="20" viewBox="0 0 21.125 20">
    <g id="Groupe_64714" data-name="Groupe 64714" transform="translate(3)">
      <path id="Tracé_67263" data-name="Tracé 67263" d="M0,0H21.125V20H0Z" transform="translate(-3)" fill="none" />
      <path id="Ellipse_3141" data-name="Ellipse 3141"
            d="M3.208-.75A3.958,3.958,0,1,1-.75,3.208,3.963,3.963,0,0,1,3.208-.75Zm0,6.667A2.708,2.708,0,1,0,.5,3.208,2.711,2.711,0,0,0,3.208,5.917Z"
            transform="translate(4.292 2.625)" fill="#3c485f" />
      <path id="Path_67264" data-name="Path 67264"
            d="M12.875,20.5a.625.625,0,0,1-.625-.625V18.208A2.711,2.711,0,0,0,9.542,15.5H6.208A2.711,2.711,0,0,0,3.5,18.208v1.667a.625.625,0,1,1-1.25,0V18.208A3.963,3.963,0,0,1,6.208,14.25H9.542A3.963,3.963,0,0,1,13.5,18.208v1.667A.625.625,0,0,1,12.875,20.5Z"
            transform="translate(-0.375 -2.375)" fill="#3c485f" />
      <g id="Groupe_64696" data-name="Groupe 64696" transform="translate(12.665 4.556)">
        <path id="Path_67265" data-name="Path 67265"
              d="M15.736,8.383a.487.487,0,0,1-.12-.958,2.109,2.109,0,0,0,0-4.086.487.487,0,0,1,.241-.943,3.083,3.083,0,0,1,0,5.972A.488.488,0,0,1,15.736,8.383Z"
              transform="translate(-15.25 -2.38)" fill="#3c485f" stroke="#3c485f" stroke-linecap="round"
              stroke-width="0.4" />
        <path id="Path_67266" data-name="Path 67266"
              d="M19.683,19.17a.487.487,0,0,1-.487-.487v-1.3a2.11,2.11,0,0,0-1.581-2.027.487.487,0,0,1,.243-.942,3.085,3.085,0,0,1,2.311,2.967v1.3A.487.487,0,0,1,19.683,19.17Z"
              transform="translate(-15.952 -6.6)" fill="#3c485f" stroke="#3c485f" stroke-linecap="round"
              stroke-width="0.4" />
      </g>
      <g id="Groupe_64697" data-name="Groupe 64697" transform="translate(-1.882 4.556)">
        <path id="Path_67265-2" data-name="Path 67265"
              d="M17.688,8.383a.487.487,0,0,0,.12-.958,2.109,2.109,0,0,1,0-4.087.487.487,0,0,0-.241-.943,3.083,3.083,0,0,0,0,5.973A.488.488,0,0,0,17.688,8.383Z"
              transform="translate(-13.957 -2.38)" fill="#3c485f" stroke="#3c485f" stroke-linecap="round"
              stroke-width="0.4" />
        <path id="Path_67266-2" data-name="Path 67266"
              d="M17.736,19.17a.487.487,0,0,0,.487-.487v-1.3A2.11,2.11,0,0,1,19.8,15.358a.487.487,0,0,0-.243-.943,3.085,3.085,0,0,0-2.311,2.967v1.3A.487.487,0,0,0,17.736,19.17Z"
              transform="translate(-17.25 -6.6)" fill="#3c485f" stroke="#3c485f" stroke-linecap="round"
              stroke-width="0.4" />
      </g>
    </g>
  </svg>
);
export default UsersIconGrey;
