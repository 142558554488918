import { styled } from '@mui/material';

const CustomMainButton = styled('button')`
  font-family: 'HelveticaNeueBold', sans-serif;
  font-size: 16px;
  color: ${(props) =>
    props.secondary
      ? props.theme.AppColors.blueViolet_100
      : props.theme.GreyColors.white};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border: 1px solid
    ${(props) => (props.secondary ? props.theme.GreyColors[1100] : 'transparent')};
  padding: 0 20px;
  min-height: 34px;
  border-radius: 8px;
  background: ${(props) =>
    // eslint-disable-next-line no-nested-ternary
    props.secondary
      ? props.theme.GreyColors.white
      : props.danger
      ? props.theme.AppColors.lightDanger
      : props.theme.palette.gradient_blue.main};
  cursor: pointer;
`;

export default CustomMainButton;
