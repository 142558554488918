import React from 'react';

const FilterSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.032"
    height="14.026"
    viewBox="0 0 21.032 14.026"
  >
    <g transform="translate(-952 -535)">
      <path
        className="iconListes"
        d="M960.183,549.026h4.673v-2.34h-4.673ZM952,535v2.34h21.032V535Zm3.5,8.183h14.026v-2.34H955.5Zm0,0"
        transform="translate(0)"
      />
    </g>
  </svg>
);

export default FilterSvg;
