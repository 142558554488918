import React from 'react';

const ExternalLogo2 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="146" height="146" viewBox="0 0 146 146">
    <defs>
      <filter id="Ellipse_1312" x="0" y="0" width="146" height="146" filterUnits="userSpaceOnUse">
        <feOffset input="SourceAlpha"/>
        <feGaussianBlur stdDeviation="10" result="blur"/>
        <feFlood flood-color="#1e2769" flood-opacity="0.09"/>
        <feComposite operator="in" in2="blur"/>
        <feComposite in="SourceGraphic"/>
      </filter>
    </defs>
    <g id="Group_63883" data-name="Group 63883" transform="translate(-1051 -347)">
      <g id="Group_63829" data-name="Group 63829" transform="translate(950.371 26.917)">
        <g transform="matrix(1, 0, 0, 1, 100.63, 320.08)" filter="url(#Ellipse_1312)">
          <circle id="Ellipse_1312-2" data-name="Ellipse 1312" cx="43" cy="43" r="43" transform="translate(30 30)"
                  fill="#fcfcff"/>
        </g>
      </g>
      <g id="Iconly_Light-Outline_3-User" data-name="Iconly/Light-Outline/3-User"
         transform="translate(1104.702 401.89)">
        <g id="_3-User" data-name="3-User" transform="translate(1 4)">
          <path id="Combined-Shape"
                d="M18.3,17.5h.614c3.245.021,10.66.349,10.66,5.375,0,4.993-7.157,5.319-10.61,5.34H17.684c-3.245-.021-10.662-.349-10.662-5.369,0-5,7.417-5.326,10.662-5.347H18.3Zm0,2.521c-3.994,0-8.755.491-8.755,2.827,0,2.283,4.477,2.817,8.39,2.848H18.3c3.994,0,8.754-.489,8.754-2.82C27.052,20.521,22.292,20.025,18.3,20.025Zm13.368-3.2c4.073.61,4.928,2.523,4.928,4.02,0,.914-.36,2.583-2.763,3.5a1.275,1.275,0,0,1-.449.082,1.261,1.261,0,0,1-.449-2.439c1.14-.434,1.14-.913,1.14-1.143,0-.736-.936-1.251-2.782-1.526a1.261,1.261,0,1,1,.375-2.494Zm-25.3,1.059A1.264,1.264,0,0,1,5.3,19.316c-1.846.276-2.782.79-2.782,1.526,0,.23,0,.708,1.141,1.143a1.261,1.261,0,0,1-.449,2.439,1.275,1.275,0,0,1-.449-.082C.36,23.424,0,21.755,0,20.842c0-1.5.856-3.41,4.93-4.02A1.269,1.269,0,0,1,6.364,17.881ZM18.3,0a7.68,7.68,0,1,1,0,15.359h-.045A7.655,7.655,0,0,1,10.62,7.675,7.687,7.687,0,0,1,18.3,0Zm0,2.521A5.163,5.163,0,0,0,13.142,7.68a5.133,5.133,0,0,0,5.115,5.158L18.3,14.1V12.838a5.158,5.158,0,1,0,0-10.317Zm10.4-.875a6.087,6.087,0,0,1-.143,12.035,1.238,1.238,0,0,1-.175.012,1.261,1.261,0,0,1-.173-2.509,3.565,3.565,0,0,0,.081-7.049,1.261,1.261,0,1,1,.41-2.488ZM9.345,2.685A1.261,1.261,0,0,1,8.306,4.134a3.565,3.565,0,0,0,.079,7.049,1.261,1.261,0,0,1-.173,2.509,1.238,1.238,0,0,1-.175-.012A6.087,6.087,0,0,1,7.9,1.646,1.254,1.254,0,0,1,9.345,2.685Z"
                transform="translate(0 0)" fill="#00338d" fill-rule="evenodd"/>
        </g>
      </g>
    </g>
  </svg>
);

export default ExternalLogo2;
