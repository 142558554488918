import { styled } from "@mui/material";

const AppBody = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 30px;
  flex-grow: 1;
  flex-basis: 0;
  padding: 48px 56px 34px 56px;
  overflow: auto;
`;

export default AppBody;
