import React from 'react';

function PlusIcon(props) {
  return (
    <svg
      data-name="Group 63982"
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      {...props}
    >
      <path d="M0 0h16v16H0Z" fill="none" />
      <path
        id="plus"
        d="M7.435 11.431V8.567H4.57c-.472 0-.858-.255-.858-.566s.387-.566.858-.566h2.865V4.571c0-.476.255-.858.565-.858s.563.383.563.858v2.864h2.868c.473 0 .859.256.859.566s-.387.566-.859.566H8.563v2.864c0 .473-.251.855-.562.855s-.566-.383-.566-.855Z"
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={0.5}
      />
    </svg>
  );
}

export default PlusIcon;
