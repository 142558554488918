import {createSlice} from '@reduxjs/toolkit';
import type {RootState} from "store";

interface UserProjectProfileSliceState {
  updatedItems: any[];
  assignManyProjectsToUserSuccess: boolean,
  selectedPlatform: string
}

const initialState: UserProjectProfileSliceState = {
  updatedItems: [],
  assignManyProjectsToUserSuccess: false,
  selectedPlatform: '',
};
/* eslint-disable no-param-reassign */
const userProjectProfile = createSlice({
  name: 'userProjectProfile',
  initialState,
  reducers: {
    addUpdatedItems: (state, {payload: item}) => {
      state.updatedItems.push(item);
    },
    removeUpdatedItem: (state, {payload: item}) => {
      state.updatedItems = state.updatedItems.filter(i => i !== item);
    },
    removeAllUpdatedItems: (state) => {
      state.updatedItems = [];
    },
    setAssignManyProjectsToUserSuccess: (state, {payload: assignManyProjectsToUserSuccess}) => {
      state.assignManyProjectsToUserSuccess = assignManyProjectsToUserSuccess;
    },
    setSelectedPlatform: (state, {payload: selectedPlatform}) => {
      state.selectedPlatform = selectedPlatform;
    },
    cleanManyAssignmentInfos: (state) => {
      state.selectedPlatform = initialState.selectedPlatform;
      state.assignManyProjectsToUserSuccess = initialState.assignManyProjectsToUserSuccess;
    },
    resetUserProjectProfile: () => initialState,
  },
});

export const {
  addUpdatedItems,
  removeUpdatedItem,
  removeAllUpdatedItems,
  setAssignManyProjectsToUserSuccess,
  setSelectedPlatform,
  cleanManyAssignmentInfos
} = userProjectProfile.actions;

export const selectUserProjectProfileDomain = (state: RootState): any =>
  state.userManagement.userProjectProfile;

export default userProjectProfile.reducer;

