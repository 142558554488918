import { Features } from 'utils/constants/Features';

export const SHARED_ENTITIES = {
  DISCIPLINE: {
    label: 'Discipline',
    entityName: 'Discipline',
    code: 'DISCIPLINE',
    feature: Features.DS_PROJECT_DISCIPLINE,
  },
  CONTRACTOR: {
    label: 'Contractor',
    entityName: 'Company',
    code: 'CONTRACTOR',
    feature: Features.DS_PROJECT_CONTRACTOR_SUB_CONTRACTOR,
  },
  SUB_CONTRACTOR: {
    label: 'Sub contractor',
    entityName: 'Subcontractor',
    code: 'SUB_CONTRACTOR',
    feature: Features.DS_PROJECT_CONTRACTOR_SUB_CONTRACTOR,
  },
  VENDOR: {
    label: 'Vendor',
    entityName: 'Vendor',
    code: 'VENDOR',
    feature: Features.DS_PROJECT_VENDOR,
  },
};

export const PROJECT_WORK_DIMENSIONS_TAB_NAME = {
  WITH_WORK_LOCATIONS: {
    ...SHARED_ENTITIES,
    WORK_LOCATION: {
      label: 'Work locations',
      entityName: 'Work locations',
      code: 'WORK_LOCATION',
      feature: Features.DS_PROJECT_WORK_LOCATION,
    },
  },
  WITHOUT_WORK_LOCATIONS: {
    ...SHARED_ENTITIES,
    AREA: {
      label: 'Area',
      entityName: 'Area',
      code: 'AREA',
      feature: Features.DS_PROJECT_AREA_SYSTEM,
    },
    SYSTEM: {
      label: 'System',
      entityName: 'System',
      code: 'SYSTEM',
      feature: Features.DS_PROJECT_AREA_SYSTEM,
    },
  },
};

export const PROJECT_WORK_DIMENSIONS_ALL_TABS = {
  ...PROJECT_WORK_DIMENSIONS_TAB_NAME.WITH_WORK_LOCATIONS,
  ...PROJECT_WORK_DIMENSIONS_TAB_NAME.WITHOUT_WORK_LOCATIONS,
};

export const requiredFieldError = 'This field is required';
export const emptyListError = (entityName: string): any =>
  `The list of ${entityName} must contain at least 1 record `;
export const duplicatedNameError = (entityName: string): any =>
  `${entityName} with the same name exists on the list, please choose another one`;
export const duplicatedNumberError = `duplicated number`;
export const NAME_FIELD = {
  NUMBER: 'number',
  NAME: 'name',
};
