import {Stack, styled} from "@mui/material";

export const ProjectAdministrationUserManagementWrapper = styled(Stack)(
  ({theme}) => ({

    backgroundColor: theme.palette.appBackground.main,
    display: 'flex',
    height: '100vh',
    width: '100%',

    '& .filterWrapper': {
      minHeight: "unset",
    },
    '& .filter__inputs--container': {
      flexFlow: 'row wrap',
      gap: '40px',
      '& > *': {
        width: 'calc((100% / 3) - 40px)',
        minHeight: 'unset',
        maxHeight: '40px',
      },
    },
    '& .close': {
      width: '10px',
      height: '10px',
    },


    [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},

    [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},

    [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},

    [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},

    [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},

    [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {},

  })
)
