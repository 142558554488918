import {Outlet} from "react-router-dom";
import ProfileSettingsMenu from "./ProfileSettingsMenu";
import {AppProjectsSettingsWrapper} from "../AppProjectsSettings/Wrapper";

const AppProfileSettings = () => {

  return (
    <AppProjectsSettingsWrapper>
      <ProfileSettingsMenu/>
      <Outlet/>
    </AppProjectsSettingsWrapper>
  );
};

export default AppProfileSettings;
export {default as AccessManagementProfile} from "../AccessManagementProfile/AccessManagementProfile";
export {
  default as AccessManagementProfilePrivilege
} from "../AccessManagementProfile/AccessManagementProfilePrivilege";

