import React from "react";

const ProjectDetailsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26.542"
    height="26.542"
    viewBox="0 0 26.542 26.542"
  >
    <g
      id="Groupe_3794"
      data-name="Groupe 3794"
      transform="translate(-719.75 -299.75)"
    >
      <path
        id="Tracé_11881"
        data-name="Tracé 11881"
        d="M733.021,326.042a13.021,13.021,0,1,1,13.021-13.021,13.021,13.021,0,0,1-13.021,13.021Zm0-25.174a12.153,12.153,0,1,0,12.153,12.153,12.153,12.153,0,0,0-12.153-12.153Zm0,0"
        transform="translate(0 0)"
        fill="#00338d"
        stroke="#00338d"
        strokeWidth="0.5"
      />
      <path
        id="Tracé_11882"
        data-name="Tracé 11882"
        d="M914.6,497.208a2.6,2.6,0,1,1,2.6-2.6,2.6,2.6,0,0,1-2.6,2.6Zm0-4.34a1.736,1.736,0,1,0,1.736,1.736,1.736,1.736,0,0,0-1.736-1.736Zm0,0"
        transform="translate(-181.583 -181.583)"
        fill="#00338d"
        stroke="#00338d"
        strokeWidth="0.5"
      />
      <path
        id="Tracé_11883"
        data-name="Tracé 11883"
        d="M1050.6,497.208a2.6,2.6,0,1,1,2.6-2.6,2.6,2.6,0,0,1-2.6,2.6Zm0-4.34a1.736,1.736,0,1,0,1.736,1.736,1.736,1.736,0,0,0-1.736-1.736Zm0,0"
        transform="translate(-310.205 -181.583)"
        fill="#00338d"
        stroke="#00338d"
        strokeWidth="0.5"
      />
      <path
        id="Tracé_11884"
        data-name="Tracé 11884"
        d="M778.6,497.208a2.6,2.6,0,1,1,2.6-2.6,2.6,2.6,0,0,1-2.6,2.6Zm0-4.34a1.736,1.736,0,1,0,1.736,1.736,1.736,1.736,0,0,0-1.736-1.736Zm0,0"
        transform="translate(-52.962 -181.583)"
        fill="#00338d"
        stroke="#00338d"
        strokeWidth="0.5"
      />
    </g>
  </svg>
);
export default ProjectDetailsIcon;
