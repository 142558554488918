import {styled} from "@mui/material";
import Timeline from "@mui/lab/Timeline";

export const TimelineMenuWrapper = styled(Timeline)(
  ({theme}) => ({

    color: theme.palette.light.main,


    '& .timelineDot': {
      backgroundColor: theme.palette.light.main,
      width: '9px',
      height: '9px',
      margin: '0',
      borderRadius: '50%',
      position: 'absolute',
      top: '0',
      zIndex: '1',
      left: '0',
      transform: 'translate(-4.5px, -4.5px)',

      '&.selected': {
        boxShadow: '0px 0px 0px 10px rgba(255,255,255,0.43)',
      },
    },

    '& .timelineConnector': {
      height: '133px',
      width: '9px',
      marginLeft: '1px',
      background: theme.palette.timeline.main,
      opacity: '0.53',
    },

    '& .timelineContent': {
      padding: '0 16px',
      marginLeft: '15px',
      position: 'absolute',
      top: '-15px',
    },


    [`@media (max-width:${theme.breakpoints.values.xs}px)`]: {},

    [`@media (max-width:${theme.breakpoints.values.sm}px)`]: {},

    [`@media (max-width:${theme.breakpoints.values.md}px)`]: {},

    [`@media (max-width:${theme.breakpoints.values.lg}px)`]: {},

    [`@media (max-width:${theme.breakpoints.values.xl}px)`]: {},

    [`@media (min-width:${theme.breakpoints.values.xl}px)`]: {},

  })
)
