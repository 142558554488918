import { api } from '.';

const platformBaseUrl = '/platforms';

export enum PlatformCategoriesEnum {
  COLLABORATION_APPS = 'COLLABORATION_APPS',
  EXTERNAL_APPS = 'EXTERNAL_APPS',
  OPERATIONAL_APPS = 'OPERATIONAL_APPS',
}

export enum PlatformEnum {
  COLLAB = 'COLLAB',
  JPASS = 'JPASS',
  ESP = 'ESP',
  DIGITAL_SPACE = 'DIGITAL_SPACE',
}

export interface Platform {
  id: number;
  code: string;
  name: string;
  description: string;
  link: string;
  iconName: string;
  parentPlatform: Platform;
  subPlatforms: Platform[];
  category: PlatformCategoriesEnum;
}

export const platformApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPlatforms: builder.query<Platform[], { idParent?: number }>({
      query: (params) => {
        const queryParams = params != null ? { params } : {};
        return {
          url: `${platformBaseUrl}/list`,
          ...queryParams,
        };
      },
    }),
    getPlatformsByCodes: builder.query<Platform[], string[]>({
      query: (platformCode) => ({
        url: `${platformBaseUrl}/by-codes`,
        params: { platformCode },
      }),
    }),
    getPlatformsByProjectId: builder.query<Platform[], number>({
      query: (projectId) => `${platformBaseUrl}/${projectId}`,
    }),
    downloadApkByPlatform: builder.query<any, { platform: string }>({
      query: (params) => ({
        url: `${platformBaseUrl}/download-apk`,
        responseType: 'blob',
        timeout: 600000,
        cache: 'no-cache',
        params,
      }),
    }),
  }),
});

export const {
  useGetPlatformsQuery,
  useLazyGetPlatformsByProjectIdQuery,
  useGetPlatformsByCodesQuery,
  useLazyDownloadApkByPlatformQuery,
} = platformApi;
