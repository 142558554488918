import React from 'react';
import { AppButton } from 'components/Buttons';
import { Stack } from '@mui/material';
import { SettingsBtnSvg } from 'assets/svgs/componentsIcons';
import { useNavigate } from 'react-router-dom';
import { SETTINGS_PROJECTS } from 'routes/constants';

function ProgramSettingsBtn() {
  const navigate = useNavigate();

  return (
    <Stack className="settings--btn flex__row--center">
      <AppButton
        customClass="settings"
        onClick={() => {
          navigate(SETTINGS_PROJECTS);
        }}
        startIcon={<SettingsBtnSvg />}
      />
    </Stack>
  );
}

export default ProgramSettingsBtn;
