import React from 'react';
import PropTypes from 'prop-types';

const FileUploadSvg = ({ id }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="51"
    height="51"
    viewBox="0 0 51 51"
  >
    <defs>
      <clipPath id={`clip-path_${id}`}>
        <rect
          id="Rectangle_17444"
          data-name="Rectangle 17444"
          width="51"
          height="51"
          transform="translate(748 475)"
          fill="#fff"
        />
      </clipPath>
    </defs>
    <g
      id="Upload"
      transform="translate(-748 -475)"
      clipPath={`url(#clip-path_${id})`}
    >
      <g
        id="Groupe_1106"
        data-name="Groupe 1106"
        transform="translate(752.491 481)"
        opacity="0.2"
      >
        <g id="Groupe_1105" data-name="Groupe 1105">
          <path
            id="Tracé_9313"
            data-name="Tracé 9313"
            d="M738.469,318.746a13.354,13.354,0,0,0-26.191-.008,9.348,9.348,0,0,0,1.077,18.634h6.677a1.335,1.335,0,1,0,0-2.671h-6.677a6.677,6.677,0,1,1,.079-13.354,1.335,1.335,0,0,0,1.327-1.188,10.683,10.683,0,0,1,21.224,0,1.415,1.415,0,0,0,1.406,1.188,6.677,6.677,0,1,1,0,13.354h-6.677a1.335,1.335,0,1,0,0,2.671h6.677a9.348,9.348,0,0,0,1.078-18.626Zm0,0"
            transform="translate(-703.999 -308)"
            fill="#5f6a7e"
          />
        </g>
        <path
          id="Tracé_9314"
          data-name="Tracé 9314"
          d="M887.96,508.1a1.335,1.335,0,0,0,1.921-1.855l-.033-.033-5.663-5.665a1.336,1.336,0,0,0-1.889,0l0,0-5.664,5.665a1.335,1.335,0,1,0,1.855,1.921l.033-.033,3.385-3.387v16.807a1.335,1.335,0,1,0,2.67,0V504.714Zm0,0"
          transform="translate(-861.866 -484.137)"
          fill="#5f6a7e"
        />
      </g>
    </g>
  </svg>
);

FileUploadSvg.propTypes = {
  id: PropTypes.string,
};

export default FileUploadSvg;
