import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Avatar, IconButton, Stack, Typography} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';
import StatusTag from 'components/StatusTag';
import {USER_TYPE} from 'containers/ProjectAdministrationUserManagement/constants';
import UserManagementOption
  from 'containers/ProjectAdministrationUserManagement/UserManagementItems/UserManagementItem/UserManagementOption';
import {useGetUserAvatarByIdQuery} from 'services/apis/user';
import {fillUpdatedUserForm, setFreezeForm,} from 'services/slices/user-management/user-management-option';
import {formatDate} from 'utils/dateUtils';

function UserManagementItem({user, userDetails}) {
  const {data: avatar} = useGetUserAvatarByIdQuery(user?.id);
  const dispatch = useDispatch();
  const [openElem, setOpenElem] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [userAvatar, setUserAvatar] = useState('');
  const handleClose = () => {
    setAnchorEl(null);
    setOpenElem(null);
  };
  const handleClick = (e, id) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setOpenElem(id);
    dispatch(
      setFreezeForm({
        azureDirectoryId: user.azureDirectoryId,
        frozen: user.frozen,
      }),
    );
  };
  const handelDisplayName = () => {
    if (user?.firstName && user?.lastName) {
      return `${user?.firstName} ${user?.lastName}`;
    }
    const nameParts = user?.mail?.split('@')?.[0]?.split('.');
    if (nameParts?.[0] && nameParts?.[1]) {
      return `${nameParts?.[0]} ${nameParts?.[1]}`;
    }
    return nameParts?.[0] || '';
  };

  useEffect(() => {
    if (avatar) {
      setUserAvatar(URL.createObjectURL(avatar));
    }
  }, [avatar]);

  return (
    <Stack className="listing_item_row user__item">
      <Stack
        onClick={() => userDetails(user?.id, user?.external)}
        className="pointer"
        gap={10}
        direction="row"
        alignItems="center"
        justifyItems="center"
      >
        <Avatar alt={user?.firstName} src={userAvatar}/>
        <Typography variant="subtitle1_HelveticaNeue_Medium" color="text.main">
          {handelDisplayName()}
        </Typography>
      </Stack>
      <Stack>
        <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.gray">
          {formatDate(user?.creationDate)}
        </Typography>
      </Stack>
      <Stack>
        <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.gray">
          {user?.jobTitle}
        </Typography>
      </Stack>
      <Stack>
        <Typography variant="subtitle1_HelveticaNeue_Medium" color="text.gray">
          {user?.external ? USER_TYPE.EXTERNAL.label : USER_TYPE.INTERNAL.label}
        </Typography>
      </Stack>
      <Stack>
        <Typography variant="subtitle1_HelveticaNeue_Medium" color="text.gray">
          {user?.specificRole?.label}
        </Typography>
      </Stack>
      <Stack>
        <StatusTag isActive={!user?.frozen}/>
      </Stack>
      <Stack>
        <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.gray">
          {formatDate(user?.updateDate)}
        </Typography>
      </Stack>
      <IconButton
        className="button-options"
        aria-label="more"
        id="long-button"
        aria-haspopup="true"
        onClick={(e) => {
          handleClick(e, user.id);
          dispatch(fillUpdatedUserForm(user));
        }}
      >
        <MoreVertIcon/>
      </IconButton>

      <UserManagementOption
        openElem={openElem}
        anchorEl={anchorEl}
        handleClose={handleClose}
      />
    </Stack>
  );
}

UserManagementItem.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    azureDirectoryId: PropTypes.string,
    frozen: PropTypes.bool,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    mail: PropTypes.string,
    external: PropTypes.bool,
    jobTitle: PropTypes.string,
    specificRole: PropTypes.string,
    creationDate: PropTypes.string,
    updateDate: PropTypes.string,
  }),
  userDetails: PropTypes.func,
};

export default UserManagementItem;
