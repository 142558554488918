import React from "react";

const DiscussionIcon = () => (
  <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 24" width="28" height="24">
    <title>iconDiscussion</title>
    <defs>
      <image width="28" height="23" id="img4"
             href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAXCAMAAAAvFN9lAAAAAXNSR0IB2cksfwAAAEtQTFRFAAAAAID/AHz/AHz/AHv/AHz/AHv/AHz/AHj/AHz/AHz/AHv/AHz/AH3/AHv/AHv/AHv/AHr/AHv/AHz/AHr/AHv/AHz/AHz/AHz/0SIUTgAAABl0Uk5TABC//99AcMAggH+fUD+w8OAwoO9gz9CQrw/VHB0AAADUSURBVHicdZHbdoQgDEVPuENVBAfG///SAtYWpLOfCDskWQT4gxjvEbJzUNr0oRVDZDHAQY7dCMG+FkCujSr1Znv8YvSFKZITPpXm2PGQIWKvw9aXeEoYGCll+PcluQuq007yZ7Qqt6nsjeHPrpY5V9phcY7FSWobD8LLR65okqVsTMoHWcXu1rUNdf1aUuWoeUCTIXN+lC6xrcnHmkcllnfJmOid87iTX4kofNCfJN4B2yiXPKYydpbR6GzLPV5DrlF2S0SpLVgFTMR06vn2RuduE98MrQjxpIw3ZwAAAABJRU5ErkJggg==" />
    </defs>
    <style>
    </style>
    <use id="Background" href="#img4" x="0" y="0" />
  </svg>
);
export default DiscussionIcon;
