import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  Typography,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import ChevronDownSvg from 'assets/svgs/ChevronDownSvg';
import PropTypes from 'prop-types';
import { SelectWrapper } from './Wrapper';

function FormSelect({
  menuItems = [],
  label,
  value,
  onChange,
  customClassName,
  valueKey = 'label',
  defaultSelectValue = {},
  labelColor = 'text.secondary',
  isValid = true,
  error,
  disabled,
  multiple,
  menuHeight = 'unset',
}) {
  const customizedStyleObj = {
    content: `"${label}"`,
    opacity: 0.42,
    fontFamily: 'HelveticaNeueMedium',
    fontSize: '14px',
    color: '#5F6A7E',
    textTransform: 'capitalize',
  };
  return (
    <SelectWrapper className={customClassName} isValid={isValid}>
      <FormControl
        className="inputs__label"
        size="small"
        disabled={disabled}
        error={error}
      >
        <InputLabel className="select__label" shrink>
          <Typography color={labelColor} variant="h5_HelveticaNeue_Regular">
            {label}
          </Typography>
        </InputLabel>
        <Select
          value={value || defaultSelectValue}
          isValid={isValid && !error}
          onChange={onChange}
          IconComponent={ChevronDownSvg}
          inputProps={{ 'aria-label': 'select' }}
          sx={{
            '& .MuiSelect-select .notranslate::after': !label ? {} : customizedStyleObj,
          }}
          multiple={multiple}
          MenuProps={{ style: { maxHeight: menuHeight } }}
        >
          {menuItems?.map((item) => (
            <MenuItem key={item.id} value={item || []}>
              <Typography color="text.secondary" variant="subtitle1_HelveticaNeue_Medium">
                {item?.[valueKey] || item}{' '}
              </Typography>
            </MenuItem>
          ))}
        </Select>
        {(error || !isValid) && (
          <FormHelperText className="errorMsg">This field is required</FormHelperText>
        )}
      </FormControl>
    </SelectWrapper>
  );
}

FormSelect.propTypes = {
  customClassName: PropTypes.string,
  label: PropTypes.string,
  menuItems: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  menuHeight: PropTypes.string,
  onChange: PropTypes.func,
  valueKey: PropTypes.string,
  defaultSelectValue: PropTypes.string,
  error: PropTypes.bool,
  isValid: PropTypes.bool,
  labelColor: PropTypes.string,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
};

export default FormSelect;
