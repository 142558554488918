import { PLATFORM_SETTINGS_TYPES } from 'helpers/constants';

export const OPERATIONS = {
  ADD: 'ADD',
  DELETE: 'DELETE',
  EDIT: 'EDIT',
  SUCCESS_DELETE: 'SUCCESS_DELETE',
  SUCCESS_ADD: 'SUCCESS_ADD',
  ERROR_DELETE: 'ERROR_DELETE',
};

export const PLATFORM_SETTINGS_TYPE = {
  PROGRAM: {
    code: PLATFORM_SETTINGS_TYPES.PROGRAM,
    label: 'Program',
    baseRoute: '/programs',
  },
  SECTOR: {
    code: PLATFORM_SETTINGS_TYPES.SECTOR,
    label: 'Sector',
    baseRoute: '/sectors',
  },
  BU: {
    code: PLATFORM_SETTINGS_TYPES.BU,
    label: 'Business Unit',
    baseRoute: '/bus',
  },
  CLIENT: {
    code: PLATFORM_SETTINGS_TYPES.CLIENT,
    label: 'Client',
    baseRoute: '/clients',
  },
  DISCIPLINE: {
    code: PLATFORM_SETTINGS_TYPES.DISCIPLINE,
    label: 'Engineering Discipline',
    baseRoute: '/disciplines',
  },
  VENDOR: {
    code: PLATFORM_SETTINGS_TYPES.VENDOR,
    label: 'Vendor',
    baseRoute: '/vendor',
  },
  CONTRACTOR: {
    code: PLATFORM_SETTINGS_TYPES.CONTRACTOR,
    label: 'Contractor',
    baseRoute: '/contractor',
  },
  SUB_CONTRACTOR: {
    code: PLATFORM_SETTINGS_TYPES.CONTRACTOR,
    label: 'SubContractor',
    baseRoute: '/sub-contractor',
  },
};
export const EMAIL_ERRORS = {
  EMAIL_ALREADY_EXISTS: 'Email already exists',
  INVALID_EMAIL: 'Please enter a valid email address',
};
