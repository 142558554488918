import { api } from '.';

const portfolioBaseUrl = '/portfolios';

export const portfolioApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPortfoliosByProgramCode: builder.query<any, number>({
      query: (programCode) => ({
        url: `${portfolioBaseUrl}/by-program-code`,
        params: { programCode },
      }),
    }),
  }),
});

export const { useLazyGetPortfoliosByProgramCodeQuery } = portfolioApi;
