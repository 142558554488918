import {
  duplicatedNameError,
  duplicatedNumberError,
  NAME_FIELD,
  requiredFieldError,
} from './constants';

export const searchDuplicatedName = (
  projectDatas: any[],
  setDuplicatedName: any,
): boolean => {
  const encounteredNames = new Set();
  const duplicatedNames = projectDatas
    ?.map((dp) => dp.name.toLowerCase())
    .filter((name) => {
      if (encounteredNames.has(name)) {
        return true; // Name is duplicated
      }
      encounteredNames.add(name);
      return false;
    });
  if (duplicatedNames.length > 0) {
    const formatter = new Intl.ListFormat('en', { style: 'narrow', type: 'unit' });
    setDuplicatedName(`duplicated name : ${formatter.format(duplicatedNames)}`);
    return true;
  }
  return false;
};

export const checkDuplicated = (
  list: any[],
  element: any,
  nameField: string,
): boolean => {
  if (element?.index >= 0) {
    return !(
      list?.findIndex(
        (item, index) =>
          ((element.id === undefined && item?.id === undefined) ||
            element.id !== item?.id) &&
          index !== element.index &&
          item?.[nameField] !== undefined &&
          item?.[nameField] !== null &&
          item?.[nameField].length !== 0 &&
          item?.[nameField]?.toLowerCase() === element[nameField]?.trim()?.toLowerCase(),
      ) >= 0
    );
  }
  return !(
    list?.findIndex(
      (item) =>
        item?.[nameField] !== undefined &&
        item?.[nameField] !== null &&
        item?.[nameField].length !== 0 &&
        item?.[nameField]?.toLowerCase() === element?.[nameField]?.trim()?.toLowerCase(),
    ) >= 0
  );
};

export const addProjectDatasToList = (
  projectDatas: any[],
  projectData: any,
  projectId: any,
): any[] => {
  const updatedProjectData: any = {
    ...projectData,
    name: projectData?.name?.trim(),
    number: projectData?.number?.trim(),
  };
  if (projectData?.index >= 0) {
    return [
      ...projectDatas.slice(0, projectData?.index),
      updatedProjectData,
      ...projectDatas.slice(parseInt(projectData?.index, 10) + 1),
    ];
  }
  return [...projectDatas, { ...updatedProjectData, projectId }];
};
export const prepareFormatData = (workLocations: any[], pictures: any[]): FormData => {
  const formData = new FormData();
  const workLocationBlob = new Blob(
    [
      JSON.stringify({
        workLocations,
      }),
    ],
    {
      type: 'application/json',
    },
  );
  formData.append('workLocationWrapper', workLocationBlob);
  if (pictures?.length > 0) {
    pictures?.forEach((picture) => {
      formData.append('pictures', picture.file, picture.fileName);
    });
  }
  return formData;
};
export const existNewDataOrUpdated = (projectDatas: any[]): boolean =>
  projectDatas?.filter(
    (dp) =>
      dp?.id === undefined ||
      dp?.id === null ||
      dp.index !== undefined ||
      dp.isDeleted === true,
  )?.length > 0;

export const checkFieldNameValidation = (
  submit: boolean,
  projectDatas: any[],
  projectData: any,
  entityName: string,
): string | boolean => {
  if (submit) {
    if (
      projectData?.name === undefined ||
      projectData?.name === null ||
      projectData?.name?.trim().length === 0
    ) {
      return requiredFieldError;
    }
    if (!checkDuplicated(projectDatas, projectData, NAME_FIELD.NAME)) {
      return duplicatedNameError(entityName);
    }
  }
  return false;
};

export const checkFieldNumberValidation = (
  submit: boolean,
  projectDatas: any[],
  projectData: any,
): string | boolean => {
  if (submit && !checkDuplicated(projectDatas, projectData, NAME_FIELD.NUMBER)) {
    return duplicatedNumberError;
  }
  return false;
};
export const areListsNotEqual = (list1: any[], list2: any[]): any =>
  list1?.length !== list2?.length ||
  list1.some(
    (item1, index) =>
      item1?.id !== list2[index]?.id ||
      item1?.name !== list2[index]?.name ||
      item1?.flagDeleted !== list2[index]?.flagDeleted,
  );
